import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, Pair, WETH, Fraction } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { CROB, CRX, NATIVE_CRO, ORC, UNI, USDC, USDT, XCRX } from '../../constants'
import { REWARDS_V2_INTERFACE } from '../../constants/abis/rewards-v2'
import { CRXILLION, SPACE_CRXILLIONS } from '../../constants/collections'
import { useActiveWeb3React } from '../../hooks'
import { useRewardsV2Contract } from '../../hooks/useContract'
import { useBlockNumber } from '../application/hooks'
import { useAvgBlockTimeInSeconds } from '../mhub/helper'
import { useMultipleContractSingleData, useSingleCallResult } from '../multicall/hooks'
import { CRXILLION_INFO, ProjectInfo, SPACE_CRXILLION_INFO } from '../nftStake/projects'
import { LP_STATUS_TYPES } from '../stake/hooks'
import { tryParseAmount } from '../swap/hooks'


export function useServiceFee(contractAddress: string, tokenCount: number | undefined): TokenAmount | undefined{
    const rewardContract = useRewardsV2Contract(contractAddress)
    const serviceFeeRes = useSingleCallResult(rewardContract, 'serviceFee', [tokenCount])
    const serviceFee = (!serviceFeeRes?.loading && serviceFeeRes?.result ) ? new TokenAmount(NATIVE_CRO, serviceFeeRes?.result[0]) : undefined
    return serviceFee
}



// TODO add staking rewards addresses here
export const LP_REWARDS_V2_INFO: {
    [chainId in ChainId]?: {
        collection: Token
        projectInfo: ProjectInfo
        tokens: [Token, Token]
        stakingRewardAddress: string
        rewardToken: Token
    }[]
} = {
    [ChainId.CRONOSMAINNET]: [
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            tokens: [XCRX, WETH[ChainId.CRONOSMAINNET]],
            stakingRewardAddress: '0xd1828CB74fd2C517DD9688BF2c6794009bc6c942',
            rewardToken: XCRX,
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
            stakingRewardAddress: '0xB5752de558687165ee86B7B72C2Bb3c3C05D4c50',
            rewardToken: XCRX,
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            tokens: [USDC, WETH[ChainId.CRONOSMAINNET]],
            stakingRewardAddress: '0x8E4bcb702Baf32E0FFe4E23D8583e31DB761D2c0',
            rewardToken: XCRX,
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            tokens: [USDC, USDT],
            stakingRewardAddress: '0x7e2f0Befaf06A24913C0703Af46937Ff06fFdaFC',
            rewardToken: XCRX,
        },
        {
            collection: SPACE_CRXILLIONS,
            projectInfo: SPACE_CRXILLION_INFO,
            tokens: [CROB, WETH[ChainId.CRONOSMAINNET]],
            stakingRewardAddress: '0x74b990188cAe549036cAE877B03737ed9A7b39a9',
            rewardToken: XCRX,
        },
        {
            collection: SPACE_CRXILLIONS,
            projectInfo: SPACE_CRXILLION_INFO,
            tokens: [ORC, WETH[ChainId.CRONOSMAINNET]],
            stakingRewardAddress: '0x75803F19A745c4c15d7F4812AAA348093Ae14D94',
            rewardToken: XCRX,
        },
        // {
        //     collection: CRXILLION,
        //     projectInfo: CRXILLION_INFO,
        //     tokens: [WETH[ChainId.CRONOSMAINNET], USDT],
        //     stakingRewardAddress: '0x5F0e3C0410c6261CE1A3B4503B6B72c8a964B45F',
        //     rewardToken: XCRX,
        // },
    ]
}

export interface LPRewardsV2Info {
    stakingRewardAddress: string
    tokens: [Token, Token]
    rewardToken: Token
    collection: Token
    projectInfo: ProjectInfo

    totalAmount: TokenAmount
    rewardPerBlock: TokenAmount
    rewardPerDay: Fraction

    maxBoosterCount: number
    boostPermillage: number
    boostDiv: number

    pendingReward: TokenAmount
    pendingRewardFromBoost: Fraction 
    stakedAmount: TokenAmount
    boosters: number[]
    userShare: number
    userRewardRate: Fraction | undefined
    userRewardBoostRate: Fraction | undefined
    startBlock: number | undefined
    bonusEndBlock: number | undefined
    blockLefttoStart: number | undefined

    estimatedAPR: number
    status: LP_STATUS_TYPES | undefined
}

// gets the staking info from the network for the active chain id
export function useLPRewardsV2Info(rewardToFilterBy?: string | null): LPRewardsV2Info[] {
    const { chainId, account } = useActiveWeb3React()
    const info = useMemo(
        () =>
            chainId
                ? LP_REWARDS_V2_INFO[chainId]?.filter(
                    stakingRewardInfo =>
                        (rewardToFilterBy === undefined) ? true :
                            (rewardToFilterBy === stakingRewardInfo.stakingRewardAddress) // rewardToFilterBy.equals(stakingRewardInfo.token)
                ) ?? []
                : [],
        [chainId, rewardToFilterBy]
    )

    const uni = chainId ? UNI[chainId] : undefined

    const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

    const accountArg = useMemo(() => [account ?? undefined], [account])

    // get all the info from the staking rewards contracts
    const startBlockRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'startBlock')
    const bonusEndBlockRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'bonusEndBlock')

    const rewardPerBlockRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'rewardPerBlock')
    const totalAmountRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'totalAmount')
    const pendingRewardRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'pendingReward', accountArg)
    const userInfoRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'userInfo', accountArg)
    const boostersOfRes = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'boostersOf', accountArg)

    const maxBoosterCount = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'maxBoosterCount')
    const boostPermillage = useMultipleContractSingleData(rewardsAddresses, REWARDS_V2_INTERFACE, 'boostPermillage')

    const avgBlockTimeinSeconds = useAvgBlockTimeInSeconds()
    const blockNumber = useBlockNumber()
    

    return useMemo(() => {
        if (!chainId || !uni) return []

        return rewardsAddresses.reduce<LPRewardsV2Info[]>((memo, rewardsAddress, index) => {
            // these two are dependent on account

            const rewardPerBlockState = rewardPerBlockRes[index]
            const totalAmountState = totalAmountRes[index]

            const pendingRewardState = pendingRewardRes[index]
            const userInfoState = userInfoRes[index]
            const boostersOfState = boostersOfRes[index]

            const startBlockState = startBlockRes[index]
            const bonusEndBlockState = bonusEndBlockRes[index]

            const maxBoosterCountState = maxBoosterCount[index]
            const boostPermillageState = boostPermillage[index]

            if (
                // these may be undefined if not logged in
                !pendingRewardState?.loading &&
                !userInfoState?.loading &&
                !boostersOfState?.loading &&
                // always need these
                rewardPerBlockState &&
                !rewardPerBlockState.loading &&
                totalAmountState &&
                !totalAmountState.loading &&
                startBlockState &&
                !startBlockState?.loading &&
                bonusEndBlockState &&
                !bonusEndBlockState?.loading &&
                maxBoosterCountState &&
                !maxBoosterCountState.loading &&
                boostPermillageState &&
                !boostPermillageState.loading
            ) {
                if (
                    pendingRewardState?.error ||
                    userInfoState?.error ||
                    rewardPerBlockState?.error ||
                    totalAmountState?.error ||
                    startBlockState?.error ||
                    bonusEndBlockState?.error ||
                    maxBoosterCountState?.error ||
                    boostPermillageState?.error
                ) {
                    console.error('Failed to load LP rewards v2 info')
                    return memo
                }

                // get the LP token
                const tokens = info[index].tokens
                const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

                // check for account, if no account set to 0

                const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(userInfoState?.result?.[0] ?? 0))
                const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalAmountState?.result?.[0]))
                const rewardPerBlock = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardPerBlockState.result?.[0]))
                const dailyBlockSize = avgBlockTimeinSeconds ? Math.round(86400 / (avgBlockTimeinSeconds ?? 0)) : undefined
                const rewardPerDay = dailyBlockSize ? rewardPerBlock.multiply((dailyBlockSize ?? 0).toString()) : new Fraction('0')

                const userShare =  totalStakedAmount.greaterThan("0") ? parseFloat( stakedAmount?.divide(totalStakedAmount).toFixed(6) ) : 0 
                const userRewardRate = totalStakedAmount.greaterThan("0") ? stakedAmount?.divide(totalStakedAmount).multiply(rewardPerDay) : new Fraction('0')
                const startBlock = startBlockState?.result?.[0]
                const bonusEndBlock = bonusEndBlockState?.result?.[0]

                const boosters = boostersOfState?.result ? boostersOfState?.result[0] : []

                const estimatedAPR = (rewardPerDay && totalStakedAmount && !totalStakedAmount?.equalTo("0")) ? parseFloat(rewardPerDay?.multiply('365').divide(totalStakedAmount).toFixed(6)) : 0

                let blockLefttoStart = undefined
                let poolStatus = undefined
                if ((blockNumber && startBlock && bonusEndBlock)) {

                    if (startBlock > blockNumber) {
                        blockLefttoStart = startBlock - blockNumber
                        poolStatus = LP_STATUS_TYPES.HALTED
                    }
                    else if (blockNumber > startBlock && blockNumber < bonusEndBlock) {
                        poolStatus = LP_STATUS_TYPES.LIVE
                    }
                    else {
                        poolStatus = LP_STATUS_TYPES.FINISHED
                    }
                }

                const maxBoosterCount = parseInt(maxBoosterCountState?.result?.[0])
                const boostPermillage = parseInt(boostPermillageState?.result?.[0])
                const boostDiv = 1000
                const userBoost = boostPermillage * boosters.length
                const userRewardBoostRate = totalStakedAmount.greaterThan("0") ? stakedAmount?.divide(totalStakedAmount).multiply(rewardPerDay).multiply(userBoost.toString()).divide(boostDiv.toString()) : new Fraction('0')

                const pendingReward = new TokenAmount(info[index].rewardToken, JSBI.BigInt(pendingRewardState?.result?.[0] ?? '0'))
                const pendingRewardFromBoost = pendingReward?.multiply( ( boosters.length * boostPermillage).toString()  ).divide(boostDiv.toString())
                

                memo.push({
                    stakingRewardAddress: rewardsAddress,
                    tokens: info[index].tokens,
                    rewardToken: info[index].rewardToken,
                    collection: info[index].collection,
                    projectInfo: info[index].projectInfo,
                    totalAmount: totalStakedAmount,
                    rewardPerBlock: rewardPerBlock,
                    rewardPerDay: rewardPerDay,
                    pendingReward: pendingReward,
                    pendingRewardFromBoost: pendingRewardFromBoost,
                    stakedAmount: stakedAmount,
                    boosters: boosters,
                    userShare: userShare,
                    userRewardRate: userRewardRate,
                    userRewardBoostRate: userRewardBoostRate,
                    startBlock: startBlock,
                    bonusEndBlock: bonusEndBlock,
                    blockLefttoStart: blockLefttoStart,
                    status: poolStatus,
                    maxBoosterCount: maxBoosterCount,
                    boostPermillage: boostPermillage,
                    boostDiv: boostDiv,
                    estimatedAPR: estimatedAPR,
                })
            }
            return memo
        }, [])
    }, [uni, info, chainId, rewardsAddresses, avgBlockTimeinSeconds, blockNumber, bonusEndBlockRes, boostPermillage, boostersOfRes, maxBoosterCount, pendingRewardRes, rewardPerBlockRes, startBlockRes, totalAmountRes, userInfoRes])
}


// based on typed value
export function useDerivedStakeInfo(
    typedValue: string,
    stakingToken: Token,
    userLiquidityUnstaked: TokenAmount | undefined
): {
    parsedAmount?: CurrencyAmount
    error?: string
} {
    const { account } = useActiveWeb3React()

    const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

    const parsedAmount =
        parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
            ? parsedInput
            : undefined

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!parsedAmount) {
        error = error ?? 'Enter an amount'
    }

    return {
        parsedAmount,
        error
    }
}

// based on typed value
export function useDerivedUnstakeInfo(
    typedValue: string,
    stakingAmount: TokenAmount
): {
    parsedAmount?: CurrencyAmount
    error?: string
} {
    const { account } = useActiveWeb3React()

    const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

    const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!parsedAmount) {
        error = error ?? 'Enter an amount'
    }

    return {
        parsedAmount,
        error
    }
}
