import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, ETHER } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import SingleStakingModal from '../../components/earn/SingleStakingModal'
import { useSingleStakingInfo } from '../../state/singleStake/hooks'
import SingleUnstakingModal from '../../components/earn/SingleUnstakingModal'
import SingleClaimRewardModal from '../../components/earn/SingleClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'

import { numberWithCommas } from '../../utils/helper'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard) <{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const StyledBottomCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function SSManage({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account, chainId } = useActiveWeb3React()

  const stakingInfo = useSingleStakingInfo(rewardAddr)?.[0]

  // get currencies and pair
  const currencyA = useCurrency(stakingInfo?.token.address)
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const token = currencyA === ETHER ? tokenA : tokenA
  const backgroundColor = useColor(token)

  // get WETH value osf staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  const depositedTokenPriceinUSDC = useUSDCPrice(stakingInfo?.token)
  const strDepositedTokenPrice = depositedTokenPriceinUSDC ? `${depositedTokenPriceinUSDC.toFixed(18)}` : `1`
  const depositedTokenPrice = parseFloat(strDepositedTokenPrice)

  let totalDepositInUSDC: number | undefined
  if (totalSupplyOfStakingToken) {
    totalDepositInUSDC =
      (JSBI.toNumber(stakingInfo.totalStakedAmount.raw) / Math.pow(10, stakingInfo.token.decimals)) *
      depositedTokenPrice
  }
  const totalDepositStr = totalDepositInUSDC ? `${totalDepositInUSDC.toFixed()}` : `1`

  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo?.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `1`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

  let apr = '-'
  if (totalDepositStr !== `1`) {
    const numApr =
      ((parseFloat(stakingInfo.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365 * rewardTokenPrice) /
        parseFloat(totalDepositStr)) *
      100
    const aprValue = Math.round(numApr).toString()
    apr = `🔥 ${aprValue}%`
  }
  let poolRate = numberWithCommas(stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))

  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    apr = '0%'
    poolRate = '0'
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  const totalRewardRate = parseFloat(stakingInfo?.totalRewardRate?.toFixed()) * 60 * 60 * 24 * 7
  const userRewardRate = parseFloat(stakingInfo?.rewardRate?.toFixed()) * 60 * 60 * 24 * 7
  const userRewardinUSDC = totalDepositInUSDC ? ((userRewardRate / totalRewardRate) * totalDepositInUSDC).toFixed(2) : 0

  const unclaimedTokenAmount = parseFloat(countUpAmount)
  const unclaimedRewardinUSDC = (unclaimedTokenAmount * rewardTokenPrice).toFixed(2)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Round {stakingInfo?.round} - Stake {stakingInfo?.token.symbol} to earn {stakingInfo?.rewardToken.symbol} token
        </TYPE.mediumHeader>
        <CurrencyLogo currency={currencyA ?? undefined} size={'24px'} />
        {" → "}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {totalDepositInUSDC ? `$${numberWithCommas(totalDepositInUSDC.toFixed(0))}` : `-`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {poolRate} {` ${stakingInfo?.rewardToken.symbol} / week`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {apr}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>

      {showAddLiquidityButton && (
        <VoteCard>
          <CardBGImage />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Get ${currencyA?.symbol} tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.token.symbol} tokens to earn more ${stakingInfo?.rewardToken.symbol}. No need to worry about impermanent loss!`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/swap/?outputCurrency=${currencyA && currencyId(currencyA)}`}
              >
                {`Buy ${currencyA?.symbol} token`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <SingleStakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <SingleUnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <SingleClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSection>
              <CardBGImage desaturate />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your token deposits</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                  </TYPE.white>
                  <TYPE.white>{currencyA?.symbol}</TYPE.white>
                </RowBetween>

                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {`$${numberWithCommas(userRewardinUSDC)}`}
                  </TYPE.white>
                  <TYPE.white> ≈ USDC</TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    color="#939393"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonPrimary>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.rewardRate
                    ?.multiply((60 * 60 * 24 * 7).toString())
                    ?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / week `}
                </TYPE.black>
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${numberWithCommas(unclaimedRewardinUSDC)}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you withdraw, the contract will automagically claim {stakingInfo?.rewardToken.symbol} on your behalf!
        </TYPE.main>

        {!showAddLiquidityButton && (
          <DataRow style={{ marginBottom: '1rem' }}>
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                ? 'Deposit'
                : `Deposit ${stakingInfo?.token.symbol} Tokens`}
            </ButtonPrimary>

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="160px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}
        {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
          <TYPE.main>
            {userLiquidityUnstaked.toSignificant(6)} {stakingInfo?.token.symbol} tokens available
          </TYPE.main>
        )}
        <TYPE.main>{`1 ${stakingInfo?.rewardToken.symbol} = ${rewardTokenPrice.toFixed(4)} USDC`}</TYPE.main>

      </PositionInfo>
    </PageWrapper>
  )
}
