import React, { useState, useCallback } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'

import { IDOInfo } from '../../state/ido/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import ETHCurrencyInputPanel from '../ETHCurrencyInputPanel'
import { tryParseAmount } from '../../state/swap/hooks'
import { useIDOContract } from '../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import Box from '@mui/material/Box'
import { useETHBalances, useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { TokenAmount } from '@cronosdex/sdk'
import { NATIVE_CRO } from '../../constants'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'

export const DataCard = styled(AutoColumn) <{ disabled?: boolean }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #5b5eb1 0%, #0d0c20 100%);
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const AmountContributed = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const PoolData = styled(DataCard)`
  background: #1b1b34;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

interface IDOParticipateModalProps {
  isOpen: boolean
  onDismiss: () => void
  idoInfo: IDOInfo
}



export default function IDOParticipateModal({ isOpen, onDismiss, idoInfo }: IDOParticipateModalProps) {
  const { account } = useActiveWeb3React()
  const reqUserBalance = useTokenBalance(account ?? undefined, idoInfo?.holdReq?.token ?? undefined)
  const idoContract = useIDOContract(idoInfo?.contractAddress)
  const [typedValue, setTypedValue] = useState('')
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])
  let reqSatisfied = true
  // const parsedAmount = new TokenAmount(idoInfo.contributionToken, typedValue)
  const [approval, approveCallback] = useApproveCallback(idoInfo.maxContribution, idoInfo.contractAddress)
  if (idoInfo?.holdReq && reqUserBalance) {
    reqSatisfied =
      reqUserBalance?.greaterThan(idoInfo?.holdReq ?? '0') 
      || reqUserBalance?.equalTo(idoInfo?.holdReq ?? '0')
  }


  

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])
  // used for max input button
  const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const ethTokenAmount = new TokenAmount(NATIVE_CRO, ethBalance?.raw ?? '0')
  const tokenBalance = useTokenBalance(account ?? undefined, idoInfo?.contributionToken)
  const currTokenAmount = idoInfo?.contributionToken === NATIVE_CRO ? ethTokenAmount : tokenBalance
  
  const maxTokenAmountInput = idoInfo?.maxContribution.subtract(idoInfo?.amountContributedinETH)
  const maxAmountInput = maxTokenAmountInput.greaterThan(currTokenAmount ?? '0') ? currTokenAmount : maxTokenAmountInput

  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const typedAmount = tryParseAmount(typedValue, idoInfo?.contributionToken)
  let error = true
  if (typedAmount && maxAmountInput) {
    if (idoInfo?.amountContributedinETH.greaterThan('0')) {
      if (
        (typedAmount.lessThan(maxAmountInput) || typedAmount.equalTo(maxAmountInput)) &&
        typedAmount.greaterThan('0')
      ) {
        error = false
      } else {
        error = true
      }
    } else if (typedAmount.equalTo(idoInfo?.minContribution) || typedAmount.equalTo(idoInfo?.maxContribution)) {
      error = false
    } else if (typedAmount.greaterThan(idoInfo?.minContribution) && typedAmount.lessThan(idoInfo?.maxContribution)) {
      error = false
    } else error = true
  }

  async function onAttemptToApprove() {
    return approveCallback()
  }

  async function onParticipate() {
    setAttempting(true)
    if (idoContract && typedAmount && !error) {
      if (idoInfo?.contributionToken === NATIVE_CRO) {
        idoContract
          .ExchangeCROforTokenManual({ value: `0x${typedAmount.raw.toString(16)}` })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Participate ${idoInfo?.token.symbol} ${idoInfo?.name} IDO`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {

        idoContract
          .ExchangeCROforToken(`0x${typedAmount.raw.toString(16)}`)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Participate ${idoInfo?.token.symbol} ${idoInfo?.name} IDO`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      }

    } else {
      setAttempting(false)
      throw new Error('Attempting to participate failed. Please contact support.')
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Participate</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          <Box sx={{ width: '100%', mr: 1, marginTop: '10px' }}>
            <DataRow style={{ gap: '2' }}>
              <PoolData>
                <AutoColumn gap="sm">
                  <TYPE.body style={{ margin: 0 }} fontSize={12}>
                    Min Contribution
                  </TYPE.body>
                  <TYPE.body fontSize={18} fontWeight={500}>
                    {`${idoInfo?.minContribution.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol
                      }`}
                  </TYPE.body>
                </AutoColumn>
              </PoolData>
              <PoolData>
                <AutoColumn gap="sm">
                  <TYPE.body style={{ margin: 0 }} fontSize={12}>
                    Max Contribution
                  </TYPE.body>
                  <TYPE.body fontSize={18} fontWeight={500}>
                    {`${idoInfo?.maxContribution.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol
                      }`}
                  </TYPE.body>
                </AutoColumn>
              </PoolData>
            </DataRow>
          </Box>

          {idoInfo?.holdReq && (
            <AmountContributed dim={true}>
              <div>
                <TYPE.black fontWeight={600}>Required Token Amount</TYPE.black>
              </div>

              {reqSatisfied && (
                <TYPE.black style={{ color: 'green' }}>
                  {idoInfo?.holdReq.toFixed(2, { groupSeparator: ',' })} {idoInfo?.holdReq.token.symbol}
                </TYPE.black>
              )}

              {!reqSatisfied && (
                <TYPE.black style={{ color: '#f56565' }}>
                  {idoInfo?.holdReq.toFixed(2, { groupSeparator: ',' })} {idoInfo?.holdReq.token.symbol}
                </TYPE.black>
              )}
            </AmountContributed>
          )}

          {idoInfo?.contributionToken === NATIVE_CRO && (
            <ETHCurrencyInputPanel
              value={typedValue}
              onUserInput={onUserInput}
              onMax={handleMax}
              showMaxButton={true}
              currency={idoInfo?.contributionToken}
              disableCurrencySelect={true}
              customBalanceText={'Available to participate: '}
              id="participate-liquidity-token"
            />
          )}

          {idoInfo?.contributionToken !== NATIVE_CRO && (

            <CurrencyInputPanel
              value={typedValue}
              onUserInput={onUserInput}
              onMax={handleMax}
              showMaxButton={true}
              currency={idoInfo?.contributionToken}
              disableCurrencySelect={true}
              customBalanceText={'Available to participate: '}
              id="participate-liquidity-token"
            />
          )}
          <AmountContributed dim={true}>
            <div>
              <TYPE.black fontWeight={600}>Amount Contributed</TYPE.black>
            </div>

            <TYPE.black>
              {idoInfo?.amountContributedinETH.toFixed(2, { groupSeparator: ',' })} {idoInfo?.contributionToken.symbol}
            </TYPE.black>
          </AmountContributed>

          {/* <Break></Break> */}

            

          {idoInfo?.contributionToken !== NATIVE_CRO  && (
              <RowBetween>

              <ButtonConfirmed
                  mr="0.5rem"
                  onClick={onAttemptToApprove}
                  confirmed={approval === ApprovalState.APPROVED}
                  disabled={approval !== ApprovalState.NOT_APPROVED}
                >
                  Approve
                </ButtonConfirmed>
    
                <ButtonError
                  disabled={error || !reqSatisfied || approval === ApprovalState.NOT_APPROVED }
                  error={false}
                  onClick={onParticipate}
                  style={{ color: '#d0d0d0' }}
                >
                  {reqSatisfied
                    ? 'Participate'
                    : `You need to be holding ${idoInfo?.holdReq?.toFixed(0)} ${idoInfo?.holdReq?.token.symbol
                    } to participate`}
                </ButtonError>
              </RowBetween>
          )}

          {idoInfo?.contributionToken === NATIVE_CRO  && (
              <RowBetween>
                <ButtonError
                  disabled={error || !reqSatisfied }
                  error={false}
                  onClick={onParticipate}
                  style={{ color: '#d0d0d0' }}
                >
                  {reqSatisfied
                    ? 'Participate'
                    : `You need to be holding ${idoInfo?.holdReq?.toFixed(0)} ${idoInfo?.holdReq?.token.symbol
                    } to participate`}
                </ButtonError>
              </RowBetween>
          )}
          
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Participating IDO</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{idoInfo?.token.symbol}</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Participated {idoInfo?.contributionToken.symbol}</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
