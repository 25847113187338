/* eslint-disable react/jsx-key */
import React, { useCallback, useState } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { ButtonPrimary } from '../../Button'
import FightAdventureMechanicsModal from './FightAdventureMechanicsModal'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
  max-height: ${isMobile ? '430px' : '100%'};
  overflow-y: auto;
  scrollbar-gutter: stable;
`


interface FightAdventureStoryModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function FightAdventureStoryModal({ isOpen, onDismiss }: FightAdventureStoryModalProps) {
  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])
  const [showMechanicsModal, setShowMechanicsModal] = useState(false)

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>


      <FightAdventureMechanicsModal
        isOpen={showMechanicsModal}
        onDismiss={() => setShowMechanicsModal(false)}
      />

      <ContentWrapper gap="lg">
        <GlobalStyles
          styles={{
            '*::-webkit-scrollbar': {
              width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: '#5b5eb1',
              outline: '1px solid #5b5eb1',
              borderRadius: '12px'
            }
          }}
        />
        <RowBetween>
          <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Adventure: Skirmishes


            <ButtonPrimary style={{ width: 'fit-content', marginLeft: '8px', padding: '8px' }}
              onClick={() => setShowMechanicsModal(true)}>
              <SettingsSuggestRoundedIcon
                sx={{ fontSize: 18, color: 'white' }}
              />
            </ButtonPrimary>

          </TYPE.mediumHeader>

          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <ScrollContent>

          <div style={{ margin: '1rem'}}>
            <img style={{width: '100%'}} alt="skirmishes" src="https://media.discordapp.net/attachments/911246161233457172/1102322532276785262/thebattle.png"/>
          </div>

          <TYPE.white
            fontWeight={500}
            fontSize={14}
            style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
          >
            After a tiresome but fruitful arrival phase,
            Space Crxillions have started to look out for their
            fellows from the vanguard squad that went missing.
            In the meantime, the recon mission commenced by OG Crxillions resulted in the capture of
            those missing Space Crxillions. Though OGs have shown no violence to the
            fancy suited new folk of the Metaverse Hub, they made no progress in communicating and
            extracting meaningful information.<br />
            <br />
            Space Crxillions found out that their missing friends are
            held captive before long. But the status-quo was only broken when the harvesting of
            the arrival place started to decline for real. Knowing a full-force aggression is unwise,
            Space Crxillions would only run the risk of small and half-blind skirmishes to get their hands on key
            places where harvesting flash rewards is possible.
            Return of a skirmish attack is not only the rewards of MHUB but also the loot captured from the opponent.<br />
            <br />
            Without an OG-summit call, the retaliation of OGs are pretty balanced.
            In the form of civil encounters. Meanwhile, both parties start gathering and form squads
            for their defense. But attacks are only individual. For now...
            <br />
            <br />
          </TYPE.white>
          <TYPE.white
            style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto', cursor: 'pointer', textDecoration: 'underline' }}

            onClick={() => setShowMechanicsModal(true)}
            fontWeight={500}
            fontSize={14}>
            Read the mechanics of the adventure here↗</TYPE.white>
        </ScrollContent>

      </ContentWrapper>
    </Modal>
  )
}
