/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
// import image from '../../../assets/images/spaceCrxillion01.png'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
max-height: ${isMobile ? '400px' : '100%'};
overflow-y: auto;
  scrollbar-gutter: stable;
`


interface MissionReconMechanicsModalProps {
    isOpen: boolean
    onDismiss: () => void
}

export default function MissionReconMechanicsModal({ isOpen, onDismiss }: MissionReconMechanicsModalProps) {
    const wrappedOnDismiss = useCallback(() => {
        onDismiss()
    }, [onDismiss])

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            <ContentWrapper gap="lg">
                <GlobalStyles
                    styles={{
                        '*::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5b5eb1',
                            outline: '1px solid #5b5eb1',
                            borderRadius: '12px'
                        }
                    }}
                />
                <RowBetween>
                    <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Mechanics: Mission Recon
                    </TYPE.mediumHeader>

                    <CloseIcon onClick={wrappedOnDismiss} />
                </RowBetween>


                <ScrollContent>

                    <TYPE.white
                        fontWeight={500}
                        fontSize={14}
                        style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
                    >
                        Mission Recon is a raffle-like adventure. Each entry requires you to send a Crxillion on adventure and pay a certain amount of MHUB tokens.<br></br>
                        The payment amount depends on which of the 3 possible routes you choose your Crxillion to take.
                        <br></br><br></br>
                        That means there are 3 entry levels to the adventure providing different winning chance units (<i>wcu</i>) with different base fees: <br></br>
                        Level 1 - 400 MHUB for 1 <i>wcu</i><br></br>
                        Level 2 - 700 MHUB for 2 <i>wcu</i> <br></br>
                        Level 3 - 900 MHUB for 3 <i>wcu</i><br></br><br></br>

                        Entering earlier is advantageous in terms of fees as they increase by 20 after each 100 entries.
                        Only base fees are charged at the beginning but when there are 100 entrants before you, a level 3 entry will cost 920 instead of 900.
                        In general, when there are K * 100 entrant an entry will cost [base fee + K * 20 MHUB].<br></br><br></br>

                        The adventure will continue at least for 3 months. A group of winners (at least 3) will be randomly picked via the smart contract towards the end of every month until the adventure is over.
                        First winner in the group will receive 5% of the total MHUB collected during the adventure at the time.
                        The others will receive an exclusive Space Crxillion with Space Suits.
                        Your winning chance is simply [total <i>wcu</i>  / your <i>wcu</i>].<br></br><br></br>

                        Be warned! Once you dispatch a Crxillion to the mission, it is not going to return until the very end. That is, your NFT will be locked in the contract
                        until the adventure is finished, which is going to happen only when we release the next adventure involving Crxillions.
                        <br></br><br></br>
                        Happy adventuring!
                    </TYPE.white>
                         
                </ScrollContent>
            </ContentWrapper>
        </Modal>
    )
}
