/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { DataCard } from '../earn/styled'
import ImageListItem from '@mui/material/ImageListItem'
import iamge from '../../assets/svg/262.png'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { LPBoostStakingInfo } from '../../state/boostStake/LPBoost'
import { NFTPortfolioInfo } from '../../state/nftPortfolio/hooks'
import GlobalStyles from '@mui/material/GlobalStyles'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { SSBoostStakingInfo } from '../../state/boostStake/SSBoost'
import { LPRewardsV2Info } from '../../state/rewardsV2/lpRewardsv2'
import { SSRewardsV2Info } from '../../state/rewardsV2/ssRewardsv2'

// const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} arrow />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 500,
//     zIndex: 1203
//   }
// })

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  box-shadow: rgb(173 168 90 / 85%) 0px 0px 5px 3px;
  padding: 1rem;
  z-index: 1;
  width: 140px;
  margin: auto;
  height: 140px;
  @keyframes example {
    0% {
      border: 1px solid #767860;
    }
    25% {
      border: 1px solid #5b5eb1;
    }
    50% {
      border: 1px solid #edeef2;
    }
    100% {
      border: 1px solid green;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  width:120px;
  height:120px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width:100px;
  height:100px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  width:80px;
  height:80px;
  `};
`
const EmptyPoolData = styled(DataCard)`
  background: none;
  border: 2px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
  width: 140px;
  margin: auto;
  height: 140px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  width:120px;
  height:120px;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width:100px;
  height:100px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  width:80px;
  height:80px;
  `};
`
const Styledimg = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
`

const StyledFormControl = styled(FormControl)`
  padding: 0px 20px !important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0px !important;
`};
`

interface StakingModalProps {
  visible: boolean
  stakingInfo: LPBoostStakingInfo | SSBoostStakingInfo | LPRewardsV2Info | SSRewardsV2Info
  portfolioInfo: NFTPortfolioInfo[]
  maxBoosterCount: number
}

interface NFTInfo {
  tokenID: string
  tokenURI: string
  metaData: any
}

interface Metadata {
  image: string
  name: string
}

function NFTCards({ visible, stakingInfo, portfolioInfo, maxBoosterCount }: StakingModalProps) {
  const [nftInfoList, setNFTInfoList] = useState<NFTInfo[]>([])
  const [update, setUpdate] = React.useState(false)
  const [previousPortfolio, setPreviousPortfolio] = useState<NFTPortfolioInfo[]>([])
  const [loading, setLoading] = React.useState(false)
  const [emptyCards, setEmptyCards] = React.useState([] as any)

  async function getReq(url: string): Promise<Metadata> {
    return fetch(url)
      .then(async response => {
        const tokenData = await response.text()
        const metaData = JSON.parse(tokenData)
        metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
        return metaData
      })
      .catch(error => {
        console.error('Error:', error)
        return null
      })
  }

  async function getMetaData(portfolioInfo: NFTPortfolioInfo[]) {
    const portList = []
    for (let idx = 0; idx < portfolioInfo.length; idx++) {
      let metaData: Metadata | undefined = undefined

      if (portfolioInfo[idx].tokenURI !== 'undefined') {
        if (stakingInfo?.projectInfo.hasMetadata) {
          let metadataURI = portfolioInfo[idx].tokenURI
          if (stakingInfo?.projectInfo.tokenURIPrefix) {
            metadataURI = stakingInfo?.projectInfo.tokenURIPrefix + portfolioInfo[idx].tokenURI
          }
          metaData = await getReq(metadataURI)
        } else {
          metaData = { image: '', name: '' }
          metaData.image = portfolioInfo[idx]?.tokenURI
          if (stakingInfo?.projectInfo.imageURIPrefix) {
            metaData.image = stakingInfo?.projectInfo.imageURIPrefix + portfolioInfo[idx].tokenURI
          }
        }
      }
      if (metaData) {
        metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
        portList[idx] = {
          metaData: metaData,
          tokenID: portfolioInfo[idx].tokenID,
          tokenURI: portfolioInfo[idx].tokenURI
        }
        setNFTInfoList([...portList])
        setUpdate(!update)
      } else {
        portList[idx] = {
          metaData: { image: 'https://swap.crodex.app/ant.jpg', name: 'Placeholder' },
          tokenID: portfolioInfo[idx].tokenID,
          tokenURI: portfolioInfo[idx].tokenURI
        }
        setNFTInfoList([...portList])
        setUpdate(!update)
      }
    }
  }

  useEffect(() => {
    if (portfolioInfo.length === 0) {
      setLoading(false)
    } else if (portfolioInfo.length !== 0 && portfolioInfo[0].tokenURI !== undefined) {
      setLoading(false)
      if (previousPortfolio !== undefined) {
        if (previousPortfolio.length !== portfolioInfo.length) {
          getMetaData(portfolioInfo)
          setPreviousPortfolio(portfolioInfo)
          const card = []
          for (let i = 0; i < maxBoosterCount - portfolioInfo.length; i++) {
            card.push(i)
          }
          setEmptyCards(card)
        }
      } else {
        setPreviousPortfolio(portfolioInfo)
        const card = []
        for (let i = 0; i < maxBoosterCount - portfolioInfo.length; i++) {
          card.push(i)
        }
        setEmptyCards(card)
      }
    } else if (portfolioInfo[0]?.tokenURI === undefined) {
      setLoading(true)
    }
  }, [portfolioInfo, previousPortfolio])

  return (
    <>
      {visible && (
        <>
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms'
            }}
            unmountOnExit
          >
            <LinearProgress style={{ margin: '15px' }} color="secondary" />
          </Fade>
          <ContentWrapper gap="lg">
            <StyledFormControl>
              <GlobalStyles
                styles={{
                  '*::-webkit-scrollbar': {
                    width: '0.4em'
                  },
                  '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#5b5eb1',
                    outline: '1px solid #5b5eb1',
                    borderRadius: '12px'
                  }
                }}
              />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: '15px' }}>
                {nftInfoList.map(nftInfo => {
                  return (
                    <Grid item xs={4} style={{ marginBottom: '15px' }}>
                      <PoolData style={{ padding: '0rem' }}>
                        <ImageListItem key={nftInfo.tokenID}>
                          <Styledimg src={nftInfo.metaData.image} alt={iamge} loading="lazy" />
                        </ImageListItem>
                      </PoolData>
                      <p style={{ textAlign: 'center', margin: '10px 0 0 0' }}>#{nftInfo.tokenID.toString()}</p>
                    </Grid>
                  )
                })}
                {emptyCards.map(() => {
                  return (
                    <Grid item xs={4} style={{ marginBottom: '15px' }}>
                      <EmptyPoolData style={{ padding: '0rem' }}>
                        <ImageListItem></ImageListItem>
                      </EmptyPoolData>
                    </Grid>
                  )
                })}
              </Grid>
            </StyledFormControl>
          </ContentWrapper>
        </>
      )}
    </>
  )
}

export default React.memo(NFTCards)
