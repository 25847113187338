import React, { useCallback, useEffect, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, ETHER } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ExternalLink, TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO, GRAPH_URI } from '../../constants'

import { numberWithCommas } from '../../utils/helper'
import CurrencyLogo from '../../components/CurrencyLogo'
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';



const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`
const InfoLink = styled(ExternalLink)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bg3};
  padding: 6px 6px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`

const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard) <{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const StyledBottomCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 8 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: none;
  padding: 1rem;
  z-index: 1;
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function Manage({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account, chainId } = useActiveWeb3React()

  // get currencies and pair
  const stakingInfo = useStakingInfo(rewardAddr)?.[0]

  const currencyA = stakingInfo?.tokens[0]
  const currencyB = stakingInfo?.tokens[1]

  // const [currencyA, currencyB] = [useCurrency(stakingInfo?.tokens[0].symbol), useCurrency(stakingInfo?.tokens[1].symbol)]

  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = usePair(tokenA, tokenB)

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by CRO value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo?.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `1`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

  const totalDeposit = valueOfTotalStakedAmountInUSDC ? `${valueOfTotalStakedAmountInUSDC.toFixed()}` : `1`

  const totalRewardRate = parseFloat(stakingInfo?.totalRewardRate?.toFixed()) * 60 * 60 * 24 * 7
  const userRewardRate = parseFloat(stakingInfo?.rewardRate?.toFixed()) * 60 * 60 * 24 * 7
  const userRewardinUSDC = totalDeposit ? ((userRewardRate / totalRewardRate) * parseFloat(totalDeposit)).toFixed(2) : 0

  const unclaimedTokenAmount = parseFloat(countUpAmount)
  const unclaimedRewardinUSDC = (unclaimedTokenAmount * rewardTokenPrice).toFixed(2)
  let poolRate = numberWithCommas(stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))


  //  // APR FROM LIQ //
  const [dailyVolume, setDailyVolume] = useState(0)
  const pairInfo = usePair(stakingInfo?.tokens[0]!, stakingInfo?.tokens[1]!)?.[1]
  const pairAddress = pairInfo?.liquidityToken.address
  const token0reserve = pairInfo ? parseFloat(pairInfo.reserve0.toFixed()) : 0
  const token0price = useUSDCPrice(pairInfo?.token0)
  const token0priceStr = token0price ? `${token0price.toFixed(18)}` : `0`
  const token0priceUSD = parseFloat(token0priceStr)
  const pairReserve = token0reserve * token0priceUSD * 2
  const liqAPR = dailyVolume ? Math.ceil((dailyVolume * 0.003 * 365 * 100) / pairReserve) : 9



  async function getHourlyPairData(pairAddress: string): Promise<any> {
    return fetch(GRAPH_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ query: "{\n      pairHourDatas(first:24, orderBy: hourStartUnix, orderDirection: desc, where:{pair:\"" + pairAddress.toLowerCase() + "\"}){\n    hourlyVolumeUSD\n  }\n}" })
    })
      .then(r => r.json())
      .catch(error => {
        console.error('Error:', error)
        return null
      })
  }

  async function getDailyVoume() {
    if (pairAddress) {
      const hourlyVolData = await getHourlyPairData(pairAddress)
      if (hourlyVolData) {
        let dailyVol = 0
        for (var i = 0; i < hourlyVolData.data.pairHourDatas.length; i++) {
          dailyVol += parseInt(hourlyVolData.data.pairHourDatas[i].hourlyVolumeUSD)
        }
        setDailyVolume(dailyVol)
      }
    }
  }

  useEffect(() => {
    if (stakingInfo?.tokens) {
      getDailyVoume()
    }
  }, [stakingInfo])


  let apr = '-'
  let rewardsAPR = 0

  if (totalDeposit !== `1`) {
    const numApr =
      ((parseFloat(stakingInfo.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365 * rewardTokenPrice) /
        parseFloat(totalDeposit)) *
      100
    rewardsAPR = Math.ceil(numApr)
    const totalAPR = rewardsAPR + liqAPR
    apr = `${totalAPR}% 🔥`
  }

  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    apr = '0%'
    poolRate = '0'
  }

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Round {stakingInfo?.round} - Stake {currencyA?.symbol}-{currencyB?.symbol} LP to earn {stakingInfo?.rewardToken.symbol}
        </TYPE.mediumHeader>

        <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
        {" → "}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} CRO`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {poolRate}
              {` ${stakingInfo?.rewardToken.symbol} / week`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR</TYPE.body>
            {!isRewardEnded && (

              <TYPE.body fontSize={18} fontWeight={500}>
                <Tooltip title={<div><TYPE.white> From Rewards: {rewardsAPR}% </TYPE.white> <TYPE.white>From Swap Fees: {liqAPR}%</TYPE.white></div>}>
                  <IconButton sx={{ paddingTop: '7px' }}>
                    <HelpIcon sx={{ fontSize: 16, color: "#5b5eb1" }} />
                  </IconButton>
                </Tooltip>
                {apr}
              </TYPE.body>

            )}
            {isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}> {apr}</TYPE.body>
            )}

          </AutoColumn>
        </PoolData>
      </DataRow>

      {showAddLiquidityButton && (
        <VoteCard
        style={{
          background: '#1f1243cc',
          boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
          border: ' 1.5px solid rgb(13, 12, 32)'
        }}
      >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Get CRX-V2 Liquidity tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`CRX-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
              >
                {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <StakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <UnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSection>
              <CardBGImage desaturate />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your liquidity deposits</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                  </TYPE.white>
                  <TYPE.white>
                    CRX-V2 {currencyA?.symbol}-{currencyB?.symbol}
                  </TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {`$${numberWithCommas(userRewardinUSDC)}`}
                  </TYPE.white>
                  <TYPE.white> ≈ USDC</TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonPrimary>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.rewardRate
                    ?.multiply((60 * 60 * 24 * 7).toString())
                    ?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / week`}
                </TYPE.black>
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${numberWithCommas(unclaimedRewardinUSDC)}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you withdraw, the contract will automagically claim {stakingInfo?.rewardToken.symbol} on your behalf!
        </TYPE.main>

        {!showAddLiquidityButton && (
          <DataRow style={{ marginBottom: '1rem' }}>
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit CRX-V2 LP Tokens'}
            </ButtonPrimary>

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="160px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}          
        
        <DataRow style={{ marginBottom: '1rem' }}>

          <InfoLink
          href={'https://info.crodex.app/pair/' + pairInfo?.liquidityToken.address}
          target="_blank"
          >
          View pair analytics ↗
          </InfoLink>
          
        </DataRow>


        {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
          <TYPE.main>{userLiquidityUnstaked.toSignificant(6)} CRX-V2 LP tokens available</TYPE.main>
        )}

        <TYPE.main>{`1 ${stakingInfo?.rewardToken.symbol} = ${rewardTokenPrice.toFixed(4)} USDC`}</TYPE.main>
      </PositionInfo>
    </PageWrapper>
  )
}
