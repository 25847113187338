import React, { useCallback, useEffect, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { JSBI, TokenAmount, ETHER, Fraction } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ExternalLink, TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import ClaimRewardModal from '../../components/Boosters/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { useOtherPair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO, GRAPH_URI } from '../../constants'

import { numberWithCommas } from '../../utils/helper'
import CurrencyLogo from '../../components/CurrencyLogo'
import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { EBISUSBAY } from '../../constants/collections'
import BoosterNFTStakingModal from '../../components/Boosters/BoosterNFTStakingModal'
import { useNFTPortfolio, useNFTUserDeposits } from '../../state/nftPortfolio/hooks'
import BoosterNFTWithdrawModal from '../../components/Boosters/BoosterNFTWithdrawModal'
import NFTCards from '../../components/nftStakingModal/NFTCards'
import { useOtherLPBoostStakingInfo } from '../../state/boostStake/otherLPBoost'
import TokenStakingModal from '../../components/Boosters/TokenStakingModal'
import PartialTokenWithdrawModal from '../../components/Boosters/PartialTokenWithdrawModal'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledBoosterCard = styled(DataCard)<{ dim: any }>`
  background: #080812;
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 2;
`

const StyledLiqCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const InfoLink = styled(ExternalLink)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bg3};
  padding: 6px 6px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`

const StyledRewardCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg7};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
`

const PoolData = styled(DataCard)`
  background: none;
  padding: 1rem;
  z-index: 1;
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function OtherLPBoostStakeManage({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account, chainId } = useActiveWeb3React()

  // get currencies and pair
  const stakingInfo = useOtherLPBoostStakingInfo(rewardAddr)?.[0]
  const nftPortfolio = useNFTPortfolio(stakingInfo?.collection)
  const boosterInfo = useNFTUserDeposits(stakingInfo?.collection, stakingInfo?.boosters)

  const currencyA = stakingInfo?.pairTokens[0]
  const currencyB = stakingInfo?.pairTokens[1]
  // const [currencyA, currencyB] = [useCurrency(stakingInfo?.tokens[0].symbol), useCurrency(stakingInfo?.tokens[1].symbol)]

  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = useOtherPair(stakingInfo.pairTokens[0], stakingInfo.pairTokens[1], stakingInfo.token.address)

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showLPStakingModal, setShowLPStakingModal] = useState(false)
  const [showLPWithdrawModal, setShowLPWithdrawModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const [showBoosterNFTStakingModal, setBoosterNFTStakingModal] = useState(false)
  const [showBoosterNFTWithdrawModal, setBoosterNFTWithdrawModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  // const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const WETH = currencyA === ETHER ? tokenA : tokenB

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by CRO value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  const countUpBoosterAmount = stakingInfo?.earnedFromBoostAmount?.toFixed(6) ?? '0'
  const countUpBoosterAmountPrevious = usePrevious(countUpBoosterAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleLPDepositClick = useCallback(() => {
    if (account) {
      setShowLPStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTDepositClick = useCallback(() => {
    if (account) {
      setBoosterNFTStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTWithdrawClick = useCallback(() => {
    if (account) {
      setBoosterNFTWithdrawModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo?.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `1`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

  const totalDeposit = valueOfTotalStakedAmountInUSDC ? `${valueOfTotalStakedAmountInUSDC.toFixed()}` : `1`

  const userRewardinUSDC = (stakingInfo?.userShare && valueOfTotalStakedAmountInUSDC) ? valueOfTotalStakedAmountInUSDC?.multiply( parseInt( (100000 * parseFloat(stakingInfo?.userShare.toFixed(6))).toString() ).toString() ).divide("100000") : new Fraction('0')

  const unclaimedTokenAmount = parseFloat(countUpAmount) + parseFloat(countUpBoosterAmount)
  const unclaimedRewardinUSDC = (unclaimedTokenAmount * rewardTokenPrice).toFixed(2)
  let poolRate = numberWithCommas(stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))
  let userRate = stakingInfo?.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(4, { groupSeparator: ',' }) ?? '-'

  //  // APR FROM LIQ //
  const [dailyVolume, setDailyVolume] = useState(0)
  const pairInfo = useOtherPair(stakingInfo.pairTokens[0], stakingInfo.pairTokens[1], stakingInfo.token.address)?.[1]
  const pairAddress = pairInfo?.liquidityToken.address
  const token0reserve = pairInfo ? parseFloat(pairInfo.reserve0.toFixed()) : 0
  const token0price = useUSDCPrice(pairInfo?.token0)
  const token0priceStr = token0price ? `${token0price.toFixed(18)}` : `0`
  const token0priceUSD = parseFloat(token0priceStr)
  const pairReserve = token0reserve * token0priceUSD * 2
  const liqAPR = dailyVolume ? Math.ceil((dailyVolume * 0.003 * 365 * 100) / pairReserve) : 9

  const hasNFTs = nftPortfolio.length > 0 ? true : false
  const hasBooster = stakingInfo?.boosters.length > 0 ? true : false
  const hasDeposit = stakingInfo?.stakedAmount?.greaterThan('0')
  const hasReward = stakingInfo?.earnedAmount?.greaterThan('0')

  const userBoostPerc = Math.round((stakingInfo?.boostPermillage / stakingInfo?.boostDiv) * stakingInfo?.boosters.length * 100)
  let userBoostRate =
    stakingInfo?.rewardRate
      ?.multiply(((60 * 60 * 24 * 7 * userBoostPerc) / 100).toString())
      ?.toFixed(4, { groupSeparator: ',' }) ?? '-'

  async function getHourlyPairData(pairAddress: string): Promise<any> {
    return fetch(GRAPH_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        query:
          '{\n      pairHourDatas(first:24, orderBy: hourStartUnix, orderDirection: desc, where:{pair:"' +
          pairAddress.toLowerCase() +
          '"}){\n    hourlyVolumeUSD\n  }\n}'
      })
    })
      .then(r => r.json())
      .catch(error => {
        console.error('Error:', error)
        return null
      })
  }

  async function getDailyVoume() {
    if (pairAddress) {
      const hourlyVolData = await getHourlyPairData(pairAddress)
      if (hourlyVolData) {
        let dailyVol = 0
        for (let i = 0; i < hourlyVolData?.data?.pairHourDatas.length; i++) {
          dailyVol += parseInt(hourlyVolData?.data?.pairHourDatas[i].hourlyVolumeUSD)
        }
        setDailyVolume(dailyVol)
      }
    }
  }

  useEffect(() => {
    if (stakingInfo?.pairTokens) {
      getDailyVoume()
    }
  }, [stakingInfo])

  let apr = '-'
  let rewardsAPR = 0

  if (totalDeposit !== `1`) {
    const numApr =
      ((parseFloat(stakingInfo.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365 * rewardTokenPrice) /
        parseFloat(totalDeposit)) *
      100
    rewardsAPR = Math.ceil(numApr)
    const totalAPR = rewardsAPR + liqAPR
    apr = `${totalAPR}% 🔥`
  }

  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    apr = '0%'
    poolRate = '0'
    userBoostRate = '0'
    userRate = '0'
  }

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Stake {currencyA?.symbol}-{currencyB?.symbol} LP to earn{' '}
          {stakingInfo?.rewardToken.symbol}
        </TYPE.mediumHeader>

        <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
        {' → '}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} CRO`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>

        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR</TYPE.body>
            {!isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}>
                <Tooltip
                  title={
                    <div>
                      <TYPE.white> From Rewards: {rewardsAPR}% </TYPE.white>{' '}
                      <TYPE.white>From Swap Fees: {liqAPR}%</TYPE.white>
                    </div>
                  }
                >
                  <IconButton sx={{ paddingTop: '7px' }}>
                    <HelpIcon sx={{ fontSize: 16, color: '#5b5eb1' }} />
                  </IconButton>
                </Tooltip>
                {apr}
              </TYPE.body>
            )}
            {isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}>
                {' '}
                {apr}
              </TYPE.body>
            )}
          </AutoColumn>
        </PoolData>
      </DataRow>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {poolRate}
              {` ${stakingInfo?.rewardToken.symbol} / week`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        { stakingInfo?.maxBoosterCount > 0 && (
            <PoolData>
            <AutoColumn gap="sm">
              <TYPE.body style={{ margin: 0 }}>Max. Boost</TYPE.body>
              <TYPE.body fontSize={18} fontWeight={500}>
              {`${stakingInfo?.maxBoosterCount} ${stakingInfo?.collection.name} x ${Math.round((stakingInfo?.boostPermillage / stakingInfo?.boostDiv * 100) * 10) / 10}% = ${Math.round(stakingInfo?.boostPermillage / stakingInfo?.boostDiv * stakingInfo?.maxBoosterCount * 100)}%  `}

              </TYPE.body>
            </AutoColumn>
          </PoolData>
        )}
        
      </DataRow>

      {stakingInfo?.maxBoosterCount > 0 && !hasNFTs && stakingInfo?.projectInfo?.ebisusBay && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Buy {stakingInfo?.collection.symbol} NFTs</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.collection.symbol} NFTs to earn more ${stakingInfo?.rewardToken.symbol}.`}
                </TYPE.white>
              </RowBetween>
              <a
                href={stakingInfo?.projectInfo.ebisusBay}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
                  <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '2px' }} />
                  {`Buy ${stakingInfo?.collection.symbol} at Ebisu's Bay`}
                </ButtonPrimary>
              </a>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {/* {showAddLiquidityButton && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 2. Get CRX-V2 Liquidity tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`CRX-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
              >
                <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={36} />
                &nbsp;&nbsp;&nbsp;
                {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )} */}

      {stakingInfo && (
        <>
          <TokenStakingModal
            isOpen={showLPStakingModal}
            onDismiss={() => setShowLPStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <PartialTokenWithdrawModal
            isOpen={showLPWithdrawModal}
            onDismiss={() => setShowLPWithdrawModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityStaked={stakingInfo?.stakedAmount}
          />

          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />

          <BoosterNFTStakingModal
            isOpen={showBoosterNFTStakingModal}
            onDismiss={() => setBoosterNFTStakingModal(false)}
            stakingInfo={stakingInfo}
            portfolioInfo={nftPortfolio}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <BoosterNFTWithdrawModal
            isOpen={showBoosterNFTWithdrawModal}
            onDismiss={() => setBoosterNFTWithdrawModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      { stakingInfo?.maxBoosterCount > 0 && (
          <TYPE.main style={{ textAlign: 'center' }} fontSize={19}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⚠️
          </span>
          Deposit your boosters before depositing tokens.
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: '#acaed9', textDecoration: 'none' }}
            href={
              'https://crodex.medium.com/crodex-introduces-yet-another-utility-for-your-nfts-on-cronos-reward-boosting-fd5dfc2ce6d6'
            }
          >
            {' '}
            Learn more↗
          </a>
        </TYPE.main>
      )}
      

      <PositionInfo gap="lg" justify="center" dim={false} style={{ marginTop: '3rem'}}>
        <BottomSection gap="lg" justify="center">
          { stakingInfo?.maxBoosterCount > 0 && (
              <StyledBoosterCard dim={!hasBooster}>
              <CardBGImage desaturate />
              <AutoColumn gap="sm">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your booster deposits</TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white fontSize={25} fontWeight={600}>
                    {stakingInfo?.boosters.length} {stakingInfo?.collection.name}
                  </TYPE.white>
                  <TYPE.white fontWeight={600}>{userBoostPerc}% Boost</TYPE.white>
                </RowBetween>
              </AutoColumn>
              <AutoColumn gap="sm">
                <RowBetween>
                  <NFTCards
                    visible={hasBooster}
                    stakingInfo={stakingInfo}
                    portfolioInfo={boosterInfo}
                    maxBoosterCount={stakingInfo?.maxBoosterCount}
                  />
                </RowBetween>
              </AutoColumn>
            </StyledBoosterCard>
          )}
          

          <StyledLiqCard dim={!hasDeposit}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your liquidity deposits</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                </TYPE.white>
                <TYPE.white>
                   {stakingInfo.token.symbol}
                </TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                {`$${userRewardinUSDC?.toFixed(4, { groupSeparator: ',' })}`}
                </TYPE.white>
                <TYPE.white> ≈ USDC</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledLiqCard>

          <StyledRewardCard dim={!hasReward}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonPrimary>
                )}
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {userRate ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / week`}
                </TYPE.black>
              </RowBetween>

              {hasBooster && (
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.largeHeader fontSize={36} fontWeight={600}>
                    <CountUp
                      key={countUpBoosterAmount}
                      isCounting
                      decimalPlaces={4}
                      start={parseFloat(countUpBoosterAmountPrevious)}
                      end={parseFloat(countUpBoosterAmount)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.largeHeader>

                  <TYPE.black fontSize={16} fontWeight={500}>
                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                      🚀
                    </span>
                    {userBoostRate}
                    {` ${stakingInfo?.rewardToken.symbol} / week`}
                  </TYPE.black>
                </RowBetween>
              )}

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${numberWithCommas(unclaimedRewardinUSDC)}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledRewardCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you withdraw, the contract will automagically claim {stakingInfo?.rewardToken.symbol} on your behalf!
        </TYPE.main>

        {/* NFT STAKING SECTION */}

        <DataRow style={{ marginBottom: '1rem' }}>
          {stakingInfo?.maxBoosterCount > 0 && !isRewardEnded && !stakingInfo?.stakedAmount.greaterThan('0') && !hasBooster && nftPortfolio.length > 0 && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTDepositClick}
            >
              Deposit <br></br> Boosters
            </ButtonPrimary>
          )}

          {stakingInfo?.maxBoosterCount > 0 && hasBooster && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTWithdrawClick}
            >
              Withdraw <br></br> Boosters
            </ButtonPrimary>
          )}

          {!isRewardEnded && !showAddLiquidityButton && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleLPDepositClick}>
              Deposit LP <br></br> Tokens
            </ButtonPrimary>
          )}

          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={() => setShowLPWithdrawModal(true)}>
              Withdraw LP <br></br> Tokens
            </ButtonPrimary>
          )}
        </DataRow>

        <DataRow style={{ marginBottom: '1rem' }}>
          <InfoLink href={'https://dexscreener.com/cronos/' + pairInfo?.liquidityToken.address} target="_blank">
            View pair analytics ↗
          </InfoLink>
        </DataRow>

        <TYPE.main>{`1 ${stakingInfo?.rewardToken.symbol} = ${rewardTokenPrice.toFixed(4)} USDC`}</TYPE.main>
      </PositionInfo>
    </PageWrapper>
  )
}
