/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { DataCard } from '../earn/styled'
import { JSBI, TokenAmount } from '@cronosdex/sdk'
// import { useActiveWeb3React } from '../../hooks'
// import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useNFTStakingContract } from '../../hooks/useContract'
// import { useDerivedStakeInfo } from '../../state/singleStake/hooks'

// import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'

import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import iamge from '../../assets/svg/262.png'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { NFTStakeInfo } from '../../state/nftStake/hooks'
import { NFTPortfolioInfo } from '../../state/nftPortfolio/hooks'
import GlobalStyles from '@mui/material/GlobalStyles'
import Checkbox from '@mui/material/Checkbox'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    zIndex: 1203
  }
})

const ErrorSection = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`


const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const StyledImageListItemBar = styled(ImageListItemBar)`
  .MuiImageListItemBar-titleWrap {
    display: none;
  }
  .MuiImageListItemBar-actionIcon {
    background: #46499333;
    width: 100%;
    text-align: center;
  }
`

const Styledimg = styled.img`
  width: 75px;
  height: 75px;
  margin: auto;
  display: flex;
`

const CheckboxStyle = styled(Checkbox)`
  color: ${({ theme }) => theme.bg3}!important;
  &.Mui-checked {
    color: ${({ theme }) => theme.bg3}!important;
  }
`

const StyleFormControlLabel = styled(FormControlLabel)`
  margin-right: 0 !important;
`
const StyledFormControl = styled(FormControl)`
  max-height: 430px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 0px 20px !important;
`
interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: NFTStakeInfo
  nftDepositInfo: NFTPortfolioInfo[]
  userLiquidityUnstaked: TokenAmount | undefined
}

interface NFTInfo {
  tokenID: string
  tokenURI: string
  metaData: any
}

export default function NFTBatchUnstakingModal({
  isOpen,
  onDismiss,
  stakingInfo,
  nftDepositInfo,
  userLiquidityUnstaked
}: StakingModalProps) {
  // const { chainId } = useActiveWeb3React()
  const [nftInfoList, setNFTInfoList] = useState<NFTInfo[]>([])
  const [allSelectedNft, setAllSelectedNft] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [previousPortfolio, setPreviousPortfolio] = useState<NFTPortfolioInfo[]>([])
  const [update, setUpdate] = React.useState(false)
  const [allCheck, setAllCheck] = React.useState(false)

  const [metadataFail, setMetadataFail] = React.useState(false)

  async function getReq(url: string): Promise<object> {
    return fetch(url)
      .then(async response => {
        const tokenData = await response.text()
        // tokenData = tokenData.replaceAll("\n", "");
        // tokenData = tokenData.substring(0, tokenData.lastIndexOf(',')) + tokenData.substring(tokenData.lastIndexOf(',') + 1);
        const metaData = JSON.parse(tokenData)
        metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
        return metaData
      })
      .catch(error => {
        console.error('Error:', error)
        return null
      })
  }

  async function getMetaData(portfolioInfo: NFTPortfolioInfo[], operation: string ) {
    if (operation === 'getData') {
      const portList = []
      for (let idx = 0; idx < portfolioInfo.length; idx++) {
        let metaData = {} as any
        if ( stakingInfo?.projectInfo.hasMetadata ){
          let metadataURI = portfolioInfo[idx].tokenURI
          if ( stakingInfo?.projectInfo.tokenURIPrefix ){
            metadataURI = stakingInfo?.projectInfo.tokenURIPrefix + portfolioInfo[idx].tokenURI
          }
          metaData = await getReq(metadataURI)
        } else {
          metaData.image = portfolioInfo[idx].tokenURI
          if ( stakingInfo?.projectInfo.imageURIPrefix ){
            metaData.image = stakingInfo?.projectInfo.imageURIPrefix + portfolioInfo[idx].tokenURI
          } 
        }
        
        if (metaData) {
          metaData.image = metaData.image.replace("ipfs://", "https://ipfs.io/ipfs/")
          portList[idx] = {
            metaData: metaData,
            tokenID: portfolioInfo[idx].tokenID,
            tokenURI: portfolioInfo[idx].tokenURI,
          }
          setNFTInfoList([...portList])
          setUpdate(!update)
        } else {
          portList[idx] = {
            metaData: {image: "https://swap.crodex.app/ant.jpg", name: 'Placeholder'},
            tokenID: portfolioInfo[idx].tokenID,
            tokenURI: portfolioInfo[idx].tokenURI,
          }
          setMetadataFail(true)
          setNFTInfoList([...portList])
          setUpdate(!update)
        }

      }
    } else if (operation === 'updateData') {
      setUpdate(!update)
    }
  }

  const handleChangeNftAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const initialChecked: React.SetStateAction<string[]> = []
    if ((event.target as HTMLInputElement).checked === true) {
      nftDepositInfo.map(item => {
        initialChecked?.push(item.tokenID.toString())
      })
    }
    setAllCheck((event.target as HTMLInputElement).checked)
    setAllSelectedNft(initialChecked)
    getMetaData(nftDepositInfo, 'updateData')
  }

  useEffect(() => {
    if(nftDepositInfo.length === 0){
      setLoading(false)
    }
    else if (nftDepositInfo.length !== 0 && isOpen === true && nftDepositInfo[0].tokenURI !== undefined) {
      setLoading(false)
      if (previousPortfolio !== undefined) {
        if (previousPortfolio.length !== nftDepositInfo.length) {
          getMetaData(nftDepositInfo, 'getData')
          setPreviousPortfolio(nftDepositInfo)
        }
      } else {
        setPreviousPortfolio(nftDepositInfo)
      }
    } else if (nftDepositInfo[0]?.tokenURI === undefined) {
      setLoading(true)
    }
  }, [nftDepositInfo, isOpen, previousPortfolio])

  useEffect(() => {
    if (isOpen === false) {
      setAllSelectedNft([])
      setAllCheck(false)
    }
  }, [isOpen])

  const handleChangeNft = (event: React.ChangeEvent<HTMLInputElement>) => {
    const initialChecked = allSelectedNft

    if ((event.target as HTMLInputElement).checked === true) {
      initialChecked?.push((event.target as HTMLInputElement).value as never)
      if (allCheck === false && initialChecked.length === nftDepositInfo.length) {
        setAllCheck(true)
      }
    } else if ((event.target as HTMLInputElement).checked === false) {
      initialChecked.forEach((element, index) => {
        if (element === (event.target as HTMLInputElement).value) {
          initialChecked.splice(index, 1)
        }
        if (allCheck === true) {
          setAllCheck(false)
        }
      })
    }
    setAllSelectedNft(initialChecked)
    getMetaData(nftDepositInfo, 'updateData')
  }

  // track and parse user input
  // const [typedValue, setTypedValue] = useState('')
  // const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo.stakedAmount.token, userLiquidityUnstaked)
  // const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  // let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  // if (parsedAmountWrapped?.greaterThan('0')) {
  //   hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
  //     stakingInfo.stakedAmount.add(parsedAmountWrapped),
  //     stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
  //     stakingInfo.totalRewardRate
  //   )
  // }
  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // approval data for stake
  // const deadline = useTransactionDeadline()
  // const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)

  // const isArgentWallet = useIsArgentWallet()
  const stakingContract = useNFTStakingContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    setAttempting(true)
    if (stakingContract) {
      if (allSelectedNft.length !== 0) {
        const numberSelectedNFT = allSelectedNft.map(i => Number(i))
        if (stakingInfo?.interactionInfo?.payable) {
          const withdrawFee = stakingInfo?.interactionInfo?.withdrawFee
            ? JSBI.BigInt(
              stakingInfo?.interactionInfo?.withdrawFee * Math.pow(10, 18) * numberSelectedNFT.length
            ).toString()
            : 0
          const estimatedGas = await stakingContract.estimateGas['withdrawNFTBatch'](numberSelectedNFT, { value: withdrawFee }).then(estimatedGasLimit => {return estimatedGasLimit})
          const estimatedGasLimit = estimatedGas.mul(120).div(100).toString()
          stakingContract
            .withdrawNFTBatch(numberSelectedNFT, { value: withdrawFee, gasLimit: estimatedGasLimit})
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Withdraw NFT`
              })
              setHash(response.hash)
            })
            .catch((error: any) => {
              setAttempting(false)
              console.log(error)
            })
        } else {
          const estimatedGas = await stakingContract.estimateGas['withdrawNFTBatch'](numberSelectedNFT).then(estimatedGasLimit => {return estimatedGasLimit})
          const estimatedGasLimit = estimatedGas.mul(120).div(100).toString()
          stakingContract
            .withdrawNFTBatch(numberSelectedNFT, {gasLimit: estimatedGasLimit})
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Withdraw NFT`
              })
              setHash(response.hash)
            })
            .catch((error: any) => {
              setAttempting(false)
              console.log(error)
            })
        }
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  // const onUserInput = useCallback((typedValue: string) => {
  //   setSignatureData(null)
  //   setTypedValue(typedValue)
  // }, [])

  // used for max input button
  // const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  // const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  // const handleMax = useCallback(() => {
  //   maxAmountInput && onUserInput(maxAmountInput.toExact())
  // }, [maxAmountInput, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Batch Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <StyledFormControl>
            <GlobalStyles
              styles={{
                '*::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '*::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                  backgroundColor: '#5b5eb1',
                  outline: '1px solid #5b5eb1',
                  borderRadius: '12px'
                }
              }}
            />
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms'
              }}
              unmountOnExit
            >
              <LinearProgress style={{ margin: '15px' }} color="secondary" />
            </Fade>
            {!loading && nftInfoList.length!==0 &&
                        <FormControlLabel
                        disableTypography={true}
                        label="Select All NFTs"
                        control={
                          <CheckboxStyle id="checkedAll" checked={allCheck} value="checkedAll" onChange={handleChangeNftAll} />
                        }
                      />
            }
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {nftInfoList.map(nftInfo => {
                return (
                  <Grid item xs={4}>
                    <PoolData style={{ padding: '0.5rem' }}>
                      <ImageListItem key={nftInfo.tokenID}>
                        <CustomWidthTooltip title={nftInfo.metaData.name} placement="right">
                          <Styledimg src={nftInfo.metaData.image} alt={iamge} loading="lazy" />
                        </CustomWidthTooltip>
                        <StyledImageListItemBar
                          position="below"
                          actionIcon={
                            <StyleFormControlLabel
                              value={nftInfo.tokenID}
                              control={
                                <CheckboxStyle
                                  id={nftInfo.tokenID}
                                  value={nftInfo.tokenID}
                                  checked={allSelectedNft.some(element => element == nftInfo.tokenID)}
                                  onChange={handleChangeNft}
                                />
                              }
                              label={`#${nftInfo.tokenID}`}
                            />
                          }
                        />
                      </ImageListItem>
                    </PoolData>
                  </Grid>
                )
              })}
            </Grid>
          </StyledFormControl>

          {metadataFail && (
              <ErrorSection dim={true}>
                <TYPE.black fontWeight={600}>NFT metadata failed to load.</TYPE.black>
              </ErrorSection>
            )}


          <RowBetween>
            <ButtonError
              disabled={allSelectedNft.length === 0}
              // error={!!error && !!parsedAmount}
              onClick={onWithdraw}
            >
              {'Withdraw'}
            </ButtonError>
          </RowBetween>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Withdrawing NFT</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              #{allSelectedNft.join(', ')} {stakingInfo?.collection.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Withdrew #{allSelectedNft.join(', ')} {stakingInfo?.collection.symbol}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
