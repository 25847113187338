import { JSBI, Token, TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { METAVERSEHUB, WCRO } from '../../../constants'
import { CRXILLION, SPACE_CRXILLIONS } from '../../../constants/collections'
import { useActiveWeb3React } from '../../../hooks'
import { useFightAdventureContract } from '../../../hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from '../../multicall/hooks'
import { NFTPortfolioInfo } from '../../nftPortfolio/hooks'
import { UINTMAX } from '../../nftStake/hooks'

import CRXILLION_RANKS from '../ranking/crxillionRanks.json'
import SPACE_CRXILLION_RANKS from '../ranking/spaceCrxillionRanks.json'


export const FIGHT_ADVENTURE_CONTRACT_ADDRESS = "0xa7B442D23d85BfB023D219a8dE4CC2B69683539C"
export const GLOBAL_LAST_X_ATTACK_HISTORY = 25
export const DEFAULT_TOTAL_ATTACK_FEE = 20

export interface PowerInfo {
    rank: number
    low: number
    high: number
}

export function powerOfCrxillion(tokenID: any): PowerInfo {
    const jsonObject: any = CRXILLION_RANKS;
    const powerInfo: PowerInfo = {
        rank: jsonObject[tokenID],
        low: 2600 - jsonObject[tokenID] + 301,
        high: 3600
    }
    return powerInfo
}

export function powerOfSpaceCrxillion(tokenID: any): PowerInfo {
    const jsonObject: any = SPACE_CRXILLION_RANKS;
    const powerInfo: PowerInfo = {
        rank: jsonObject[tokenID],
        low: 3000 - jsonObject[tokenID] + 1,
        high: 3600
    }
    return powerInfo
}

export function powerOfCollection(collection: Token, tokenID: any): PowerInfo {

    if (collection === SPACE_CRXILLIONS) {
        return powerOfSpaceCrxillion(tokenID)
    }
    if ( collection === CRXILLION ) {
        return powerOfCrxillion(tokenID)
    }
    return {
        low: 0,
        high: 0,
        rank: 0
    }
}


export interface FightAdventureInfo {
    crxillionCount: number | undefined
    spaceCrxillionCount: number | undefined
    pendingReward: TokenAmount | undefined
    globallyCannotAttackBefore: Date | undefined
    cannotAttackBefore: Date | undefined
    totalAttackFee: TokenAmount | undefined
    attackLoot: TokenAmount | undefined
    attackTax: TokenAmount | undefined
    attackBuyback: TokenAmount | undefined
    globalAttackWait: number | undefined
    individualAttackWait: number | undefined
    joinWait: number | undefined
    isEvent: boolean | undefined
    lootBalanceOf: TokenAmount | undefined
    globalBattleHistory: number[] | undefined
    userAttackHistory: number[] | undefined
    userDefendHistory: number[] | undefined
    totalClaimedLoot: TokenAmount | undefined
    crxillionAmount: number | undefined
    crxillionIDs: number[] | undefined
    spaceCrxillionAmount: number | undefined
    spaceCrxillionIDs: number[] | undefined
}

export function useFightAdventureServiceFee(tokenCount: number | undefined): TokenAmount | undefined {
    const fightAdventureContract = useFightAdventureContract(FIGHT_ADVENTURE_CONTRACT_ADDRESS)
    const serviceFeeRes = useSingleCallResult(fightAdventureContract, 'serviceFee', [tokenCount])
    const serviceFee = (!serviceFeeRes?.loading && serviceFeeRes?.result) ? new TokenAmount(WCRO, serviceFeeRes?.result[0]) : undefined
    return serviceFee
}


export interface BattleHistoryInfo {
    attackerCollection: Token
    defenderCollection: Token
    attackerId: number
    defenderId: number
    attackerHit: number
    defenderHit: number
    reward: TokenAmount
    date: Date
}

function countDownFromX(x: number, n: number): number[] {
    // If n is negative or zero, return an empty array
    if (n <= 0) {
        return [];
    }
    // Create an array containing the last n numbers counting down from x
    const array: number[] = [];
    for (let i = x; i > x - n; i--) {
        if (i > 0) {
            array.push(i);
        }
    }
    return array;
}


export interface JoinableInfo {
    tokenID: number
    joinableDate: Date
}


export function useCrxillionCannotJoinBefore(portfolioInfos: NFTPortfolioInfo[]): JoinableInfo[] {

    const fightAdventureContract = useFightAdventureContract(FIGHT_ADVENTURE_CONTRACT_ADDRESS)

    const tokenURIArgs: any[][] = [ ]
    const portfolioInfoLen = portfolioInfos ? portfolioInfos?.length : 0
    for ( var idx = 0; idx < portfolioInfoLen ?? 0; idx++ ){
      tokenURIArgs.push([portfolioInfos[idx].tokenID])
    }
    // get all proposal states
    const cannotJoinBeforeRes = useSingleContractMultipleData(fightAdventureContract, 'crxillionCannotJoinBefore', tokenURIArgs)
    return cannotJoinBeforeRes
        .map((_p, i) => {
            const joinableList: JoinableInfo = {
                tokenID: parseInt(portfolioInfos[i].tokenID),
                joinableDate: new Date(parseInt(cannotJoinBeforeRes[i]?.result?.[0]) * 1000)
            }
            return joinableList
        })
}


export function useSpaceCrxillionCannotJoinBefore(portfolioInfos: NFTPortfolioInfo[]): JoinableInfo[] {

    const fightAdventureContract = useFightAdventureContract(FIGHT_ADVENTURE_CONTRACT_ADDRESS)

    const tokenURIArgs: any[][] = [ ]
    const portfolioInfoLen = portfolioInfos ? portfolioInfos?.length : 0
    for ( var idx = 0; idx < portfolioInfoLen ?? 0; idx++ ){
      tokenURIArgs.push([portfolioInfos[idx].tokenID])
    }
    // get all proposal states
    const cannotJoinBeforeRes = useSingleContractMultipleData(fightAdventureContract, 'spaceCrxillionCannotJoinBefore', tokenURIArgs)
    return cannotJoinBeforeRes
        .map((_p, i) => {
            const joinableList: JoinableInfo = {
                tokenID: parseInt(portfolioInfos[i].tokenID),
                joinableDate: new Date(parseInt(cannotJoinBeforeRes[i]?.result?.[0]) * 1000)
            }
            return joinableList
        })
}


export function useFightAdventureAttackHistory(attackList: any[]): BattleHistoryInfo[] {

    const fightAdventureContract = useFightAdventureContract(FIGHT_ADVENTURE_CONTRACT_ADDRESS)

    const attackIdArgs: any[][] = []
    const attackListLen = attackList ? attackList?.length : 0
    for (var idx = 0; idx < attackListLen ?? 0; idx++) {
        attackIdArgs.push([(parseInt(attackList[idx]) - 1).toString()])
    }
    // get all proposal states
    const attackHistoryRes = useSingleContractMultipleData(fightAdventureContract, 'attackHistory', attackIdArgs)
    return attackHistoryRes
        .map((_p, i) => {
            const attackHistory: BattleHistoryInfo = {
                attackerCollection: attackHistoryRes[i]?.result?.[0] ? CRXILLION : SPACE_CRXILLIONS,
                defenderCollection: attackHistoryRes[i]?.result?.[0] ? SPACE_CRXILLIONS : CRXILLION,
                attackerId: parseInt(attackHistoryRes[i]?.result?.[1]),
                defenderId: parseInt(attackHistoryRes[i]?.result?.[2]),
                attackerHit: parseInt(attackHistoryRes[i]?.result?.[3]),
                defenderHit: parseInt(attackHistoryRes[i]?.result?.[4]),
                reward: new TokenAmount(METAVERSEHUB, JSBI.BigInt(attackHistoryRes[i]?.result?.[5] ?? '0')),
                date: new Date(parseInt(attackHistoryRes[i]?.result?.[6]) * 1000)
            }
            return attackHistory
        })
}

// gets the staking info from the network for the active chain id
export function useFightAdventure(): FightAdventureInfo {

    const { chainId, account } = useActiveWeb3React()

    const fightAdventureContract = useFightAdventureContract(FIGHT_ADVENTURE_CONTRACT_ADDRESS)

    const pendingRewardRes = useSingleCallResult(fightAdventureContract, 'pendingReward')
    const crxillionCountRes = useSingleCallResult(fightAdventureContract, 'crxillionCount')
    const spaceCrxillionCountRes = useSingleCallResult(fightAdventureContract, 'spaceCrxillionCount')
    const attackCountRes = useSingleCallResult(fightAdventureContract, 'attackCount')

    const totalAttackFeeRes = useSingleCallResult(fightAdventureContract, 'totalAttackFee')
    const attackTaxRes = useSingleCallResult(fightAdventureContract, 'attackTax')
    const attackBuybackRes = useSingleCallResult(fightAdventureContract, 'attackBuyback')

    const globalAttackWaitRes = useSingleCallResult(fightAdventureContract, 'globalAttackWait')
    const individualAttackWaitRes = useSingleCallResult(fightAdventureContract, 'individualAttackWait')
    const joinWaitRes = useSingleCallResult(fightAdventureContract, 'joinWait')

    const globallyCannotAttackBeforeRes = useSingleCallResult(fightAdventureContract, 'globallyCannotAttackBefore')
    const cannotAttackBeforeRes = useSingleCallResult(fightAdventureContract, 'cannotAttackBefore', [account ?? undefined])

    const userInfoRes = useSingleCallResult(fightAdventureContract, 'getUserInfo', [account ?? undefined])
    const lootBalanceOfRes = useSingleCallResult(fightAdventureContract, 'lootBalanceOf', [account ?? undefined])
    const userAttackHistoryRes = useSingleCallResult(fightAdventureContract, 'getUserAttackHistory', [account ?? undefined])
    const userDefendHistoryRes = useSingleCallResult(fightAdventureContract, 'getUserDefendHistory', [account ?? undefined])

    return useMemo(() => {

        let memo: FightAdventureInfo = {
            crxillionCount: undefined,
            spaceCrxillionCount: undefined,
            globallyCannotAttackBefore: undefined,
            cannotAttackBefore: undefined,
            pendingReward: undefined,
            totalAttackFee: undefined,
            attackLoot: undefined,
            attackTax: undefined,
            attackBuyback: undefined,
            globalAttackWait: undefined,
            individualAttackWait: undefined,
            joinWait: undefined,
            isEvent: undefined,
            lootBalanceOf: undefined,
            globalBattleHistory: undefined,
            userAttackHistory: undefined,
            userDefendHistory: undefined,
            totalClaimedLoot: undefined,
            crxillionAmount: undefined,
            crxillionIDs: undefined,
            spaceCrxillionAmount: undefined,
            spaceCrxillionIDs: undefined
        }

        if (!chainId) return memo
        else {
            if (
                !attackCountRes?.loading &&
                !globallyCannotAttackBeforeRes?.loading &&
                !cannotAttackBeforeRes?.loading &&
                !crxillionCountRes?.loading &&
                !spaceCrxillionCountRes?.loading &&
                !pendingRewardRes?.loading &&
                !totalAttackFeeRes?.loading &&
                !attackTaxRes?.loading &&
                !attackBuybackRes?.loading &&
                !globalAttackWaitRes?.loading &&
                !individualAttackWaitRes?.loading &&
                !joinWaitRes?.loading &&
                !lootBalanceOfRes?.loading &&
                !userInfoRes?.loading &&
                !userAttackHistoryRes?.loading &&
                !userDefendHistoryRes?.loading
            ) {
                if (
                    attackCountRes?.error ||
                    globallyCannotAttackBeforeRes?.error ||
                    cannotAttackBeforeRes?.error ||
                    crxillionCountRes?.error ||
                    spaceCrxillionCountRes?.error ||
                    pendingRewardRes?.error ||
                    totalAttackFeeRes?.error ||
                    attackTaxRes?.error ||
                    attackBuybackRes?.error ||
                    globalAttackWaitRes?.error ||
                    individualAttackWaitRes?.error ||
                    joinWaitRes?.error ||
                    lootBalanceOfRes?.error ||
                    userInfoRes?.error ||
                    userAttackHistoryRes?.error ||
                    userDefendHistoryRes?.error
                ) {
                    console.error('Failed to load Fight Adventure info')
                    return memo
                }

                // data from contract
                memo.crxillionCount = crxillionCountRes?.result?.[0]
                memo.spaceCrxillionCount = spaceCrxillionCountRes?.result?.[0]
                memo.pendingReward = new TokenAmount(METAVERSEHUB, JSBI.BigInt(pendingRewardRes.result?.[0]) ?? '0')

                memo.totalAttackFee = new TokenAmount(WCRO, JSBI.BigInt(totalAttackFeeRes.result?.[0]))
                memo.attackTax = new TokenAmount(WCRO, JSBI.BigInt(attackTaxRes.result?.[0]))
                memo.attackBuyback = new TokenAmount(WCRO, JSBI.BigInt(attackBuybackRes.result?.[0]))
                memo.attackLoot = memo.totalAttackFee.subtract( memo.attackTax.add(memo.attackBuyback))
                memo.isEvent = DEFAULT_TOTAL_ATTACK_FEE > parseInt(memo.totalAttackFee.toFixed(0)) ? true : false

                // in minutes
                memo.globalAttackWait = parseInt(globalAttackWaitRes.result?.[0]?.toString()) / ( 60 )
                // in hours
                memo.individualAttackWait = parseInt(individualAttackWaitRes.result?.[0]?.toString()) / ( 60 * 60 )
                // in days
                memo.joinWait = parseInt(joinWaitRes.result?.[0]?.toString()) / ( 60 * 60 * 24 )

                memo.lootBalanceOf = new TokenAmount(WCRO, JSBI.BigInt(lootBalanceOfRes.result?.[0] ?? '0'))
                memo.userAttackHistory = userAttackHistoryRes?.result?.[0]
                memo.userDefendHistory = userDefendHistoryRes?.result?.[0]
                memo.globalBattleHistory = countDownFromX(parseInt(attackCountRes?.result?.[0] ?? '0'), GLOBAL_LAST_X_ATTACK_HISTORY)

                memo.globallyCannotAttackBefore =  new Date(parseInt(globallyCannotAttackBeforeRes?.result?.[0]) * 1000)
                memo.cannotAttackBefore =  new Date(parseInt(cannotAttackBeforeRes?.result?.[0]) * 1000)
                // user info
                memo.totalClaimedLoot = new TokenAmount(WCRO, JSBI.BigInt(userInfoRes.result?.[0] ?? '0'))
                memo.crxillionAmount = userInfoRes.result?.[1]
                let userDepositedCrxillions = []
                for (var idx = 0; idx < userInfoRes.result?.[2].length; idx++) {
                    const tokenIDstr = userInfoRes.result?.[2][idx]?.toString()
                    if (tokenIDstr !== UINTMAX)
                        userDepositedCrxillions.push((parseInt(tokenIDstr)))
                }
                memo.crxillionIDs = userDepositedCrxillions

                memo.spaceCrxillionAmount = userInfoRes.result?.[3]
                let userDepositedSpaceCrxillions = []
                for (var idx = 0; idx < userInfoRes.result?.[4].length; idx++) {
                    const tokenIDstr = userInfoRes.result?.[4][idx]?.toString()
                    if (tokenIDstr !== UINTMAX)
                        userDepositedSpaceCrxillions.push((parseInt(tokenIDstr)))
                }
                memo.spaceCrxillionIDs = userDepositedSpaceCrxillions
                return memo
            }
            return memo
        }
    }, [chainId, account, attackBuybackRes, attackTaxRes, globalAttackWaitRes, individualAttackWaitRes, joinWaitRes, attackCountRes, lootBalanceOfRes, globallyCannotAttackBeforeRes, cannotAttackBeforeRes, totalAttackFeeRes, userAttackHistoryRes, userDefendHistoryRes, userInfoRes, crxillionCountRes, spaceCrxillionCountRes, pendingRewardRes])
}
