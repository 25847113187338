import { ChainId, Token } from '@cronosdex/sdk'


export const EBISUSBAY = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'EbisusBay',
  'EbisusBay',
)

export const CAPONEBEAR = new Token(
  ChainId.CRONOSMAINNET,
  '0x11138B85adCAdA7324d70B32D856333Da8714D4b',
  18,
  'Capone Bear NFT',
  'Capone Bear NFT'
)


export const TESTCRXILLION = new Token(
  ChainId.CRONOSMAINNET,
  '0x164FD8B3Af0e82F1Ed9ab55478D886d1b208e07C',
  18,
  'TESTCRXILLION',
  'TESTCRXILLION'
)

export const BURN_CRO_BEARS_AND_BULLS = new Token(
  ChainId.CRONOSMAINNET,
  '0xc730470792fC8E80B1742E2ad99d2BBda24c0614',
  18,
  'Burn Cro Bulls & Bears',
  'Burn Cro Bulls & Bears'
)

export const SPACE_CRXILLIONS = new Token(
  ChainId.CRONOSMAINNET,
  '0xB6782F208ABe10E8962407d139f6698Bfc4FD13F',
  18,
  'Space Crxillion',
  'Space Crxillion'
)

export const CRXILLION = new Token(
  ChainId.CRONOSMAINNET,
  '0xE1bAa24525B5f48F9B9df54802E44E85c0D81099',
  18,
  'Crxillion',
  'Crxillion'
)

export const CROUGARS = new Token(
  ChainId.CRONOSMAINNET,
  '0xdCcCEd571dcbC82Bbeadf95Af20ee4442E21208C',
  18,
  'Crougars',
  'Crougars'
)

export const WEALTH_WOLFS = new Token(
  ChainId.CRONOSMAINNET,
  '0x22DDE2253698da7cbf9AeBbEB285698dD9001885',
  18,
  'Wealth Wolfs',
  'Wealth Wolfs'
)

export const COWZ = new Token(
  ChainId.CRONOSMAINNET,
  '0xdbFDf81D1fDD2e79e8ffaDE50c219452587e9488',
  18,
  'Cowz',
  'Cowz'
)

export const CROUGARX = new Token(
  ChainId.CRONOSMAINNET,
  '0x45958Cb5ede8dc9E1a797C1526D4523db1471F15',
  18,
  'Crougarx',
  'Crougarx'
)

export const CYBORG_BROTHERHOOD = new Token(
  ChainId.CRONOSMAINNET,
  '0xd0062DEb460Eac0cB7cE77C312F8aa58378b6d8a',
  18,
  'Cyborg Brotherhood',
  'Cyborg Brotherhood'
)

export const CROMAN_SOCIETY = new Token(
  ChainId.CRONOSMAINNET,
  '0x4c555b1f854d6127d397cf49f84f496564804c81',
  18,
  'Croman Society',
  'Croman Society'
)

export const CRONOGODS = new Token(
  ChainId.CRONOSMAINNET,
  '0xD366dc03fE6cE8fd620A48Cb2b9a0F87752aF15b',
  18,
  'Cronogods',
  'Cronogods'
)

export const CRONOGODDESSES = new Token(
  ChainId.CRONOSMAINNET,
  '0x972A2DDd1E364105cDe1f6E70f8078974A3D7A9b',
  18,
  'CronoGoddesses',
  'CronoGoddesses'
)

export const CMB = new Token(
  ChainId.CRONOSMAINNET,
  '0x939b90c529F0e3a2C187E1b190Ca966a95881FDe',
  18,
  'Cronos Monkey Business',
  'Cronos Monkey Business'
)

export const CAT_WIF_GAT = new Token(
  ChainId.CRONOSMAINNET,
  '0x4FCC767F7bd840695F2Af59a8e92aED4A16090E4',
  18,
  'Cat Wif Gat',
  'Cat Wif Gat'
)

export const METAVERSEPIXELS = new Token(
  ChainId.CRONOSMAINNET,
  '0x19E1F891002240fBEA77ccC2aDb6e73b93B3b97A',
  18,
  'Metaverse Pixels',
  'Metaverse Pixels'
)

export const CRONOSVERSE_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x0aCDA31Cf1F301a7Eb8f988D47F708FbA058F8f5',
  18,
  'CronosVerse',
  'CronosVerse'
)

export const CRONOS_GOATS = new Token(
  ChainId.CRONOSMAINNET,
  '0x93A3906FF14C9Aa4F33377aE94Cc4A9858D346b9',
  18,
  'Cronos Goats',
  'Cronos Goats'
)

export const CROODLE_APE_V2 = new Token(
  ChainId.CRONOSMAINNET,
  '0x2330B55ca30b0398D1cC9C714b3E8a6240Cc0B66',
  18,
  'Croodle Ape V2',
  'Croodle Ape V2'
)

export const TIME_TROPS = new Token(
  ChainId.CRONOSMAINNET,
  '0x55c8f67e6D3f037297Daf94AD2bCC67CC477BEa9',
  18,
  'Time Troops',
  'Time Troops'
)

export const SHINY_CRO_PEPE = new Token(
  ChainId.CRONOSMAINNET,
  '0x3ac8d48DdF2C09460F435206BDC7BA04F2560114',
  18,
  'Shiny CroPepe',
  'Shiny CroPepe'
)

export const SPICY_CROPEPE = new Token(
  ChainId.CRONOSMAINNET,
  '0xbCC5101564a626CE9FC7FF8287573d42bCe66f97',
  18,
  'Spicy CroPepe',
  'Spicy CroPepe'
)

export const GREENSTIX_OG_CANDLE = new Token(
  ChainId.CRONOSMAINNET,
  '0x3ed598C23Bd46a171158aaC2035b0282AC1f9217',
  18,
  'GreenStix OG Candle',
  'GreenStix OG Candle'
)

export const STRANGE_CAMEL_SOCIETY = new Token(
  ChainId.CRONOSMAINNET,
  '0x9B168D4D0caEbC30b09b9a0B93e3C075862F6172',
  18,
  'Strange Camel Society',
  'Strange Camel Society'
)

export const GDRTOKEN_FOUNDERS = new Token(
  ChainId.CRONOSMAINNET,
  '0xE6C0e1b419Dd838E9C0E351e7F3f61b8B613aDF7',
  18,
  'GDRToken Founders',
  'GDRToken Founders'
)

export const CRONK_OG = new Token(
  ChainId.CRONOSMAINNET,
  '0xdeb93385ab6cCD19210b447841F25c292DdD5E26',
  18,
  'CROnk OG',
  'CROnk OG'
)

export const BBELITE = new Token(
  ChainId.CRONOSMAINNET,
  '0x98F9195CD7842Aef783d1255CFe6AA97F32b2564',
  18,
  'Busy Bears Elite Club Card',
  'Busy Bears Elite Club Card'
)

export const ARMYJACKALS = new Token(
  ChainId.CRONOSMAINNET,
  '0xa423B83f5e8C5A2b47676316f22B91E6dFcE3Cd1',
  18,
  'ArmyJackals',
  'ArmyJackals'
)

export const CRONOS_TOWERS = new Token(
  ChainId.CRONOSMAINNET,
  '0x37f25F400983256e84c39749d05d65fD96Ca461d',
  18,
  'Cronos Towers',
  'Cronos Towers'
)

export const ARMY_100K_TOKEN_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x48354e47f58428017861FAd4E262271027BEb8Af',
  18,
  '$Army 100k NFT',
  '$Army 100k NFT'
)

export const CRX_GOLD_TICKET = new Token(
  ChainId.CRONOSMAINNET,
  '0xE1bAa24525B5f48F9B9df54802E44E85c0D81099',
  18,
  'CRX GOLD TICKET',
  'CRX GOLD TICKET'
)

export const CRX_SILVER_TICKET = new Token(
  ChainId.CRONOSMAINNET,
  '0xE1bAa24525B5f48F9B9df54802E44E85c0D81099',
  18,
  'CRX SILVER TICKET',
  'CRX SILVER TICKET'
)

export const CRX_BRONZE_TICKET = new Token(
  ChainId.CRONOSMAINNET,
  '0xE1bAa24525B5f48F9B9df54802E44E85c0D81099',
  18,
  'CRX BRONZE TICKET',
  'CRX BRONZE TICKET'
)

export const CROWD_TICKET = new Token(
  ChainId.CRONOSMAINNET,
  '0xE1bAa24525B5f48F9B9df54802E44E85c0D81099',
  18,
  'CROWD TICKET',
  'CROWD TICKET'
)

export const CROKINGNFT = new Token(
  ChainId.CRONOSMAINNET,
  '0xAD4bDA67bB8b6A502496EEF4dFba2ffdBAc38dB6',
  18,
  'CroKing NFT',
  'CroKing NFT'
)

export const BOBA_NFTEA = new Token(
  ChainId.CRONOSMAINNET,
  '0x0125Ce96f9A4324dD514D20bD8324c696619F9fe',
  18,
  'BOBA NFTEA',
  'BOBA NFTEA'
)

export const RECKLESS_ROBOTS = new Token(
  ChainId.CRONOSMAINNET,
  '0xD56AFcdB787e233325757D3ED7A987F11FB3fa08',
  18,
  'RECKLESS ROBOTS',
  'RECKLESS ROBOTS'
)

export const LOOTERS = new Token(
  ChainId.CRONOSMAINNET,
  '0xeE0F2Aa0A15d8A7Ddc8f0eE59bC555e43555C98d',
  18,
  'Looters',
  'Looters'
)

export const CROMELEONS = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'CROmeleons',
  'CROmeleons'
)

export const SCARECRO = new Token(
  ChainId.CRONOSMAINNET,
  '0x5f399c9029f397e3f6b2e3be89478884fd201e57',
  18,
  'ScareCro',
  'ScareCro'
)

export const CORGICLUB = new Token(
  ChainId.CRONOSMAINNET,
  '0xa506b3938377635Fa9C091E9af8748Fa1C9A2424',
  18,
  'Corgi Club',
  'Corgi Club'
)

export const MARIESCYBORGS = new Token(
  ChainId.CRONOSMAINNET,
  '0x2245a6e61dE5661D93183Ab3ADd8924b1DC774db',
  18,
  "Marie's Cyborgs",
  "Marie's Cyborgs"
)


export const KARDIA = new Token(
  ChainId.CRONOSMAINNET,
  '0x2ea93ef899a28d6049146b98c7CFCA4C7f541a8d',
  18,
  "Kardia",
  "Kardia"
)

export const CROARMY_DIAMONDHAND = new Token(
  ChainId.CRONOSMAINNET,
  '0xd301f02e44b1eea1ff925b90755e16d6ccf7c6dd',
  18,
  "CroArmy Diamond Hand",
  "CroArmy Diamond Hand"
)


export const BUSHIF = new Token(
  ChainId.CRONOSMAINNET,
  '0x0D21051BD422A9A54988d25b1Be1211Dc7f3D7bD',
  18,
  "Bushicro Female Warrior",
  "Bushicro Female Warrior"
)

export const CRONOS_FOX_ELITE = new Token(
  ChainId.CRONOSMAINNET,
  '0xaC22b85bd9B71C7C6FE874C8F710A3AE45f76C76',
  18,
  "Cronos Fox Elite",
  "Cronos Fox Elite"
)

export const CRONOS_BULLS_AND_BEARS = new Token(
  ChainId.CRONOSMAINNET,
  '0xAE36CaD133c11E590bfA4c632A02f85965101BB7',
  18,
  "Cronos Bulls&Bears",
  "Cronos Bulls&Bears"
)

export const NEW_CRONOS_BULLS_AND_BEARS = new Token(
  ChainId.CRONOSMAINNET,
  '0xBB3b93a962442ef33246DA27eB1636e15A3b9065',
  18,
  "(New) Cronos Bulls&Bears",
  "(New) Cronos Bulls&Bears"
)

export const TRIPPY_TREASURE_CHEST = new Token(
  ChainId.CRONOSMAINNET,
  '0x9C9BB3BDa7914437C466f833D2BE25a405957a3a',
  18,
  "Trippy Treasure Chest",
  "Trippy Treasure Chest"
)


export const GOBLIN_CRO_PET = new Token(
  ChainId.CRONOSMAINNET,
  '0xe3f94fCAf605F24B30A0567719b62E4775500412',
  18,
  "Goblin Cro Pet",
  "Goblin Cro Pet"
)

export const CRONOS_CHIBIS = new Token(
  ChainId.CRONOSMAINNET,
  '0x84Fda8563E6556a7Fb1578E10e114dB56d64638d',
  18,
  "Cronos Chibis",
  "Cronos Chibis"
)

export const ALIEN_APOCALYPSE = new Token(
  ChainId.CRONOSMAINNET,
  '0x5628c7a229ff920e3c7aB668107e822Fa84244B4',
  18,
  "Alien Apocalypse",
  "Alien Apocalypse"
)

export const MAFIA_SAFE_HOUSE = new Token(
  ChainId.CRONOSMAINNET,
  '0xeD743C93C8f5C105655703B3847777Add81E05e4',
  18,
  "Mafia Safe House",
  "Mafia Safe House"
)

// CONTRACT MISSING
export const ALIENFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x39128237ea72040B96D3ea516619A226126ec838',
  18,
  'ALIENFT',
  'ALIENFT'
)

export const ALIENCRO_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x4fa95F07Ce43335552F7fb04eA5B07E2C93AB620',
  18,
  'ALIENCRO',
  'ALIENCRO'
)


export const THE_SPORTS_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0xF4C80B77AA1F73E09348693a81d744Fcd1263A2D',
  18,
  'The Sports NFT',
  'The Sports NFT'
)

export const ICYCRO_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x1aBBA40B94070Bf699970d8fCA9c71C1168273d3',
  18,
  'ICY VIP',
  'ICY VIP'
)

export const ICY_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x0Ea3bE4Ca12b7B43e974264a210f4de0e273A027',
  18,
  'ICY',
  'ICY'
)

export const LIGHTWAVE_BOOSTER = new Token(
  ChainId.CRONOSMAINNET,
  '0x4Ffca5C2790ef342c1EF25d48D1FFc8a2B4b1D9A',
  18,
  'Lightwave Booster',
  'Lightwave Booster'
)

export const DEGENZ_SKULLS = new Token(
  ChainId.CRONOSMAINNET,
  '0xe46837d6bf5b6f6c90c1b170aecbfdec0e4425ea',
  18,
  'Degenz Skulls',
  'Degenz Skulls'
)

export const GANGVERSE_SOCIAL_CLUB = new Token(
  ChainId.CRONOSMAINNET,
  '0xaBaa122B9E6B64FCBDD55A03eEb8729220F9C589',
  18,
  'Gangverse Social Club',
  'Gangverse Social Club'
)

export const BABY_CROGES_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x1d954Bd544f203bdDC52Fd8f03Eb7Ea4aDbDF3e2',
  18,
  'Baby Croges',
  'Baby Croges'
)

export const NOBUDDIES_GEN2 = new Token(
  ChainId.CRONOSMAINNET,
  '0x1fE73D4Cc73ea313056E41fe82E58d59D29c6592',
  18,
  'NoBuddies (Gen 2)',
  'NoBuddies (Gen 2)'
)

export const CRONOS_BOT_SOCIETY = new Token(
  ChainId.CRONOSMAINNET,
  '0x57bB37249fAaC77c404a3CddE08687A20aA7a77D',
  18,
  'Cronos Bot Society',
  'Cronos Bot Society'
)

export const CRO_PUNK = new Token(
  ChainId.CRONOSMAINNET,
  '0xBd75b085A99Dd29E04C3A35B090A4eD8102447CA',
  18,
  'CroPunks Club',
  'CroPunks Club'
)

export const CHEFZ = new Token(
  ChainId.CRONOSMAINNET,
  '0x1375D3D2169C66d325514EB61933A08c276Ca531',
  18,
  'Chefz',
  'Chefz'
)

// CONTRACT MISSING
export const CRONOS_FOOTBALL_CLUB = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'Cronos Football Club',
  'Cronos Football Club'
)


export const ZOMBABIES_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0xD0BD375a43B58Fd8329980898802667a64623F60',
  18,
  'Zombabies',
  'Zombabies'
)

export const CRYPTOCHAD = new Token(
  ChainId.CRONOSMAINNET,
  '0x41524cE5E966963CC76D87b39E3E8aac21bCC3FD',
  18,
  'CryptoChad',
  'CryptoChad'
)

export const CRONOSRAGDOLL = new Token(
  ChainId.CRONOSMAINNET,
  '0x21E68422011a330beFdf45b0E45072c947316C95',
  18,
  'Cronos Ragdoll',
  'Cronos Ragdoll'
)

export const CROARMY_JARHEADS = new Token(
  ChainId.CRONOSMAINNET,
  '0xD06eF4dA6d75f6E4047999d7D9b2f899688f31a0',
  18,
  'CroArmy Jarheads',
  'CroArmy Jarheads'
)

export const REBEL_PUNKS = new Token(
  ChainId.CRONOSMAINNET,
  '0xc7E77C602D549747AB33C2F0137Cbcb42eeF2bB8',
  18,
  'Rebel Punks',
  'Rebel Punks'
)


export const CROFLIPS_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x24116CF1B25C3A284d39F5E357865E238143084C',
  18,
  'Croflips',
  'Croflips'
)

export const BUSHIM = new Token(
  ChainId.CRONOSMAINNET,
  '0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0',
  18,
  "Bushicro Male Warrior",
  "Bushicro Male Warrior"
)

export const BRAINDEAD_MONKEYS = new Token(
  ChainId.CRONOSMAINNET,
  '0xda663336FEC3Ef6e310be6522F35E391aAE6F43a',
  18,
  "Braindead Monkeys",
  "Braindead Monkeys"
)

export const CROFAM_LADIES_VIP = new Token(
  ChainId.CRONOSMAINNET,
  '0x5286c663e03A2e4c155f6CFe595D61E07f136A34',
  18,
  "Crofam Ladies VIP",
  "Crofam Ladies VIP"
)

export const GREEN_CARD = new Token(
  ChainId.CRONOSMAINNET,
  '0xd565Af56d9bbf2DB76b2DE0E2D18B524736ae83b',
  18,
  "GreenRoom Access Pass",
  "GreenRoom Access Pass",
)

export const ROBOTMEERKAT = new Token(
  ChainId.CRONOSMAINNET,
  '0xB41398EE9A7a9c7570F1973690Ce98370C3a78E4',
  18,
  'Robotmeerkat',
  'Robotmeerkat'
)

export const REBEL_KANGAS = new Token(
  ChainId.CRONOSMAINNET,
  '0xB494b51a76E9b95708c9Fa9D1477FE5e3eFAf281',
  18,
  'Rebel Kangas',
  'Rebel Kangas'
)

export const SMART_MARS = new Token(
  ChainId.CRONOSMAINNET,
  '0x4B2e1f709Df771F2111f74522F79A97F95aAf1D6',
  18,
  'Smart Martians',
  'Smart Martians'
)

export const CROLON_MARS = new Token(
  ChainId.CRONOSMAINNET,
  '0xcAe06e7B36cbb3BCe19f41640f1104e99F5395F4',
  18,
  'Crolon Mars',
  'Crolon Mars'
)

export const SHROOK_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0xDA556F996f864d0E8Fc833cF66f3813d4642897b',
  18,
  'Shrook NFT',
  'Shrook NFT'
)


export const COLONEL_TENDIE = new Token(
  ChainId.CRONOSMAINNET,
  '0x3d5a4188fd85D1A5D4d21fA986e62E3567A5A288',
  18,
  'Colonel Tendies',
  'Colonel Tendies'
)

export const BLUMIES = new Token(
  ChainId.CRONOSMAINNET,
  '0xB278947b945eB7dea7F1822A1801d7e9aEdD8e63',
  18,
  'Blumies',
  'Blumies'
)


export const CROWBOYS = new Token(
  ChainId.CRONOSMAINNET,
  '0x46A417dD49C8e29637a27a371f19ad889767BC99',
  18,
  'Crowboys Genesis Crates',
  'Crowboys Genesis Crates'
)

export const ICY_VALKYRIES = new Token(
  ChainId.CRONOSMAINNET,
  '0x07174D2B657b2243E5C11Df2Cd3D4afD1E45DBBb',
  18,
  'Icy Valkyries',
  'Icy Valkyries'
)

export const CROVILIANS = new Token(
  ChainId.CRONOSMAINNET,
  '0x130a6c2884325d302830179C691D328dDAd9b78C',
  18,
  'Crovilians',
  'Crovilians'
)

export const ICY_VIKING_TREASURE = new Token(
  ChainId.CRONOSMAINNET,
  '0x7677cbE702Ff3d0e66d3BaC0fD71Cb641A276Ae0',
  18,
  'Icy Viking Treasure Chests',
  'Icy Viking Treasure Chests'
)

export const SQUID_CROC_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x10571Ca51976b6565Ef3791AAD8997D85A82dB63',
  18,
  'Squid Croc',
  'Squid Croc'
)

export const CRONOS_WOMEN = new Token(
  ChainId.CRONOSMAINNET,
  '0x91B0C2e86576AC85A096599300BfDF6b2F1A9b22',
  18,
  'Cronos Women',
  'Cronos Women'
)

export const BAD_BILLY_GOATS = new Token(
  ChainId.CRONOSMAINNET,
  '0xd055ECAedD74815DB72458C055Daffdc12e59E41',
  18,
  'Bad Billy Goats',
  'Bad Billy Goats'
)

export const CROHOGS = new Token(
  ChainId.CRONOSMAINNET,
  '0xFD265797d7Bc6C25f2Cd148ec12f36a8eE00862a',
  18,
  'CroHogs',
  'CroHogs'
)

export const BAD_TREEHOUSE = new Token(
  ChainId.CRONOSMAINNET,
  '0x302789418f6A5DfDe9faB3b8FE9Bc4EEe020E84F',
  18,
  'Bad Treehouse',
  'Bad Treehouse'
)

export const THUNDER_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x20aAf55ce6784E2B43A6A143f664Cd9602B04848',
  18,
  'Thunder',
  'Thunder'
)

export const PUMPED_SHINU = new Token(
  ChainId.CRONOSMAINNET,
  '0xe821a4b88ab0a4d263b962a2417dc63cb0ba789e',
  18,
  'Pumped Shinu',
  'Pumped Shinu'
)

export const LADY_SHINU = new Token(
  ChainId.CRONOSMAINNET,
  '0x0e645F11fe29aCA0d016471586817dB8A82eED66',
  18,
  'Lady Shinu',
  'Lady Shinu'
)

export const BABY_PHENIX = new Token(
  ChainId.CRONOSMAINNET,
  '0xCbb5D78e3042c7E079342FC0bFe1BC2b04522F11',
  18,
  'Baby Phenix',
  'Baby Phenix'
)

export const BB_TREASURY_COIN = new Token(
  ChainId.CRONOSMAINNET,
  '0xE8c9eC751C93fe8E7e12567FcF6Aaf2793e007a1',
  18,
  'BB Treasury Coins',
  'BB Treasury Coins'
)

export const TRAPPED_CROCOW = new Token(
  ChainId.CRONOSMAINNET,
  '0x856bf4F1A9A77EB285AC19F1B03c2B76E7841ccA',
  18,
  'Trapped CroCow',
  'Trapped CroCow'
)

export const BAD_MEERKAT = new Token(
  ChainId.CRONOSMAINNET,
  '0x22B2f2223d26f27c27494efbF4E6646812193a43',
  18,
  'Bad Meerkat',
  'Bad Meerkat'
)

export const BAD_MEERKAT_DEGEN = new Token(
  ChainId.CRONOSMAINNET,
  '0xdc9B6e10E0648E2258afe6705690C1E81cf933d7',
  18,
  'Bad Meerkat Degen',
  'Bad Meerkat Degen'
)

export const WICKED_AZZ_PAWNZ = new Token(
  ChainId.CRONOSMAINNET,
  '0x0bA3D2BE7977075CbDAE40ccBBf73c4FEbfdcb4A',
  18,
  'Wicked Azz Pawnz',
  'Wicked Azz Pawnz'
)

export const CRONOS_Y00TS = new Token(
  ChainId.CRONOSMAINNET,
  '0xf8c82cc093D22f6dC782F0D781f4770E17266C23',
  18,
  'Cronos y00ts',
  'Cronos y00ts'
)

export const CRO_SUCCUBUS = new Token(
  ChainId.CRONOSMAINNET,
  '0x899e75ec5e229fc80683e6fe4271600da4aadeb0',
  18,
  'Cro Succubus',
  'Cro Succubus'
)


export const LA_BRIGADE_DE_CUISINE = new Token(
  ChainId.CRONOSMAINNET,
  '0x5702C37deD614a5d64dA6ec1071f93fb236Ba7C9',
  18,
  'La Brigade De Cuisine',
  'La Brigade De Cuisine'
)

export const BOB_ADVENTURES = new Token(
  ChainId.CRONOSMAINNET,
  '0x7841dA84C0eC87744306C6420Eb039b8Cbd39d70',
  18,
  'BOB Adventures',
  'BOB Adventures'
)

export const CROSSBREEDS = new Token(
  ChainId.CRONOSMAINNET,
  '0x3403692d3527058925162f1F67370cB632Dc9b6b',
  18,
  'Crossbreeds',
  'Crossbreeds'
)

export const CRAZY_CROSSBREEDS = new Token(
  ChainId.CRONOSMAINNET,
  '0x4AEef4fbe0e32b9b0aEF6c43DD2625BCA5B9501A',
  18,
  'Crazy Crossbreeds',
  'Crazy Crossbreeds'
)


export const MAFIA = new Token(
  ChainId.CRONOSMAINNET,
  '0xc7294d93084af03ecdc6a18ae9aa3eebeda0502a',
  18,
  'Mafia',
  'Mafia'
)

export const CRO_OF_THRONES = new Token(
  ChainId.CRONOSMAINNET,
  '0x42ED65e25546585fe87eFBB7C6B382E92c3cB8E7',
  18,
  'Cro of Thrones',
  'Cro of Thrones'
)

export const TEENAGE_PHENIX = new Token(
  ChainId.CRONOSMAINNET,
  '0x083fa144084a1b062157578e58f4ad4bc2154861',
  18,
  'Teenage Phenix',
  'Teenage Phenix'
)

export const CRONOS_BOSSES = new Token(
  ChainId.CRONOSMAINNET,
  '0xd91091EB14B71e3CD9f0F39C4589720F665410de',
  18,
  'Cronos Bosses',
  'Cronos Bosses'
)

export const APES_UTOPIA = new Token(
  ChainId.CRONOSMAINNET,
  '0xCe55EDF574FbB862C34CacC05f8386e26641B195',
  18,
  'Apes Utopia',
  'Apes Utopia'
)

export const TRAPPAZOIDS = new Token(
  ChainId.CRONOSMAINNET,
  '0x266F8324EEe7CD663dD25837322aF985b80bF841',
  18,
  'Trappazoids',
  'Trappazoids'
)

export const BUSY_BEARS = new Token(
  ChainId.CRONOSMAINNET,
  '0x4e42d23d4ca068bebe6985bcef741dc0ff87abb2',
  18,
  'Busy Bears',
  'Busy Bears'
)

export const PIXEL_CITY = new Token(
  ChainId.CRONOSMAINNET,
  '0xAECD843fC3677a2d3Fc142b9C165977D57fE4C40',
  18,
  'Pixel City',
  'Pixel City'
)

export const GHOST_OG = new Token(
  ChainId.CRONOSMAINNET,
  '0xe0160145ec09f98fb3Dbc03b2b5da08D16871e3f',
  18,
  'Ghost OG',
  'Ghost OG'
)

export const COCKHEADZ_OG_FOUNDER = new Token(
  ChainId.CRONOSMAINNET,
  '0x86bD74dB7E8559F41D13E3f627951b38E8ED7062',
  18,
  'Cockheadz OG Founder',
  'Cockheadz OG Founder'
)

export const CRO_HAMSTER = new Token(
  ChainId.CRONOSMAINNET,
  '0xd5d89E4999374cA5A458A9D00C7A13662562FD73',
  18,
  'Cro Hamster',
  'Cro Hamster'
)

export const OXLUCKLESS_V2 = new Token(
  ChainId.CRONOSMAINNET,
  '0x0d511FAA531d8D36aDE88daFcBbb9cE83Be42111',
  18,
  '0xLuckless v2.0',
  '0xLuckless v2.0'
)

export const OXLUCKLESS_V1 = new Token(
  ChainId.CRONOSMAINNET,
  '0xd0B45e7bFcc58C99203E60a363E2574e01B0a581',
  18,
  '0xLuckless v1.0',
  '0xLuckless v1.0'
)

export const OXLUCKLESS_V1_5 = new Token(
  ChainId.CRONOSMAINNET,
  '0xad350404b1564c732f3ed6673cfd8bdf93206a97',
  18,
  '0xLuckless v1.5',
  '0xLuckless v1.5'
)

export const OXLUCKLESS_V2_2 = new Token(
  ChainId.CRONOSMAINNET,
  '0x5141E99523002317BE6D63214Bf300cBD8269bE6',
  18,
  '0xLuckless v2.2',
  '0xLuckless v2.2'
)

export const RARE_PEPE_CULT = new Token(
  ChainId.CRONOSMAINNET,
  '0x8eD66bbF8B3B0577443c3fB3c712f69165515376',
  18,
  'Rare Pepe Cult',
  'Rare Pepe Cult'
)

export const LIL_SPERMY = new Token(
  ChainId.CRONOSMAINNET,
  '0x7dc514357c12E1FD1C4A2623C514bC90D7A4B4e9',
  18,
  'Lil Spermy',
  'Lil Spermy'
)

export const DR_DAO_X_MUTANT = new Token(
  ChainId.CRONOSMAINNET,
  '0x72c97F79867219381EFA83127534710D559a2220',
  18,
  'Dr Dao X Mutant',
  'Dr Dao X Mutant'
)

export const DR_DAO_OG = new Token(
  ChainId.CRONOSMAINNET,
  '0x28238122BECBE1D727F2D23631eD83d6cbe833Bc',
  18,
  'Dr Dao OG',
  'Dr Dao OG'
)

export const AI_LABS = new Token(
  ChainId.CRONOSMAINNET,
  '0x44aa4cB2efF7167A306449EDe9b550e57Ca33036',
  18,
  'AI Labs',
  'AI Labs'
)

export const AI_EXPERIMENTS = new Token(
  ChainId.CRONOSMAINNET,
  '0x51eaf8c081Cca683A5542c438f9429CE46D238e4',
  18,
  'AI Experiments',
  'AI Experiments'
)

export const ULTIMATE_X_MUTANTS = new Token(
  ChainId.CRONOSMAINNET,
  '0x21037397bDA5CF87c17b5Ec7A07f6935B6c6EA65', 
  18,
  'Ultimate X Mutants',
  'Ultimate X Mutants'
)

export const DAOLIST_PASS_3D = new Token(
  ChainId.CRONOSMAINNET,
  '0x1C7F4fE849e52B013242CDCb0C88f6A3c2Ea28aF',
  18,
  'Daolist Pass 3D',
  'Daolist Pass 3D'
)

// CONTRACT MISSING
export const CROMODO_DRAGONS = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'Cromodo Dragons',
  'Cromodo Dragons',
)


// CONTRACT MISSING
export const PANELS_PROJECT = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'Panels Project',
  'Panels Project',
)


// CONTRACT MISSING
export const CROPET_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0x8513af602e45bC60D94f0033edc6E983be4B4b3f',
  18,
  'CroPet',
  'CroPet',
)

// CONTRACT MISSING
export const SLAVIK_FRUIT = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'Slavik Fruit Farm',
  'Slavik Fruit Farm',
)

// CONTRACT MISSING
export const BDM_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'BrainDead Monkeys',
  'BrainDead Monkeys',
)

// CONTRACT MISSING
export const SHAPES_NFT = new Token(
  ChainId.CRONOSMAINNET,
  '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
  18,
  'Shapes NFT',
  'Shapes NFT',
)