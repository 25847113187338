import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { ChainId, JSBI, WETH } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import NFTStakingModal from '../../components/nftStakingModal/NFTStakingModal'
import NFTClaimRewardModal from '../../components/nftStakingModal/NFTClaimRewardModal'
import NFTUnstakingModal from '../../components/nftStakingModal/NFTUnstakingModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'

import { numberWithCommas } from '../../utils/helper'
import { useNFTStakeInfo, useNFTUserDepositsInfo } from '../../state/nftStake/hooks'
import { useNFTPortfolioGangVerse, useNFTUserDeposits } from '../../state/nftPortfolio/hooks'
import NFTBatchUnstakingModal from '../../components/nftStakingModal/NFTBatchUnstakingModal'
import NFTBatchStakingModal from '../../components/nftStakingModal/NFTBatchStakingModal'
import { EBISUSBAY } from '../../constants/collections'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 8 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function NFTStakeManageGangVerse({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account } = useActiveWeb3React()
  const stakingInfo = useNFTStakeInfo(rewardAddr)?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const userDeposits = useNFTUserDepositsInfo(rewardAddr)
  const stakedNFTs = userDeposits?.length ?? 0
  let perNFTPriceFloat = 0
  const nftPriceCROinUSDC = useUSDCPrice(WETH[ChainId.CRONOSMAINNET])
  const strnftPriceCROinUSDC = nftPriceCROinUSDC ? `${nftPriceCROinUSDC.toFixed(18)}` : `0`
  const nftPriceinCRO = parseFloat(strnftPriceCROinUSDC)
  perNFTPriceFloat += stakingInfo?.projectInfo?.mintPriceinWCROAmount! * nftPriceinCRO
  const nftPriceCustominUSDC = useUSDCPrice(stakingInfo?.projectInfo.mintPriceinCustomAmount?.tokenObj)
  const strnftPriceCustominUSDC = nftPriceCustominUSDC ? `${nftPriceCustominUSDC.toFixed(18)}` : `0`
  const nftPriceinCustom = parseFloat(strnftPriceCustominUSDC)
  const customTokenAmount = stakingInfo?.projectInfo.mintPriceinCustomAmount
    ? stakingInfo?.projectInfo.mintPriceinCustomAmount?.amount
    : 0
  perNFTPriceFloat += customTokenAmount * nftPriceinCustom
  const perNFTPrice = parseInt(perNFTPriceFloat.toString())

  let mintPriceStr = ''
  if (stakingInfo?.projectInfo.mintPriceinCustomAmount) {
    mintPriceStr += `${stakingInfo?.projectInfo.mintPriceinCustomAmount.amount} ${stakingInfo?.projectInfo.mintPriceinCustomAmount.tokenObj.symbol} + `
  }
  if (stakingInfo?.projectInfo.mintPriceinWCROAmount) {
    mintPriceStr += `${stakingInfo?.projectInfo.mintPriceinWCROAmount} CRO ≈ $${perNFTPrice}`
  }

  const stakedNFTPrice = stakedNFTs * perNFTPrice
  const nftPortfolio = useNFTPortfolioGangVerse(stakingInfo?.collection)
  const nftDeposits = useNFTUserDeposits(stakingInfo?.collection, userDeposits)
  const availableNFTcount = nftPortfolio ? nftPortfolio.length : 0
  const hasNFT = availableNFTcount ? true : false
  let showMintNFTButton = true
  if (stakingInfo?.stakedAmount?.equalTo('0') === false || hasNFT === true) showMintNFTButton = false

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showBatchStakingModal, setShowBatchStakingModal] = useState(false)
  const [showBatchUnstakingModal, setShowBatchUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))
  const backgroundColor = useColor(stakingInfo?.rewardToken)
  // const isStaking = Boolean(stakingInfo?.stakedAmount?.greaterThan('0'))

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  /////////////////////
  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo?.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `0`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)
  let poolRate = numberWithCommas(stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))
  const userRewardRate = numberWithCommas(stakingInfo?.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(3))
  const totalDepositedNFT = stakingInfo ? JSBI.toNumber(stakingInfo?.totalStakedAmount?.raw) : 0
  const totalDepositedNFTinUSDC = totalDepositedNFT * perNFTPrice
  const yearlyRewardRate = parseFloat(stakingInfo?.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365
  let apr = Math.round(((yearlyRewardRate * rewardTokenPrice) / totalDepositedNFTinUSDC) * 100)

  const unclaimedTokenAmount = parseFloat(countUpAmount)
  const unclaimedRewardinUSDC = (unclaimedTokenAmount * rewardTokenPrice).toFixed(2)

  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    poolRate = '0'
    apr = 0
  }

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBatchDepositClick = useCallback(() => {
    if (account) {
      setShowBatchStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Stake {stakingInfo?.collection.symbol} to earn {stakingInfo?.rewardToken.symbol} token
        </TYPE.mediumHeader>
        <CurrencyLogo currency={stakingInfo?.collection ?? undefined} size={'24px'} />
        {' → '}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {numberWithCommas(totalDepositedNFT) ?? `-`} {'NFT'}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {` ${poolRate} ${stakingInfo?.rewardToken?.symbol} / week`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {apr ? `🔥 ${apr}%` : `0%`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>

      {showMintNFTButton && stakingInfo?.projectInfo?.ebisusBay && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Buy {stakingInfo?.collection.symbol} NFTs</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.collection.symbol} NFTs to earn more ${stakingInfo?.rewardToken.symbol}. No need to worry about impermanent loss!`}
                </TYPE.white>
              </RowBetween>
              <a
                href={stakingInfo?.projectInfo.ebisusBay}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
                  <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '2px' }} />
                  {`Buy ${stakingInfo?.collection.symbol} at Ebisu's Bay`}
                </ButtonPrimary>
              </a>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <NFTStakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            portfolioInfo={nftPortfolio}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <NFTUnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
            nftDepositInfo={nftDeposits}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <NFTBatchStakingModal
            isOpen={showBatchStakingModal}
            onDismiss={() => setShowBatchStakingModal(false)}
            stakingInfo={stakingInfo}
            portfolioInfo={nftPortfolio}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <NFTBatchUnstakingModal
            isOpen={showBatchUnstakingModal}
            onDismiss={() => setShowBatchUnstakingModal(false)}
            stakingInfo={stakingInfo}
            nftDepositInfo={nftDeposits}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <NFTClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={showMintNFTButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showMintNFTButton}>
            <CardSection>
              <CardBGImage desaturate />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your NFT deposits</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {stakedNFTs ?? '-'}
                  </TYPE.white>
                  <TYPE.white>{stakingInfo?.collection?.symbol}</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {`$${numberWithCommas(stakedNFTPrice)}`}
                  </TYPE.white>
                  <TYPE.white> ≈ USDC</TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={!stakingInfo?.earnedAmount?.greaterThan(JSBI.BigInt(0))}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonPrimary>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {userRewardRate ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / week`}
                </TYPE.black>
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${numberWithCommas(unclaimedRewardinUSDC)}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          This calculation is based on a NFT mint price of <b>{mintPriceStr}</b>
        </TYPE.main>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: '#acaed9', textDecoration: 'none' }}
            href={stakingInfo?.projectInfo.webSite}
          >
            Project Website↗
          </a>
        </TYPE.main>

        {!showMintNFTButton && !stakingInfo?.batch && (
          <DataRow style={{ marginBottom: '1rem' }}>
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                ? 'Deposit'
                : `Deposit ${stakingInfo?.collection.symbol} NFTs`}
            </ButtonPrimary>

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="160px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}

        <DataRow style={{ marginBottom: '1rem' }}>
          {hasNFT && stakingInfo?.batch && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleBatchDepositClick}>
              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                ? 'Batch Deposit'
                : `Batch Deposit ${stakingInfo?.collection.symbol} NFTs`}
            </ButtonPrimary>
          )}

          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="160px"
                onClick={() => setShowBatchUnstakingModal(true)}
              >
                Batch Withdraw
              </ButtonPrimary>
            </>
          )}
        </DataRow>

        {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
          <TYPE.main>
            {availableNFTcount} {stakingInfo?.collection?.symbol} NFTs available
          </TYPE.main>
        )}

        <TYPE.main>{`1 ${stakingInfo?.rewardToken.symbol} = ${rewardTokenPrice.toFixed(4)} USDC`}</TYPE.main>
      </PositionInfo>
    </PageWrapper>
  )
}
