import { useMemo } from "react"
import { UNI } from "../../constants"
import { useActiveWeb3React } from "../../hooks"
import { usePairContract } from "../../hooks/useContract"
import { useSingleCallResult } from "../multicall/hooks"

export interface PairFinderInfo {
    token0: string | undefined
    token1: string | undefined
}

export function usePairFinder(pairAddress: string): PairFinderInfo {

    const { chainId } = useActiveWeb3React()
    const uni = chainId ? UNI[chainId] : undefined

    const pairContract = usePairContract(pairAddress ?? undefined)

    const token0Res = useSingleCallResult(pairContract ?? undefined, 'token0')
    const token1Res = useSingleCallResult(pairContract ?? undefined, 'token1')
    

    return useMemo(() => {

        let memo: PairFinderInfo = {
            token0: undefined,
            token1: undefined
        }

        if (!chainId || !uni) return memo
        else {
            if (
                !token0Res?.loading &&
                !token1Res?.loading
            ) {
                if (
                    token0Res?.error ||
                    token1Res?.error
                ) {
                    console.error('Failed to load Pair Finder Info')
                    return memo
                }

                memo.token0 = token0Res?.result?.[0] ?? undefined
                memo.token1 = token1Res?.result?.[0] ?? undefined

                return memo
            }
            return memo
        }


    }, [chainId, token0Res, token1Res, uni])
}