import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { TYPE } from '../../theme'

import { AutoColumn } from '../../components/Column'
import Grid from '@mui/material/Grid'
import CurrencyLogo from '../../components/CurrencyLogo'
import { RCRX, XCRX } from '../../constants'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { isMobile } from 'react-device-detect'
import { BarWave } from 'react-cssfx-loading'
import { ButtonPrimary } from '../../components/Button'
import { LightCard } from '../../components/Card'

import image from '../../assets/images/xVault.png'
import { useXVaultInfo } from '../../state/xVault/hooks'
import { useActiveWeb3React } from '../../hooks'
import XVaultDepositModal from '../../components/xVault/xVaultDepositModal'
import { useWalletModalToggle } from '../../state/application/hooks'
import XVaultWithdrawModal from '../../components/xVault/xVaultWithdrawModal'
import XVaultClaimModal from '../../components/xVault/xVaultClaimModal'

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: #1f1243cc;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const IMG = styled.img`
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  width: 200px;
  margin: auto;
`

const ImageDiv = styled.div`
  background-size: cover;
  background-position: right top;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`
const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
`

const ImageGrid = styled(Grid)`
  display: inherit;
  margin: auto;
  @media (max-width: 600px) {
    display: none;
  }
`

const TokenIconBox = styled(Avatar)`
  border-radius: 12px !important;
  background-color: #333460c4 !important;
`

const TokenInfoBox = styled(Cards)`
  background-color: #0d0c209c;
  border-radius: 12px;
  box-shadow: inset #9475bc7d 0px 1px 2px 1px;
  height: 100%;
`
const ClaimBox = styled(List)`
  background: linear-gradient(321deg, #0d0c20 13%, #221b46 78%);
  border-radius: 12px;
  box-shadow: inset #9475bc7d 0px 1px 2px 1px;
  height: 100%;
  display: flex;
  @media (max-width: 600px) {
    display: grid;
  }
`
const TokenInfoTitle = styled(TYPE.white)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ffffffa1 !important;
`
const TokenInfoBalance = styled(TYPE.white)`
  font-weight: 600 !important;
  font-size: 20px !important;
`

export const Rainbow = styled.div`
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.1rem;
  width: 85%;
  margin: auto;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#fe61be, #fe61be), linear-gradient(#fd7a50, #fd7a50),
      linear-gradient(rgb(160 255 219), rgb(160 255 219)), linear-gradient(#377af5, #377af5);
    animation: rotate 2s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 35px);
    height: calc(100% - 35px);
    background: white;
    border-radius: 5px;
    animation: opacityChange 3s infinite alternate;
  }
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  @keyframes opacityChange {
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`

const StyledCard = styled(Cards)`
  padding: 0.5rem;
  box-shadow: inset #221c227d -1px -1px 3px 2px;
  border: none;
`

export default function XVault() {
  const { account } = useActiveWeb3React()

  const xVaultInfo = useXVaultInfo()
  const isLoading = xVaultInfo?.depositedAmount ? false : true

  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showClaimModal, setShowClaimModal] = useState(false)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowDepositModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleWithdrawClick = useCallback(() => {
    if (account) {
      setShowWithdrawModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleClaimClick = useCallback(() => {
    if (account) {
      setShowClaimModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <>
      <ImageDiv />

      <PageWrapper gap="lg" justify="center">
        <>
          <Grid container spacing={1}>
            <XVaultDepositModal isOpen={showDepositModal} onDismiss={() => setShowDepositModal(false)} />
            <XVaultWithdrawModal
              isOpen={showWithdrawModal}
              onDismiss={() => setShowWithdrawModal(false)}
              xVaultInfo={xVaultInfo}
            />
            <XVaultClaimModal
              isOpen={showClaimModal}
              onDismiss={() => setShowClaimModal(false)}
              xVaultInfo={xVaultInfo}
            />

            <Grid item sm={12} xs={12}>
              <Cards>
                <Grid container spacing={0}>
                  <Grid item sm={isMobile ? 12 : 8} xs={12}>
                    <TYPE.white fontWeight={600} fontSize={30}>
                      xVault
                    </TYPE.white>
                    <TYPE.white
                      fontWeight={500}
                      fontSize={14}
                      style={{ textAlign: 'justify', marginTop: '16px', lineHeight: '21px' }}
                    >
                      xVault is the intermediary instrument of CRODEX protocol, where you can pledge your xCRX to receive rCRX.
                      rCRX is the receipt token representing your share of revenue coming from <br></br>
                      <li style={{ marginLeft: '0.5rem'}}>
                        Min. 40% of platform-wide trade fees distributed bi-monthly,
                      </li>
                      <li style={{ marginLeft: '0.5rem'}}>
                        9% of all xCRX sells distributed immediately as dividends upon each sell.<br></br>
                      </li>

                    </TYPE.white>
                    <TYPE.white fontWeight={500}
                      fontSize={14}
                      style={{ textAlign: 'justify', marginTop: '16px', lineHeight: '21px' }}>
                        <a style={{ color: 'white' }} target="_blank" href="https://miro.medium.com/max/1400/1*tFptSfL9Lx9BKVhCtaIH5g.png">See the diagram outlining how you can invest and earn in CRODEX↗</a>
                    </TYPE.white>
                  </Grid>
                  {!isMobile && (
                    <ImageGrid item sm={4}>
                      <IMG src={image} alt="crx" style={{ width: 200, height: 184 }} />
                    </ImageGrid>
                  )}
                </Grid>
              </Cards>
            </Grid>

            <Grid item xs={12} sx={{}}>
              <StyledCard>
                {isLoading && (
                  <Grid>
                    <BarWave style={{ minHeight: '200px', margin: '20px auto' }} color="#5b5eb1"></BarWave>
                  </Grid>
                )}

                {!isLoading && (
                  <Grid container style={{ margin: '1rem auto' }}>
                    <>
                      <Grid item sm={3} style={{ width: '100%' }}>
                        <ListItem style={{ textAlign: 'center' }}>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <TYPE.white fontWeight={600} fontSize={12} color={'#ffffffa1'}>
                                Total Deposited
                              </TYPE.white>
                            }
                            secondary={
                              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
                                <CurrencyLogo
                                  currency={XCRX}
                                  size={'24px'}
                                  style={{
                                    marginRight: '0.4rem',
                                    fontSize: 15,
                                    color: 'white',
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                  }}
                                ></CurrencyLogo>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem'
                                  }}
                                >
                                  {xVaultInfo?.depositedAmount?.toFixed(2, { groupSeparator: ',' })}
                                </TYPE.white>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem',
                                    marginLeft: '0.4rem'
                                  }}
                                >
                                  xCRX
                                </TYPE.white>
                              </div>
                            }
                          />
                        </ListItem>
                      </Grid>
                    
                      <Grid item sm={3} style={{ width: '100%' }}>
                        <ListItem style={{ textAlign: 'center' }}>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <TYPE.white fontWeight={600} fontSize={12} color={'#ffffffa1'}>
                                Avg. Daily Distribution
                              </TYPE.white>
                            }
                            secondary={
                              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
                                <CurrencyLogo
                                  currency={XCRX}
                                  size={'24px'}
                                  style={{
                                    marginRight: '0.4rem',
                                    fontSize: 14,
                                    color: 'white',
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                  }}
                                ></CurrencyLogo>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem'
                                  }}
                                >
                                  {xVaultInfo?.dailyRewardRate?.toFixed(2, { groupSeparator: ',' })}
                                </TYPE.white>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem',
                                    marginLeft: '0.4rem'
                                  }}
                                >
                                  xCRX
                                </TYPE.white>
                              </div>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item sm={2} style={{ width: '100%' }}>
                        <ListItem style={{ textAlign: 'center' }}>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <TYPE.white fontWeight={600} fontSize={12} color={'#ffffffa1'}>
                                Estimated APY
                              </TYPE.white>
                            }
                            secondary={
                              <div style={{ textAlign: 'center', margin: 'auto' }}>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '14px'
                                  }}
                                >
                                  🔥 {xVaultInfo?.estimatedAPY}%
                                </TYPE.white>
                              </div>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </>

                    <Grid item sm={4} style={{ width: '100%' }}>
                      <ClaimBox>
                        <ListItem style={{ textAlign: 'center' }}>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <TYPE.white fontWeight={600} fontSize={12} color={'#ffffffa1'}>
                                Total Claimed
                              </TYPE.white>
                            }
                            secondary={
                              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
                                <CurrencyLogo
                                  currency={XCRX}
                                  size={'24px'}
                                  style={{
                                    marginRight: '0.4rem',
                                    fontSize: 14,
                                    color: 'white',
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                  }}
                                ></CurrencyLogo>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem'
                                  }}
                                >
                                  {xVaultInfo?.totalClaimed?.toFixed(2, { groupSeparator: ',' })}
                                </TYPE.white>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem',
                                    marginLeft: '0.4rem'
                                  }}
                                >
                                  xCRX
                                </TYPE.white>
                              </div>
                            }
                          />
                        </ListItem>
                        <ListItem style={{ textAlign: 'center' }}>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <TYPE.white fontWeight={600} fontSize={12} color={'#ffffffa1'}>
                                Your xVault Share
                              </TYPE.white>
                            }
                            secondary={
                              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
                                <TYPE.white
                                  fontWeight={600}
                                  fontSize={14}
                                  style={{
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '0.2rem'
                                  }}
                                >
                                  {xVaultInfo?.userShare} %
                                </TYPE.white>
                              </div>
                            }
                          />
                        </ListItem>
                      </ClaimBox>
                    </Grid>
                  </Grid>
                )}
              </StyledCard>
            </Grid>

            {/* REVENUE SHARE CARD*/}

            <Grid item sm={12} xs={12} sx={{ display: 'flex' }}>
              <Cards>
                {isLoading && (
                  <Grid>
                    <BarWave style={{ minHeight: '200px', margin: '20px auto' }} color="#5b5eb1"></BarWave>
                  </Grid>
                )}

                {!isLoading && (
                  <Grid container spacing={1} style={{ marginTop: '1rem' }}>
                    <Grid item md={4} sm={6} xs={12}>
                      <TokenInfoBox>
                        <TYPE.white fontSize={18} textAlign={'center'}>
                          Pledge xCRX to Get rCRX
                        </TYPE.white>

                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <TokenIconBox variant="square">
                                <CurrencyLogo currency={XCRX} size={'36px'}></CurrencyLogo>
                              </TokenIconBox>
                            </ListItemAvatar>
                            <ListItemText
                              disableTypography={true}
                              primary={<TokenInfoTitle>xCRX in Your Wallet</TokenInfoTitle>}
                              secondary={<TokenInfoBalance>{xVaultInfo?.xCRXBalance?.toFixed(2, { groupSeparator: ',' })}</TokenInfoBalance>}
                            />
                          </ListItem>
                        </List>
                        <Rainbow>
                          <ButtonPrimary
                            className="select-button"
                            style={{
                              borderRadius: '12px',
                              fontSize: '14px',
                              width: '100%',
                              margin: 'auto'
                            }}
                            onClick={handleDepositClick}
                          >
                            Pledge
                          </ButtonPrimary>
                        </Rainbow>
                      </TokenInfoBox>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <TokenInfoBox>
                        <TYPE.white fontSize={18} textAlign={'center'}>
                          Return rCRX to Redeem xCRX
                        </TYPE.white>

                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <TokenIconBox variant="square">
                                <CurrencyLogo currency={RCRX} size={'36px'}></CurrencyLogo>
                              </TokenIconBox>
                            </ListItemAvatar>
                            <ListItemText
                              disableTypography={true}
                              primary={<TokenInfoTitle>rCRX in Your Wallet</TokenInfoTitle>}
                              secondary={<TokenInfoBalance>{xVaultInfo?.rCRXBalance?.toFixed(2, { groupSeparator: ',' })}</TokenInfoBalance>}
                            />
                          </ListItem>
                        </List>

                        <Rainbow>
                          <ButtonPrimary
                            className="select-button"
                            style={{
                              borderRadius: '12px',
                              fontSize: '14px',
                              width: '100%',
                              margin: 'auto'
                            }}
                            onClick={handleWithdrawClick}
                          >
                            Redeem
                          </ButtonPrimary>
                        </Rainbow>
                        
                        { xVaultInfo?.availableToWithdraw !== undefined && !xVaultInfo?.availableToWithdraw && (
                          <TYPE.white fontSize={10} style={{ marginTop: '0.5rem', textAlign: 'center'}}>
                            Locked Until: {xVaultInfo?.lockedUntil?.toUTCString()}
                          </TYPE.white>
                        )}



                      </TokenInfoBox>
                    </Grid>
                    <Grid item md={4} sm={12} style={{ width: '100%' }}>
                      <TokenInfoBox>
                        <TYPE.white fontSize={18} textAlign={'center'}>
                          xVault Account Balance
                        </TYPE.white>

                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <TokenIconBox variant="square">
                                <CurrencyLogo currency={XCRX} size={'36px'}></CurrencyLogo>
                              </TokenIconBox>
                            </ListItemAvatar>
                            <ListItemText
                              disableTypography={true}
                              primary={<TokenInfoTitle>Claimable xCRX</TokenInfoTitle>}
                              secondary={
                                <TokenInfoBalance>{xVaultInfo?.withdrawableDividend?.toFixed(2, { groupSeparator: ',' })}</TokenInfoBalance>
                              }
                            />
                          </ListItem>
                        </List>
                        <Rainbow>
                          <ButtonPrimary
                            className="select-button"
                            style={{
                              borderRadius: '12px',
                              fontSize: '14px',
                              width: '100%',
                              margin: 'auto'
                            }}
                            onClick={handleClaimClick}
                          >
                            Claim
                          </ButtonPrimary>
                        </Rainbow>
                      </TokenInfoBox>
                    </Grid>
                  </Grid>
                )}
              </Cards>
            </Grid>
          </Grid>
        </>
      </PageWrapper>
    </>
  )
}
