import React, { useRef } from 'react'
import { MessageCircle } from 'react-feather'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import LinkIcon from '../../assets/images/link.png'

import { ExternalLink } from '../../theme'
import { ProjectInfo } from '../../state/ido/projects'

const StyledMenuButton = styled.button`
  width: max-content;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: #1b1b34;
  color: white;

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    border: 1px solid #565a69;
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 9.125rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  z-index: 100;
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.white};
  :hover {
    color: ${({ theme }) => theme.text2};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

interface MenuProps {
  projectInfo: ProjectInfo
  applicationModal: ApplicationModal
}

export default function Menu({ projectInfo, applicationModal }: MenuProps) {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(applicationModal)
  const toggle = useToggleModal(applicationModal)
  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        Links <img src={LinkIcon} style={{ width: '0.8rem', height: '0.8rem', margin: '0 0.2rem' }}></img>
      </StyledMenuButton>

      {open && (
        <MenuFlyout>

          {(projectInfo.webSite &&
            <MenuItem id="link" href={projectInfo.webSite}>
              <MessageCircle size={14} />
              Web Site
            </MenuItem>
          )}

          {(projectInfo.whitepaper &&
            <MenuItem id="link" href={projectInfo.whitepaper}>
              <MessageCircle size={14} />
              Whitepaper
            </MenuItem>
          )}

          {(projectInfo.twitter &&
            <MenuItem id="link" href={projectInfo.twitter}>
              <MessageCircle size={14} />
              Twitter
            </MenuItem>
          )}


          {(projectInfo.telegram &&
            <MenuItem id="link" href={projectInfo.telegram}>
              <MessageCircle size={14} />
              Telegram
            </MenuItem>
          )}

          {(projectInfo.discord &&
            <MenuItem id="link" href={projectInfo.discord}>
              <MessageCircle size={14} />
              Discord
            </MenuItem>
          )}

          {(projectInfo.github &&
            <MenuItem id="link" href={projectInfo.github}>
              <MessageCircle size={14} />
              Github
            </MenuItem>
          )}

          {(projectInfo.medium &&
            <MenuItem id="link" href={projectInfo.medium}>
              <MessageCircle size={14} />
              Medium
            </MenuItem>
          )}

          {(projectInfo.reddit &&
            <MenuItem id="link" href={projectInfo.reddit}>
              <MessageCircle size={14} />
              Reddit
            </MenuItem>
          )}

          {(projectInfo.docs &&
            <MenuItem id="link" href={projectInfo.docs}>
              <MessageCircle size={14} />
              Docs
            </MenuItem>
          )}

          {(projectInfo.audit &&
            <MenuItem id="link" href={projectInfo.audit}>
              <MessageCircle size={14} />
              Audit
            </MenuItem>
          )}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
