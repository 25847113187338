import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Fraction, JSBI } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardBGImage, CardSection, DataCard } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import ClaimRewardModal from '../../components/Boosters/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { CountUp } from 'use-count-up'

import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'

import { numberWithCommas } from '../../utils/helper'
import CurrencyLogo from '../../components/CurrencyLogo'
import { EBISUSBAY } from '../../constants/collections'
import BoosterNFTStakingModal from '../../components/Boosters/BoosterNFTStakingModal'
import { useNFTPortfolio, useNFTUserDeposits } from '../../state/nftPortfolio/hooks'
import BoosterNFTWithdrawModal from '../../components/Boosters/BoosterNFTWithdrawModal'
import NFTCards from '../../components/nftStakingModal/NFTCards'
import { useSSBoostStakingInfo } from '../../state/boostStake/SSBoost'
import TokenWithdrawModal from '../../components/Boosters/TokenWithdrawModal'
import TokenStakingModal from '../../components/Boosters/TokenStakingModal'
import PartialTokenWithdrawModal from '../../components/Boosters/PartialTokenWithdrawModal'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledBoosterCard = styled(DataCard) <{ dim: any }>`
  background: #080812;
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 2;
`


const StyledLiqCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const StyledRewardCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg7};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
`

const PoolData = styled(DataCard)`
  background: none;
  padding: 1rem;
  z-index: 1;
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function SSBoostStakeManage({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account } = useActiveWeb3React()

  // get currencies and pair
  const stakingInfo = useSSBoostStakingInfo(rewardAddr)?.[0]
  const nftPortfolio = useNFTPortfolio(stakingInfo?.collection)
  const boosterInfo = useNFTUserDeposits(stakingInfo?.collection, stakingInfo?.boosters)

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // fade cards if nothing staked or nothing earned yet

  // get WETH value osf staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  const depositedTokenPriceinUSDC = useUSDCPrice(stakingInfo?.token)
  let totalDepositInUSDC: Fraction | undefined
  if (totalSupplyOfStakingToken && depositedTokenPriceinUSDC) {
    totalDepositInUSDC =
      stakingInfo?.totalStakedAmount.multiply(depositedTokenPriceinUSDC?.adjusted)
  }
  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo?.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `1`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  const countUpBoosterAmount = stakingInfo?.earnedFromBoostAmount?.toFixed(6) ?? '0'
  const countUpBoosterAmountPrevious = usePrevious(countUpBoosterAmount) ?? '0'

  const toggleWalletModal = useWalletModalToggle()

  // toggle for staking modal and unstaking modal
  const [showSSStakingModal, setShowSSStakingModal] = useState(false)
  const [showSSWithdrawModal, setShowSSWithdrawModal] = useState(false)
  const [showSSPartialWithdrawModal, setShowSSPartialWithdrawModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const [showBoosterNFTStakingModal, setBoosterNFTStakingModal] = useState(false)
  const [showBoosterNFTWithdrawModal, setBoosterNFTWithdrawModal] = useState(false)

  const handleLPDepositClick = useCallback(() => {
    if (account) {
      setShowSSStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTDepositClick = useCallback(() => {
    if (account) {
      setBoosterNFTStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTWithdrawClick = useCallback(() => {
    if (account) {
      setBoosterNFTWithdrawModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const userRewardinUSDC = (stakingInfo?.userShare && totalDepositInUSDC) ? totalDepositInUSDC?.multiply(parseInt((100000 * parseFloat(stakingInfo?.userShare.toFixed(6))).toString()).toString()).divide("100000") : new Fraction('0')
  const unclaimedTokenAmount = parseFloat(countUpAmount) + parseFloat(countUpBoosterAmount)
  const unclaimedRewardinUSDC = (unclaimedTokenAmount * rewardTokenPrice).toFixed(2)
  let poolRate = numberWithCommas(stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))
  let userRate = stakingInfo?.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(4, { groupSeparator: ',' }) ?? '-'

  const hasNFTs = nftPortfolio.length > 0 ? true : false
  const hasBooster = stakingInfo?.boosters.length > 0 ? true : false
  const hasDeposit = stakingInfo?.stakedAmount?.greaterThan('0')
  const hasReward = stakingInfo?.earnedAmount?.greaterThan('0')

  const userBoostPerc = Math.round((stakingInfo?.boostPermillage / stakingInfo?.boostDiv) * stakingInfo?.boosters.length * 100)
  let userBoostRate =
    stakingInfo?.rewardRate
      ?.multiply(((60 * 60 * 24 * 7 * userBoostPerc) / 100).toString())
      ?.toFixed(4, { groupSeparator: ',' }) ?? '-'

  let apr = '-'
  if (totalDepositInUSDC) {
    const numApr =
      ((parseFloat(stakingInfo.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365 * rewardTokenPrice) /
        parseFloat(totalDepositInUSDC?.toFixed(0))) *
      100
    const aprValue = Math.round(numApr).toString()
    apr = `🔥 ${aprValue}%`
  }

  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    apr = '0%'
    poolRate = '0'
    userBoostRate = '0'
    userRate = '0'
  }

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Stake {stakingInfo?.token.symbol} to earn {stakingInfo?.rewardToken.symbol} token
        </TYPE.mediumHeader>

        <CurrencyLogo currency={stakingInfo?.token ?? undefined} size={'24px'} />
        {' → '}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {`$ ${totalDepositInUSDC?.toFixed(2, { groupSeparator: ',' })}`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>

        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {apr}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {poolRate}
              {` ${stakingInfo?.rewardToken.symbol} / week`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        { stakingInfo?.maxBoosterCount > 0 && (
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Max. Boost</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {`${stakingInfo?.maxBoosterCount} NFTs x ${(stakingInfo?.boostPermillage / stakingInfo?.boostDiv) *
                100}% = ${Math.round((stakingInfo?.boostPermillage / stakingInfo?.boostDiv) *
                  stakingInfo?.maxBoosterCount *
                  100)}%  `}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      )}
      </DataRow>


      {stakingInfo?.maxBoosterCount > 0 && !hasNFTs && stakingInfo?.projectInfo?.customMintPage && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Mint {stakingInfo?.collection.symbol} NFTs</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.collection.symbol} NFTs to earn more ${stakingInfo?.rewardToken.symbol}.`}
                </TYPE.white>
              </RowBetween>
              <a
                href={stakingInfo?.projectInfo.customMintPage}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
                <CurrencyLogo currency={stakingInfo?.collection} size={'36px'} style={{ marginRight: '6px' }} />
                  {`Mint ${stakingInfo?.collection.symbol}`}
                </ButtonPrimary>
              </a>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}


      {stakingInfo?.maxBoosterCount > 0 && !hasNFTs && stakingInfo?.projectInfo?.ebisusBay && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Buy {stakingInfo?.collection.symbol} NFTs</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.collection.symbol} NFTs to earn more ${stakingInfo?.rewardToken.symbol}.`}
                </TYPE.white>
              </RowBetween>
              <a
                href={stakingInfo?.projectInfo.ebisusBay}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
                  <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '2px' }} />
                  {`Buy ${stakingInfo?.collection.symbol} at Ebisu's Bay`}
                </ButtonPrimary>
              </a>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {stakingInfo?.maxBoosterCount > 0 && showAddLiquidityButton && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 2. Get ${stakingInfo?.token.symbol} tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.token.symbol} tokens to earn more ${stakingInfo?.rewardToken.symbol}. No need to worry about impermanent loss!`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/swap/?outputCurrency=${stakingInfo?.token && currencyId(stakingInfo?.token)}`}
              >
                <CurrencyLogo currency={stakingInfo?.token} size={'36px'} />
                &nbsp;&nbsp;&nbsp;
                {`Buy ${stakingInfo?.token.symbol} token`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <TokenStakingModal
            isOpen={showSSStakingModal}
            onDismiss={() => setShowSSStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <TokenWithdrawModal
            isOpen={showSSWithdrawModal}
            onDismiss={() => setShowSSWithdrawModal(false)}
            stakingInfo={stakingInfo}
          />

          <PartialTokenWithdrawModal
            isOpen={showSSPartialWithdrawModal}
            onDismiss={() => setShowSSPartialWithdrawModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityStaked={stakingInfo?.stakedAmount}
          />

          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />

          <BoosterNFTStakingModal
            isOpen={showBoosterNFTStakingModal}
            onDismiss={() => setBoosterNFTStakingModal(false)}
            stakingInfo={stakingInfo}
            portfolioInfo={nftPortfolio}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <BoosterNFTWithdrawModal
            isOpen={showBoosterNFTWithdrawModal}
            onDismiss={() => setBoosterNFTWithdrawModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

{ stakingInfo?.maxBoosterCount > 0 && (
      <TYPE.main style={{ textAlign: 'center' }} fontSize={19}>
        <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
          ⚠️
        </span>
        Deposit your boosters before depositing tokens.
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: '#acaed9', textDecoration: 'none' }}
          href={
            'https://crodex.medium.com/crodex-introduces-yet-another-utility-for-your-nfts-on-cronos-reward-boosting-fd5dfc2ce6d6'
          }
        >
          {' '}
          Learn more↗
        </a>
      </TYPE.main>
            )}
      <PositionInfo gap="lg" justify="center" dim={false} style={{ marginTop: '3rem'}}>
        <BottomSection gap="lg" justify="center">
        { stakingInfo?.maxBoosterCount > 0 && (
          <StyledBoosterCard dim={!hasBooster}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your booster deposits</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={25} fontWeight={600}>
                  {stakingInfo?.boosters.length} {stakingInfo?.collection.name}
                </TYPE.white>
                <TYPE.white fontWeight={600}>{userBoostPerc}% Boost</TYPE.white>
              </RowBetween>
            </AutoColumn>
            <AutoColumn gap="sm">
              <RowBetween>
                <NFTCards
                  visible={hasBooster}
                  stakingInfo={stakingInfo}
                  portfolioInfo={boosterInfo}
                  maxBoosterCount={stakingInfo?.maxBoosterCount}
                />
              </RowBetween>
            </AutoColumn>
          </StyledBoosterCard>
          )}
          <StyledLiqCard dim={!hasDeposit}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your token deposits</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                </TYPE.white>
                <TYPE.white>{stakingInfo?.token.symbol}</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`$${userRewardinUSDC?.toFixed(2, { groupSeparator: ',' })}`}
                </TYPE.white>
                <TYPE.white> ≈ USDC</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledLiqCard>

          <StyledRewardCard dim={!hasReward}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonPrimary>
                )}
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {userRate ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / week`}
                </TYPE.black>
              </RowBetween>

              {hasBooster && (
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.largeHeader fontSize={36} fontWeight={600}>
                    <CountUp
                      key={countUpBoosterAmount}
                      isCounting
                      decimalPlaces={4}
                      start={parseFloat(countUpBoosterAmountPrevious)}
                      end={parseFloat(countUpBoosterAmount)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.largeHeader>

                  <TYPE.black fontSize={16} fontWeight={500}>
                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                      🚀
                    </span>
                    {userBoostRate}
                    {` ${stakingInfo?.rewardToken.symbol} / week`}
                  </TYPE.black>
                </RowBetween>
              )}

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${numberWithCommas(unclaimedRewardinUSDC)}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledRewardCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you withdraw, the contract will automagically claim {stakingInfo?.rewardToken.symbol} on your behalf!
        </TYPE.main>

        {/* NFT STAKING SECTION */}

        <DataRow style={{ marginBottom: '1rem' }}>
          { stakingInfo?.maxBoosterCount > 0 && !isRewardEnded && !stakingInfo?.stakedAmount.greaterThan('0') && !hasBooster && nftPortfolio.length > 0 && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTDepositClick}
            >
              Deposit <br></br> Boosters
            </ButtonPrimary>
          )}

          { stakingInfo?.maxBoosterCount > 0 && hasBooster && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTWithdrawClick}
            >
              Withdraw <br></br> Boosters
            </ButtonPrimary>
          )}

          {!isRewardEnded && !showAddLiquidityButton && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleLPDepositClick}>
              Deposit <br></br> Tokens
            </ButtonPrimary>
          )}
        </DataRow>

        <DataRow style={{ marginBottom: '1rem' }}>
          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={() => setShowSSPartialWithdrawModal(true)}
            >
              Withdraw <br></br> Tokens
            </ButtonPrimary>
          )}

          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={() => setShowSSWithdrawModal(true)}>
              Withdraw All &<br></br> Claim
            </ButtonPrimary>
          )}
        </DataRow>

        <TYPE.main>{`1 ${stakingInfo?.rewardToken.symbol} = ${rewardTokenPrice.toFixed(4)} USDC`}</TYPE.main>
      </PositionInfo>
    </PageWrapper>
  )
}
