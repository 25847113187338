import React, { createContext, useState, useEffect } from 'react';

interface Token {
    address: string;
    name: string;
    symbol: string;
}

interface Transactions {
    buys: number;
    sells: number;
}

interface Volume {
    h24: number;
    h6: number;
    h1: number;
    m5: number;
}

interface PriceChange {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
}

interface Liquidity {
    usd: number;
    base: number;
    quote: number;
}

interface PairData {
    chainId: string;
    dexId: string;
    url: string;
    aprBase: number;
    pairAddress: string;
    baseToken: Token;
    quoteToken: Token;
    priceNative: string;
    priceUsd: string;
    txns: {
        m5: Transactions;
        h1: Transactions;
        h6: Transactions;
        h24: Transactions;
    };
    volume: Volume;
    priceChange: PriceChange;
    liquidity: Liquidity;
    fdv: number;
}


const APR_BASE: { [address: string]: number } = {
    '0x4Bd044b02512947e228E911Cf24C9116993E03DC': 8, // xCRX-CRO
    '0xd141aeD3FA86f411808D5D74BEbBC038e94f300D': 8, // CRX-CRO 
    '0x182414159C3eeF1435aF91Bcf0d12AbcBe277A46': 8, // USDC-CRO 
    '0xaa9d281B761d8988Af83B4E2bF3fC8f5dB2E7aE5': 8,  // USDC-USDT
    '0x36EAe4191Eff57503C56bfF3D195De10E0942865': 16, // CROB-CRO
    '0xD5312B696419621dd23D7eAcCa66B7A64087AA6A': 8 // ORC-CRO
};


const Dexscreener = createContext<Record<string, PairData>>({});

const BASE_URL = 'https://api.dexscreener.com/latest/dex/pairs/cronos/';
const PAIR_ADDRESSES = [
    '0x4Bd044b02512947e228E911Cf24C9116993E03DC', // xCRX-CRO
    '0xd141aeD3FA86f411808D5D74BEbBC038e94f300D', // CRX-CRO
    '0x182414159C3eeF1435aF91Bcf0d12AbcBe277A46', // USDC-CRO
    '0xaa9d281B761d8988Af83B4E2bF3fC8f5dB2E7aE5', // USDC-USDT
    '0x36EAe4191Eff57503C56bfF3D195De10E0942865', // CROB-CRO
    '0xD5312B696419621dd23D7eAcCa66B7A64087AA6A' // ORC-CRO
];

const DexscreenerDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [data, setData] = useState<Record<string, PairData>>({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryString = PAIR_ADDRESSES.join(',');
                const url = BASE_URL + queryString;
                const response = await fetch(url);
                const result = await response.json();
                const transformedData = result.pairs.reduce((acc: Record<string, any>, pair: any) => {
                    const { pairAddress, ...rest } = pair;
                    acc[pairAddress] = {
                        ...rest,
                        aprBase: APR_BASE[pairAddress] || 0
                    };
                    return acc;
                }, {});
                setData(transformedData); 
            } catch (error) {
                console.error('API error', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 60000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Dexscreener.Provider value={data}>
            {children}
        </Dexscreener.Provider>
    );
};

export { Dexscreener, DexscreenerDataProvider };
