import React, { useState } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink, ExternalLink } from '../../theme'
import { ButtonPrimary } from '../Button'
import { useColor } from '../../hooks/useColor'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import useUSDCPrice from '../../utils/useUSDCPrice'
import CurrencyLogo from '../CurrencyLogo'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Break } from '../earn/styled'
import { SSRewardsV2Info } from '../../state/rewardsV2/ssRewardsv2'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { SS_POOL_STATUS_TYPES } from '../../state/singleStake/hooks'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: #1f1243cc;
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  box-shadow: ${({ theme, showBackground }) => (showBackground ? "inset #f9af00  -1px -1px 6px 3px" : 'inset #dde7e77d -1px -1px 3px 2px')} ;
  border: ${({ theme, showBackground }) => (showBackground ? '1.5px solid #f9af00' : '1.5px solid #0d0c20')};
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 33px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 33px 1fr 96px;
  `};
`


const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function SSRewardsV2Card({ rewardInfo }: { rewardInfo: SSRewardsV2Info }) {

  const currency = unwrappedToken(rewardInfo?.token)

  const isStaking = Boolean(rewardInfo?.stakedAmount.greaterThan('0') || rewardInfo?.boosters.length > 0)

  const backgroundColor = useColor(rewardInfo?.token)

  const stakingTokenPrice = useUSDCPrice(rewardInfo?.token)
  const valueOfTotalStakedAmountInUSDC = rewardInfo?.totalAmount?.multiply(stakingTokenPrice?.adjusted ?? '0')

  const rewardTokenPrice = useUSDCPrice(rewardInfo?.rewardToken)

  const rewardAPR = valueOfTotalStakedAmountInUSDC.greaterThan('0') ? (parseFloat(rewardInfo?.rewardPerDay?.multiply("365").multiply(rewardTokenPrice?.adjusted ?? '0').divide(valueOfTotalStakedAmountInUSDC ?? '1').toFixed(2))) : 0

  const estimatedAPY = (Math.pow(1 + rewardAPR / 365, 365) - 1)

  const isRewardEnded = rewardInfo?.status === SS_POOL_STATUS_TYPES.FINISHED ? true : false
  const hasBooster = rewardInfo?.boosters.length > 0 ? true : false

  // const [showTimerTooltip, setShowTimerTooltip] = useState(false);
  const [showAPRTooltip, setShowAPRTooltip] = useState(false);

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>

      <TopSection>
        <CurrencyLogo currency={currency} size={'24px'} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '0px' }}>
          {currency.symbol}
          {/* <Tooltip
                        open={showTimerTooltip}
                        onOpen={() => setShowTimerTooltip(true)}
                        onClose={() => setShowTimerTooltip(false)}
                        title={<TYPE.white> {stakingInfo?.periodFinish?.toUTCString()} </TYPE.white>}>
                        <IconButton sx={{ paddingTop: '7px' }} onClick={() => setShowTimerTooltip(!showTimerTooltip)}>
                            <QueryBuilderOutlinedIcon sx={{ fontSize: 16, color: "white" }} />
                        </IconButton>
                    </Tooltip> */}
        </TYPE.white>

        <StyledInternalLink to={`/rewards/ss/${rewardInfo.stakingRewardAddress}`} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>


      {rewardInfo?.status === SS_POOL_STATUS_TYPES.HALTED && (
        <>
          <Break></Break>
          <ExternalLink href={`https://cronoscan.com/block/countdown/${rewardInfo?.startBlock}`} style={{ textAlign: 'center', marginBottom: '0.3rem', marginTop: '0.3rem' }}>
            <TYPE.white fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
              Remaining blocks for the reward distribution to start: {rewardInfo?.blockLefttoStart}
            </TYPE.white>
            <TimerOutlinedIcon
              sx={{ marginLeft: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
            />
          </ExternalLink>
        </>
      )}




      <Break></Break>





      <StatContainer>
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>

            {`$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`}
          </TYPE.white>
        </RowBetween>

        <RowBetween>
          <TYPE.white> Daily Reward Rate </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={rewardInfo?.rewardToken} size={'20px'} />
            {`${rewardInfo?.rewardPerDay?.toFixed(0, { groupSeparator: ',' })} ${rewardInfo?.rewardToken.symbol} / day`}
          </TYPE.white>
        </RowBetween>

        <RowBetween>
          <TYPE.white> Max. boost </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={rewardInfo?.collection} size={'20px'} />
            {`${rewardInfo?.maxBoosterCount} ${rewardInfo?.collection.name} x ${rewardInfo?.boostPermillage / rewardInfo?.boostDiv * 100}% = ${rewardInfo?.boostPermillage / rewardInfo?.boostDiv * rewardInfo?.maxBoosterCount * 100}%  `}
          </TYPE.white>
        </RowBetween>


        <RowBetween>
          <TYPE.white> APR → APY </TYPE.white>
          {!isRewardEnded && (

            <TYPE.white>
              <Tooltip
                open={showAPRTooltip}
                onOpen={() => setShowAPRTooltip(true)}
                onClose={() => setShowAPRTooltip(false)}
                title={<div><TYPE.white> {` APR → APY (daily compound) `} </TYPE.white></div>}>
                <IconButton sx={{ paddingTop: '7px' }}>
                  <HelpOutlineIcon sx={{ fontSize: 16, color: "white" }} onClick={() => setShowAPRTooltip(!showAPRTooltip)} />
                </IconButton>
              </Tooltip>
              {Math.ceil(rewardAPR * 100)}% → {Math.ceil(estimatedAPY * 100)}%
            </TYPE.white>
          )}
          {isRewardEnded && (
            <TYPE.white> 0%</TYPE.white>
          )}
        </RowBetween>
      </StatContainer>


      {!isRewardEnded && isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your reward rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${rewardInfo?.userRewardRate?.toFixed(2, { groupSeparator: ',' })} ${rewardInfo?.rewardToken.symbol} / day`}
            </TYPE.black>
          </BottomSection>
        </>
      )}

      {rewardInfo?.stakedAmount.greaterThan('0') && !isRewardEnded && hasBooster && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your boost rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                🚀
              </span>
              {`${rewardInfo?.userRewardBoostRate?.toFixed(2, { groupSeparator: ',' })} ${rewardInfo?.rewardToken.symbol} / day`}
            </TYPE.black>
          </BottomSection>
        </>
      )}


    </Wrapper>
  )
}
