import { Token } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { UNI } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useNFTContract } from '../../hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from '../multicall/hooks'

export interface NFTPortfolioInfo {
  tokenID: string
  tokenURI: string
}

export function useNFTUserDeposits(collection: Token, tokenIDList: any[]): NFTPortfolioInfo[] {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined

  const nftContract = useNFTContract(collection?.address ?? undefined)

  const tokenURIArgs: any[][] = []
  const userDepositLen = tokenIDList ? tokenIDList?.length : 0
  for (let idx = 0; idx < userDepositLen ?? 0; idx++) {
    tokenURIArgs.push([tokenIDList[idx]])
  }
  const tokenURIRes = useSingleContractMultipleData(nftContract ?? undefined, 'tokenURI', tokenURIArgs ?? undefined)
  const tokenURIList: any[] = []
  for (let idy = 0; idy < tokenURIRes.length; idy++) {
    if (tokenURIRes?.[idy].result?.[0])
      var uri = tokenURIRes?.[idy].result?.[0].replace('ipfs://', 'https://ipfs.io/ipfs/')
    tokenURIList.push(uri)
  }

  return useMemo(() => {
    if (!chainId || !uni) return []

    return tokenURIList.reduce<NFTPortfolioInfo[]>((memo, tokenURIItem, index) => {
      // these two are dependent on account

      memo.push({
        tokenID: tokenURIArgs[index][0],
        tokenURI: tokenURIList[index]
      })

      return memo
    }, [])
  }, [chainId, tokenURIList, tokenURIArgs, uni])
}

export function useNFTPortfolio(collection: Token): NFTPortfolioInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined

  const nftContract = useNFTContract(collection?.address ?? undefined)
  const accountArg = useMemo(() => [account ?? undefined], [account])
  const accountMultiArg = account ?? undefined
  const balanceOfRes = useSingleCallResult(nftContract ?? undefined, 'balanceOf', accountArg ?? undefined)
  const balanceInt = parseInt(balanceOfRes?.result?.[0].toString())
  const tokenOfOwnerByIndexArgs = []
  for (let idx = 0; idx < balanceInt; idx++) {
    tokenOfOwnerByIndexArgs.push([accountMultiArg, idx])
  }
  const tokenOfOwnerByIndexRes = useSingleContractMultipleData(
    nftContract ?? undefined,
    'tokenOfOwnerByIndex',
    tokenOfOwnerByIndexArgs ?? undefined
  )

  const tokenURIArgs: any[][] = []
  for (let idy = 0; idy < tokenOfOwnerByIndexRes.length; idy++) {
    if (tokenOfOwnerByIndexRes?.[idy].result?.[0])
      tokenURIArgs.push([parseInt(tokenOfOwnerByIndexRes?.[idy].result?.[0].toString())])
  }
  const tokenURIRes = useSingleContractMultipleData(nftContract ?? undefined, 'tokenURI', tokenURIArgs ?? undefined)

  const tokenURIList: any[] = []
  for (let idz = 0; idz < tokenURIRes.length; idz++) {
    if (tokenURIRes?.[idz].result?.[0]) {
      const uri = tokenURIRes?.[idz].result?.[0].replace('ipfs://', 'https://ipfs.io/ipfs/')
      tokenURIList.push(uri)
    } else {
      const unuri = 'undefined'
      tokenURIList.push(unuri)
    }
  }

  return useMemo(() => {
    if (!chainId || !uni) return []

    return tokenURIList.reduce<NFTPortfolioInfo[]>((memo, tokenURIItem, index) => {
      // these two are dependent on account

      memo.push({
        tokenID: tokenURIArgs[index][0],
        tokenURI: tokenURIList[index]
      })

      return memo
    }, [])
  }, [chainId, tokenURIList, tokenURIArgs, uni])
}

export function useNFTPortfolioGangVerse(collection: Token): NFTPortfolioInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined

  const nftContract = useNFTContract(collection?.address ?? undefined)
  const accountArg = useMemo(() => [account ?? undefined], [account])
  const accountMultiArg = account ?? undefined

  const balanceOfRes = useSingleCallResult(nftContract ?? undefined, 'balanceOf', accountArg ?? undefined)
  const balanceInt = parseInt(balanceOfRes?.result?.[0].toString())
  const tokenOfOwnerByIndexArgs = []
  for (let idx = 0; idx < balanceInt; idx++) {
    tokenOfOwnerByIndexArgs.push([accountMultiArg, idx])
  }
  const tokensOfOwnerRes = useSingleCallResult(nftContract ?? undefined, 'tokensOfOwner', accountArg ?? undefined)
  const tokenURIArgs: any[][] = []
  if (tokensOfOwnerRes?.result) {
    for (let idy = 0; idy < tokensOfOwnerRes?.result[0].length; idy++) {
      if (tokensOfOwnerRes?.result[0][idy]) tokenURIArgs.push([parseInt(tokensOfOwnerRes?.result[0][idy].toString())])
    }
  }
  const tokenURIRes = useSingleContractMultipleData(nftContract ?? undefined, 'tokenURI', tokenURIArgs ?? undefined)

  const tokenURIList: any[] = []
  for (let idz = 0; idz < tokenURIRes.length; idz++) {
    if (tokenURIRes?.[idz].result?.[0]) {
      const uri = tokenURIRes?.[idz].result?.[0].replace('ipfs://', 'https://ipfs.io/ipfs/')
      tokenURIList.push(uri)
    } else {
      const unuri = 'undefined'
      tokenURIList.push(unuri)
    }
  }

  return useMemo(() => {
    if (!chainId || !uni) return []

    return tokenURIList.reduce<NFTPortfolioInfo[]>((memo, tokenURIItem, index) => {
      // these two are dependent on account

      memo.push({
        tokenID: tokenURIArgs[index][0],
        tokenURI: tokenURIList[index]
      })

      return memo
    }, [])
  }, [chainId, tokenURIList, tokenURIArgs, uni])
}

export interface NFTInfo {
  balance: number | undefined
  totalSupply: number | undefined
}

export function useNFTInfo(collection: Token): NFTInfo {
  const { chainId, account } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined

  const nftContract = useNFTContract(collection?.address ?? undefined)
  const accountArg = useMemo(() => [account ?? undefined], [account])

  const balanceOfRes = useSingleCallResult(nftContract ?? undefined, 'balanceOf', accountArg ?? undefined)
  const balanceInt = parseInt(balanceOfRes?.result?.[0].toString())
  const totalSupplyRes = useSingleCallResult(nftContract ?? undefined, 'totalSupply')
  const totalSupplyInt = parseInt(totalSupplyRes?.result?.[0].toString())

  return useMemo(() => {
    const memo: NFTInfo = {
      balance: undefined,
      totalSupply: undefined
    }

    if (!chainId || !uni) return memo
    else {
      if (!balanceOfRes?.loading && !totalSupplyRes?.loading) {
        if (balanceOfRes?.error || totalSupplyRes?.error) {
          console.error('Failed to load NFT info')
          return memo
        }

        memo.balance = balanceInt
        memo.totalSupply = totalSupplyInt

        return memo
      }
      return memo
    }
  }, [chainId, balanceOfRes, totalSupplyRes, uni])
}
