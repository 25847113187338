import React, { useState, useCallback } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount, JSBI } from '@cronosdex/sdk'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import {  useRewardsV2Contract } from '../../hooks/useContract'
import { useDerivedUnstakeInfo } from '../../state/stake/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import {  useServiceFee } from '../../state/rewardsV2/lpRewardsv2'
import { TransactionResponse } from '@ethersproject/providers'
import { SSRewardsV2Info } from '../../state/rewardsV2/ssRewardsv2'


const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: SSRewardsV2Info
  userLiquidityUnstaked: TokenAmount | undefined
}

export default function SSWithdrawV2Modal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked }: StakingModalProps) {

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedUnstakeInfo(typedValue, stakingInfo.stakedAmount)

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])


  const stakingContract = useRewardsV2Contract(stakingInfo.stakingRewardAddress)
  const serviceFee = useServiceFee(stakingInfo?.stakingRewardAddress, 0) 

  async function onWithdraw() {
    setAttempting(true)
    if (stakingContract && parsedAmount && serviceFee) {
      const serviceFeeRes = JSBI.BigInt( parseFloat(serviceFee?.toExact()) * Math.pow(10, 18)).toString()

      stakingContract.withdraw(`0x${parsedAmount.raw.toString(16)}`, {value: serviceFeeRes })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Withdraw Tokens`
        })
        setHash(response.hash)
      })
      .catch((error: any) => {
        setAttempting(false)
        console.log(error)
      })

  }}

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(stakingInfo?.stakedAmount)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo.token}
            label={''}
            hideBalance={true}
            disableCurrencySelect={true}
            myBalanceText={`Available to withdraw: ${stakingInfo?.stakedAmount?.toSignificant()}`}
            id="withdraw-single-token"
          />

          <RowBetween>
            <ButtonError
              disabled={!!error }
              error={!!error && !!parsedAmount}
              onClick={onWithdraw}
            >
              {error ?? 'Withdraw'}
            </ButtonError>
          </RowBetween>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Withdrawing Tokens</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} {stakingInfo?.token.symbol} Token</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew {parsedAmount?.toSignificant(4)} {stakingInfo?.token.symbol} Token</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
