import React, { useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { TYPE } from '../../theme'
import { RowBetween, RowFixed } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import { useLocation } from 'react-router-dom'
import IDOCard from '../../components/IDO/IDOCard'
// import { useActiveWeb3React } from '../../hooks'
import { IDOState, useIDOInfo } from '../../state/ido/hooks'
import { darken } from 'polished'
// import { IDO_UPCOMING_LIST } from '../../state/ido/upcomingLaunchpads'
// import IDOUpcomingCard from '../../components/IDO/IDOUpcomingCard'
// import { useWalletModalToggle } from '../../state/application/hooks'
import IDOTokenMintModal from '../../components/IDO/IDOTokenMintModal'
import CrowdLogo from '../../assets/images/crowdLogo.png'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link } from 'react-router-dom'
import { TransCard } from '../../components/Card'
import { Text } from 'rebass'
import { StyledToggle, ToggleElement } from '../../components/Toggle'
import crowdImage from '../../assets/images/crowdbg.png'
import { BarWave } from 'react-cssfx-loading'
import SearchOffIcon from '@mui/icons-material/SearchOff';

const StyleRowBetween = styled.div`
  background: #130f29a3;
  border: 1px solid #58498c;
  border-radius: 12px;
  padding: 10px;
  width: 100%;
}
`

const CustomTabs = styled(Tabs)`
  .MuiButtonBase-root {
    background: #6cbdb726;
    color: white;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    &.Mui-selected {
      color: white;
      font-weight: 600;
      background: #6cb5bd4f;
    }
  }
  .MuiTabs-indicator {
    background-color: #43ace3;
  }
`

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  height: 2.5rem;
  position: relative;
  padding: 0 0.75rem;
  flex: inherit;
  border: ${({ theme, active, warning }) => active && `1px solid ${warning ? theme.red1 : theme.primary1}`};
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }
  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
  }
`

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`

const ImageDiv = styled.div`
  background-image: url(${crowdImage});
  background-size: cover;
  background-position: right top;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`

export default function IDO() {
  // const { chainId } = useActiveWeb3React()
  const queries = useLocation().search
  const typeFromQS = new URLSearchParams(queries).get('state')

  let orgIdoInfos = useIDOInfo()
  const isLoading = orgIdoInfos?.length === 0

  const hasLiveProjects = orgIdoInfos?.some(idoInfo => idoInfo.status === IDOState.LIVE);
  const hasUpcomingProjects = orgIdoInfos?.some(idoInfo => idoInfo.status === IDOState.UPCOMING);

  // let idoUpcomingInfos = IDO_UPCOMING_LIST
  let currentTypeInfo = hasLiveProjects ? IDOState.LIVE : hasUpcomingProjects ? IDOState.UPCOMING : IDOState.LAUNCHED
  
  if (typeFromQS) {
    currentTypeInfo = parseInt(typeFromQS)
  }

  const [value, setValue] = React.useState(currentTypeInfo.toString())
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  let idoInfos = undefined;

  if (currentTypeInfo !== undefined) {
    idoInfos = orgIdoInfos?.filter(idoInfo => (idoInfo?.status === currentTypeInfo ? true : false))
    idoInfos.reverse()
  }

  const [filterText, setFilterText] = useState('')
  function filterByTokenSymbol(value: string) {
    setFilterText(value)
  }

  if (filterText) {
    const regex = new RegExp(filterText, 'i')
    idoInfos = idoInfos?.filter(idoInfo =>
      regex.test(idoInfo?.token.symbol!) || regex.test(idoInfo?.contributionToken.symbol!) ? true : false
    )
    // idoUpcomingInfos = idoUpcomingInfos?.filter(idoInfo => (regex.test(idoInfo?.token.symbol!) ? true : false))
  }

  // const idoUpcomingExist = Boolean(typeof chainId === 'number' && (idoUpcomingInfos?.length ?? 0) > 0)

  const [showIDOTokenMintModal, setShowIDOTokenMintModal] = useState(false)



  const [onlyClaimable, setOnlyClaimable] = useState(false)
  function toggle() {
    setOnlyClaimable(!onlyClaimable)
  }
  if (onlyClaimable) {
    idoInfos = idoInfos?.filter(idoInfo => idoInfo?.isClaimable)
  }

  const noResult = (idoInfos) && idoInfos?.length === 0

  return (
    <>
      <ImageDiv />
      <PageWrapper gap="lg" justify="center">
        <TopSection gap="md">
          <img src={CrowdLogo} style={{ margin: ' 0rem auto', marginBottom: '1rem', height: '7rem' }} alt="Crowd" />
          <DataCard
            style={{
              background: '#1f1243cc',
              boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
              border: ' 1.5px solid rgb(13, 12, 32)'
            }}
          >
            <CardSection>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600} fontSize={20}>
                    CROWD Launchpad
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white fontSize={14}>
                    CROWD Launchpad is the prime address for high-quality projects looking to bootstrap liquidity for
                    their launch at the Cronos network via an Initial DEX Offering (IDO)
                  </TYPE.white>
                </RowBetween>
                {/* <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="fit-content"
                margin="auto"
                onClick={handleTokenMintClick}
              >
                Buy Ticket
              </ButtonPrimary>{' '} */}
              </AutoColumn>
            </CardSection>
          </DataCard>
        </TopSection>

        <CustomTabs value={value} onChange={handleChange} aria-label="nav tabs example">
          <Tab
            component={Link}
            value="0"
            label={<TYPE.white> Upcoming </TYPE.white>}
            to={`/ido?state=${IDOState.UPCOMING}`}
          />
          <Tab
            component={Link}
            value="1"
            label={<TYPE.white> Launched </TYPE.white>}
            to={`/ido?state=${IDOState.LAUNCHED}`}
          />
          <Tab component={Link} value="2" label={<TYPE.white> Live </TYPE.white>} to={`/ido?state=${IDOState.LIVE}`} />
        </CustomTabs>

        <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
          {idoInfos && (
            <>
              <IDOTokenMintModal isOpen={showIDOTokenMintModal} onDismiss={() => setShowIDOTokenMintModal(false)} />
            </>
          )}
          <StyleRowBetween>
            {currentTypeInfo === IDOState.LAUNCHED && (
              <RowBetween style={{ marginBottom: '15px' }}>
                <RowFixed>
                  <TYPE.black fontWeight={600}>Show Only Claimables</TYPE.black>
                </RowFixed>
                <StyledToggle isActive={true} onClick={toggle}>
                  <ToggleElement isActive={onlyClaimable} isOnSwitch={true}>
                    On
                  </ToggleElement>
                  <ToggleElement isActive={!onlyClaimable} isOnSwitch={true}>
                    Off
                  </ToggleElement>
                </StyledToggle>
              </RowBetween>
            )}

            <RowBetween>
              <TYPE.black fontWeight={600}>Filter Tokens</TYPE.black>

              <OptionCustom style={{ width: '225px' }} tabIndex={-1}>
                <Input placeholder={'CRX'} onChange={e => filterByTokenSymbol(e.target.value)} />
              </OptionCustom>
            </RowBetween>
          </StyleRowBetween>

          <PoolSection>

          { isLoading && (
                <TransCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center"></Text>
                    <BarWave style={{ margin: '20px auto' }} color="#5b5eb1"></BarWave>
                  </AutoColumn>
                </TransCard>
              )}

              { !isLoading && noResult && (
                <TransCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center">
                    <SearchOffIcon sx={{ fontSize: 48, color:"#5b5eb1" }}/>
                    </Text>
                  </AutoColumn>
                </TransCard>
              )}

            {idoInfos && idoInfos?.length !== 0 &&
              idoInfos?.map(idoInfo => {
                return <IDOCard key={idoInfo?.contractAddress} idoInfo={idoInfo} />
              })}

            {/* {!idoUpcomingExist && currentTypeInfo === IDOState.UPCOMING && (
              <LightCard padding="45px 10px">
                <AutoColumn gap="sm" justify="center">
                  <Text textAlign="center"></Text>
                </AutoColumn>
              </LightCard>
            )}

            {idoUpcomingExist &&
              currentTypeInfo === IDOState.UPCOMING &&
              idoUpcomingInfos?.map(idoUpcomingInfo => {
                return <IDOUpcomingCard key={idoUpcomingInfo?.name} idoUpcomingInfo={idoUpcomingInfo} />
              })} */}
          </PoolSection>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
