import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../theme'
import { Text } from 'rebass'
import hacken from '../../assets/images/hacken.svg'

const StyledAudit = styled.div`
  display: flex;
  bottom: 0;
  padding: 1rem;
  transition: opacity 0.25s ease;
  color: white;
  :hover {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export default function Audit() {
  return (
    <ExternalLink href={'https://wp.hacken.io/wp-content/uploads/2021/11/Crodex_23112021SCAudit_Report.pdf'}>
      <StyledAudit>
        <Text fontWeight={600}>{'Audited by '}</Text>
        <img src={hacken} style={{ margin: 'auto 5px', width: '100px', height: '15px' }} alt="hacken" />
      </StyledAudit>
    </ExternalLink>
  )
}
