import { JSBI, TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { METAVERSEHUB, UNI, WCRO } from '../../constants/'
import { useActiveWeb3React } from '../../hooks'
import { useMHubTokenContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../multicall/hooks'


export const MHUB_TOKEN_CONTRACT_ADDRESSES = METAVERSEHUB.address


export interface MHUBTokenInfo {
    balance: TokenAmount | undefined
    withdrawableDividend: TokenAmount | undefined
    totalClaimed: TokenAmount | undefined
    totalDistributed: TokenAmount | undefined
    totalWithdrawn: TokenAmount | undefined
}

// gets the staking info from the network for the active chain id
export function useMHUBTokenInfo(): MHUBTokenInfo {

    const { chainId, account } = useActiveWeb3React()

    const uni = chainId ? UNI[chainId] : undefined
    
    const mhubTokenContract = useMHubTokenContract(MHUB_TOKEN_CONTRACT_ADDRESSES)

    const balance = useSingleCallResult(mhubTokenContract, 'balanceOf', [account ?? undefined])
    
    const withdrawableDividend = useSingleCallResult(mhubTokenContract, 'withdrawableDividendOf', [account ?? undefined])

    const totalClaimed = useSingleCallResult(mhubTokenContract, 'withdrawnDividendOf', [account ?? undefined])

    const totalDistributed = useSingleCallResult(mhubTokenContract, 'totalDistributed')

    const totalWithdrawn = useSingleCallResult(mhubTokenContract, 'totalWithdrawn')

    return useMemo(() => {

        let memo : MHUBTokenInfo = {
            withdrawableDividend: undefined,
            balance: undefined,
            totalClaimed: undefined,
            totalDistributed: undefined,
            totalWithdrawn: undefined
        }

        if (!chainId || !uni) return memo
        else {
            if (
                !withdrawableDividend?.loading &&
                !balance?.loading &&
                !totalClaimed?.loading &&
                !totalDistributed?.loading &&
                !totalWithdrawn?.loading
            ){
                if (
                    withdrawableDividend?.error ||
                    balance?.error ||
                    totalClaimed?.error ||
                    totalDistributed?.error ||
                    totalWithdrawn?.error
                ) {
                    console.error('Failed to load MHUB Token info')
                    return memo
                }
                memo.withdrawableDividend = new TokenAmount(WCRO, JSBI.BigInt(withdrawableDividend?.result?.[0]?? 0))
                memo.balance = new TokenAmount(METAVERSEHUB, JSBI.BigInt(balance?.result?.[0]?? 0))
                memo.totalClaimed = new TokenAmount(WCRO, JSBI.BigInt(totalClaimed?.result?.[0]?? 0))
                memo.totalDistributed = new TokenAmount(WCRO, JSBI.BigInt(totalDistributed?.result?.[0]?? 0))
                memo.totalWithdrawn = new TokenAmount(WCRO, JSBI.BigInt(totalWithdrawn?.result?.[0]?? 0))

                return memo 
            }
            return memo
        }   
    }, [chainId, uni, withdrawableDividend, balance, totalClaimed, totalDistributed, totalWithdrawn])
}