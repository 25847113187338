import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, Pair, WETH } from '@cronosdex/sdk'
import { useMemo } from 'react'
import {
  AUTO,
  AVAX,
  BNB,
  CRONOSVERSE,
  CRX,
  DAI,
  FTM,
  MAI,
  MAI_NATIVE,
  MIM,
  UNI,
  USDC,
  USDT,
  WBTC,
  WRAPPEDETHER
} from '../../constants'
import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1633636800

export const REWARDS_DURATION_DAYS = 28


export enum LP_STAKE_TYPES {
  LEGACY,
  RENEWABLE
}

export enum LP_STATUS_TYPES {
  FINISHED,
  HALTED,
  LIVE
}


// TODO add staking rewards addresses h ere
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    rewardToken: Token
    round: number
    type: LP_STAKE_TYPES 
  }[]
} = {
  [ChainId.CRONOSMAINNET]: [
    ////////////////////////////
    ///// ROUND 0 REWARDS /////
    //////////////////////////
    {
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0xDb752eB155F6075d4Ba0e09c371eB5eBB0D4bAA5',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [CRX, USDT],
      stakingRewardAddress: '0xc2FF850F3921C1dbeD263aa1Fa94FE2A898870a8',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [CRX, USDC],
      stakingRewardAddress: '0x681E1dC139FEB9024F1C63b37673cFCD630817Bb',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0xcdd27c1C74631700CA0Fa165f3450435C8D009f4',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [MIM, USDT],
      stakingRewardAddress: '0xeE1B28B5922426D8B1A9169F0e9681c2D870ECe3',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [MAI, USDC],
      stakingRewardAddress: '0xe8aD609cb055329d84e6F7f430b098B46E9d3f51',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WBTC, USDT],
      stakingRewardAddress: '0x81E200976B7928aEFD34CE51544e65FE73e88bE4',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], USDT],
      stakingRewardAddress: '0x5d05Ce6ae9FDC9dC3fBba240a98320Bc604f80a7',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], USDC],
      stakingRewardAddress: '0x55B0FC13045B0bf6CD74631246b92f7abCFcCca2',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], DAI],
      stakingRewardAddress: '0x12EE4bc798Fd985195b0d293c2c61fBf3DcDfe04',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], MIM],
      stakingRewardAddress: '0x7d29D4b61E8A0A482BDf8e6c9b1985c67dD43780',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], MAI],
      stakingRewardAddress: '0xAE7E71e15547dA622eaeAfA9b4272B4Ac1f9615a',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },

    {
      tokens: [AVAX, USDT],
      stakingRewardAddress: '0xd34d5d5D635b3090b85c3A3A96B0E8d78316aE0f',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], FTM],
      stakingRewardAddress: '0x5a25d023F298025aE1eC0549Fcba5F0f3D6c7Fc2',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], BNB],
      stakingRewardAddress: '0x536F4b39D659c17C4255E2d235f5fdb836Fa165E',
      rewardToken: CRX,
      round: 0,
      type: LP_STAKE_TYPES.LEGACY
    },


    ////////////////////////////
    ///// ROUND 1 REWARDS /////
    //////////////////////////
    {
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0xC42F861Fb18c752ce7A848f1B637f05ca7ED47Fa',
      rewardToken: CRX,
      round: 1,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [CRX, USDT],
      stakingRewardAddress: '0xF6f07c09f47cdEb7BE01bf6c3882EeFA59f8aE09',
      rewardToken: CRX,
      round: 1,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], USDT],
      stakingRewardAddress: '0x1d970F305bE431569a7f1Cd5ce9453e67BE08Dee',
      rewardToken: CRX,
      round: 1,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], USDC],
      stakingRewardAddress: '0xff58e6E81d63a08df29211127af46c6598C11846',
      rewardToken: CRX,
      round: 1,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], MAI_NATIVE],
      stakingRewardAddress: '0x680Dc09b0Ab8b83d470F7DD8EA64B5eEBE17B21D',
      rewardToken: CRX,
      round: 1,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], BNB],
      stakingRewardAddress: '0xcF2dfef2d324EDc277e26F788e7e9B3a2ceCCC2a',
      rewardToken: CRX,
      round: 1,
      type: LP_STAKE_TYPES.LEGACY
    },


    ////////////////////////////
    ///// ROUND 2 REWARDS /////
    //////////////////////////
    {
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0xb0d451f6d8EFbc080e77889F44cBc6C1Cd4F25f6',
      rewardToken: CRX,
      round: 2,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], WRAPPEDETHER],
      stakingRewardAddress: '0xe72390684D52007Be8c3A3E14018Ad0Af6ff20dF',
      rewardToken: CRX,
      round: 2,
      type: LP_STAKE_TYPES.LEGACY
    },



    ////////////////////////////
    ///// ROUND 3 REWARDS /////
    //////////////////////////
    {
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0x554d6c39ADF6a68143513B6a6eF0e960a5b96F12',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [CRX, USDC],
      stakingRewardAddress: '0xf281A59eC4883c78590f22BA9a1e7A96638555d5',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [CRX, USDT],
      stakingRewardAddress: '0xafad9a8C25b749167194367C4E85DC67326D0fB4',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0x093F0b322b16ba6303D31ac6610e79c96782C81F',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], USDC],
      stakingRewardAddress: '0x688526A2C4f93aff2fAFce0C1271b420a2cc5f52',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [DAI, USDC],
      stakingRewardAddress: '0xfcB28AF784956345dB56ddFE93C683F4C17663ff',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },

    {
      tokens: [WETH[ChainId.CRONOSMAINNET], WRAPPEDETHER],
      stakingRewardAddress: '0x2B8A90EB8b71c10dE4DfA43048438e648013B9FB',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], MAI_NATIVE],
      stakingRewardAddress: '0xB2D081914681ce57869302ef6A5A6F0B86331757',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], AUTO],
      stakingRewardAddress: '0xA30Cb4f815F0d736888b6b6840aa917e2d295a9d',
      rewardToken: CRX,
      round: 3,
      type: LP_STAKE_TYPES.LEGACY
    },



    ////////////////////////////
    ///// ROUND 4 REWARDS /////
    //////////////////////////
    {
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0x3c9F97D67c87231A1FD3440B039C802a40DC1660',
      rewardToken: CRX,
      round: 4,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [CRX, USDT],
      stakingRewardAddress: '0x0120Ee0EaE3A5dFAf7fbf69dEbAE100d7898a929',
      rewardToken: CRX,
      round: 4,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0x34810AF1A04359210e36Fb14050693Fe3C7319cC',
      rewardToken: CRX,
      round: 4,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      tokens: [DAI, USDC],
      stakingRewardAddress: '0x9f6AD90389d730312C227b9048083295Bc1EA332',
      rewardToken: CRX,
      round: 4,
      type: LP_STAKE_TYPES.LEGACY
    },

    ////////////////////////////
    ///// ROUND 5 REWARDS /////
    //////////////////////////
    {
      tokens: [WETH[ChainId.CRONOSMAINNET], CRONOSVERSE],
      stakingRewardAddress: '0xF3691c069De07Ba6966FD81a3e7b481FAa2E231C',
      rewardToken: CRONOSVERSE,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    // {
    //   tokens: [WETH[ChainId.CRONOSMAINNET], ROLLIUM],
    //   stakingRewardAddress: '0x3bb1761c94B9d694Ea4C58c8aeD224492EFD7EFA',
    //   rewardToken: CRX,
    //   round: 6,
    //   type: LP_STAKE_TYPES.RENEWABLE
    // },


    /////////////////////////
    ///// TEST REWARDS /////
    ///////////////////////
    // {
    //   tokens: [WETH[ChainId.CRONOSMAINNET], AUTO],
    //   stakingRewardAddress: '0xa49616B75A046a4b14A7C0d7071108a94418965B',
    //   rewardToken: CRX,
    //   round: 2
    // },
  ]
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the reward tokens
  rewardToken: Token
  // round info
  round: number
  // type
  type: LP_STAKE_TYPES
  // pool status
  poolStatus: LP_STATUS_TYPES
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(rewardToFilterBy?: string | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(
            stakingRewardInfo =>
            (rewardToFilterBy === undefined) ? true :
            (rewardToFilterBy === stakingRewardInfo.stakingRewardAddress) // rewardToFilterBy.equals(stakingRewardInfo.token)
          ) ?? []
        : [],
    [chainId, rewardToFilterBy]
  )

  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            info[index].rewardToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        const isRewardEnded = new Date().getTime() > periodFinishMs ? true : false

        let poolStatus;
        if ( info[index].type === LP_STAKE_TYPES.LEGACY )
          poolStatus = LP_STATUS_TYPES.FINISHED
        else if ( isRewardEnded )
          poolStatus = LP_STATUS_TYPES.HALTED
        else
          poolStatus = LP_STATUS_TYPES.LIVE


        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          rewardToken: info[index].rewardToken,
          round: info[index].round,
          type: info[index].type,
          poolStatus: poolStatus,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni])
}


// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
