/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
// import image from '../../../assets/images/spaceCrxillion01.png'
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
max-height: ${isMobile ? '400px' : '100%'};
overflow-y: auto;
  scrollbar-gutter: stable;
`


// const IMG = styled.img`
//   display: block;
//   -webkit-background-size: cover;
//   background-size: cover;
//   background-repeat: no-repeat;
//   -webkit-background-position: center;
//   background-position: center;
//   width: 100%;
//   object-fit: cover;
//   width: 200px;
// `

const CustomCell = styled(TableCell)`
    color: white !important;
`



function createData(
    range: string,
    power: number,
    apr: number | undefined
) {
    return { range, power, apr };
}


const rows = [
    createData("[0, 1499]", 9, undefined),
    createData("[1500, 2299]", 8, undefined),
    createData("[2300, 2699]", 7, undefined),
    createData("[2700, 2899]", 6, undefined),
    createData("[2900, 2999]", 5, undefined),
];


interface ArrivalAdventureMechanicsModalProps {
    isOpen: boolean
    onDismiss: () => void
}

export default function ArrivalAdventureMechanicsModal({ isOpen, onDismiss }: ArrivalAdventureMechanicsModalProps) {
    const wrappedOnDismiss = useCallback(() => {
        onDismiss()
    }, [onDismiss])

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            <ContentWrapper gap="lg">
                <GlobalStyles
                    styles={{
                        '*::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5b5eb1',
                            outline: '1px solid #5b5eb1',
                            borderRadius: '12px'
                        }
                    }}
                />
                <RowBetween>
                    <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Mechanics: The Arrival
                    </TYPE.mediumHeader>

                    <CloseIcon onClick={wrappedOnDismiss} />
                </RowBetween>


                <ScrollContent>

                    <TYPE.white
                        fontWeight={500}
                        fontSize={14}
                        style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
                    >
                        Dispatch and Recall transactions incur 1 CRO fee per NFT. Each harvest (claim) also incurs 1 CRO fee; but every time you Dispatch or Recall, you automatically harvest your pending resources without extra fee. The collected fees will be used to buyback MHUB at a time decided by the team before the Adventure finalizes.
                    </TYPE.white>

                    <TYPE.white
                        fontWeight={500}
                        fontSize={14}
                        style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
                    >
                        Space Crxillions that have arrived earlier hold an advantage of having an easier access to resources. Having a lower ID implies an earlier arrival. Each Space Crxillion committed to the adventure gets a share of the resources in proportion to their harvesting power given in the following sheet.
                    </TYPE.white>



                    <TableContainer component={Card} style={{ background: 'transparent', marginTop: '1rem', margin: 'auto', width: 'fit-content' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <CustomCell>
                                        <TYPE.white fontFamily={'monospace'}>
                                            ID Range
                                        </TYPE.white>
                                    </CustomCell>
                                    <CustomCell align="right">
                                        <TYPE.white fontFamily={'monospace'}>
                                            Harvesting Power
                                        </TYPE.white>
                                    </CustomCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ color: 'white' }}>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.range}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <CustomCell component="th" scope="row">
                                            <TYPE.white fontFamily={'monospace'}>
                                                {row.range}
                                            </TYPE.white>
                                        </CustomCell>

                                        <CustomCell align="right">
                                            <TYPE.white fontFamily={'monospace'}>

                                                {row.power} <span role="img" aria-label="wizard-icon" style={{ marginLeft: '8px' }}>
                                                    ⚡
                                                </span>
                                            </TYPE.white>

                                        </CustomCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ScrollContent>
            </ContentWrapper>
        </Modal>
    )
}
