/* eslint-disable react/jsx-key */
import React, { useCallback, useState } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { ButtonPrimary } from '../../Button'
import ArrivalAdventureMechanicsModal from './ArrivalAdventureMechanicsModal'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
  max-height: ${ isMobile ? '430px' : '100%'};
  overflow-y: auto;
  scrollbar-gutter: stable;
`


interface ArrivalAdventureStoryModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function ArrivalAdventureStoryModal({ isOpen, onDismiss }: ArrivalAdventureStoryModalProps) {
  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])
  const [showMechanicsModal, setShowMechanicsModal] = useState(false)

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>


      <ArrivalAdventureMechanicsModal
        isOpen={showMechanicsModal}
        onDismiss={() => setShowMechanicsModal(false)}
      />

      <ContentWrapper gap="lg">
      <GlobalStyles
              styles={{
                '*::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '*::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                  backgroundColor: '#5b5eb1',
                  outline: '1px solid #5b5eb1',
                  borderRadius: '12px'
                }
              }}
            />
        <RowBetween>
          <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Adventure: The Arrival 


                <ButtonPrimary style={{ width:'fit-content', marginLeft:'8px', padding:'8px'}}
                  onClick={() => setShowMechanicsModal(true)}>
                  <SettingsSuggestRoundedIcon
                    sx={{ fontSize: 18, color: 'white' }}
                  />
                </ButtonPrimary>

          </TYPE.mediumHeader>
            
          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <ScrollContent>

        <div style={{ margin: '1rem' }}>
          <iframe
            frameBorder={0}
            allowFullScreen
            width="100%"
            height="300"
            src="https://www.youtube.com/embed/8itmjAXYbYk?autoplay=1&controls=0&color=white&autohide=1&showinfo=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>

        <TYPE.white
          fontWeight={500}
          fontSize={14}
          style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px',  hyphens: 'auto' }}
        >
          The Metaverse Hub has always been a peaceful place of gathering for Metaverse and NFT enthusiasts on Cronos. <br/>
          The one and only permanent inhabitants of the Hub are Crxillions: highly acknowledged, pleasently charming, hyper realistic felines
          that are proven to be loyal assets for their investors.<br/>
          Many visitors from a vast variety of places have joined the Crxillions in the Hub from time to time.
          They have all been expected and appreciated visitors.<br/>
          A short while ago, the inhabitants of the Hub detected a group of unexpected guests. And it feels... Strange. <br/>
          From the unknown corners of Cosmos, another gang of Crxillions arrives at the Hub. Unexpected by the OG Crxillions.<br/>
          How come they look much like OG Crxillions: Uncertain.
          How they managed to perfectly locate themselves out of OG Crxillions' reach: Uncertain.
          Their intentions: Uncertain. And only time will tell.
          For all we know, they are coming down to the Hub from outer space with traits belonging to space.
          It is only natural we call them... Space Crxillions.
          Seemingly, Space Crxillions are here to stay. Like their adventures are just starting.
          They now arrive at the Hub and start collecting resources at once.
          Naturally, earlier arrivers are advantageous in this initial resource rush.
        </TYPE.white>   
        <TYPE.white
              style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px',  hyphens: 'auto', cursor: 'pointer', textDecoration: 'underline' }}

              onClick={() => setShowMechanicsModal(true)}
              fontWeight={500}
              fontSize={14}>          
              Read the mechanics of the adventure here↗</TYPE.white>
        </ScrollContent>
     
      </ContentWrapper>
    </Modal>
  )
}
