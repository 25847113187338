import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'
import { Currency, CurrencyAmount, Token } from '@cronosdex/sdk'
import { useZapperContract } from '../../hooks/useContract'
import { ZAPPER_CONTRACT_ADDRESS } from '../../state/zapper/hooks'
import { useToken } from '../../hooks/Tokens'
import { usePairFinder } from '../../state/zapper/pairFinder'
import { ZERO_ADDRESS } from '../../constants'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : ''
}


interface ZapperInModalProps {
  isOpen: boolean
  onDismiss: () => void
  inputCurrency: Currency | undefined | null
  outputCurrency: Currency | undefined | null
  amountIn: CurrencyAmount | undefined
  minAmountIn: CurrencyAmount | undefined
}

export default function ZapperInModal({ isOpen, onDismiss, inputCurrency, outputCurrency, amountIn, minAmountIn }: ZapperInModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }


  const zapperContract = useZapperContract(ZAPPER_CONTRACT_ADDRESS)

  let isNativeCRO = false
  let inputCurrencyAddress = ''
  if (inputCurrency) {
    isNativeCRO = inputCurrency.symbol === "CRO" ? true : false
    inputCurrencyAddress = currencyKey(inputCurrency)
  }
  const fromToken = useToken(inputCurrencyAddress)

  let outputCurrencyAddress = ''
  if (outputCurrency) {
    outputCurrencyAddress = currencyKey(outputCurrency)
  }
  const pairInfo = usePairFinder(outputCurrencyAddress)

  const toToken0 = useToken(pairInfo?.token0)
  const toToken1 = useToken(pairInfo?.token1)


  async function onZapIn() {
    if (zapperContract && amountIn && minAmountIn) {
      setAttempting(true)
      if (isNativeCRO) {
        await zapperContract
          .zap(ZERO_ADDRESS, toToken0?.address, toToken1?.address, `0x${amountIn.raw.toString(16)}`, 1, 1, { value: `0x${amountIn.raw.toString(16)}` })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Zap CRO to ${toToken0?.symbol}-${toToken1?.symbol} LP`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        await zapperContract
          .zap(fromToken?.address, toToken0?.address, toToken1?.address, `0x${amountIn.raw.toString(16)}`, 1, 1)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Zap ${fromToken?.symbol} to ${toToken0?.symbol}-${toToken1?.symbol} LP`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      }

    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Zapper</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <AutoColumn justify="center" gap="md">
            <TYPE.body>Zapping</TYPE.body>
            <TYPE.body fontWeight={600} fontSize={36}>
              {amountIn?.toSignificant(6)}
            </TYPE.body>
            <TYPE.body> {inputCurrency?.symbol} to {toToken0?.symbol}-{toToken1?.symbol} LP</TYPE.body>
          </AutoColumn>
          <ButtonError disabled={!!error} error={!!error} onClick={onZapIn}>
            {error ?? 'Zap Now!'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>
              Zapping
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Zapped!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
