import * as React from 'react'
import { LightCard } from '../../components/Card'
import styled from 'styled-components'
import image from '../../assets/images/spaceCrxillion0.png'
import { TYPE } from '../../theme'
import Grid from '@mui/material/Grid'
import { isMobile } from 'react-device-detect'
import ArrivalAdventure from '../../components/MHUB/ArrivalAdventure/ArrivalAdventure'
import MissionRecon from '../../components/MHUB/MissionRecon/MissionRecon'
import FightAdventure from '../../components/MHUB/FightAdventure/FightAdventure'

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: #1f1243cc;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`
const IMG = styled.img`
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  width: 200px;
`

export default function HUB() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} sx={{ display: 'flex' }}>
          <Cards>
            <Grid container spacing={0}>
              <Grid item xs={isMobile ? 12 : 8}>
                <TYPE.white fontWeight={600} fontSize={30} style={{ marginLeft: '20px' }}>
                The Metaverse Hub Adventures
                </TYPE.white>
                <TYPE.white
                  fontWeight={500}
                  fontSize={14}
                  style={{ textAlign: 'justify', marginLeft: '20px', marginTop: '16px', lineHeight: '21px' }}
                >The Adventures are here to deliberately reveal the grand story of the Metaverse Hub and they will allow you to be a part of it while having a proper DeFi experience.
                </TYPE.white>
              </Grid>
              {!isMobile && (
                <Grid item xs={4} margin={'auto'}>
                  <IMG src={image} alt="crx" style={{ width: 200, height: 184, marginLeft:'auto' }} />
                </Grid>
              )}
            </Grid>
          </Cards>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FightAdventure />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MissionRecon />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ArrivalAdventure />
        </Grid>
      </Grid>

    </>
  )
}
