/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
import { useFightAdventure } from '../../../state/mhub/adventures/fightAdventure'
// import image from '../../../assets/images/spaceCrxillion01.png'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
max-height: ${isMobile ? '400px' : '100%'};
overflow-y: auto;
  scrollbar-gutter: stable;
`


interface FightAdventureMechanicsModalProps {
    isOpen: boolean
    onDismiss: () => void
}

export default function FightAdventureMechanicsModal({ isOpen, onDismiss }: FightAdventureMechanicsModalProps) {
    const adventureInfo = useFightAdventure()
    const wrappedOnDismiss = useCallback(() => {
        onDismiss()
    }, [onDismiss])

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            <ContentWrapper gap="lg">
                <GlobalStyles
                    styles={{
                        '*::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5b5eb1',
                            outline: '1px solid #5b5eb1',
                            borderRadius: '12px'
                        }
                    }}
                />
                <RowBetween>
                    <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Mechanics: Skirmishes
                    </TYPE.mediumHeader>

                    <CloseIcon onClick={wrappedOnDismiss} />
                </RowBetween>


                <ScrollContent>

                    <TYPE.white
                        fontWeight={500}
                        fontSize={14}
                        style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
                    >
                        <i>Skirmishes</i> is a reward capturing and loot collecting adventure.
                        It involves both OG and Space Crxillions.
                        Regardless of the type of your NFT, there are 3 actions you can take.
                        <br />
                        <ol style={{ paddingInlineStart: '15px' }}>
                            <li>
                                <b>Join Squad:</b> Join the squad to perform attacks or passively collect loots*.
                            </li>
                            <li>
                                <b>Abandon Squad:</b> You can abandon the squad and retrieve your NFT immediately. <i>No fees</i>.
                                But of course, you cannot perform attacks or collect defense loots any longer.
                            </li>
                            <li>
                                <b>Attack:</b> Pay <i>{adventureInfo?.totalAttackFee?.toFixed(0)} CRO</i> and choose one of your Crxillions (OG or Space) in the squad
                                to have an attempt on capturing the accumulated reward* and collecting loots**.
                                Regardless of the outcome (win or lose) your NFT will be returned to you.
                            </li>
                        </ol>
                         <i>(*) MHUB</i> rewards accumulate in a common pool over time. You will need to attack and win the skirmish to grab the accrued reward. <br/>

                        Out of the paid fee;<br />
                        <ul style={{ paddingInlineStart: '15px' }}>
                            <li>
                                <i>(**) {adventureInfo.attackLoot?.toFixed(0)} CRO</i> is the loot of the skirmish.
                            </li>
                            <li>
                                <i>{adventureInfo.attackTax?.toFixed(0)} CRO</i> will be taken as tax
                            </li>
                            <li>
                                <i>{adventureInfo.attackBuyback?.toFixed(0)} CRO</i> will be claimed by the <a style={{ color: 'white'}} href="https://cronoscan.com/address/0x0e53a1f83750ce62efb3ae1b33aa086c151734ee" > Buyback Wallet</a>. <br />
                                The collected CRO will only be used to buyback MHUB, create <b><i>CRX/MHUB liquidity</i></b>  and burn them!
                            </li>
                            
                        </ul>
                        <b>Attack mechanics:</b> Most importantly, the rank of your NFT now matters!
                        Each Crxillion has a particular range of Attack/Defense points. <br />

                        For a Space Crxillion the range is calculated by <b><i>[3000 - rank + 1, 3600]</i></b> and,
                        for an OG Crxillion the range is calculated by <b><i>[2600 - rank + 301, 3600]</i></b>. <br />
                        <br/>

                        Example;<br />
                        <ul style={{ paddingInlineStart: '15px' }}>
                            <li>
                                When OG <b>Rank 1</b> attacks or defends, it scores a point between <b><i>2900</i></b> and <b><i>3600</i></b>,
                                while OG Rank 2600 scores a point between <b><i>301</i></b> and <b><i>3600</i></b>. <br />
                            </li>
                            <li>
                                When Space <b>Rank 1</b> attacks or defends, it scores a point between <b><i>3000</i></b> and <b><i>3600</i></b>,
                                while Space Rank 3000 scores a point between <b><i>1</i></b> and <b><i>3600</i></b>. <br />
                            </li>
                        </ul>

                        Whoever scores a higher point (attacker or defender) wins the skirmish.
                        Equal points favor the attacker.<br/>

                        <br/>
                        <b>You pick the attacker, but who defends?</b><br/>
                        - The defender is a random NFT from the opposing collection.
                        Could have any rank. Thus, your chances are significantly higher if you pick a higher ranked attacker.<br/>

                        <br/>
                        <b>What happens if the attacker wins?</b><br/>
                        - The loot <i>({adventureInfo.attackLoot?.toFixed(0)} CRO)</i> goes to the attacker.
                        The accumulated <b>MHUB</b> reward goes to the attacker.<br/>

                        <br/>
                        <b>What happens if the defender wins?</b><br/>
                        - The loot <i>({adventureInfo.attackLoot?.toFixed(0)} CRO)</i> goes to the owner of the defender, if it is in the squad. Otherwise, the loot is deducted as tax.
                        <b>MHUB</b> rewards keep accumulating.<br/>

                        <br/>
                        <b>When an attack is performed:</b><br/>
                        - A <b>{adventureInfo?.globalAttackWait}-minute</b> global-timer is triggered. Noone can perform an attack until the timer expires.<br/>
                        - A <b>{adventureInfo?.individualAttackWait}-hour</b> wallet-timer is triggered. The wallet cannot perform an attack until the timer expires.<br/>
                        - A <b>{adventureInfo?.joinWait}-day</b> NFT-timer is triggered. The NFT cannot rejoin the squad until the timer expires.<br/>
                        <br/>
                        Fee and timer policies are subject to adjustments in the future.
                    </TYPE.white>

                </ScrollContent>
            </ContentWrapper>
        </Modal>
    )
}
