/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon, CustomLightSpinner } from '../../../theme'
import { ButtonConfirmed, ButtonError } from '../../Button'
import { DataCard } from '../../earn/styled'
import { JSBI, TokenAmount } from '@cronosdex/sdk'

import { useMissionReconContract, useNFTContract } from '../../../hooks/useContract'
import { ApprovalState, useApproveCallback, useNFTApprovalStatus } from '../../../hooks/useApproveCallback'

import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../../ModalViews'

import Circle from '../../../assets/images/blue-loader.svg'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import iamge from '../../../assets/svg/262.png'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { NFTPortfolioInfo } from '../../../state/nftPortfolio/hooks'
import GlobalStyles from '@mui/material/GlobalStyles'
import Checkbox from '@mui/material/Checkbox'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { SPACE_CRXILLION_INFO } from '../../../state/nftStake/projects'
import { CRXILLION } from '../../../constants/collections'
import {
  MissionReconAdventureInfo,
  MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS,
  useMissionReconFee
} from '../../../state/mhub/adventures/missionReconAdventure'
import { METAVERSEHUB } from '../../../constants'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useTokenBalance } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    zIndex: 1203
  }
})

const ErrorSection = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const StyledImageListItemBar = styled(ImageListItemBar)`
  .MuiImageListItemBar-titleWrap {
    display: none;
  }
  .MuiImageListItemBar-actionIcon {
    background: #46499333;
    width: 100%;
    text-align: center;
  }
`

const Styledimg = styled.img`
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  margin-bottom: 0.5rem;
`

const CheckboxStyle = styled(Checkbox)`
  color: ${({ theme }) => theme.bg3}!important;
  &.Mui-checked {
    color: ${({ theme }) => theme.bg3}!important;
  }
`
const RadioStyle = styled(Radio)`
  color: ${({ theme }) => theme.bg3}!important;
  &.Mui-checked {
    color: ${({ theme }) => theme.bg3}!important;
  }
  &.Mui-disabled {
    color: #373941 !important;
  }
`

const StyleFormControlLabel = styled(FormControlLabel)`
  margin-right: 0 !important;
`

const StyledFormControl = styled(FormControl)`
  max-height: 330px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 0px 20px !important;
`

const RadioFromControl = styled(FormControlLabel)`
  margin: 0px !important;
  .MuiFormControlLabel-label {
    &.Mui-disabled {
      color: #373941 !important;
    }
  }
`

interface MissionReconDepositModalProps {
  isOpen: boolean
  onDismiss: () => void
  adventureInfo: MissionReconAdventureInfo
  portfolioInfo: NFTPortfolioInfo[]
}

interface NFTInfo {
  tokenID: string
  tokenURI: string
  metaData: any
}

interface Metadata {
  image: string
  name: string
}

export default function MissionReconDepositModal({
  isOpen,
  onDismiss,
  adventureInfo,
  portfolioInfo
}: MissionReconDepositModalProps) {
  const { account } = useActiveWeb3React()
  const [nftInfoList, setNFTInfoList] = useState<NFTInfo[]>([])
  const [allSelectedNft, setAllSelectedNft] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [previousPortfolio, setPreviousPortfolio] = useState<NFTPortfolioInfo[]>([])
  const [update, setUpdate] = React.useState(false)
  const [allCheck, setAllCheck] = React.useState(false)
  const [ticketList, setTicketList] = React.useState<string[]>([])
  const mhubBalance = useTokenBalance(account ?? undefined, METAVERSEHUB)
  const feeToPay = useMissionReconFee(ticketList ?? [])
  const error = feeToPay?.greaterThan(mhubBalance ?? '0') ? 'Insufficient Balance' : undefined
  const [metadataFail, setMetadataFail] = React.useState(false)
  const [radioValues, setRadioValues] = useState<{ id: string; pair: string; disabled: boolean }[]>([])

  const getPairResult = (pairList: any[], nftList: any[]) => {
    const temp: string[] = []
    nftList.forEach((nft: any) => {
      const nftIndex = pairList.findIndex((item: { id: any }) => item.id === nft)
      temp.push(pairList[nftIndex].pair)
    })
    setTicketList(temp)
  }

  const setPairWithIndex = (p: any, temp: any) => {
    let newArr = radioValues

    if (p === 'add') {
      newArr = [...radioValues, { id: temp, pair: '0', disabled: false }]
      setRadioValues(newArr)
    } else if (p === 'rmv') {
      const nftIndex = newArr.findIndex(item => item.id === temp)
      newArr[nftIndex].disabled = true
      setRadioValues(newArr)
    } else if (p === 'setFalse') {
      const nftIndex = newArr.findIndex(item => item.id === temp)
      newArr[nftIndex].disabled = false
      setRadioValues(newArr)
    } else {
      const nftIndex = newArr.findIndex(item => item.id === temp.name)
      newArr[nftIndex].pair = temp.value
      setRadioValues(newArr)
    }
    getPairResult(newArr, allSelectedNft)
  }

  const setPairAll = (initialChecked: any[]) => {
    const newArr: React.SetStateAction<{ id: string; pair: string; disabled: boolean }[]> = []

    if (initialChecked.length !== 0) {
      initialChecked.forEach((nftId: string) => {
        const nftIndex = radioValues.findIndex(item => item.id === nftId)
        newArr.push({ id: nftId, pair: nftIndex === -1 ? '0' : radioValues[nftIndex].pair, disabled: false })
      })
      setRadioValues(newArr)
    } else {
      const newValues = radioValues
      newValues.forEach(item => {
        item.disabled = true
      })
      setRadioValues(newValues)
    }
    getPairResult(newArr, initialChecked)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPairWithIndex('chng', event.target as HTMLInputElement)
  }
  async function getReq(url: string): Promise<Metadata> {
    return fetch(url)
      .then(async response => {
        const tokenData = await response.text()
        const metaData = JSON.parse(tokenData)
        metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
        return metaData
      })
      .catch(error => {
        console.error('Error:', error)
        return null
      })
  }

  async function getMetaData(portfolioInfo: NFTPortfolioInfo[], operation: string) {
    if (operation === 'getData') {
      const portList = []
      for (let idx = 0; idx < portfolioInfo.length; idx++) {
        let metaData: Metadata | undefined = undefined

        if (portfolioInfo[idx].tokenURI !== 'undefined') {
          if (SPACE_CRXILLION_INFO.hasMetadata) {
            let metadataURI = portfolioInfo[idx].tokenURI
            if (SPACE_CRXILLION_INFO.tokenURIPrefix) {
              metadataURI = SPACE_CRXILLION_INFO.tokenURIPrefix + portfolioInfo[idx].tokenURI
            }
            metaData = await getReq(metadataURI)
          } else {
            metaData = { image: '', name: '' }
            metaData.image = portfolioInfo[idx]?.tokenURI
            if (SPACE_CRXILLION_INFO.imageURIPrefix) {
              metaData.image = SPACE_CRXILLION_INFO.imageURIPrefix + portfolioInfo[idx].tokenURI
            }
          }
        }
        if (metaData) {
          metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
          portList[idx] = {
            metaData: metaData,
            tokenID: portfolioInfo[idx].tokenID,
            tokenURI: portfolioInfo[idx].tokenURI
          }
          setNFTInfoList([...portList])
          setUpdate(!update)
        } else {
          portList[idx] = {
            metaData: { image: 'https://swap.crodex.app/ant.jpg', name: 'Placeholder' },
            tokenID: portfolioInfo[idx].tokenID,
            tokenURI: portfolioInfo[idx].tokenURI
          }
          setMetadataFail(true)
          setNFTInfoList([...portList])
          setUpdate(!update)
        }
      }
    } else if (operation === 'updateData') {
      setUpdate(!update)
    }
  }

  useEffect(() => {
    if (portfolioInfo.length === 0) {
      setLoading(false)
    } else if (portfolioInfo.length !== 0 && isOpen === true && portfolioInfo[0].tokenURI !== undefined) {
      setLoading(false)
      if (previousPortfolio !== undefined) {
        if (previousPortfolio.length !== portfolioInfo.length) {
          getMetaData(portfolioInfo, 'getData')
          setPreviousPortfolio(portfolioInfo)
        }
      } else {
        setPreviousPortfolio(portfolioInfo)
      }
    } else if (portfolioInfo[0]?.tokenURI === undefined) {
      setLoading(true)
    }
  }, [portfolioInfo, isOpen, previousPortfolio])

  let approval = useNFTApprovalStatus(CRXILLION, MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS)

  const [approvalToken, approveCallback] = useApproveCallback(
    new TokenAmount(METAVERSEHUB, JSBI.BigInt(15000000 * Math.pow(10, 18)).toString()),
    MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS
  )

  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  const adventureContract = useMissionReconContract(MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS)
  const nftContract = useNFTContract(CRXILLION.address)

  const handleChangeNft = (event: React.ChangeEvent<HTMLInputElement>) => {
    const initialChecked = allSelectedNft
    const val = (event.target as HTMLInputElement).value as never
    if ((event.target as HTMLInputElement).checked === true) {
      initialChecked?.push(val)
      const nftIndex = radioValues.findIndex(item => item.id === val)
      if (nftIndex === -1) {
        setPairWithIndex('add', val)
      } else {
        setPairWithIndex('setFalse', val)
      }
      if (allCheck === false && initialChecked.length === 10) {
        setAllCheck(true)
      }
    } else if ((event.target as HTMLInputElement).checked === false) {
      initialChecked.forEach((element, index) => {
        if (element === (event.target as HTMLInputElement).value) {
          initialChecked.splice(index, 1)
          setPairWithIndex('rmv', (event.target as HTMLInputElement).value)
        }
        if (allCheck === true) {
          setAllCheck(false)
        }
      })
    }
    setAllSelectedNft(initialChecked)
    getMetaData(portfolioInfo, 'updateData')
  }

  const handleChangeNftAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const initialChecked: React.SetStateAction<string[]> = []
    if ((event.target as HTMLInputElement).checked === true) {
      portfolioInfo.slice(0, 10).map(item => {
        initialChecked?.push(item.tokenID.toString())
      })
    }
    setAllCheck((event.target as HTMLInputElement).checked)
    setAllSelectedNft(initialChecked)
    getMetaData(portfolioInfo, 'updateData')
    setPairAll(initialChecked)
  }

  useEffect(() => {
    if (isOpen === false) {
      setAllSelectedNft([])
      setAllCheck(false)
      setRadioValues([])
    }
  }, [isOpen])

  async function onStake() {
    setAttempting(true)
    if (adventureContract && feeToPay) {
      if (approval === ApprovalState.APPROVED && allSelectedNft.length !== 0) {
        const numberSelectedNFT = allSelectedNft.map(i => Number(i))
        const estimatedGas = await adventureContract.estimateGas['enterRaffle'](numberSelectedNFT, ticketList).then(
          estimatedGasLimit => {
            return estimatedGasLimit
          }
        )
        const estimatedGasLimit = estimatedGas
          .mul(120)
          .div(100)
          .toString()
        adventureContract
          .enterRaffle(numberSelectedNFT, ticketList, { gasLimit: estimatedGasLimit })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Dispatch Crxillions`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  async function onAttemptToApproveToken() {
    return approveCallback()
  }

  async function onAttemptToApprove() {
    if (adventureContract && nftContract) {
      approval = ApprovalState.PENDING
      nftContract
        .setApprovalForAll(MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS, true)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Approval Arrival Adventure`
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    } else {
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Dispatch Crxillions</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <StyledFormControl>
            <GlobalStyles
              styles={{
                '*::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '*::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                  backgroundColor: '#5b5eb1',
                  outline: '1px solid #5b5eb1',
                  borderRadius: '12px'
                }
              }}
            />
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms'
              }}
              unmountOnExit
            >
              <LinearProgress style={{ margin: '15px' }} color="secondary" />
            </Fade>

            {!loading && nftInfoList.length !== 0 && (
              <FormControlLabel
                disableTypography={true}
                label="Select All"
                control={
                  <CheckboxStyle id="checkedAll" checked={allCheck} value="checkedAll" onChange={handleChangeNftAll} />
                }
              />
            )}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {nftInfoList.map(nftInfo => {
                return (
                  <Grid item xs={6}>
                    <PoolData style={{ padding: '0.5rem' }}>
                      <ImageListItem key={nftInfo.tokenID}>
                        <CustomWidthTooltip title={nftInfo.metaData.name} placement="right">
                          <Styledimg src={nftInfo.metaData.image} alt={iamge} loading="lazy" />
                        </CustomWidthTooltip>
                        <StyledImageListItemBar
                          position="below"
                          actionIcon={
                            <StyleFormControlLabel
                              value={nftInfo.tokenID}
                              control={
                                <CheckboxStyle
                                  id={nftInfo.tokenID}
                                  checked={allSelectedNft.some(element => element == nftInfo.tokenID)}
                                  value={nftInfo.tokenID}
                                  onChange={handleChangeNft}
                                  disabled={
                                    allSelectedNft.length === 10 &&
                                    !allSelectedNft.some(element => element == nftInfo.tokenID)
                                  }
                                />
                              }
                              label={`#${nftInfo.tokenID}`}
                            />
                          }
                        />
                        <FormControl style={{ width: '100%' }}>
                          <RadioGroup
                            name={nftInfo.tokenID}
                            defaultValue={'0'}
                            onChange={handleChange}
                            style={{ margin: 'auto' }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                          >
                            <RadioFromControl
                              disabled={!allSelectedNft.some(element => element == nftInfo.tokenID)}
                              value="0"
                              labelPlacement="bottom"
                              control={<RadioStyle />}
                              label="#1"
                            />
                            <RadioFromControl
                              disabled={!allSelectedNft.some(element => element == nftInfo.tokenID)}
                              value="1"
                              labelPlacement="bottom"
                              control={<RadioStyle />}
                              label="#2"
                            />
                            <RadioFromControl
                              disabled={!allSelectedNft.some(element => element == nftInfo.tokenID)}
                              value="2"
                              labelPlacement="bottom"
                              control={<RadioStyle />}
                              label="#3"
                            />
                          </RadioGroup>
                        </FormControl>
                      </ImageListItem>
                    </PoolData>
                  </Grid>
                )
              })}
            </Grid>
          </StyledFormControl>

          <ErrorSection dim={false}>
            <TYPE.subHeader fontWeight={600}>Selected NFT count: {allSelectedNft.length}</TYPE.subHeader>
          </ErrorSection>

          <ErrorSection dim={false}>
            <TYPE.black fontWeight={600}>
              Required MHUB:
              {feeToPay && ` ${feeToPay?.toSignificant(4)}`}
              {!feeToPay && (
                <CustomLightSpinner style={{ marginLeft: '0.5rem' }} src={Circle} alt="loader" size={'18px'} />
              )}
            </TYPE.black>
          </ErrorSection>

          {metadataFail && (
            <ErrorSection dim={true}>
              <TYPE.black fontSize={12} fontWeight={400}>
                NFT metadata failed to load.
              </TYPE.black>
            </ErrorSection>
          )}

          <RowBetween>
            {(approvalToken !== ApprovalState.APPROVED || approval !== ApprovalState.APPROVED) && (
              <>
                <ButtonConfirmed
                  mr="0.5rem"
                  onClick={onAttemptToApproveToken}
                  confirmed={approvalToken === ApprovalState.APPROVED}
                  altDisabledStyle={approvalToken === ApprovalState.PENDING} // show solid button while waiting
                  disabled={approvalToken !== ApprovalState.NOT_APPROVED}
                >
                  Approve MHUB
                </ButtonConfirmed>

                <ButtonConfirmed
                  mr="0.5rem"
                  onClick={onAttemptToApprove}
                  confirmed={approval === ApprovalState.APPROVED}
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  disabled={approval !== ApprovalState.NOT_APPROVED}
                >
                  Approve Crxillion
                </ButtonConfirmed>
              </>
            )}

            {approvalToken === ApprovalState.APPROVED && approval === ApprovalState.APPROVED && (
              <ButtonError
                disabled={!!error || approval !== ApprovalState.APPROVED || allSelectedNft.length === 0}
                onClick={onStake}
                error={!!error}
              >
                {error ?? 'Dispatch'}
              </ButtonError>
            )}
          </RowBetween>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Dispatching Crxillions</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              #{allSelectedNft.join(', ')} {CRXILLION.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Dispatched #{allSelectedNft.join(', ')} {CRXILLION.symbol}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
