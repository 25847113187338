import { ChainId, Token } from "@cronosdex/sdk";


export const IDO_UPCOMING_LIST = [

]

// TODO add staking rewards addresses here
export const IDO_UPCOMING_INFO: {
    [chainId in ChainId]?: {
        name: string
        token: Token
        startDate: Date | undefined
        endDate: Date | undefined
    }[]
} = {
    [ChainId.CRONOSMAINNET]: IDO_UPCOMING_LIST
}