import React, { useState } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import CurrencyLogo from '../CurrencyLogo'
import { ETHER, Fraction } from '@cronosdex/sdk'
import { ButtonPrimary } from '../Button'
import { useColor } from '../../hooks/useColor'
import { Break } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { numberWithCommas } from '../../utils/helper'
import { SSBoostStakingInfo } from '../../state/boostStake/SSBoost'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SINGLE_STAKE_TYPES } from '../../state/singleStake/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { BOOSTED_POOLS_BENEFICIARY_WALLET, BOOSTED_POOLS_DEPLOYER_WALLET, GOD_WALLETS } from '../../constants'
import { useBoostedStakingRewardsContract } from '../../hooks/useContract'
// import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: #1f1243cc;
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  box-shadow: ${({ theme, showBackground }) => (showBackground ? "inset #ff9999 -1px -1px 6px 3px" : 'inset #dde7e77d -1px -1px 3px 2px')} ;
  border: ${({ theme, showBackground }) => (showBackground ? '1.5px solid #ff9999' : '1.5px solid #0d0c20')};


`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 33px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 33px 1fr 96px;
  `};
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function SSBoostCard({ stakingInfo }: { stakingInfo: SSBoostStakingInfo }) {
    const { account } = useActiveWeb3React()

    const token0 = stakingInfo.token

    const currency0 = unwrappedToken(token0)
    // const currency1 = unwrappedToken(token1)

    const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0') || stakingInfo?.boosters.length > 0)
    // get the color of the token
    const token = currency0 === ETHER ? token0 : token0
    const backgroundColor = useColor(token)

    const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)

    const depositedTokenPriceinUSDC = useUSDCPrice(stakingInfo.token)

    let totalDepositInUSDC: Fraction | undefined
    if (totalSupplyOfStakingToken && depositedTokenPriceinUSDC) {
        totalDepositInUSDC =
            stakingInfo?.totalStakedAmount.multiply(depositedTokenPriceinUSDC?.adjusted)
    }
    const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo.rewardToken)
    const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `1`
    const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

    let poolRate = numberWithCommas(stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))
    let myRate = stakingInfo.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(4, { groupSeparator: ',' })


    let apr = '-'
    let apy = '-'

    if (totalDepositInUSDC) {
        const numApr =
            ((parseFloat(stakingInfo.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365 * rewardTokenPrice) /
                parseFloat(totalDepositInUSDC?.toFixed(0))) *
            100
        const aprValue = Math.round(numApr).toString()
        apr = `${aprValue}%`
        apy = ` ${((Math.pow(1 + (numApr) / 36500, 365) - 1) * 100).toFixed(0)}% 🔥`
    }

    const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
    if (isRewardEnded) {
        apr = '0%'
        poolRate = '0'
        myRate = '0'
    }

    const userBoostPerc = Math.round(stakingInfo?.boostPermillage / stakingInfo?.boostDiv * stakingInfo?.boosters.length * 100)
    let myBoostRate = stakingInfo?.rewardRate?.multiply((60 * 60 * 24 * 7 * userBoostPerc / 100).toString())?.toFixed(4, { groupSeparator: ',' }) ?? '-'
    const hasBooster = stakingInfo?.boosters.length > 0 ? true : false

    // const [showTimerTooltip, setShowTimerTooltip] = useState(false);
    const [showAPRTooltip, setShowAPRTooltip] = useState(false);
    const stakingContract = useBoostedStakingRewardsContract(stakingInfo?.stakingRewardAddress)

    const contractBalance = useETHBalances(stakingInfo?.stakingRewardAddress ? [stakingInfo?.stakingRewardAddress] : [])?.[stakingInfo?.stakingRewardAddress ?? '']
    const isGodMode = account && GOD_WALLETS.includes(account) ? true : false
    const isDeployer = account && BOOSTED_POOLS_DEPLOYER_WALLET === account
    const addTransaction = useTransactionAdder()

    async function onCroTransfer() {
        if (stakingContract) {
          await stakingContract
            .transferCro(BOOSTED_POOLS_BENEFICIARY_WALLET)
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: `Claim CRO rewards`
              })
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
      }
      
    return (
        <Wrapper showBackground={isStaking} bgColor={backgroundColor}>

            <TopSection>
                <CurrencyLogo currency={currency0} size={'24px'} />
                <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '0px' }}>
                    {currency0.symbol}
                    {/* <Tooltip
                        open={showTimerTooltip}
                        onOpen={() => setShowTimerTooltip(true)}
                        onClose={() => setShowTimerTooltip(false)}
                        title={<TYPE.white> {stakingInfo?.periodFinish?.toUTCString()} </TYPE.white>}>
                        <IconButton sx={{ paddingTop: '7px' }} onClick={() => setShowTimerTooltip(!showTimerTooltip)}>
                            <QueryBuilderOutlinedIcon sx={{ fontSize: 16, color: "white" }} />
                        </IconButton>
                    </Tooltip> */}
                </TYPE.white>

                <StyledInternalLink to={`/rewards/boost/single/${stakingInfo.stakingRewardAddress}`} style={{ width: '100%' }}>
                    <ButtonPrimary padding="8px" borderRadius="8px">
                        {isStaking ? 'Manage' : 'Deposit'}
                    </ButtonPrimary>
                </StyledInternalLink>
            </TopSection>

            <Break></Break>
      {isGodMode && (
        <>
          <StatContainer >
            <RowBetween>
              <TYPE.white> CRO Balance </TYPE.white>
              {isDeployer ? (<ButtonPrimary onClick={() => onCroTransfer()} width="100px" padding="8px" borderRadius="8px">
                {contractBalance?.toFixed(0)} Claim
              </ButtonPrimary>
              ) : (
                <TYPE.white> {contractBalance?.toFixed(0)}</TYPE.white>
              )}
            </RowBetween>
          </StatContainer>
          <Break></Break>
        </>
      )}

            <StatContainer>
                <RowBetween>
                    <TYPE.white> Total deposited</TYPE.white>
                    <TYPE.white>{totalDepositInUSDC ? `$${numberWithCommas(totalDepositInUSDC.toFixed(0))}` : `-`}</TYPE.white>
                </RowBetween>
                <RowBetween>
                    <TYPE.white> Pool rate </TYPE.white>
                    <TYPE.white style={{ display: 'flex' }}>
                        <CurrencyLogo style={{ marginRight: '3px' }} currency={stakingInfo?.rewardToken} size={'20px'} />
                        {`${poolRate} ${stakingInfo.rewardToken.symbol} / week`}</TYPE.white>
                </RowBetween>
                {stakingInfo?.maxBoosterCount > 0 && (
                    <RowBetween>
                        <TYPE.white> Max. boost </TYPE.white>
                        <TYPE.white style={{ display: 'flex' }}>
                            <CurrencyLogo style={{ marginRight: '3px' }} currency={stakingInfo?.collection} size={'20px'} />
                            {`${stakingInfo?.maxBoosterCount} ${stakingInfo?.collection.name} x ${stakingInfo?.boostPermillage / stakingInfo?.boostDiv * 100}% = ${Math.round(stakingInfo?.boostPermillage / stakingInfo?.boostDiv * stakingInfo?.maxBoosterCount * 100)}%  `}
                        </TYPE.white>
                    </RowBetween>
                )}
                <RowBetween>
                    <TYPE.white> APR → APY </TYPE.white>
                    {!isRewardEnded && (

                        <TYPE.white>
                            <Tooltip
                                open={showAPRTooltip}
                                onOpen={() => setShowAPRTooltip(true)}
                                onClose={() => setShowAPRTooltip(false)}
                                title={<div><TYPE.white> {` APR → APY (daily compound) `} </TYPE.white></div>}>
                                <IconButton sx={{ paddingTop: '7px' }}>
                                    <HelpOutlineIcon sx={{ fontSize: 16, color: "white" }} onClick={() => setShowAPRTooltip(!showAPRTooltip)} />
                                </IconButton>
                            </Tooltip>
                            {apr} → {apy}
                        </TYPE.white>

                    )}
                    {isRewardEnded && (
                        <TYPE.white> {apr}</TYPE.white>
                    )}
                </RowBetween>
            </StatContainer>

            {isRewardEnded && stakingInfo?.type == SINGLE_STAKE_TYPES.LEGACY && (
                <>
                    <Break />
                    <BottomSection showBackground={true}>
                        <TYPE.black color={'white'} fontWeight={500}>
                            <span>This reward pool is finished. Please withdraw your funds.</span>
                        </TYPE.black>
                    </BottomSection>
                </>
            )}

            {isRewardEnded && stakingInfo?.type == SINGLE_STAKE_TYPES.RENEWABLE && (
                <>
                    <Break />
                    <BottomSection showBackground={true}>
                        <TYPE.black color={'white'} fontWeight={500}>
                            <span>This reward pool is paused and not distributing rewards at the moment.</span>
                        </TYPE.black>
                    </BottomSection>
                </>
            )}

            {!isRewardEnded && isStaking && (
                <>
                    <Break />
                    <BottomSection showBackground={true}>
                        <TYPE.black color={'white'} fontWeight={500}>
                            <span>Your reward rate</span>
                        </TYPE.black>

                        <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
                            <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                                ⚡
                            </span>
                            {`${myRate} ${stakingInfo?.rewardToken.symbol} / week`}
                        </TYPE.black>
                    </BottomSection>
                </>
            )}


            {stakingInfo?.maxBoosterCount > 0 && stakingInfo?.stakedAmount.greaterThan('0') && !isRewardEnded && hasBooster && (
                <>
                    <Break />
                    <BottomSection showBackground={true}>
                        <TYPE.black color={'white'} fontWeight={500}>
                            <span>Your boost rate</span>
                        </TYPE.black>

                        <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
                            <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                                🚀
                            </span>
                            {`${myBoostRate} ${stakingInfo?.rewardToken.symbol} / week`}
                        </TYPE.black>
                    </BottomSection>
                </>
            )}
        </Wrapper>
    )
}
