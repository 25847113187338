import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { ButtonPrimary } from '../../components/Button'

import { IDOState, useIDOInfo } from '../../state/ido/hooks'
import { CardBGImage, DataCard } from '../../components/IDO/styled'

import Calendar from '../../assets/images/calendar.png'
import moment from 'moment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Divider from '@mui/material/Divider'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import IDOParticipateModal from '../../components/IDO/IDOParticipateModal'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useActiveWeb3React } from '../../hooks'
import IDOClaimModal from '../../components/IDO/IDOClaimModal'
import IDOTokenMintModal from '../../components/IDO/IDOTokenMintModal'
import CurrencyLogo from '../../components/CurrencyLogo'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Menu from '../../components/Menu'
import { ApplicationModal } from '../../state/application/actions'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: '8px !important',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#eeeeeeb8'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5b5eb1'
  }
}))

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

// const VoteCard = styled(DataCard)`
//   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
//   overflow: hidden;
// `

const StyledReserveCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.black};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: 40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: -1;
`

const StyledClaimedCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg7};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: -1;
`

const StyledUnclaimedCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg6};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
`

const StyledClaimableCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
`

const PoolData = styled(DataCard)`
  background: #1b1b34;
  padding: 1rem;
  z-index: 1;
  background: transparent;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function IDOManage({
  match: {
    params: { idoAddr }
  }
}: RouteComponentProps<{ idoAddr: string }>) {
  const { account } = useActiveWeb3React()

  // get currencies and pair
  const idoInfo = useIDOInfo(idoAddr)?.[0]

  // toggle for participate modal and claim modal
  const [showIDOParticipateModal, setShowIDOParticipateModal] = useState(false)
  const [showIDOClaimModal, setShowIDOClaimModal] = useState(false)

  const [showIDOTokenMintModal, setShowIDOTokenMintModal] = useState(false)

  const toggleWalletModal = useWalletModalToggle()

  // const handleTokenMintClick = useCallback(() => {
  //   if (account) {
  //     setShowIDOTokenMintModal(true)
  //   } else {
  //     toggleWalletModal()
  //   }
  // }, [account, toggleWalletModal])

  const handleParticipateClick = useCallback(() => {
    if (account) {
      setShowIDOParticipateModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleClaimClick = useCallback(() => {
    if (account) {
      setShowIDOClaimModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <Accordion
        defaultExpanded={true}
        style={{
          background: 'linear-gradient(360deg, rgb(48, 48, 74) 0%, rgb(27, 27, 52) 78%)',
          border: '1px solid #565A69',
          borderRadius: '12px'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CurrencyLogo currency={idoInfo?.token} size={'33px'} style={{ marginRight: '10px' }} />
          <TYPE.mediumHeader style={{ color: 'white', fontSize: '24px' }}>
            {idoInfo?.name}
          </TYPE.mediumHeader>
        </AccordionSummary>
        <AccordionDetails>
          <RowBetween style={{ margin: '1rem 0' }}>
            <TYPE.subHeader style={{ color: 'white' }}>{idoInfo?.project.description}</TYPE.subHeader>
          </RowBetween>

          {idoInfo?.project.idoDescription && (
            <>

              <TYPE.subHeader style={{ color: 'white', fontSize: '18px' }}>
                IDO Information
              </TYPE.subHeader>
              <RowBetween style={{ margin: '1rem 0' }}>
                <TYPE.subHeader style={{ color: 'white' }}>{idoInfo?.project.idoDescription}</TYPE.subHeader>
              </RowBetween>

            </>
          )}

          <RowBetween style={{ margin: '1rem 0' }}>
            <Menu applicationModal={ApplicationModal.MENU} projectInfo={idoInfo?.project}></Menu>
          </RowBetween>
          <DataRow style={{ gap: '24px' }}>
            <PoolData>
              <AutoColumn gap="sm">
                <TYPE.body style={{ margin: 0, color: '#bfbfd3' }} fontWeight={600}>
                  Start Date
                </TYPE.body>
                <Divider style={{ background: '#565a69' }} />
                <TYPE.body fontSize={16} fontWeight={500}>
                  <List style={{ padding: '0' }}>
                    <ListItem alignItems="flex-start" style={{ padding: '0' }}>
                      <ListItemAvatar>
                        <img src={Calendar} style={{ width: '30px', height: '30px' }} alt="logo" />
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        primary={moment(idoInfo?.startDate.toUTCString()).format('dddd, MMM DD YYYY')}
                        secondary={
                          <React.Fragment>
                            <TYPE.white
                              sx={{ display: 'block' }}
                              variant="subtitle2"
                              style={{ color: 'white' }}
                            >
                              {idoInfo?.startDate
                                .toUTCString()
                                .split(' ')
                                .slice(4, 6)
                                .join(' ')}
                            </TYPE.white>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </TYPE.body>
              </AutoColumn>
            </PoolData>
            <PoolData>
              <AutoColumn gap="sm">
                <TYPE.body style={{ margin: 0, color: '#bfbfd3' }} fontWeight={600}>
                  End Date
                </TYPE.body>
                <Divider style={{ background: '#565a69' }} />
                <TYPE.body fontSize={16} fontWeight={500}>
                  <List style={{ padding: '0' }}>
                    <ListItem alignItems="flex-start" style={{ padding: '0' }}>
                      <ListItemAvatar>
                        <img src={Calendar} style={{ width: '30px', height: '30px' }} alt="logo" />
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        primary={moment(idoInfo?.endDate.toUTCString()).format('dddd, MMM DD YYYY')}
                        secondary={
                          <React.Fragment>
                            <TYPE.white
                              sx={{ display: 'block' }}
                              variant="subtitle2"
                              style={{ color: 'white' }}
                            >
                              {idoInfo?.endDate
                                .toUTCString()
                                .split(' ')
                                .slice(4, 6)
                                .join(' ')}
                            </TYPE.white>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </TYPE.body>
              </AutoColumn>
            </PoolData>
          </DataRow>
        </AccordionDetails>
      </Accordion>

      {/* {true && (
        <VoteCard>
          <CardBGImage />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Get Crowd Ticket</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`CRX-V2 LP tokens are required. Once you've added liquidity to the pool you can stake your liquidity tokens on this page.`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="fit-content"
                margin="auto"
                onClick={handleTokenMintClick}
              >
                Buy Ticket
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
        </VoteCard>
      )} */}
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader fontSize={22} style={{ margin: 'auto', textAlign: 'center' }}>
          {idoInfo?.statusText}
        </TYPE.mediumHeader>
      </RowBetween>

      <DataRow style={{ gap: '24px', display: 'contents' }}>
        <PoolData style={{ background: 'linear-gradient(360deg, rgb(48 48 74) 0%, rgb(27, 27, 52) 78%)' }}>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0, textAlign: 'center', color: '#bfbfd3' }}>Total Collected</TYPE.body>
            <Divider style={{ background: '#565a69', width: '9rem', margin: 'auto' }} />
            <TYPE.body fontSize={18} fontWeight={500} style={{ textAlign: 'center' }}>
              {`${idoInfo?.totalHardCap.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
            </TYPE.body>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" value={idoInfo?.collectedPercentage} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <TYPE.white variant="body2" color="white">{`${idoInfo?.collectedPercentage}%`}</TYPE.white>
              </Box>
            </Box>
            <TYPE.white fontSize={14} variant="body2" color="white" style={{ textAlign: 'center' }}>
              {`1 ${idoInfo?.token.symbol} = ${idoInfo?.tokenPrice.toSignificant(8, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol
                }`}
            </TYPE.white>

            {idoInfo?.status === IDOState.LIVE && idoInfo?.canParticipate && (
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="fit-content"
                margin="auto"
                onClick={handleParticipateClick}
              >
                Participate
              </ButtonPrimary>
            )}
          </AutoColumn>
          <Box sx={{ width: '100%', mr: 1, marginTop: '10px' }}>
            <DataRow style={{ gap: '2' }}>
              {idoInfo?.softCap && idoInfo?.isSoftEnabled ? (
                <PoolData>
                  <AutoColumn gap="sm">
                    <TYPE.body style={{ margin: 0 }} fontSize={12}>
                      Soft Cap
                    </TYPE.body>
                    <TYPE.body fontSize={18} fontWeight={500}>
                      {`${idoInfo?.softCap.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
                    </TYPE.body>
                  </AutoColumn>
                </PoolData>
              ) : (
                <PoolData>
                  <AutoColumn gap="sm">
                    <TYPE.body style={{ margin: 0 }} fontSize={12}>
                      Target
                    </TYPE.body>
                    <TYPE.body fontSize={18} fontWeight={500}>
                      {`${idoInfo?.hardCap.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
                    </TYPE.body>
                  </AutoColumn>
                </PoolData>
              )}

              <PoolData>
                <AutoColumn gap="sm">
                  <TYPE.body style={{ margin: 0 }} fontSize={12}>
                    Min Contribution
                  </TYPE.body>
                  <TYPE.body fontSize={18} fontWeight={500}>
                    {`${idoInfo?.minContribution.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol
                      }`}
                  </TYPE.body>
                </AutoColumn>
              </PoolData>
              <PoolData>
                <AutoColumn gap="sm">
                  <TYPE.body style={{ margin: 0 }} fontSize={12}>
                    Max Contribution
                  </TYPE.body>
                  <TYPE.body fontSize={18} fontWeight={500}>
                    {`${idoInfo?.maxContribution.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol
                      }`}
                  </TYPE.body>
                </AutoColumn>
              </PoolData>
            </DataRow>
          </Box>
        </PoolData>
      </DataRow>

      {idoInfo && (
        <>
          <IDOParticipateModal
            isOpen={showIDOParticipateModal}
            onDismiss={() => setShowIDOParticipateModal(false)}
            idoInfo={idoInfo}
          />
          <IDOClaimModal isOpen={showIDOClaimModal} onDismiss={() => setShowIDOClaimModal(false)} idoInfo={idoInfo} />
          <IDOTokenMintModal isOpen={showIDOTokenMintModal} onDismiss={() => setShowIDOTokenMintModal(false)} />
        </>
      )}

      {!idoInfo?.isContractWhitelisted && (
        <TYPE.main style={{ textAlign: 'center' }} fontSize={16}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          This is an open sale! No whitelisting required.
        </TYPE.main>
      )}

      {idoInfo?.isContractWhitelisted && idoInfo?.isAccountWhitelisted && (
        <TYPE.main style={{ textAlign: 'center' }} fontSize={16}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          This wallet is whitelisted! You may participate at this phase.
        </TYPE.main>
      )}

      {idoInfo?.isContractWhitelisted && !idoInfo?.isAccountWhitelisted && (
        <TYPE.main style={{ textAlign: 'center' }} fontSize={16}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ❌
          </span>
          This wallet is not whitelisted for the current phase.
        </TYPE.main>
      )}

      <PositionInfo gap="lg" justify="center" dim={!idoInfo?.isContributing}>
        <BottomSection gap="lg" justify="center">
          <StyledReserveCard dim={!idoInfo?.isContributing}>
            <CardBGImage desaturate />
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your Reserve</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`${idoInfo?.amountContributed.toFixed(2, { groupSeparator: ',' })} `}
                </TYPE.white>
                <TYPE.white>{idoInfo?.token.symbol}</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledReserveCard>
          <StyledClaimedCard dim={!idoInfo?.isContributing}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.black>Your Claimed Amount </TYPE.black>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`${idoInfo?.amountClaimed.toFixed(2, { groupSeparator: ',' })} `}
                </TYPE.white>
                <TYPE.white>{idoInfo?.token.symbol}</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledClaimedCard>

          <StyledUnclaimedCard dim={!idoInfo?.isContributing}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.black>Your Unclaimed Amount </TYPE.black>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`${idoInfo?.amountUnclaimed.toFixed(2, { groupSeparator: ',' })} `}
                </TYPE.white>
                <TYPE.white>{idoInfo?.token.symbol}</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledUnclaimedCard>

          <StyledClaimableCard dim={!idoInfo?.isContributing}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your Claimable Amount</TYPE.black>
                </div>
                {idoInfo?.amountClaimable.greaterThan('0') && (
                  <ButtonPrimary padding="8px" borderRadius="8px" width="fit-content" onClick={handleClaimClick}>
                    Claim
                  </ButtonPrimary>
                )}
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`${idoInfo?.amountClaimable.toFixed(2, { groupSeparator: ',' })} `}
                </TYPE.white>
                <TYPE.white>{idoInfo?.token.symbol}</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledClaimableCard>
        </BottomSection>

        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <Tooltip title={"Afghanistan, Belarus, Bosnia and Herzegovina, Burundi, Central African Republic, China, Congo (Democratic Republic), Cuba, Côte d’Ivoire, Eritrea, Fiji, Guinea Guinea-Bissau, Haiti, Hong Kong, Iran, Iraq, Lao People’s Democratic Republic, Lebanon, Liberia, Libya, Macau, Myanmar; North Korea; State of Palestine, Puerto Rico, Rwanda, South Sudan, Sudan, Syrian Arab Republic, Taiwan, Turkmenistan, United States of America, Venezuela, Yemen, Zimbabwe"}>
            <IconButton sx={{ paddingTop: '7px' }}>
              <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
                  ⭐️
                </span>
                Please be aware that residents of the following jurisdictions are legally barred from participating on Initial DEX Offerings (IDO). This includes all presale phases.
                By participating in this IDO, you guarantee that you act in compliance with your local laws and regulations.
              </TYPE.main>
            </IconButton>
          </Tooltip>
        </TYPE.main>
      </PositionInfo>
    </PageWrapper>
  )
}
