/* eslint-disable react/jsx-key */
import React, { useCallback, useState } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { ButtonPrimary } from '../../Button'
import ArrivalAdventureMechanicsModal from './MissionReconMechanicsModal'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
  max-height: ${isMobile ? '430px' : '100%'};
  overflow-y: auto;
  scrollbar-gutter: stable;
`


interface MissionReconStoryModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function MissionReconStoryModal({ isOpen, onDismiss }: MissionReconStoryModalProps) {
  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])
  const [showMechanicsModal, setShowMechanicsModal] = useState(false)

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>


      <ArrivalAdventureMechanicsModal
        isOpen={showMechanicsModal}
        onDismiss={() => setShowMechanicsModal(false)}
      />

      <ContentWrapper gap="lg">
        <GlobalStyles
          styles={{
            '*::-webkit-scrollbar': {
              width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: '#5b5eb1',
              outline: '1px solid #5b5eb1',
              borderRadius: '12px'
            }
          }}
        />
        <RowBetween>
          <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Adventure: Mission Recon


            <ButtonPrimary style={{ width: 'fit-content', marginLeft: '8px', padding: '8px' }}
              onClick={() => setShowMechanicsModal(true)}>
              <SettingsSuggestRoundedIcon
                sx={{ fontSize: 18, color: 'white' }}
              />
            </ButtonPrimary>

          </TYPE.mediumHeader>

          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <ScrollContent>

          <div style={{ margin: '1rem' }}>
            <iframe
              frameBorder={0}
              allowFullScreen
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/lqgGwy_WL-U?autoplay=1&controls=0&color=white&autohide=1&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>

          <TYPE.white
            fontWeight={500}
            fontSize={14}
            style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
          >
            A little while before "the arrival" of Space Crxillions, a vanguard squad had scattered around the Metaverse Hub.
            Because they did not know whether this new soil was safe to walk, they were equipped with protective suits.
            The OG Crxillions -inhabitants of the Hub- did not pay attention to this petty intrusion. Metaverse Hub has always been swarmed by by-passers afterall.
            But "the arrival" changed the status-quo. The members of the Crxillion Club had an urge to call for a Crxillion Summit.
            The summit resulted with the decision to organize a recon mission.
            Mission is decided to be voluntary and self-funded. At least for the time being. Should a Crxillion go on the recon mission,
            it has the full claim of whatever and whoever they happen to capture. There are 3 possible routes with varying ranges for adventurers to take.
            Naturally, longer the range higher the chances of capturing an intruder as well as the expenses.
            Only one strict rule is set in the summit: Abandoning a mission is unacceptable.
            It is time, Crxillions meet their space fellas!
          </TYPE.white>
          <TYPE.white
            style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto', cursor: 'pointer', textDecoration: 'underline' }}

            onClick={() => setShowMechanicsModal(true)}
            fontWeight={500}
            fontSize={14}>
            Read the mechanics of the adventure here↗</TYPE.white>
        </ScrollContent>

      </ContentWrapper>
    </Modal>
  )
}
