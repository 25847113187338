import React, { useState } from 'react'
import styled from 'styled-components'
import { ExternalLink, TYPE } from '../../../theme'
import { LightCard } from '../../Card'
import { Break } from '../MintNFT/styled'
import CurrencyLogo from '../../CurrencyLogo'
import { METAVERSEHUB } from '../../../constants'
import { useArrivalAdventure } from '../../../state/mhub/adventures/arrivalAdventure'
import { EBISUSBAY, SPACE_CRXILLIONS } from '../../../constants/collections'
import { RowBetween } from '../../Row'
import { ButtonPrimary } from '../../Button'
// import { BarWave } from 'react-cssfx-loading'
import { Hypnosis } from 'react-cssfx-loading'

import ArrivalAdventureDepositModal from './ArrivalAdventureDepositModal'
import ArrivalAdventureWithdrawModal from './ArrivalAdventureWithdrawModal'
import { useNFTPortfolio, useNFTUserDeposits } from '../../../state/nftPortfolio/hooks'
import ArrivalAdventureStoryModal from './ArrivalAdventureStoryModal'
import { isMobile } from 'react-device-detect'

import DispatchIcon from '../../../assets/images/dispatch.png'
import RecallIcon from '../../../assets/images/recall.png'

import './index.css'
import ArrivalAdventureClaimModal from './ArrivalAdventureClaimModal'
import { ADVENTURE_STATUS } from '../../../state/mhub/helper'

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';

import ArrivalAdventureMechanicsModal from './ArrivalAdventureMechanicsModal'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`


const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
`

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: #1f1243cc;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`



export default function ArrivalAdventure() {
  const adventureInfo = useArrivalAdventure()
  const nftPortfolio = useNFTPortfolio(SPACE_CRXILLIONS)
  const nftDeposits = useNFTUserDeposits(SPACE_CRXILLIONS, adventureInfo?.userTokenList ?? [])
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showStoryModal, setShowStoryModal] = useState(false)
  const [showClaimModal, setShowClaimModal] = useState(false)
  const [showMechanicsModal, setShowMechanicsModal] = useState(false)

  const isLoading = adventureInfo?.rewardPerDay !== undefined ? false : true
  const isStaking = (adventureInfo?.userTokenAmount ?? 0) > 0 ? true : false
  const hasNFT = nftPortfolio?.length !== 0 ? true : false

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      <ArrivalAdventureDepositModal
        isOpen={showDepositModal}
        onDismiss={() => setShowDepositModal(false)}
        adventureInfo={adventureInfo}
        portfolioInfo={nftPortfolio}
      />

      <ArrivalAdventureWithdrawModal
        isOpen={showWithdrawModal}
        onDismiss={() => setShowWithdrawModal(false)}
        adventureInfo={adventureInfo}
        nftDepositInfo={nftDeposits}
      />

      <ArrivalAdventureStoryModal isOpen={showStoryModal} onDismiss={() => setShowStoryModal(false)} />

      <ArrivalAdventureClaimModal isOpen={showClaimModal} adventureInfo={adventureInfo} onDismiss={() => setShowClaimModal(false)} />

      <Cards>
      
      {(adventureInfo?.status === undefined || adventureInfo?.status === ADVENTURE_STATUS.WAITING_TO_START) && (
        <RowBetween align="center">
          <ButtonPrimary
            disabled={true}
            style={{ background: 'transparent', border: 'none' }}
            height="100%"
            padding="8px"
          >
            <>
              <CurrencyLogo currency={SPACE_CRXILLIONS} size={'48px'} ></CurrencyLogo>

              <TYPE.white fontWeight={600} fontSize={24} style={{ textAlign: 'center', margin: 'auto' }}>
                The Arrival
              </TYPE.white>
              <a className="play-btn"></a>
            </>
          </ButtonPrimary>
        </RowBetween>
      )}

      {(adventureInfo?.status === ADVENTURE_STATUS.LIVE || adventureInfo?.status === ADVENTURE_STATUS.FINISHED) && (
        <RowBetween align="center">
          <ButtonPrimary
            style={{ background: 'transparent', border: 'none', boxShadow: 'rgb(91 95 177) 0px 0px 12px' }}
            height="100%"
            padding="8px"
            onClick={() => setShowStoryModal(true)}
          >
            <>
              <CurrencyLogo currency={SPACE_CRXILLIONS} size={'48px'} ></CurrencyLogo>

              <TYPE.white fontWeight={600} fontSize={24} style={{ textAlign: 'center', margin: 'auto' }}>
                The Arrival
              </TYPE.white>
              <a className="play-btn"></a>
            </>
          </ButtonPrimary>
        </RowBetween>
      )}

        {(adventureInfo?.status === ADVENTURE_STATUS.WAITING_TO_START && adventureInfo?.blockLefttoStart) && (
          <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
            <ExternalLink href={`https://cronoscan.com/block/countdown/${adventureInfo?.startBlock}`}>
              <TYPE.white fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
                Remaining blocks for the adventure to start: {adventureInfo?.blockLefttoStart}
              </TYPE.white>
              <TimerOutlinedIcon
                sx={{ marginLeft: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
              />
            </ExternalLink>
          </div>

        )}


        <Break style={{ width: '100%', margin: '16px auto auto' }} />

        {isLoading && (
          <div style={{ display: 'flex', height: '200px', alignItems: 'center' }}>
            <Hypnosis style={{ margin: '20px auto' }} color="#5b5eb1"></Hypnosis>
          </div>
        )}

        {!isLoading && adventureInfo?.status === ADVENTURE_STATUS.WAITING_TO_START && (
          <div style={{ display: 'flex', height: '200px', alignItems: 'center' }}>
            <Hypnosis style={{ margin: '20px auto' }} color="#5b5eb1"></Hypnosis>
          </div>
        )}

        {!isLoading && adventureInfo?.status !== ADVENTURE_STATUS.WAITING_TO_START && (
          <>
            <StatContainer>
              <RowBetween>
                <TYPE.white>Harvesting Space Crxillions </TYPE.white>
                <TYPE.white style={{ display: 'flex' }}>
                  {adventureInfo?.totalStakeCount?.toString()}
                  <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={SPACE_CRXILLIONS} size={'20px'} />
                </TYPE.white>
              </RowBetween>

              <RowBetween>
                <TYPE.white> Total Harvesting Power </TYPE.white>

                <TYPE.black fontSize={16} fontWeight={500}>
                  {adventureInfo?.totalAmount?.toString()}
                  <span role="img" aria-label="wizard-icon" style={{ marginLeft: '8px' }}>
                    ⚡
                  </span>
                </TYPE.black>
              </RowBetween>

              <RowBetween>
                {isMobile && (
                  <TYPE.white> Daily Res. Rate </TYPE.white>
                )}
                {!isMobile && (
                  <TYPE.white> Daily Resource Rate </TYPE.white>
                )}
                <TYPE.white style={{ display: 'flex' }}>
                  {adventureInfo?.status === ADVENTURE_STATUS.LIVE ?
                    (`~${adventureInfo?.rewardPerDay?.toFixed(2)} MHUB`) :
                    (`0 MHUB`)
                  }

                  <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'20px'} />
                </TYPE.white>
              </RowBetween>
            </StatContainer>

            {isStaking && (
              <>
                <Break style={{ width: '80%', margin: '16px auto auto' }} />
                <StatContainer>

                  <RowBetween>
                    <TYPE.white> Your Harvesters </TYPE.white>


                    <TYPE.white style={{ display: 'flex' }}>
                      {adventureInfo?.userTokenAmount?.toString()}
                      <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={SPACE_CRXILLIONS} size={'20px'} />
                    </TYPE.white>

                  </RowBetween>


                  <RowBetween>
                    <TYPE.white> Your Harvesting Power </TYPE.white>

                    <TYPE.black fontSize={16} fontWeight={500}>
                      {adventureInfo?.userTotalAmount?.toString()}
                      <span role="img" aria-label="wizard-icon" style={{ marginLeft: '8px' }}>
                        ⚡
                      </span>
                    </TYPE.black>
                  </RowBetween>


                  <RowBetween>
                    {isMobile && (
                      <TYPE.white> Your Daily Res. Rate </TYPE.white>
                    )}
                    {!isMobile && (
                      <TYPE.white> Your Daily Resource Rate </TYPE.white>
                    )}

                    <TYPE.white style={{ display: 'flex' }}>
                      {adventureInfo?.status === ADVENTURE_STATUS.LIVE ?
                        (`~${adventureInfo?.userRewardRate?.toFixed(2)} MHUB`) :
                        (`0 MHUB`)
                      }
                      <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'20px'} />
                    </TYPE.white>
                  </RowBetween>


                  <RowBetween>
                    <TYPE.white> Harvested Resources </TYPE.white>

                    <ButtonPrimary
                      width="fit-content"
                      padding="6px 12px 6px 12px"
                      borderRadius="8px"
                      onClick={() => setShowClaimModal(true)}
                    >
                      <TYPE.white style={{ display: 'flex' }}>
                        Claim {adventureInfo?.pendingReward?.toFixed(2)}
                        <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'20px'} />
                      </TYPE.white>
                    </ButtonPrimary>
                  </RowBetween>
                </StatContainer>
              </>
            )}

            <Break style={{ marginTop: '1rem' }}></Break>

            <StatContainer style={{ marginBottom: '0rem' }}>
              <div style={{ margin: 'auto', display: 'flex' }}>
                {!hasNFT && !isStaking && (
                  <a
                    href={'https://app.ebisusbay.com/drops/space-crxillions'}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <ButtonPrimary padding="12px" borderRadius="8px">
                      <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '0.2rem' }} />
                      Purchase NFT to Join!
                    </ButtonPrimary>
                  </a>
                )}

                {hasNFT && (
                  <ButtonPrimary
                    borderRadius="8px"
                    padding="12px 12px 12px 18px"
                    margin="6px"
                    width="fit-content"
                    onClick={() => setShowDepositModal(true)}
                  >
                    Dispatch
                    <IconWrapper size={32} style={{ marginLeft: '8px' }}>
                      <img src={DispatchIcon} alt={'dispatch logo'} />
                    </IconWrapper>
                  </ButtonPrimary>
                )}

                {isStaking && (
                  <ButtonPrimary
                    padding="12px 12px 12px 20px"
                    borderRadius="8px"
                    margin="6px"
                    paddingLeft="18px"
                    width="fit-content"
                    onClick={() => setShowWithdrawModal(true)}
                  >
                    Recall
                    <IconWrapper size={32} style={{ marginLeft: '8px' }}>
                      <img src={RecallIcon} alt={'recall logo'} />
                    </IconWrapper>
                  </ButtonPrimary>
                )}
              </div>
            </StatContainer>
          </>
        )}
        <ArrivalAdventureMechanicsModal
          isOpen={showMechanicsModal}
          onDismiss={() => setShowMechanicsModal(false)}
        />

        {(adventureInfo?.status === ADVENTURE_STATUS.LIVE || adventureInfo?.status === ADVENTURE_STATUS.FINISHED) && (
          <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
            <SettingsSuggestRoundedIcon
              sx={{ marginRight: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
            />
            <TYPE.white onClick={() => setShowMechanicsModal(true)}
              fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}>
              Read the mechanics of the adventure here
            </TYPE.white>

          </div>
        )}

      </Cards>
    </div>
  )
}
