
import { Token } from '@cronosdex/sdk'
import { CRXILLION, SPACE_CRXILLIONS } from '../../constants/collections'
import CRXILLION_IMAGES from './images/crxillions.json'
import SPACE_CRXILLION_IMAGES from './images/spaceCrxillions.json'

export const IMAGE_PROVIDER = {
    CRXILLION: CRXILLION_IMAGES,
    SPACE_CRXILLIONS: SPACE_CRXILLION_IMAGES
}

function imageCollection (collection: Token): any{
    if (collection === CRXILLION ) return CRXILLION_IMAGES
    if (collection === SPACE_CRXILLIONS ) return SPACE_CRXILLION_IMAGES
}

export function provideNFTImage(collection: Token, tokenId: string): string {
    return imageCollection(collection)[tokenId]
}