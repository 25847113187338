import React, { useState } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import { ButtonError } from '../../Button'
import { useArrivalAdventureContract } from '../../../hooks/useContract'
import { SubmittedView, LoadingView } from '../../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { useActiveWeb3React } from '../../../hooks'
import { ArrivalAdventureInfo, ARRIVAL_ADVENTURE_CONTRACT_ADDRESS, useArrivalAdventureServiceFee } from '../../../state/mhub/adventures/arrivalAdventure'
import { JSBI } from '@cronosdex/sdk'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface ArrivalAdventureClaimModalProps {
  isOpen: boolean
  onDismiss: () => void
  adventureInfo: ArrivalAdventureInfo
}

export default function ArrivalAdventureClaimModal({ isOpen, onDismiss, adventureInfo }: ArrivalAdventureClaimModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const adventureContract = useArrivalAdventureContract(ARRIVAL_ADVENTURE_CONTRACT_ADDRESS)
  const serviceFee = useArrivalAdventureServiceFee(0)

  async function onClaimReward() {
    if (adventureContract && serviceFee ) {
      setAttempting(true)
      const serviceFeeRes = JSBI.BigInt( parseFloat(serviceFee.toExact()) * Math.pow(10, 18)).toString()
      await adventureContract
        .withdraw([], { value: serviceFeeRes } )
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claim accumulated MHUB rewards`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Claim</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {adventureInfo?.pendingReward && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {adventureInfo?.pendingReward?.toSignificant(6)}
              </TYPE.body>
              <TYPE.body>MHUB Worth of Resources</TYPE.body>
            </AutoColumn>
          )}
          <ButtonError disabled={!!error} error={!!error && !!adventureInfo?.pendingReward} onClick={onClaimReward}>
            {error ?? 'Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>
              Claiming {adventureInfo?.pendingReward?.toSignificant(6)} MHUB
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Claimed MHUB!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
