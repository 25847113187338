import React, { useState } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import CurrencyLogo from '../CurrencyLogo'
import { ChainId, ETHER, JSBI, WETH } from '@cronosdex/sdk'
import { ButtonPrimary } from '../Button'
import { useColor } from '../../hooks/useColor'
import { Break } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { numberWithCommas } from '../../utils/helper'
import { NFTStakeInfo, NFT_STAKE_TYPES } from '../../state/nftStake/hooks'
import useUSDCPrice from '../../utils/useUSDCPrice'
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { useETHBalances } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { NFT_STAKE_DEPLOYER_WALLET, GOD_WALLETS, NFT_STAKE_BENEFICIARY_WALLET } from '../../constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useNFTStakeFactoryContract } from '../../hooks/useContract'
import { GANGVERSE_SOCIAL_CLUB } from '../../constants/collections'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: #1f1243cc;
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  box-shadow: ${({ theme, showBackground }) =>
    showBackground ? 'inset #66eea6  -1px -1px 6px 3px' : 'inset #dde7e77d -1px -1px 3px 2px'};
  border: ${({ theme, showBackground }) => (showBackground ? '1.5px solid #66eea6' : '1.5px solid #0d0c20')};
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 33px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 33px 1fr 96px;
  `};
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function NFTStakeCard({ stakingInfo }: { stakingInfo: NFTStakeInfo }) {
  // nft info
  const { account } = useActiveWeb3React()

  const token0 = stakingInfo.collection
  const currency0 = unwrappedToken(token0)
  const token = currency0 === ETHER ? token0 : token0
  const backgroundColor = useColor(token)

  const contractBalance = useETHBalances(
    stakingInfo?.stakingRewardAddress ? [stakingInfo?.stakingRewardAddress] : []
  )?.[stakingInfo?.stakingRewardAddress ?? '']
  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo?.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `0`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

  let perNFTPriceFloat = 0
  const nftPriceCROinUSDC = useUSDCPrice(WETH[ChainId.CRONOSMAINNET])
  const strnftPriceCROinUSDC = nftPriceCROinUSDC ? `${nftPriceCROinUSDC.toFixed(18)}` : `0`
  const nftPriceinCRO = parseFloat(strnftPriceCROinUSDC)
  perNFTPriceFloat += stakingInfo?.projectInfo?.mintPriceinWCROAmount! * nftPriceinCRO
  const nftPriceCustominUSDC = useUSDCPrice(stakingInfo?.projectInfo?.mintPriceinCustomAmount?.tokenObj)
  const strnftPriceCustominUSDC = nftPriceCustominUSDC ? `${nftPriceCustominUSDC.toFixed(18)}` : `0`
  const nftPriceinCustom = parseFloat(strnftPriceCustominUSDC)
  const customTokenAmount = stakingInfo?.projectInfo.mintPriceinCustomAmount
    ? stakingInfo?.projectInfo.mintPriceinCustomAmount?.amount
    : 0
  perNFTPriceFloat += customTokenAmount * nftPriceinCustom
  const perNFTPrice = parseInt(perNFTPriceFloat.toString())

  let poolRate = numberWithCommas(stakingInfo?.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))
  let userRewardRate = numberWithCommas(stakingInfo?.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(3))
  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  const totalDepositedNFT = JSBI.toNumber(stakingInfo.totalStakedAmount.raw)
  const totalDepositedNFTinUSDC = totalDepositedNFT * perNFTPrice
  const yearlyRewardRate = parseFloat(stakingInfo?.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365
  let apr = Math.ceil(((yearlyRewardRate * rewardTokenPrice) / totalDepositedNFTinUSDC) * 100)

  const isGodMode = account && GOD_WALLETS.includes(account) ? true : false
  // is rewards ended ?
  const isDeployer = account && NFT_STAKE_DEPLOYER_WALLET === account
  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    poolRate = '0'
    userRewardRate = '0'
    apr = 0
  }

  const [showTimerTooltip, setShowTimerTooltip] = useState(false)
  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()

  const factoryContract = useNFTStakeFactoryContract(stakingInfo?.rewardsDistribution)

  async function onCroTransfer() {
    if (factoryContract) {
      await factoryContract
        .transferCro(stakingInfo?.collection.address, NFT_STAKE_BENEFICIARY_WALLET)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claim CRO rewards`
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <TopSection>
        <CurrencyLogo currency={currency0} size={'48px'} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '20px' }}>
          {currency0.symbol}
          <Tooltip
            open={showTimerTooltip}
            onOpen={() => setShowTimerTooltip(true)}
            onClose={() => setShowTimerTooltip(false)}
            title={<TYPE.white> {stakingInfo?.periodFinish?.toUTCString()} </TYPE.white>}
          >
            <IconButton sx={{ paddingTop: '7px' }} onClick={() => setShowTimerTooltip(!showTimerTooltip)}>
              <QueryBuilderOutlinedIcon sx={{ fontSize: 16, color: 'white' }} />
            </IconButton>
          </Tooltip>
        </TYPE.white>

        {stakingInfo.collection === GANGVERSE_SOCIAL_CLUB ? (
          <StyledInternalLink to={`/rewards/nft/custom1/${stakingInfo.stakingRewardAddress}`} style={{ width: '100%' }}>
            <ButtonPrimary padding="8px" borderRadius="8px">
              {isStaking ? 'Manage' : 'Deposit'}
            </ButtonPrimary>
          </StyledInternalLink>
        ) : (
          <StyledInternalLink to={`/rewards/nft/${stakingInfo.stakingRewardAddress}`} style={{ width: '100%' }}>
            <ButtonPrimary padding="8px" borderRadius="8px">
              {isStaking ? 'Manage' : 'Deposit'}
            </ButtonPrimary>
          </StyledInternalLink>
        )}
      </TopSection>

      <Break></Break>
      {isGodMode && (
        <>
          <StatContainer>
            <RowBetween>
              <TYPE.white> CRO Balance </TYPE.white>
              {isDeployer ? (
                <ButtonPrimary onClick={() => onCroTransfer()} width="100px" padding="8px" borderRadius="8px">
                  {contractBalance?.toFixed(0)} Claim
                </ButtonPrimary>
              ) : (
                <TYPE.white> {contractBalance?.toFixed(0)}</TYPE.white>
              )}
            </RowBetween>
          </StatContainer>
          <Break></Break>
        </>
      )}

      <StatContainer>
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>{totalDepositedNFT ? `${numberWithCommas(totalDepositedNFT)} NFT` : `-`}</TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Pool rate </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={stakingInfo?.rewardToken} size={'20px'} />
            {`${poolRate} ${stakingInfo.rewardToken.symbol} / week`}
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> APR </TYPE.white>
          <TYPE.white>{apr ? `🔥 ${apr}%` : `0%`}</TYPE.white>
        </RowBetween>
      </StatContainer>

      {isRewardEnded && stakingInfo.type === NFT_STAKE_TYPES.LEGACY && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Rewards have ended! Please move your funds to current round.</span>
            </TYPE.black>
          </BottomSection>
        </>
      )}

      {isRewardEnded && stakingInfo.type === NFT_STAKE_TYPES.RENEWABLE && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Reward distribution halted! Waiting for refill.</span>
            </TYPE.black>
          </BottomSection>
        </>
      )}

      {isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${userRewardRate} ${stakingInfo.rewardToken.symbol} / week`}
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
