import { JSBI, TokenAmount } from "@cronosdex/sdk";
import { CROKING, CRONOSVERSE, CRX, CSAI, DNOT, METAVERSEHUB, NATIVE_CRO, ORC, PPTZ, ROLLIUM, XCRX } from "../../constants";
import { CRODEX_PROJECT_INFO, CROKING_PROJECT_INFO, CRONOSVERSE_INFO, CSAI_INFO, DNOT_INFO, MARBLEVERSE_PROJECT_INFO, MHUB_INFO, ORC_INFO, PPTZ_INFO, XCRX_INFO } from "./projects";


export const IDO_LIST = [
    {
        name: "Private Sale",
        token: CRX,
        project: CRODEX_PROJECT_INFO,
        contractAddress: '0x529dC4eb20F98C24eDE88a7A0427f9d690E3a0eb',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2021, 10, 8, 19, 0, 0, 0)),
        endDate: new Date(Date.UTC(2021, 10, 9, 19, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Public Sale",
        token: CRX,
        project: CRODEX_PROJECT_INFO,
        contractAddress: '0x5B2cE0bC707F37a5848844C48F8a36424074EC66',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2021, 10, 9, 19, 0, 0, 0)),
        endDate: new Date(Date.UTC(2021, 10, 11, 19, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Private Sale",
        token: CROKING,
        project: CROKING_PROJECT_INFO,
        contractAddress: '0xEa170f708AA894Da04e0a46B395D63e1Cab99636',
        contributionToken: NATIVE_CRO,
        holdReq: new TokenAmount(CRX, JSBI.BigInt(2 * Math.pow(10, CRX.decimals))),
        startDate: new Date(Date.UTC(2022, 0, 6, 19, 0, 0, 0)),
        endDate: new Date(Date.UTC(2022, 0, 7, 19, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Public Sale",
        token: CROKING,
        project: CROKING_PROJECT_INFO,
        contractAddress: '0x2572782821239D490807E78F17FA86302d689C1F',
        contributionToken: NATIVE_CRO,
        holdReq: new TokenAmount(CRX, JSBI.BigInt(2 * Math.pow(10, CRX.decimals))),
        startDate: new Date(Date.UTC(2022, 0, 8, 19, 0, 0, 0)),
        endDate: new Date(Date.UTC(2022, 0, 9, 19, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Initial Token Sale",
        token: ROLLIUM,
        project: MARBLEVERSE_PROJECT_INFO,
        contractAddress: '0xCA244f1878f2D56dcE0ae85F71EeAeF8C18A3009',
        contributionToken: NATIVE_CRO,
        holdReq: new TokenAmount(CRX, JSBI.BigInt(2 * Math.pow(10, CRX.decimals))),
        startDate: new Date(Date.UTC(2022, 3, 2, 19, 0, 0, 0)),
        endDate: new Date(Date.UTC(2022, 3, 4, 19, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Initial Token Sale",
        token: CRONOSVERSE,
        project: CRONOSVERSE_INFO,
        contractAddress: '0xA3aC113467f08ACB4D9639c0512eCd4CB6C3E43A',
        contributionToken: NATIVE_CRO,
        holdReq: new TokenAmount(CRX, JSBI.BigInt(5 * Math.pow(10, CRX.decimals))),
        startDate: new Date(Date.UTC(2022, 5, 12, 18, 0, 0, 0)),
        endDate: new Date(Date.UTC(2022, 5, 13, 18, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Token Sale",
        token: METAVERSEHUB,
        project: MHUB_INFO,
        contractAddress: '0x0AC6d1c1d23080ADDa19AA7Fe05C6a92aE893eBF',
        contributionToken: CRX,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2022, 6, 20, 18, 0, 0, 0)),
        endDate: new Date(Date.UTC(2022, 6, 22, 18, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "Token Sale",
        token: XCRX,
        project: XCRX_INFO,
        contractAddress: '0xfCea29b445a358FcC7169FeD2f5fbBf1513DFaA1',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2022, 9, 23, 18, 0, 0, 0)),
        endDate: new Date(Date.UTC(2022, 9, 28, 19, 0, 0, 0)),
        softCap: new TokenAmount(NATIVE_CRO, '500000000000000000000000'),
        customHardCap: undefined
    },
    {
        name: "Initial $ORC Offering",
        token: ORC,
        project: ORC_INFO,
        contractAddress: '0xd3A0b4df7f55C2BD0e9a974Bb99D491FE4077D34',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2024, 3, 12, 20, 0, 0, 0)),
        endDate: new Date(Date.UTC(2024, 3, 14, 20, 0, 0, 0)),
        softCap: undefined,
        customHardCap: [
            new TokenAmount(NATIVE_CRO, '250000000000000000000000' ),
            new TokenAmount(NATIVE_CRO,'400000000000000000000000'),
        ]
    },
    {
        name: "Initial Token Sale",
        token: DNOT,
        project: DNOT_INFO,
        contractAddress: '0x61fa8F4c00977B37Fdaae5423E5c961534fA2A57',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2024, 5, 4, 20, 0, 0, 0)),
        endDate: new Date(Date.UTC(2024, 5, 11, 20, 0, 0, 0)),
        softCap: undefined,
        customHardCap: [
            new TokenAmount(NATIVE_CRO, '250000000000000000000000' ),
            new TokenAmount(NATIVE_CRO,'750000000000000000000000'),
            new TokenAmount(NATIVE_CRO,'1750000000000000000000000')
        ]
    },
    {
        name: "Initial Token Sale",
        token: CSAI,
        project: CSAI_INFO,
        contractAddress: '0x9a76cf139F182447478F7F2aab7889E8050092A3',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2024, 5, 7, 18, 0, 0, 0)),
        endDate: new Date(Date.UTC(2024, 5, 14, 18, 0, 0, 0)),
        softCap: undefined,
        customHardCap: [
            new TokenAmount(NATIVE_CRO, '100000000000000000000000'),
            new TokenAmount(NATIVE_CRO, '200000000000000000000000'),
            new TokenAmount(NATIVE_CRO, '300000000000000000000000')
        ]
    },
    {
        name: "$PPTZ Presale - Phase I",
        token: PPTZ,
        project: PPTZ_INFO,
        contractAddress: '0xe5653eB56090081Ed1B936685213d8467316Fe74',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2024, 6, 28, 20, 0, 0, 0)),
        endDate: new Date(Date.UTC(2024, 7, 2, 18, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    },
    {
        name: "$PPTZ Public Presale - Phase II",
        token: PPTZ,
        project: PPTZ_INFO,
        contractAddress: '0x86a23f94e1297e0b47201187c5188d39b13a5b1c',
        contributionToken: NATIVE_CRO,
        holdReq: undefined,
        startDate: new Date(Date.UTC(2024, 7, 4, 20, 0, 0, 0)),
        endDate: new Date(Date.UTC(2024, 7, 11, 20, 0, 0, 0)),
        softCap: undefined,
        customHardCap: undefined
    }
    // {
    //     name: "Token Sale",
    //     token: ORC_TEST1,
    //     project: ORC_INFO,
    //     contractAddress: '0xD307025cE251D1DB9baD2C15089245Ed12159d0e',
    //     contributionToken: NATIVE_CRO,
    //     holdReq: undefined,
    //     startDate: new Date(Date.UTC(2024, 9, 23, 18, 0, 0, 0)),
    //     endDate: new Date(Date.UTC(2024, 9, 28, 19, 0, 0, 0)),
    //     softCap: undefined
    // },
    // {
    //     name: "Private Sale",
    //     token: ROLLIUM,
    //     project: MARBLEVERSE_PROJECT_INFO,
    //     contractAddress: '0x491281B90b611523172F99E18f5aB54b985F3A33',
    //     contributionToken: NATIVE_CRO,
    //     holdReq: undefined,
    //     startDate: new Date(Date.UTC(2022, 2, 3, 22, 0, 0, 0)),
    //     endDate: new Date(Date.UTC(2022, 2, 4, 22, 0, 0, 0)),
    // },
    // {
    //     name: "FAILED",
    //     token: ARCANE,
    //     project: ARCANE_PROJECT_INFO,
    //     contractAddress: '0x4AAB5E15518b3B0C7AEffA88bd81336912235aa3',
    //     contributionToken: NATIVE_CRO,
    //     holdReq: new TokenAmount(CRX, JSBI.BigInt(2 * Math.pow(10, CRX.decimals))),
    //     startDate: new Date(Date.UTC(2022, 1, 28, 17, 30, 0, 0)),
    //     endDate: new Date(Date.UTC(2022, 2, 1, 17, 30, 0, 0)),
    // },
    // {
    //     name: "POSTPONED Private Sale",
    //     token: ROLLIUM,
    //     project: MARBLEVERSE_PROJECT_INFO,
    //     contractAddress: '0xBbE5622A68D82DDb4e43a9353E400ac69822d33A',
    //     contributionToken: NATIVE_CRO,
    //     holdReq: undefined,
    //     // holdReq: new TokenAmount(CRX, JSBI.BigInt(2 * Math.pow(10, CRX.decimals))),
    //     startDate: new Date(Date.UTC(2022, 1, 23, 22, 0, 0, 0)),
    //     endDate: new Date(Date.UTC(2022, 1, 24, 22, 0, 0, 0)),
    // },
    // {
    //     name: "Private Sale",
    //     token: FAKE_ROLLIUM,
    //     project: MARBLEVERSE_PROJECT_INFO,
    //     contractAddress: '0xa8C4D2344017cE2B495471823A89DED6f26Ab699',
    //     contributionToken: NATIVE_CRO,
    //     startDate: new Date(Date.UTC(2022, 1, 23, 22, 0, 0, 0)),
    //     endDate: new Date(Date.UTC(2022, 1, 24, 22, 0, 0, 0)),
    // },
    // {
    //     name: "Test Sale",
    //     token: TCN,
    //     project: TCN_PROJECT_INFO,
    //     contractAddress: '0x560FBE0Eb665AFbEF95279eFA5AF2eEd949Cb141',
    //     contributionToken: NATIVE_CRO,
    //     startDate: new Date(Date.UTC(2021, 1, 14, 19, 0, 0, 0)),
    //     endDate: new Date(Date.UTC(2022, 1, 16, 19, 0, 0, 0)),
    // },
    // {
    //     name: "Test2 Sale",
    //     token: TCN,
    //     project: TCN_PROJECT_INFO,
    //     contractAddress: '0x1A48A0190E531AA59DA0D692e3041557D7028c2f',
    //     contributionToken: NATIVE_CRO,
    //     startDate: new Date(Date.UTC(2021, 1, 15, 19, 0, 0, 0)),
    //     endDate: new Date(Date.UTC(2022, 1, 16, 19, 0, 0, 0)),
    // },
]
