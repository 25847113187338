import { Fraction, JSBI, TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { METAVERSEHUB } from '../../../constants'
import { useActiveWeb3React } from '../../../hooks'
import { useArrivalAdventureContract } from '../../../hooks/useContract'
import { useBlockNumber } from '../../application/hooks'
import { useSingleCallResult } from '../../multicall/hooks'
import { UINTMAX } from '../../nftStake/hooks'
import { ADVENTURE_STATUS, useAvgBlockTimeInSeconds } from '../helper'


export const ARRIVAL_ADVENTURE_CONTRACT_ADDRESS = "0x281641A22Ed80a78aE022e0B2597B054eCc25f28"


export interface ArrivalAdventureInfo {
    totalStakeCount: number | undefined
    rewardPerBlock: TokenAmount | undefined
    rewardPerDay: Fraction | undefined
    totalAmount: number | undefined
    pendingReward: TokenAmount | undefined
    userTotalAmount: number | undefined
    userTokenAmount: number | undefined
    userTokenList: number[] | undefined
    userShare: number | undefined
    userRewardRate: Fraction | undefined
    startBlock: number | undefined
    bonusEndBlock: number | undefined
    blockLefttoStart: number | undefined
    status: ADVENTURE_STATUS | undefined
}

export function useArrivalAdventureEffectiveAmounts(tokenIDs: string[]): number | undefined{
    const arrivalAdventureContract = useArrivalAdventureContract(ARRIVAL_ADVENTURE_CONTRACT_ADDRESS)
    const effectiveAmountRes = useSingleCallResult(arrivalAdventureContract, 'calculateEffectiveAmount', [tokenIDs])
    const totalEffectiveAmount = (!effectiveAmountRes?.loading && effectiveAmountRes?.result ) ?  effectiveAmountRes?.result[0] : undefined
    return totalEffectiveAmount
}

export function useArrivalAdventureServiceFee(tokenCount: number | undefined): TokenAmount | undefined{
    const arrivalAdventureContract = useArrivalAdventureContract(ARRIVAL_ADVENTURE_CONTRACT_ADDRESS)
    const serviceFeeRes = useSingleCallResult(arrivalAdventureContract, 'serviceFee', [tokenCount])
    const serviceFee = (!serviceFeeRes?.loading && serviceFeeRes?.result ) ? new TokenAmount(METAVERSEHUB, serviceFeeRes?.result[0]) : undefined
    return serviceFee
}

// gets the staking info from the network for the active chain id
export function useArrivalAdventure(): ArrivalAdventureInfo {

    const { chainId, account } = useActiveWeb3React()
    
    const arrivalAdventureContract = useArrivalAdventureContract(ARRIVAL_ADVENTURE_CONTRACT_ADDRESS)

    const startBlockRes = useSingleCallResult(arrivalAdventureContract, 'startBlock')
    const bonusEndBlockRes = useSingleCallResult(arrivalAdventureContract, 'bonusEndBlock')

    const rewardPerBlockRes = useSingleCallResult(arrivalAdventureContract, 'rewardPerBlock')
    const stakeCountRes = useSingleCallResult(arrivalAdventureContract, 'getStakeCount')
    const totalAmountRes = useSingleCallResult(arrivalAdventureContract, 'totalAmount')
    const pendingRewardRes = useSingleCallResult(arrivalAdventureContract, 'pendingReward', [account ?? undefined])
    const userInfoRes = useSingleCallResult(arrivalAdventureContract, 'userInfo', [account ?? undefined])
    const userTokenListRes = useSingleCallResult(arrivalAdventureContract, 'getUserTokenList', [account ?? undefined])
    const avgBlockTimeinSeconds = useAvgBlockTimeInSeconds()
    const blockNumber = useBlockNumber()

    return useMemo(() => {

        let memo : ArrivalAdventureInfo = {
            totalStakeCount: undefined,
            rewardPerBlock: undefined,
            rewardPerDay: undefined,
            totalAmount: undefined,
            pendingReward: undefined,
            userTotalAmount: undefined,
            userTokenAmount: undefined,
            userTokenList: undefined,
            userShare: undefined,
            userRewardRate: undefined,
            startBlock: undefined,
            bonusEndBlock: undefined,
            blockLefttoStart: undefined,
            status: undefined
        }

        if (!chainId) return memo
        else {
            if (
                !rewardPerBlockRes?.loading &&
                !stakeCountRes?.loading &&
                !totalAmountRes?.loading &&
                !pendingRewardRes?.loading &&
                !userInfoRes?.loading &&
                !userTokenListRes?.loading &&
                !startBlockRes?.loading &&
                !bonusEndBlockRes?.loading
            ){
                if (
                    rewardPerBlockRes?.error ||
                    stakeCountRes?.error ||
                    totalAmountRes?.error ||
                    pendingRewardRes?.error ||
                    userInfoRes?.error ||
                    userTokenListRes?.error ||
                    startBlockRes?.error ||
                    bonusEndBlockRes?.error
                ) {
                    console.error('Failed to load Arrival Adventure info')
                    return memo
                }

                // data from contract
                memo.startBlock = startBlockRes.result?.[0]
                memo.bonusEndBlock = bonusEndBlockRes.result?.[0]
                memo.totalStakeCount = stakeCountRes.result?.[0]
                memo.rewardPerBlock = new TokenAmount(METAVERSEHUB, JSBI.BigInt(rewardPerBlockRes.result?.[0]))
                memo.totalAmount = totalAmountRes.result?.[0]
                memo.pendingReward = new TokenAmount(METAVERSEHUB, JSBI.BigInt(pendingRewardRes.result?.[0] ?? '0'))
                memo.userTotalAmount = userInfoRes.result?.[0]
                memo.userTokenAmount = userInfoRes.result?.[2]

                let userTokenList = []
                const userDepositLen = userTokenListRes ? userTokenListRes?.result?.[0].length : 0
                for ( var idx = 0; idx < userDepositLen; idx++ ){
                    const tokenIDstr = userTokenListRes?.result?.[0][idx]?.toString()
                    if ( tokenIDstr !== UINTMAX)
                        userTokenList.push((parseInt(tokenIDstr)))
                }
                memo.userTokenList = userTokenList
                
                // calculated data
                const dailyBlockSize = avgBlockTimeinSeconds ? Math.round( 86400 / (avgBlockTimeinSeconds ?? 0) ) : undefined
                memo.rewardPerDay =  dailyBlockSize ? memo.rewardPerBlock.multiply((dailyBlockSize ?? 0).toString()) : undefined
                if ( memo.userTokenAmount !== undefined && memo.userTokenAmount > 0){
                    memo.userShare = memo.userTotalAmount ? (memo.totalAmount ?? 0 ) / (memo.userTotalAmount ?? 0): undefined
                    const sharePerc = memo.userShare ? ( memo.userShare?.toFixed(0) ) : "0"
                    memo.userRewardRate = (memo.userShare && memo.rewardPerDay) ? memo.rewardPerDay.divide(sharePerc) : undefined    
                }
               
                if ( (blockNumber && memo.startBlock && memo.bonusEndBlock)){

                    if ( memo.startBlock > blockNumber){
                        memo.blockLefttoStart = memo.startBlock - blockNumber
                        memo.status = ADVENTURE_STATUS.WAITING_TO_START
                    }
                    else if ( blockNumber > memo.startBlock && blockNumber < memo.bonusEndBlock){
                        memo.status = ADVENTURE_STATUS.LIVE
                    }
                    else {
                        memo.status = ADVENTURE_STATUS.FINISHED
                    }
                }
                return memo
            }
            return memo
        }
    }, [chainId, blockNumber, startBlockRes, bonusEndBlockRes, stakeCountRes, avgBlockTimeinSeconds, rewardPerBlockRes, totalAmountRes, userInfoRes, userTokenListRes ])
}
