import React, { useState } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import CurrencyLogo from '../CurrencyLogo'
import { ETHER, JSBI } from '@cronosdex/sdk'
import { ButtonPrimary } from '../Button'
import { SingleStakingInfo, SINGLE_STAKE_TYPES } from '../../state/singleStake/hooks'
import { useColor } from '../../hooks/useColor'
import { Break } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { numberWithCommas } from '../../utils/helper'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: #1f1243cc;
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  box-shadow: ${({ theme, showBackground }) => (showBackground ? "inset #ff9999 -1px -1px 6px 3px" : 'inset #dde7e77d -1px -1px 3px 2px')} ;
  border: ${({ theme, showBackground }) => (showBackground ? '1.5px solid #ff9999' : '1.5px solid #0d0c20')};
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 33px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 33px 1fr 96px;
  `};
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function SSCard({ stakingInfo }: { stakingInfo: SingleStakingInfo }) {
  const token0 = stakingInfo.token

  const currency0 = unwrappedToken(token0)
  // const currency1 = unwrappedToken(token1)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  // get the color of the token
  const token = currency0 === ETHER ? token0 : token0
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)

  const depositedTokenPriceinUSDC = useUSDCPrice(stakingInfo.token)
  const strDepositedTokenPrice = depositedTokenPriceinUSDC ? `${depositedTokenPriceinUSDC.toFixed(18)}` : `1`
  const depositedTokenPrice = parseFloat(strDepositedTokenPrice)

  let totalDepositInUSDC: number | undefined
  if (totalSupplyOfStakingToken) {
    totalDepositInUSDC =
      (JSBI.toNumber(stakingInfo.totalStakedAmount.raw) / Math.pow(10, stakingInfo.token.decimals)) *
      depositedTokenPrice
  }

  const totalDepositStr = totalDepositInUSDC ? `${totalDepositInUSDC.toFixed()}` : `1`

  const rewardTokenPriceinUSDC = useUSDCPrice(stakingInfo.rewardToken)
  const strRewardTokenPriceinUSDC = rewardTokenPriceinUSDC ? `${rewardTokenPriceinUSDC.toFixed(18)}` : `1`
  const rewardTokenPrice = parseFloat(strRewardTokenPriceinUSDC)

  let poolRate = numberWithCommas(stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(2))
  let myRate = stakingInfo.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(4, { groupSeparator: ',' })


  let apr = '-'
  let apy = '-'

  if (totalDepositStr !== `1`) {
    const numApr =
      ((parseFloat(stakingInfo.totalRewardRate.toFixed()) * 60 * 60 * 24 * 365 * rewardTokenPrice) /
        parseFloat(totalDepositStr)) *
      100
    const aprValue = Math.round(numApr).toString()
    apr = `${aprValue}%`
    apy = ` ${((Math.pow(1 + (numApr) / 36500, 365) - 1) * 100).toFixed(0)}% 🔥`

  }

  const isRewardEnded = new Date().getTime() > stakingInfo?.periodFinish?.getTime()! ? true : false
  if (isRewardEnded) {
    apr = '0%'
    poolRate = '0'
    myRate = '0'
  }

  // const [showTimerTooltip, setShowTimerTooltip] = useState(false);
  const [showAPRTooltip, setShowAPRTooltip] = useState(false);


  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>

      <TopSection>
        <CurrencyLogo currency={currency0} size={'24px'} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '0px' }}>
          {currency0.symbol}
          {/* <Tooltip
            open={showTimerTooltip}
            onOpen={() => setShowTimerTooltip(true)}
            onClose={() => setShowTimerTooltip(false)}
            title={<TYPE.white> {stakingInfo?.periodFinish?.toUTCString()} </TYPE.white>}>
            <IconButton sx={{ paddingTop: '7px' }} onClick={() => setShowTimerTooltip(!showTimerTooltip)}>
              <QueryBuilderOutlinedIcon sx={{ fontSize: 16, color: "white" }} />
            </IconButton>
          </Tooltip> */}
        </TYPE.white>

        <StyledInternalLink to={`/rewards/single/${stakingInfo.stakingRewardAddress}`} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <Break></Break>

      <StatContainer>
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>{totalDepositInUSDC ? `$${numberWithCommas(totalDepositInUSDC.toFixed(0))}` : `-`}</TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Pool rate </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={stakingInfo?.rewardToken} size={'20px'} />
            {`${poolRate} ${stakingInfo.rewardToken.symbol} / week`}</TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> APR → APY</TYPE.white>

          {!isRewardEnded && (

            <TYPE.white>
              <Tooltip
                open={showAPRTooltip}
                onOpen={() => setShowAPRTooltip(true)}
                onClose={() => setShowAPRTooltip(false)}
                title={<div><TYPE.white> {` APR → APY (daily compound) `} </TYPE.white></div>}>
                <IconButton sx={{ paddingTop: '7px' }}>
                  <HelpOutlineIcon sx={{ fontSize: 16, color: "white" }} onClick={() => setShowAPRTooltip(!showAPRTooltip)} />
                </IconButton>
              </Tooltip>
              {apr} → {apy}
            </TYPE.white>

          )}
          {isRewardEnded && (
            <TYPE.white> {apr}</TYPE.white>
          )}

        </RowBetween>
      </StatContainer>

      {isRewardEnded && stakingInfo?.type == SINGLE_STAKE_TYPES.LEGACY && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>This reward pool is finished. Please withdraw your funds.</span>
            </TYPE.black>
          </BottomSection>
        </>
      )}

      {isRewardEnded && stakingInfo?.type == SINGLE_STAKE_TYPES.RENEWABLE && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>This reward pool is paused and not distributing rewards at the moment.</span>
            </TYPE.black>
          </BottomSection>
        </>
      )}

      {isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${myRate} ${stakingInfo.rewardToken.symbol} / week`}
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
