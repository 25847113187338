import { useMemo } from 'react'
import { UNI } from '../../../constants/'
import { SPACE_CRXILLIONS } from '../../../constants/collections'
import { useActiveWeb3React } from '../../../hooks'
import { useSpaceCrxillionContract } from '../../../hooks/useContract'
import { useSingleCallResult } from '../../multicall/hooks'


export const SPACE_CRXILLION_CONTRACT_ADDRESS = SPACE_CRXILLIONS.address


export interface SpaceCrxillionInfo {
    balanceOf: number | undefined
    totalSupply: number | undefined
    isWhitelisted: boolean | undefined
    isEbisusMember: boolean | undefined
    paused: boolean | undefined
    wlPrice: number
    ebPrice: number
    regPrice: number
    maxSupply: number
    mintDate: Date
}

// gets the staking info from the network for the active chain id
export function useSpaceCrxillionInfo(): SpaceCrxillionInfo {

    const { chainId, account } = useActiveWeb3React()

    const uni = chainId ? UNI[chainId] : undefined
    
    const spaceCrxillionContract = useSpaceCrxillionContract(SPACE_CRXILLION_CONTRACT_ADDRESS)

    const balanceOf = useSingleCallResult(spaceCrxillionContract, 'balanceOf', [account ?? undefined])

    const totalSupply = useSingleCallResult(spaceCrxillionContract, 'totalSupply')

    const paused = useSingleCallResult(spaceCrxillionContract, 'paused')

    const isWhitelisted = useSingleCallResult(spaceCrxillionContract, 'isWhitelisted', [account ?? undefined])

    const isEbisusMember = useSingleCallResult(spaceCrxillionContract, 'isEbisusBayMember', [account ?? undefined])



    return useMemo(() => {

        let memo : SpaceCrxillionInfo = {
            balanceOf: undefined,
            totalSupply: undefined,
            isWhitelisted: undefined,
            isEbisusMember: undefined,
            paused: undefined,
            wlPrice: 600,
            ebPrice: 650,
            regPrice: 700,
            maxSupply: 3000,
            mintDate: new Date(Date.UTC(2022, 8, 18, 19, 0, 0, 0))
        }

        if (!chainId || !uni) return memo
        else {
            if (
                !balanceOf?.loading &&
                !totalSupply?.loading &&
                !paused?.loading &&
                !isWhitelisted?.loading &&
                !isEbisusMember?.loading
            ){
                if (
                    balanceOf?.error ||
                    totalSupply?.error ||
                    paused?.error ||
                    isWhitelisted?.error ||
                    isEbisusMember?.error
                ) {
                    console.error('Failed to load Space Crxillion info')
                    return memo
                }
                memo.balanceOf = parseInt(balanceOf?.result?.[0].toString())
                memo.totalSupply = parseInt(totalSupply?.result?.[0].toString())
                memo.paused = paused?.result?.[0]
                memo.isWhitelisted = isWhitelisted?.result?.[0]
                memo.isEbisusMember = isEbisusMember?.result?.[0]

                return memo
            }
            return memo
        }
    }, [chainId, uni, balanceOf, totalSupply, paused, isWhitelisted, isEbisusMember ])
}