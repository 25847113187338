import React, { useState, useCallback } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import { ButtonError } from '../../Button'
import { LoadingView, SubmittedView } from '../../ModalViews'
import Slider from '../../Slider'
import { ButtonPrimary } from '../../Button'
import { useETHBalances } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'
import { useSpaceCrxillionInfo } from '../../../state/mhub/mintInfo/spaceCrxillionInfo'
import { CRXILLION, EBISUSBAY, SPACE_CRXILLIONS } from '../../../constants/collections'
import { useSpaceCrxillionContract } from '../../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { JSBI } from '@cronosdex/sdk'
import CurrencyLogo from '../../CurrencyLogo'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const InfoSection = styled.div<{ dim: boolean }>`
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`


export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 0.5rem;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: #5b5eb1;
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

const StyleRowBetween = styled.div<{ dim: boolean }>`
  background: #130f29a3;
  border: 1px solid #58498c;
  border-radius: 12px;
  padding: 10px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
}
`

interface SpaceCrxillionMintModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export enum CUSTOMER {
  WHITELISTED,
  EBISUSMEMBER,
  REGULAR
}


const MAX_MINT_COUNT = 10
const MINT_GAS_FEE = 0

export default function SpaceCrxillionMintModal({ isOpen, onDismiss }: SpaceCrxillionMintModalProps) {
  const { account } = useActiveWeb3React()
  const nftInfo = useSpaceCrxillionInfo()

  const spaceCrxillionContract = useSpaceCrxillionContract(SPACE_CRXILLIONS.address)
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const [mintCount, setMintCount] = useState(1)
  const addTransaction = useTransactionAdder()
  const userCROBalance = parseInt(useETHBalances(account ? [account] : [])?.[account ?? '']?.toFixed() ?? '0')
  let error : string | undefined =  undefined

  let userPrice :number;
  let customerType = undefined;
  if ( nftInfo?.isWhitelisted ){
    customerType = CUSTOMER.WHITELISTED
    userPrice = nftInfo?.wlPrice
  }
  else if ( nftInfo?.isEbisusMember){
    customerType = CUSTOMER.EBISUSMEMBER
    userPrice = nftInfo?.ebPrice
  }
  else {
    customerType = CUSTOMER.REGULAR
    userPrice = nftInfo?.regPrice
  }
  
  if ( nftInfo?.paused ){
    error = `Minting starts at ${nftInfo?.mintDate.toUTCString()}`
  }
  else if ( ( userPrice + MINT_GAS_FEE ) * mintCount > userCROBalance ){
    error = 'Insufficient Balance'
  }

  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])


  async function mintNFTs() {
    setAttempting(true)
    if (spaceCrxillionContract && mintCount && userPrice) {

      const mintFee = JSBI.multiply(
            JSBI.BigInt( userPrice * mintCount),
            JSBI.BigInt(Math.pow(10, 18))).toString()

      spaceCrxillionContract.mint( mintCount, { value: mintFee })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Mint Space Crxillions`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    } else {
      setAttempting(false)
      throw new Error('Something went wrong. Please contact support.')
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Mint {SPACE_CRXILLIONS.name}</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          <RowBetween style={{ marginTop: '10px' }}>
            <TYPE.mediumHeader style={{ marginLeft: '13px' }}>Amount</TYPE.mediumHeader>
          </RowBetween>

          <RowBetween>
            <TYPE.mediumHeader fontSize={'45px'} style={{ marginLeft: '13px' }}>
              {mintCount}
            </TYPE.mediumHeader>
            <ButtonPrimary
              onClick={() => setMintCount(MAX_MINT_COUNT)}
              style={{
                width: '20%',
                padding: '8px'
              }}
            >
              Max
            </ButtonPrimary>
          </RowBetween>

          <Slider min={1} max={MAX_MINT_COUNT} step={1} value={mintCount} onChange={e => setMintCount(e)} />

          <RowBetween>

            <StyleRowBetween dim={! (customerType === CUSTOMER.WHITELISTED) ?? true} style={{ marginLeft: '1rem', width: '140px', padding: '10px' }}>
              <RowBetween>
                <TYPE.white fontWeight={600} fontSize={12} style={{ marginBottom: '10px' }}>
                  Whitelist <br></br>Price
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={18}>{nftInfo?.wlPrice} CRO</TYPE.white>
              </RowBetween>
            </StyleRowBetween>

            <StyleRowBetween  dim={! (customerType === CUSTOMER.EBISUSMEMBER) ?? true} style={{ marginRight: '1rem', marginLeft: '1rem', width: '140px', padding: '10px' }}>
              <RowBetween>
                <TYPE.white fontWeight={600} fontSize={12} style={{ marginBottom: '10px' }}>
                  Ebisu's Bay Member Price
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={18}>{nftInfo?.ebPrice} CRO</TYPE.white>
              </RowBetween>
            </StyleRowBetween>

            <StyleRowBetween  dim={! (customerType === CUSTOMER.REGULAR) ?? true} style={{ marginRight: '1rem', width: '140px', padding: '10px' }}>
              <RowBetween>
                <TYPE.white fontWeight={600} fontSize={12} style={{ marginBottom: '10px' }}>
                  Regular <br></br> Price
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={18}>{nftInfo?.regPrice} CRO</TYPE.white>
              </RowBetween>
            </StyleRowBetween>

          </RowBetween>

          {customerType === CUSTOMER.WHITELISTED && (
            <RowBetween>
              <InfoSection dim={true}>
              <TYPE.white style={{ display: 'flex' }} fontSize={14} fontWeight={600}> <CurrencyLogo style={{ marginRight: '6px' }} currency={CRXILLION} size={'48px'} /> 
                  <p>This wallet is whitelisted!</p> </TYPE.white>
              </InfoSection>
            </RowBetween>
          )}

          {customerType === CUSTOMER.EBISUSMEMBER && (
            <RowBetween>
              <InfoSection dim={true}>
                <TYPE.white style={{ display: 'flex' }} fontSize={14} fontWeight={600}> <CurrencyLogo style={{ marginRight: '3px' }} currency={EBISUSBAY} size={'48px'} /> 
                <p>This wallet has Ebisu's Bay Member Card!</p></TYPE.white>
              </InfoSection>
            </RowBetween>
          )}

          {customerType === CUSTOMER.REGULAR && (
            <RowBetween>
              <InfoSection dim={true}>
              <TYPE.white style={{ display: 'flex' }} fontSize={14} fontWeight={600}>   <InfoOutlinedIcon sx={{ marginRight: '3px', fontSize:42, color: "white" }}  />
                 <p>   <a target="_blank" rel="noreferrer" style={{ color:'white', textDecoration: 'none' }} href='https://app.ebisusbay.com/collection/crxillion'>Purchase an OG Crxillion to get whitelisted.</a> </p></TYPE.white>
              </InfoSection>
            </RowBetween>
          )}

          <RowBetween>
            <ButtonError disabled={!!error} error={!!error} onClick={mintNFTs}>
              { error ?? 'Mint'}
            </ButtonError>
          </RowBetween>

        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Minting NFTs</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{mintCount} Space Crxillions</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Mint</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
