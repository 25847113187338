/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
// import image from '../../../assets/images/spaceCrxillion01.png'
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { BattleHistoryInfo, powerOfCollection } from '../../../state/mhub/adventures/fightAdventure'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
//import { METAVERSEHUB } from '../../../constants'
import iamge from '../../../assets/svg/262.png'
import { provideNFTImage } from '../../../state/mhub/imageProvider'
import { METAVERSEHUB } from '../../../constants'
import CurrencyLogo from '../../CurrencyLogo'

const StyledImg = styled.img`
  width: 48px;
  height: 48px;
  margin: auto;
  display: flex;
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const StyledListItemText = styled(ListItemText)`
.MuiTypography-body1{
    height: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
}
.MuiTypography-body2{
    font-size: 0.5rem;
    color :#c9c5c5;
}
`

const ScrollContent = styled.div`
max-height: ${isMobile ? '400px' : '100%'};
overflow-y: auto;
  scrollbar-gutter: stable;
`


// const IMG = styled.img`
//   display: block;
//   -webkit-background-size: cover;
//   background-size: cover;
//   background-repeat: no-repeat;
//   -webkit-background-position: center;
//   background-position: center;
//   width: 100%;
//   object-fit: cover;
//   width: 200px;
// `

const CustomCell = styled(TableCell)`
    color: white !important;
    padding: 8px 2px !important;
`


interface FightAdventureBattleHistoryModalProps {
    isGlobal: boolean
    isOpen: boolean
    isAttack: boolean
    historyList: BattleHistoryInfo[]
    onDismiss: () => void
}

export default function FightAdventureBattleHistoryModal({ isOpen, isGlobal, isAttack, historyList, onDismiss }: FightAdventureBattleHistoryModalProps) {
    const wrappedOnDismiss = useCallback(() => {
        onDismiss()
    }, [onDismiss])

    const titleText = isGlobal ? 'Global' : isAttack ? 'Attack' : 'Defence'
    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            <ContentWrapper gap="lg">
                <GlobalStyles
                    styles={{
                        '*::-webkit-scrollbar': {
                            width: '0.2em', height: '0.2em',

                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5b5eb1',
                            outline: '1px solid #5b5eb1',
                            borderRadius: '12px'
                        }
                    }}
                />
                <RowBetween>
                    <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Skirmish History: {titleText}
                    </TYPE.mediumHeader>

                    <CloseIcon onClick={wrappedOnDismiss} />
                </RowBetween>


                <ScrollContent>
                    {historyList?.length === 0 && (
                        <TYPE.white color={'#69b7dd'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                            Nothing to show here!
                        </TYPE.white>
                    )}
                    {historyList?.length > 0 && (
                        <TableContainer component={Card} style={{ background: 'transparent', marginTop: '1rem', margin: 'auto', width: 'fit-content' }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <CustomCell align="center">
                                            <TYPE.white fontFamily={'monospace'}>
                                                Attacker
                                            </TYPE.white>
                                        </CustomCell>
                                        <CustomCell align="center">
                                            <TYPE.white fontFamily={'monospace'}>
                                                Defender
                                            </TYPE.white>
                                        </CustomCell>
                                        <CustomCell align="center">
                                            <TYPE.white fontFamily={'monospace'}>
                                                Reward
                                            </TYPE.white>
                                        </CustomCell>
                                        <CustomCell align="center">
                                            <TYPE.white fontFamily={'monospace'}>
                                                Time
                                            </TYPE.white>
                                        </CustomCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ color: 'white' }}>
                                    {historyList.map((history) => (
                                        <TableRow
                                            key={history.attackerId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <CustomCell component="th" scope="row">
                                                <ListItem>
                                                    <StyledListItemText
                                                        primary={`#${history.attackerId}`}
                                                        style={{ textAlign: 'end', margin: 'auto 0.5rem' }} />
                                                    <ListItemAvatar>
                                                        <Avatar style={{ margin: 'auto', width: '48px', height: '48px' }}>
                                                            <StyledImg src={provideNFTImage(history.attackerCollection, history.attackerId.toString())} alt={iamge} loading="lazy" />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                </ListItem>
                                                <TYPE.white color={'#93871a'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                                                    🎖️ {powerOfCollection(history.attackerCollection, history.attackerId).rank}
                                                </TYPE.white>
                                                <TYPE.white color={'#959aaa'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                                                    {`⚡ ${powerOfCollection(history.attackerCollection, history.attackerId).low}-${powerOfCollection(history.attackerCollection, history.attackerId).high}`}
                                                </TYPE.white>

                                                <TYPE.white color={'#69b7dd'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                                                    🎯 {history.attackerHit}
                                                </TYPE.white>
                                            </CustomCell>

                                            <CustomCell component="th" scope="row">
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar style={{ margin: 'auto', width: '48px', height: '48px' }}>
                                                        <StyledImg src={provideNFTImage(history.defenderCollection, history.defenderId.toString())} alt={iamge} loading="lazy" />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <StyledListItemText
                                                        primary={`#${history.defenderId}`}
                                                        style={{ margin: 'auto 0.5rem' }} />
                                                </ListItem>
                                                <TYPE.white color={'#93871a'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                                                    🎖️ {powerOfCollection(history.defenderCollection, history.defenderId).rank}
                                                </TYPE.white>
                                                <TYPE.white color={'#959aaa'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                                                    {`⚡ ${powerOfCollection(history.defenderCollection, history.defenderId).low}-${powerOfCollection(history.defenderCollection, history.defenderId).high}`}
                                                </TYPE.white>
                                                <TYPE.white color={'#69b7dd'} fontFamily={'monospace'} style={{ textAlign: 'center' }}>
                                                    🎯 {history.defenderHit}
                                                </TYPE.white>
                                            </CustomCell>
                                            <CustomCell align="center">


                                                {isGlobal ? (
                                                    <>
                                                        {history.reward.greaterThan('0') ? (
                                                            <>
                                                                <TYPE.white color={'#2e9055'} fontFamily={'monospace'} style={{ margin: '20px' }}>
                                                                    Attacker Won!
                                                                </TYPE.white>

                                                                <TYPE.white style={{ fontSize: '1rem' }} fontFamily={'monospace'}>
                                                                    {history.reward.toFixed(1)}
                                                                    <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'14px'} />
                                                                </TYPE.white>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TYPE.white color={'#942b69'} fontFamily={'monospace'} style={{ margin: '20px' }}>
                                                                    Defender Won!
                                                                </TYPE.white>

                                                                <TYPE.white style={{ fontSize: '1rem' }} fontFamily={'monospace'}>
                                                                    {history.reward.toFixed(1)}
                                                                    <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'14px'} />
                                                                </TYPE.white>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {isAttack && (history.attackerHit > history.defenderHit) && (
                                                            <>
                                                                <TYPE.white color={'#2e9055'} fontFamily={'monospace'} style={{ margin: '20px' }}>
                                                                    You Won!
                                                                </TYPE.white>

                                                                <TYPE.white style={{ fontSize: '1rem' }} fontFamily={'monospace'}>
                                                                    {history.reward.toFixed(1)}
                                                                    <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'14px'} />
                                                                </TYPE.white>
                                                            </>
                                                        )}

                                                        {isAttack && (history.attackerHit < history.defenderHit) && (
                                                            <>
                                                                <TYPE.white color={'#942b69'} fontFamily={'monospace'} style={{ margin: '20px' }}>
                                                                    You Lost!
                                                                </TYPE.white>
                                                            </>
                                                        )}

                                                        {!isAttack && (history.attackerHit > history.defenderHit) && (
                                                            <>
                                                                <TYPE.white color={'#942b69'} fontFamily={'monospace'} style={{ margin: '20px' }}>
                                                                    Defeated!
                                                                </TYPE.white>
                                                            </>
                                                        )}

                                                        {!isAttack && (history.attackerHit < history.defenderHit) && (
                                                            <>
                                                                <TYPE.white color={'#2e9055'} fontFamily={'monospace'} style={{ margin: '20px' }}>
                                                                    Defended!
                                                                </TYPE.white>
                                                            </>
                                                        )}

                                                    </>
                                                )}



                                            </CustomCell>

                                            <CustomCell align="right">
                                                <TYPE.white fontFamily={'monospace'}>
                                                    {history.date.toUTCString()}
                                                </TYPE.white>

                                            </CustomCell>
                                        </TableRow>
                                    ))}



                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                </ScrollContent>
            </ContentWrapper>
        </Modal >
    )
}
