import React, { useState, useCallback } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { TransactionResponse } from '@ethersproject/providers'

import Box from '@mui/material/Box'
import { CRX_BRONZE_TICKET, CRX_GOLD_TICKET, CRX_SILVER_TICKET } from '../../constants/collections'
import CurrencyLogo from '../CurrencyLogo'
import { ButtonPrimary } from '../Button'
import { LoadingView, SubmittedView } from '../ModalViews'
import { CROWD_TICKET_CONTRACT_ADDRESSES, IDOTicketState, useCrowdTicketInfo } from '../../state/crowdTicket/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useCrowdTicketContract } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'
import Grid from '@mui/material/Grid'

export const DataCard = styled(AutoColumn)<{ disabled?: boolean }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #5b5eb1 0%, #0d0c20 100%);
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const PoolData = styled(DataCard)`
  background: #1b1b34;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const LearnMore = styled.div<{ dim: boolean }>`
  display: block;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  opacity: ${({ dim }) => (dim ? 1 : 1)};
`

interface IDOParticipateModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function IDOTokenMintModal({ isOpen, onDismiss }: IDOParticipateModalProps) {
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [tokenType, setTokenType] = useState<IDOTicketState>(0)
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  const crowdTicketContract = useCrowdTicketContract(CROWD_TICKET_CONTRACT_ADDRESSES)
  const crowdTicketInfo = useCrowdTicketInfo()

  function onTicketBuy(ticketType: IDOTicketState) {
    setAttempting(true)
    setTokenType(ticketType)
    if (crowdTicketContract) {
      crowdTicketContract
        .mint(account, ticketType)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Buy ${IDOTicketState[tokenType]} Crowd Ticket`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to buy ticket failed. Please contact support.')
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={50}>
      {!attempting && !hash && (
        <ContentWrapper>
          <RowBetween>
            <TYPE.mediumHeader>Buy Crowd Ticket</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          <Box sx={{ width: '100%', mr: 1, marginTop: '10px' }}>
            <Grid container spacing={2} style={{ padding: '2rem 0' }}>
              <Grid item xs={4}>
                <PoolData style={{ border: '2px solid #7c564d' }}>
                  <AutoColumn gap="0" style={{ textAlign: 'center', alignItems: 'center' }}>
                    <TYPE.body style={{ margin: 0 }} fontSize={16}>
                      Bronze
                    </TYPE.body>
                    <TYPE.body style={{ margin: 0 }} fontSize={12}>
                      Tier 1
                    </TYPE.body>

                    <TYPE.body>
                      <CurrencyLogo currency={CRX_BRONZE_TICKET} size={'80px'} />
                    </TYPE.body>

                    <TYPE.body>
                      <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        width="fit-content"
                        margin="auto"
                        onClick={() => onTicketBuy(IDOTicketState.BRONZE)}
                      >
                        {crowdTicketInfo?.bronzeMintPrice?.toFixed(2)} CRX
                      </ButtonPrimary>
                    </TYPE.body>

                    <TYPE.body style={{ margin: 0 }} fontSize={9}>
                      Available Ticket: {crowdTicketInfo?.bronzeBalance?.length ?? 0}
                    </TYPE.body>
                  </AutoColumn>
                </PoolData>
              </Grid>
              <Grid item xs={4}>
                <PoolData style={{ border: '2px solid #e9e9e9' }}>
                  <AutoColumn gap="0" style={{ textAlign: 'center', alignItems: 'center' }}>
                    <TYPE.body style={{ margin: 0 }} fontSize={16}>
                      Silver
                    </TYPE.body>
                    <TYPE.body style={{ margin: 0 }} fontSize={12}>
                      Tier 2
                    </TYPE.body>
                    <TYPE.body>
                      <CurrencyLogo currency={CRX_SILVER_TICKET} size={'80px'} />
                    </TYPE.body>
                    <TYPE.body>
                      <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        width="fit-content"
                        margin="auto"
                        onClick={() => onTicketBuy(IDOTicketState.SILVER)}
                      >
                        {crowdTicketInfo?.silverMintPrice?.toFixed(2)} CRX
                      </ButtonPrimary>
                    </TYPE.body>

                    <TYPE.body style={{ margin: 0 }} fontSize={9}>
                      Available Ticket: {crowdTicketInfo?.silverBalance?.length ?? 0}
                    </TYPE.body>
                  </AutoColumn>
                </PoolData>
              </Grid>
              <Grid item xs={4}>
                <PoolData style={{ border: '2px solid #e6d28d' }}>
                  <AutoColumn gap="0" style={{ textAlign: 'center', alignItems: 'center' }}>
                    <TYPE.body style={{ margin: 0 }} fontSize={16}>
                      Gold
                    </TYPE.body>
                    <TYPE.body style={{ margin: 0 }} fontSize={12}>
                      Tier 3
                    </TYPE.body>

                    <TYPE.body>
                      <CurrencyLogo currency={CRX_GOLD_TICKET} size={'80px'} />
                    </TYPE.body>

                    <TYPE.body>
                      <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        width="fit-content"
                        margin="auto"
                        onClick={() => onTicketBuy(IDOTicketState.GOLD)}
                      >
                        {crowdTicketInfo?.goldMintPrice?.toFixed(2)} CRX
                      </ButtonPrimary>
                    </TYPE.body>

                    <TYPE.body style={{ margin: 0 }} fontSize={9}>
                      Available Ticket: {crowdTicketInfo?.goldBalance?.length ?? 0}
                    </TYPE.body>
                  </AutoColumn>
                </PoolData>
              </Grid>
            </Grid>
          </Box>
          <LearnMore dim={true} style={{ textAlign: 'center' }}>
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: '#acaed9', textDecoration: 'none' }}
              href={'https://docs.crodex.app/crowd-launchpad'}
            >
              What is included?<>↗</>
            </a>
          </LearnMore>
        </ContentWrapper>
      )}

      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Buying CROWD Ticket</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{IDOTicketState[tokenType]}</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Bought {IDOTicketState[tokenType]} CROWD Ticket</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
