import { CurrencyAmount, Token } from '@cronosdex/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonConfirmed } from '../../components/Button'
import Column, { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import ProgressSteps from '../../components/ProgressSteps'

import { useActiveWeb3React } from '../../hooks'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
    useDefaultsFromURLSearch,
} from '../../state/swap/hooks'
import { TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import Loader from '../../components/Loader'
import LPInputPanel from '../../components/LPInputPanel'
import TokenWarningModal from '../../components/TokenWarningModal'
import { useDerivedZapperInfo, useZapperActionHandlers, useZapperState, ZAPPER_CONTRACT_ADDRESS } from '../../state/zapper/hooks'
import ZapperInModal from '../../components/Zapper/ZapperInModal'
import { TransCard } from '../../components/Card'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ZapperInputPanel from '../../components/ZapperInputpanel'

const CustomTabs = styled(Tabs)`
  .MuiButtonBase-root {
    background: #6cbdb726;
    backdrop-filter: blur(30px);
    color: white;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    &.Mui-selected {
      color: white;
      font-weight: 600;
      background: #6cb5bd4f;
    }
  }
  .MuiTabs-indicator {
    background-color: #43ace3;
  }
`


export default function Zapper() {
    const loadedUrlParams = useDefaultsFromURLSearch()

    // token warning stuff
    const [loadedInputCurrency, loadedOutputCurrency] = [
        useCurrency(loadedUrlParams?.inputCurrencyId),
        useCurrency(loadedUrlParams?.outputCurrencyId)
    ]
    const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
    const urlLoadedTokens: Token[] = useMemo(
        () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
        [loadedInputCurrency, loadedOutputCurrency]
    )
    const allTokens = useAllTokens()
    let wlURLTokens = urlLoadedTokens
    for (let i = 0; i < urlLoadedTokens.length; i++) {
        if (allTokens[urlLoadedTokens[i]?.address] !== undefined) {
            delete wlURLTokens[i]
        }
    }
    wlURLTokens = wlURLTokens.filter(element => {
        return element !== undefined
    })

    const handleConfirmTokenWarning = useCallback(() => {
        setDismissTokenWarning(true)
    }, [])

    const { account } = useActiveWeb3React()
    const theme = useContext(ThemeContext)

    // toggle wallet when disconnected
    const toggleWalletModal = useWalletModalToggle()

    // swap state
    const { independentField, typedValue } = useZapperState()
    const {
        currencyBalances,
        parsedAmount,
        currencies,
        inputError: swapInputError
    } = useDerivedZapperInfo()

    const parsedAmounts = {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : parsedAmount
    }

    const { onCurrencySelection, onUserInput } = useZapperActionHandlers()
    const isValid = !swapInputError
    const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
    const handleTypeInput = useCallback(
        (value: string) => {
            onUserInput(Field.INPUT, value)
        },
        [onUserInput]
    )
    const handleTypeOutput = useCallback(
        (value: string) => {
            onUserInput(Field.OUTPUT, value)
        },
        [onUserInput]
    )

    // modal and loading
    const [{ swapErrorMessage }] = useState<{
        showConfirm: boolean
        attemptingTxn: boolean
        swapErrorMessage: string | undefined
        txHash: string | undefined
    }>({
        showConfirm: false,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        txHash: undefined
    })

    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? ''
    }


    // check whether the user has approved the router on the input token
    const [approval, approveCallback] = useApproveCallback(parsedAmount, ZAPPER_CONTRACT_ADDRESS)
    // check if user has gone through approval process, used to show two step buttons, reset on token change
    const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

    // mark when a user has submitted an approval, reset onTokenSelection for input field
    useEffect(() => {
        if (approval === ApprovalState.PENDING) {
            setApprovalSubmitted(true)
        }
    }, [approval, approvalSubmitted])

    const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
    const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))


    // show approve flow when: no error on inputs, not approved or pending, or approved in current session
    // never show if price impact is above threshold in non expert mode
    const showApproveFlow = currencies[Field.INPUT]?.name == "CRO" ? false : true
    // !swapInputError &&
    // (approval === ApprovalState.NOT_APPROVED ||
    //     approval === ApprovalState.PENDING ||
    //     (approvalSubmitted && approval === ApprovalState.APPROVED))


    const handleInputSelect = useCallback(
        inputCurrency => {
            setApprovalSubmitted(false) // reset 2 step UI for approvals
            onCurrencySelection(Field.INPUT, inputCurrency)
        },
        [onCurrencySelection]
    )

    const handleMaxInput = useCallback(() => {
        maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
    }, [maxAmountInput, onUserInput])

    const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
        onCurrencySelection
    ])

    const [showZapperInModal, setShowZapperInModal] = useState(false)

    const handleZapperInClick = useCallback(() => {
        if (account) {
            setShowZapperInModal(true)
        } else {
            toggleWalletModal()
        }
    }, [account, toggleWalletModal])

    const [value, setValue] = React.useState('0')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue)
    }

    return (
        <>
            <CustomTabs style={{ marginBottom:'3rem'}} value={value} onChange={handleChange} aria-label="nav tabs example">
                <Tab
                    component={Link}
                    value="0"
                    label={<TYPE.white> LP Zapper </TYPE.white>}
                    to={`/zapper`}
                />
                <Tab
                    component={Link}    
                    value="1"
                    label={<TYPE.white style={{textTransform:'none'}}> Form xCRX LP </TYPE.white>}
                    to={`/formLP`}
                />
            </CustomTabs>

            <TokenWarningModal
                isOpen={wlURLTokens.length > 0 && !dismissTokenWarning}
                tokens={wlURLTokens}
                onConfirm={handleConfirmTokenWarning}
            />

            <ZapperInModal
                isOpen={showZapperInModal}
                onDismiss={() => setShowZapperInModal(false)}
                inputCurrency={currencies[Field.INPUT]}
                outputCurrency={currencies[Field.OUTPUT]}
                amountIn={parsedAmount}
                minAmountIn={parsedAmount}
            />

            <AppBody>
                <SwapPoolTabs active={'swap'} />
                <Wrapper id="swap-page">

                    <AutoColumn gap={'md'} >
                        <ZapperInputPanel
                            label={'From'}
                            value={formattedAmounts[Field.INPUT]}
                            showMaxButton={!atMaxAmountInput}
                            currency={currencies[Field.INPUT]}
                            onUserInput={handleTypeInput}
                            onMax={handleMaxInput}
                            onCurrencySelect={handleInputSelect}
                            otherCurrency={currencies[Field.OUTPUT]}
                            id="swap-currency-input"
                            showCommonBases={false}
                        />


                        <AutoColumn justify="space-between">
                            <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                                <ArrowWrapper clickable={false}>
                                    <ArrowDown
                                        size="16"
                                        onClick={() => {
                                        }}
                                        color={theme.text2}
                                    />
                                </ArrowWrapper>
                            </AutoRow>
                        </AutoColumn>


                        <LPInputPanel
                            value={formattedAmounts[Field.OUTPUT]}
                            onUserInput={handleTypeOutput}
                            label={'To'}
                            showMaxButton={false}
                            currency={currencies[Field.OUTPUT]}
                            onCurrencySelect={handleOutputSelect}
                            otherCurrency={currencies[Field.INPUT]}
                            id="lp-currency-output"
                        />


                        {/* {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                            <RowBetween align="center" style={{ margin: '0.5rem', }}>
                                <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                                    Slippage Tolerance
                                </ClickableText>
                                <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                                    {allowedSlippage / 100}%
                                </ClickableText>
                            </RowBetween>
                        )} */}


                    </AutoColumn>

                    <BottomGrouping>
                        {!account ? (
                            <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                        ) : showApproveFlow ? (
                            <RowBetween>
                                <ButtonConfirmed
                                    onClick={approveCallback}
                                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                                    width="48%"
                                    altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                                    confirmed={approval === ApprovalState.APPROVED}
                                >
                                    {approval === ApprovalState.PENDING ? (
                                        <AutoRow gap="6px" justify="center">
                                            Approving <Loader stroke="white" />
                                        </AutoRow>
                                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                                        'Approved'
                                    ) : (
                                        'Approve ' + currencies[Field.INPUT]?.symbol
                                    )}
                                </ButtonConfirmed>
                                <ButtonError
                                    onClick={() => {
                                        handleZapperInClick()
                                    }}
                                    width="48%"
                                    id="swap-button"
                                    disabled={
                                        !isValid || approval !== ApprovalState.APPROVED
                                    }
                                    error={false}
                                >
                                    <Text fontSize={16} fontWeight={500}>
                                        {"Zap"}
                                    </Text>
                                </ButtonError>
                            </RowBetween>
                        ) : (
                            <ButtonError
                                onClick={() => {
                                    handleZapperInClick()
                                }}
                                id="zapper-button"
                                disabled={!isValid}
                                error={false}
                            >
                                <Text fontSize={20} fontWeight={500}>
                                    {swapInputError
                                        ? swapInputError
                                        : 'Zap'}
                                </Text>
                            </ButtonError>
                        )}
                        {showApproveFlow && (
                            <Column style={{ marginTop: '1rem' }}>
                                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                            </Column>
                        )}
                        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                    </BottomGrouping>



                </Wrapper>

                <TransCard marginTop={'1rem'}>
                    <TYPE.subHeader style={{ textAlign: 'center' }}>
                        <span role="img" aria-label="wizard-icon">
                            ⭐️
                        </span>{' '}
                        By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
                        Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity. Zapper takes 0.1% service fee.
                    </TYPE.subHeader>
                </TransCard>

            </AppBody>



        </>
    )
}
