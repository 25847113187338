import { ChainId, Fraction, JSBI, Token, TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { ORC, PPTZ, UNI } from '../../constants/'
import { IDO_ABI_INTERFACE } from '../../constants/abis/ido'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractSingleData } from '../multicall/hooks'
import { IDO_LIST } from './launchpads'
import { ProjectInfo } from './projects'
import { IDO_UPCOMING_LIST } from './upcomingLaunchpads'


export enum IDOState {
    UPCOMING,
    LAUNCHED,
    LIVE, 
}

const MAX_PERCENTAGE = 10_000
const EPS = 0.00000000001;


// TODO add staking rewards addresses here
export const IDO_INFO: {
    [chainId in ChainId]?: {
        name: string
        token: Token
        project: ProjectInfo
        contractAddress: string
        holdReq: TokenAmount | undefined
        contributionToken: Token
        startDate: Date
        endDate: Date
        softCap: TokenAmount | undefined
        customHardCap: TokenAmount[] | undefined
    }[]
} = {
    [ChainId.CRONOSMAINNET]: IDO_LIST
}



export interface IDOInfo {
    name: string
    token: Token
    project: ProjectInfo
    contractAddress: string
    contributionToken: Token
    holdReq: TokenAmount | undefined
    startDate: Date
    endDate: Date
    claimablePercent: string
    isEnabled: boolean
    hardCap: TokenAmount
    softCap: TokenAmount | undefined
    optCap: TokenAmount | undefined
    isSoftEnabled: boolean
    collectedPercentage: number
    tokenPrice: TokenAmount
    minContribution: TokenAmount
    maxContribution: TokenAmount
    totalHardCap: TokenAmount
    isContractWhitelisted: boolean
    isAccountWhitelisted: boolean
    canParticipate: boolean
    amountContributed: TokenAmount
    amountContributedinETH: TokenAmount
    amountUnclaimed: TokenAmount
    amountClaimed: TokenAmount
    amountClaimable: Fraction
    isContributing: boolean
    isClaimable: boolean
    status: IDOState
    statusText: string
    cardButtonText: string
}

// gets the staking info from the network for the active chain id
export function useIDOInfo(idoAddress?: string | null): IDOInfo[] {

    const { chainId, account } = useActiveWeb3React()

    const info = useMemo(
        () =>
            chainId
                ? IDO_INFO[chainId]?.filter(
                    idoInfo =>
                    (idoAddress === undefined ? true : (idoAddress === idoInfo.contractAddress ))
                ) ?? []
                : [],
        [chainId, idoAddress]
    )

    const uni = chainId ? UNI[chainId] : undefined
    const accountArg = useMemo(() => [account ?? undefined], [account])
    const idoAddresses = useMemo(() => info.map(({ contractAddress }) => contractAddress), [info])
    
    // contract calls //
    const isEnabled = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'enabled')
    const hardCap = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'hardCap')
    const totalHardCap = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'totalHardCap')
    const isContractWhitelisted = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'whitelist')
    const claimablePercent = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'claimable_percent')
    const tokenPrice = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'tokenPriceCro')
    const minContribution = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'minContribution')
    const maxContribution = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'maxContribution')
    
    // account related calls //
    const amountContributed = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'tokenExchanged', accountArg )
    const amountContributedinETH = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'balances', accountArg )

    const amountUnclaimed = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'tokensUnclaimed', accountArg )
    const isAccountWhitelisted = useMultipleContractSingleData(idoAddresses, IDO_ABI_INTERFACE, 'whitelisted', accountArg )

    
    return useMemo(() => {
        if (!chainId || !uni) return []

        return idoAddresses.reduce<IDOInfo[]>((memo, idoAddress, index) => {

            const isEnabledState = isEnabled[index]
            const claimablePercentState = claimablePercent[index]
            const hardCapState = hardCap[index]
            const totalHardCapState = totalHardCap[index]
            const isContractWhitelistedState = isContractWhitelisted[index]
            const isAccountWhitelistedState = isAccountWhitelisted[index]
            const amountContributedState = amountContributed[index]
            const amountContributedinETHState = amountContributedinETH[index]
            const amountUnclaimedState = amountUnclaimed[index]
            const tokenPriceState = tokenPrice[index]
            const minContributionState = minContribution[index]
            const maxContributionState = maxContribution[index]

            if (
                !claimablePercentState?.loading &&
                !isEnabledState?.loading &&
                !hardCapState?.loading &&
                !totalHardCapState?.loading &&
                !isContractWhitelistedState?.loading &&
                !isAccountWhitelistedState?.loading &&
                !amountContributedState?.loading &&
                !amountContributedinETHState?.loading &&
                !amountUnclaimedState?.loading && 
                !tokenPriceState?.loading &&
                !minContributionState?.loading &&
                !maxContributionState?.loading
            ) {
                if (
                    claimablePercentState?.error ||
                    isEnabledState?.error ||
                    hardCapState?.error || 
                    totalHardCapState?.error ||
                    isContractWhitelistedState?.error ||
                    isAccountWhitelistedState?.error ||
                    amountContributedState?.error ||
                    amountContributedinETHState?.error ||
                    amountUnclaimedState?.error ||
                    tokenPriceState?.error ||
                    minContributionState?.error ||
                    maxContributionState?.error
                ) {
                    console.error('Failed to load IDO info')
                    return memo
                }

                const isEnabled = isEnabledState.result?.[0] ?? false
                let hardCap = new TokenAmount(info[index].contributionToken, JSBI.BigInt(hardCapState?.result?.[0] ?? 0))
                const claimablePercent = (parseInt(JSBI.BigInt(claimablePercentState?.result?.[0] ?? 0).toString()) / MAX_PERCENTAGE * 100).toFixed(2)
                const tokenPrice = new TokenAmount(info[index].contributionToken, JSBI.BigInt(tokenPriceState?.result?.[0] ?? 0))
                const minContribution = new TokenAmount(info[index].contributionToken, JSBI.BigInt(minContributionState?.result?.[0] ?? 0))
                const maxContribution = new TokenAmount(info[index].contributionToken, JSBI.BigInt(maxContributionState?.result?.[0] ?? 0))
                let totalHardCap = new TokenAmount(info[index].contributionToken, JSBI.BigInt(totalHardCapState?.result?.[0] ?? 0))

                if (info[index]) {
                    const customHardCaps = info[index].customHardCap;
                    if (customHardCaps) {
                        for (let cap of customHardCaps) {
                            if (totalHardCap.lessThan(cap)) {
                                hardCap = cap;
                                break;
                            }
                        }
                    }
                }

                const optCap = totalHardCap?.greaterThan(info[index]?.softCap ?? '0') ? hardCap : info[index]?.softCap
                const isSoftEnabled = totalHardCap?.greaterThan(info[index]?.softCap ?? '0') ? false : true

                let collectedPercentage = parseFloat(totalHardCap.divide(optCap ?? '1').multiply('100').toFixed(1))
                collectedPercentage = collectedPercentage > 100 ? 100 : collectedPercentage
                totalHardCap = collectedPercentage === 100 ? hardCap : totalHardCap
                
                const isContractWhitelisted = isContractWhitelistedState?.result?.[0] ?? false
                const isAccountWhitelisted =  isAccountWhitelistedState?.result?.[0] ?? false
                const canParticipate = isContractWhitelisted ? ( isAccountWhitelisted ? true : false ) : true
                const amountContributed = new TokenAmount(info[index].token, JSBI.BigInt(amountContributedState?.result?.[0] ?? 0))

                const amountContributedinETH = new TokenAmount(info[index].contributionToken, JSBI.BigInt(amountContributedinETHState?.result?.[0] ?? 0))
                const amountUnclaimed = new TokenAmount(info[index].token, JSBI.BigInt(amountUnclaimedState?.result?.[0] ?? 0))

                
                const amountClaimed = amountContributed.subtract(amountUnclaimed)
                const claimableTokenAmount = amountContributed.multiply(`${claimablePercentState?.result?.[0] ?? 0}`).divide(`${MAX_PERCENTAGE}`).subtract(amountClaimed)
                const amountClaimable = parseFloat(claimableTokenAmount.toFixed(info[index].token.decimals)) > EPS ? claimableTokenAmount : new Fraction(`${0}`)
                
                const isContributing = amountContributed.greaterThan('0')
                const hardCapReached = totalHardCap.greaterThan(hardCap) || totalHardCap.equalTo(hardCap)

                let status = IDOState.LAUNCHED
                let statusText = ""
                let cardButtonText = "Contribute"
                if ( !isEnabled && totalHardCap.equalTo('0')) {
                    status = IDOState.UPCOMING
                    statusText = "⏰ Stay Tuned!"
                    cardButtonText = "Info"
                } else if ( !isEnabled && totalHardCap.greaterThan('0') ) {
                    status = IDOState.LAUNCHED
                    statusText = "Presale Ended."
                    cardButtonText = "Info"
                } else if ( isEnabled && hardCapReached ){
                    status = IDOState.LAUNCHED
                    statusText = "Presale Ended."
                    cardButtonText = "Info"
                } else if ( isEnabled ) {
                    status = IDOState.LIVE
                    statusText = "✨ Presale is Live Now! ✨"
                }
                
                cardButtonText = isContributing ? "Manage" : cardButtonText
                // status = amountClaimable?.greaterThan('0') ? IDOState.CLAIMABLE : status
                const isClaimable = amountClaimable?.greaterThan('0') ? true : false
                
                let customTitle = ""
                if (info[index].token === ORC || info[index].token === PPTZ) {
                    customTitle = info[index].name
                } else {
                    customTitle = info[index].token.symbol + " - " + info[index].name
                }
                memo.push({
                    name: customTitle,
                    token: info[index].token,
                    project: info[index].project,
                    contractAddress: info[index].contractAddress,
                    holdReq: info[index].holdReq,
                    contributionToken: info[index].contributionToken,
                    startDate: info[index].startDate,
                    endDate: info[index].endDate,
                    claimablePercent: claimablePercent,
                    isEnabled: isEnabled,
                    hardCap: hardCap,
                    softCap: info[index].softCap,
                    optCap: optCap,
                    isSoftEnabled: isSoftEnabled,
                    collectedPercentage: collectedPercentage,
                    tokenPrice: tokenPrice,
                    minContribution: minContribution,
                    maxContribution: maxContribution,
                    totalHardCap: totalHardCap,
                    isContractWhitelisted: isContractWhitelisted,
                    isAccountWhitelisted: isAccountWhitelisted,
                    canParticipate: canParticipate,
                    amountContributed: amountContributed,
                    amountContributedinETH: amountContributedinETH,
                    amountUnclaimed: amountUnclaimed,
                    amountClaimed: amountClaimed,
                    amountClaimable: amountClaimable,
                    isContributing: isContributing,
                    isClaimable: isClaimable,
                    cardButtonText: cardButtonText,
                    status: status,
                    statusText: statusText,
                })
            }
            return memo
        }, [])
    }, [chainId, info, uni, idoAddresses, claimablePercent, isEnabled, hardCap, totalHardCap, isContractWhitelisted, isAccountWhitelisted, amountContributed, amountUnclaimed, tokenPrice, minContribution, maxContribution, amountContributedinETH])
}

////////////////////////
// UPCOMING LAUNCPADS //
///////////////////////

// TODO add staking rewards addresses here
export const IDO_UPCOMING_INFO: {
    [chainId in ChainId]?: {
        name: string
        token: Token
        startDate: Date | undefined
        endDate: Date | undefined
        
    }[]
} = {
    [ChainId.CRONOSMAINNET]: IDO_UPCOMING_LIST
}