import { TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { METAVERSEHUB } from '../../../constants'
import { useActiveWeb3React } from '../../../hooks'
import { useMissionReconContract } from '../../../hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from '../../multicall/hooks'
import { UINTMAX } from '../../nftStake/hooks'
import { ADVENTURE_STATUS } from '../helper'


export const MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS = "0x789387d4C9181379B81853037CFe42eD144DF181"


export interface MissionReconAdventureInfo {
    totalTicketCount: number | undefined
    entrantCount: number | undefined
    isEnterable: boolean | undefined
    isExitable: boolean | undefined
    userTicketCount: number | undefined
    userTokenList: number[] | undefined
    userShare: number | undefined
    status: ADVENTURE_STATUS | undefined
}

export function useMissionReconTokenIDtoOwner(userTokenList: number[]): number[] {

    const missionReconAdventureContract = useMissionReconContract(MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS)
    const { chainId, account } = useActiveWeb3React()
    const tokenListArgs: any[][] = []
    const userDepositLen = userTokenList ? userTokenList?.length : 0
    for (var idx = 0; idx < userDepositLen ?? 0; idx++) {
        tokenListArgs.push([userTokenList[idx]])
    }
    const tokenIdtoOwnerRes = useSingleContractMultipleData(missionReconAdventureContract ?? undefined, 'tokenIdToOwner', tokenListArgs ?? undefined)
    const tokenList: any[] = []
    for (var idy = 0; idy < tokenIdtoOwnerRes.length; idy++) {
        if (tokenIdtoOwnerRes?.[idy].result?.[0])
            if (tokenIdtoOwnerRes?.[idy].result?.[0] === account)
                tokenList.push(userTokenList[idy])
    }
    return useMemo(() => {
        if (!chainId) return []

        return tokenList.reduce<number[]>((memo, tokenURIItem, index) => {
            // these two are dependent on account

            memo.push(tokenList[index])

            return memo
        }, [])
    }, [chainId, tokenList, tokenListArgs])
}


export function useMissionReconFee(ticketTypes: string[]): TokenAmount | undefined {
    const missionReconAdventureContract = useMissionReconContract(MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS)
    const feeRes = useSingleCallResult(missionReconAdventureContract, 'feeToPay', [ticketTypes])
    const feeToPay = (!feeRes?.loading && feeRes?.result) ? new TokenAmount(METAVERSEHUB, feeRes?.result[0]) : undefined
    return feeToPay
}

// gets the staking info from the network for the active chain id
export function useMissionReconAdventure(): MissionReconAdventureInfo {

    const { chainId, account } = useActiveWeb3React()

    const missionReconAdventureContract = useMissionReconContract(MISSION_RECON_ADVENTURE_CONTRACT_ADDRESS)

    const totalTicketCountRes = useSingleCallResult(missionReconAdventureContract, 'nextTicketId')
    const entrantCountRes = useSingleCallResult(missionReconAdventureContract, 'entryCount')

    const isEnterableRes = useSingleCallResult(missionReconAdventureContract, 'isEnterable')
    const isExitableRes = useSingleCallResult(missionReconAdventureContract, 'isExitable')

    const userTicketCountRes = useSingleCallResult(missionReconAdventureContract, 'userTicketCount', [account ?? undefined])
    const userTokensRes = useSingleCallResult(missionReconAdventureContract, 'getUserTokens', [account ?? undefined])


    return useMemo(() => {

        let memo: MissionReconAdventureInfo = {
            totalTicketCount: undefined,
            entrantCount: undefined,
            isEnterable: undefined,
            isExitable: undefined,
            userTicketCount: undefined,
            userTokenList: undefined,
            userShare: undefined,
            status: undefined,
        }

        if (!chainId) return memo
        else {
            if (
                !totalTicketCountRes?.loading &&
                !entrantCountRes?.loading &&
                !isEnterableRes?.loading &&
                !isExitableRes?.loading &&
                !userTicketCountRes?.loading &&
                !userTokensRes?.loading
            ) {
                if (
                    totalTicketCountRes?.error ||
                    entrantCountRes?.error ||
                    isEnterableRes?.error ||
                    isExitableRes?.error ||
                    userTicketCountRes?.error ||
                    userTokensRes?.loading
                ) {
                    console.error('Failed to load Mission Recon Adventure info')
                    return memo
                }

                // data from contract
                memo.totalTicketCount = totalTicketCountRes.result?.[0]
                memo.entrantCount = entrantCountRes.result?.[0]
                memo.isEnterable = isEnterableRes.result?.[0]
                memo.isExitable = isExitableRes.result?.[0]

                memo.userTicketCount = userTicketCountRes.result?.[0]
                memo.userShare = (memo.userTicketCount && memo.totalTicketCount) ? (memo.userTicketCount / memo.totalTicketCount) * 100 : undefined

                let userTokenList = []
                const userDepositLen = userTokensRes ? userTokensRes?.result?.[0].length : 0
                for (var idx = 0; idx < userDepositLen; idx++) {
                    const tokenIDstr = userTokensRes?.result?.[0][idx]?.toString()
                    if (tokenIDstr !== UINTMAX)
                        userTokenList.push((parseInt(tokenIDstr)))
                }
                memo.userTokenList = userTokenList


                if (memo.isEnterable) {
                    memo.status = ADVENTURE_STATUS.LIVE
                } else if (memo.isExitable) {
                    memo.status = ADVENTURE_STATUS.FINISHED
                } else {
                    memo.status = ADVENTURE_STATUS.WAITING_TO_START
                }
                return memo
            }
            return memo
        }
    }, [chainId, account, totalTicketCountRes, entrantCountRes, isEnterableRes, isExitableRes, userTicketCountRes, userTokensRes])
}
