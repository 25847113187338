import React, { useState } from 'react'
import styled from 'styled-components'
import { TYPE } from '../../../theme'
import { LightCard } from '../../Card'
import { Break } from '../MintNFT/styled'
import CurrencyLogo from '../../CurrencyLogo'
import { CRXILLION } from '../../../constants/collections'
import { RowBetween } from '../../Row'
import { ButtonPrimary } from '../../Button'
// import { BarWave } from 'react-cssfx-loading'
import { Hypnosis } from 'react-cssfx-loading'

import { useNFTPortfolio, useNFTUserDeposits } from '../../../state/nftPortfolio/hooks'

import RecallIcon from '../../../assets/images/recall.png'

import './index.css'
import { ADVENTURE_STATUS } from '../../../state/mhub/helper'

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

import ArrivalAdventureMechanicsModal from './MissionReconMechanicsModal'
import { useMissionReconAdventure, useMissionReconTokenIDtoOwner } from '../../../state/mhub/adventures/missionReconAdventure'
import MissionReconDepositModal from './MissionReconDepositModal'
import MissionReconWithdrawModal from './MissionReconWithdrawModal'
import MissionReconStoryModal from './MissionReconStoryModal'
import MissionReconWinnerListModal from './MissionReconWinnerList'
import MissionReconAdventurersModal from './MissionReconAdventurersModal'
import { CRXILLION_INFO } from '../../../state/nftStake/projects'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`


const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
`

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: #1f1243cc;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`



export default function MissionRecon() {
  const adventureInfo = useMissionReconAdventure()
  const nftPortfolio = useNFTPortfolio(CRXILLION)
  const userTokenList = useMissionReconTokenIDtoOwner(adventureInfo?.userTokenList ?? [])
  const nftDeposits = useNFTUserDeposits(CRXILLION, userTokenList ?? [])
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showStoryModal, setShowStoryModal] = useState(false)
  const [showMechanicsModal, setShowMechanicsModal] = useState(false)
  const [showWinnerListModal, setShowWinnerListModal] = useState(false)
  const [showAdventurersModal, setShowAdventurersModal] = useState(false)

  const isLoading = adventureInfo?.totalTicketCount !== undefined ? false : true
  const isStaking = ( userTokenList.length > 0 ) ? true : false
  // const hasNFT = nftPortfolio?.length !== 0 ? true : false

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      <MissionReconDepositModal
        isOpen={showDepositModal}
        onDismiss={() => setShowDepositModal(false)}
        adventureInfo={adventureInfo}
        portfolioInfo={nftPortfolio}
      />

      <MissionReconWithdrawModal
        isOpen={showWithdrawModal}
        onDismiss={() => setShowWithdrawModal(false)}
        adventureInfo={adventureInfo}
        nftDepositInfo={nftDeposits}
      />

      <MissionReconStoryModal isOpen={showStoryModal} onDismiss={() => setShowStoryModal(false)} />
      <MissionReconWinnerListModal isOpen={showWinnerListModal} onDismiss={() => setShowWinnerListModal(false)} />
      <MissionReconAdventurersModal portfolioInfo={nftDeposits} projectInfo={CRXILLION_INFO} isOpen={showAdventurersModal} onDismiss={() => setShowAdventurersModal(false)} />

      <Cards>

        {(adventureInfo?.status === undefined || adventureInfo?.status === ADVENTURE_STATUS.WAITING_TO_START) && (
          <RowBetween align="center">
            <ButtonPrimary
              disabled={true}
              style={{ background: 'transparent', border: 'none' }}
              height="100%"
              padding="8px"
            >
              <>
                <CurrencyLogo currency={CRXILLION} size={'48px'} ></CurrencyLogo>

                <TYPE.white fontWeight={600} fontSize={24} style={{ textAlign: 'center', margin: 'auto' }}>
                  Mission Recon
                </TYPE.white>
                <a className="play-btn"></a>
              </>
            </ButtonPrimary>
          </RowBetween>
        )}


        {(adventureInfo?.status === ADVENTURE_STATUS.LIVE || adventureInfo?.status === ADVENTURE_STATUS.FINISHED) && (
          <RowBetween align="center">
            <ButtonPrimary
              style={{ background: 'transparent', border: 'none', boxShadow: 'rgb(91 95 177) 0px 0px 12px' }}
              height="100%"
              padding="8px"
              onClick={() => setShowStoryModal(true)}
            >
              <>
                <CurrencyLogo currency={CRXILLION} size={'48px'} ></CurrencyLogo>

                <TYPE.white fontWeight={600} fontSize={24} style={{ textAlign: 'center', margin: 'auto' }}>
                  Mission Recon
                </TYPE.white>
                <a className="play-btn"></a>
              </>
            </ButtonPrimary>
          </RowBetween>
        )}

        {(adventureInfo?.status === ADVENTURE_STATUS.LIVE || adventureInfo?.status === ADVENTURE_STATUS.FINISHED) && (
          <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
            <MilitaryTechIcon
              sx={{ marginRight: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
            />
            <TYPE.white onClick={() => setShowWinnerListModal(true)}
              fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}>
              Winner List
            </TYPE.white>

          </div>
        )}


        {(adventureInfo?.status === ADVENTURE_STATUS.WAITING_TO_START) && (
          <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
            <TYPE.white fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
              Reconnaissance will start soon!
            </TYPE.white>
            <TimerOutlinedIcon
              sx={{ marginLeft: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
            />
          </div>
        )}


        <Break style={{ width: '100%', margin: '16px auto auto' }} />

        {isLoading && (
          <div style={{ display: 'flex', height: '200px', alignItems: 'center' }}>
            <Hypnosis style={{ margin: '20px auto' }} color="#5b5eb1"></Hypnosis>
          </div>
        )}

        {!isLoading && adventureInfo?.status === ADVENTURE_STATUS.WAITING_TO_START && (
          <div style={{ display: 'flex', height: '200px', alignItems: 'center' }}>
            <Hypnosis style={{ margin: '20px auto' }} color="#5b5eb1"></Hypnosis>
          </div>
        )}

        {!isLoading && adventureInfo?.status !== ADVENTURE_STATUS.WAITING_TO_START && (
          <>
            <StatContainer>

              <TYPE.white fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
                Mission Recon is over!
              </TYPE.white>
              {isStaking && (
                <TYPE.white fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
                  Recall your Crxillions.
                </TYPE.white>
              )}

            </StatContainer>

            {isStaking && adventureInfo?.status !== ADVENTURE_STATUS.FINISHED && (
              <>
                <Break style={{ width: '80%', margin: '16px auto auto' }} />
                <StatContainer>

                  <RowBetween>
                    <TYPE.white> Your Crxillions </TYPE.white>


                    <TYPE.white style={{ display: 'flex' }}>
                      {adventureInfo?.userTokenList?.length?.toString()}
                      <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={CRXILLION} size={'20px'} />
                    </TYPE.white>

                  </RowBetween>


                  <RowBetween>
                    <TYPE.white> Your Covered Range </TYPE.white>
                    <TYPE.black fontSize={16} fontWeight={500}>
                      {adventureInfo?.userTicketCount?.toString()}
                      <span role="img" aria-label="wizard-icon" style={{ marginLeft: '8px' }}>
                        📍
                      </span>
                    </TYPE.black>
                  </RowBetween>


                  <RowBetween>
                    <TYPE.white> Your Success Chance </TYPE.white>
                    <TYPE.black fontSize={16} fontWeight={500}>
                      {adventureInfo?.userShare?.toFixed(2)}
                      %
                    </TYPE.black>
                  </RowBetween>
                </StatContainer>
              </>
            )}

            <Break style={{ marginTop: '1rem' }}></Break>

            <StatContainer style={{ marginBottom: '0rem' }}>
              <div style={{ margin: 'auto', display: 'flex' }}>

                {isStaking && (
                  <ButtonPrimary
                    padding="12px 12px 12px 20px"
                    borderRadius="8px"
                    margin="6px"
                    paddingLeft="18px"
                    width="fit-content"
                    onClick={() => setShowWithdrawModal(true)}
                  >
                    Recall
                    <IconWrapper size={32} style={{ marginLeft: '8px' }}>
                      <img src={RecallIcon} alt={'recall logo'} />
                    </IconWrapper>
                  </ButtonPrimary>
                )}

              </div>
            </StatContainer>
          </>
        )}
        <ArrivalAdventureMechanicsModal
          isOpen={showMechanicsModal}
          onDismiss={() => setShowMechanicsModal(false)}
        />

        <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
          <SettingsSuggestRoundedIcon
            sx={{ marginRight: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
          />
          <TYPE.white onClick={() => setShowMechanicsModal(true)}
            fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}>
            Read the mechanics of the adventure here
          </TYPE.white>
        </div>

      </Cards>
    </div>
  )
}
