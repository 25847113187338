import * as React from 'react'
import { LightCard } from '../../components/Card'
import styled from 'styled-components'
import image from '../../assets/images/crxArtifact.png'
import { TYPE } from '../../theme'
import Grid from '@mui/material/Grid'
import { isMobile } from 'react-device-detect';

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: transparent;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1.5px solid #0d0c20;
`
const IMG = styled.img`
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  width: 200px;
`
export default function Auction() {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Cards sx={{ display: 'flex', background: '#000000a3 !important' }}>
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 12 : 9} style={{ paddingLeft: '0' }}>
                <TYPE.white fontWeight={600} fontSize={30} style={{ marginLeft: '20px' }}>
                  Welcome to AUCTION!
                </TYPE.white>
                <TYPE.white
                  fontWeight={500}
                  fontSize={14}
                  style={{ marginLeft: '20px', marginTop: '16px', lineHeight: '21px' }}
                >
                  Team CRODEX has been working behind the scenes in our upcoming CRX Metaverse Hub 2.0, that will bring
                  even deeper integration between our CRXILLION NFTs and DeFi applications.
                </TYPE.white>
              </Grid>
              {!isMobile &&
                <Grid item xs={3}>
                  <IMG src={image} alt="crx" style={{ width: 200 }} />
                </Grid>
              }
            </Grid>
          </Cards>
        </Grid>
      </Grid>
    </>
  )
}
