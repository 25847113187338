import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { useBoostedStakingRewardsContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'
import { LPBoostStakingInfo } from '../../state/boostStake/LPBoost'
import { JSBI } from '@cronosdex/sdk'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: LPBoostStakingInfo
}

export default function LPWithdrawModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useBoostedStakingRewardsContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    if (stakingContract && stakingInfo?.stakedAmount) {

      if (stakingInfo?.interactionInfo.payable) {
        const withdrawFee = stakingInfo?.interactionInfo?.withdrawFee
          ? JSBI.BigInt(
            stakingInfo?.interactionInfo?.withdrawFee * Math.pow(10, 18)
          ).toString()
          : 0
        setAttempting(true)
        await stakingContract
          .exit({value: withdrawFee})
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw deposited liquidity`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        setAttempting(true)
        await stakingContract
          .exit()
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw deposited liquidity`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      }

    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw LP and Claim</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Deposited liquidity</TYPE.body>
            </AutoColumn>
          )}

          {stakingInfo?.earnedAmount && stakingInfo?.maxBoosterCount > 0 &&  (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36} style={{ textAlign: 'center' }}>
                <FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />
                <br></br>
                {` + `}
                <br></br>
                <FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedFromBoostAmount} />
              </TYPE.body>
              <TYPE.body>Unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.body>
            </AutoColumn>
          )}

          {stakingInfo?.earnedAmount && stakingInfo?.maxBoosterCount === 0 &&  (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36} style={{ textAlign: 'center' }}>
                <FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />
              </TYPE.body>
              <TYPE.body>Unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.body>
            </AutoColumn>
          )}
          

          <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your {stakingInfo?.rewardToken.symbol} is claimed and your liquidity is removed from the
            mining pool.
          </TYPE.subHeader>
          <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
            {error ?? 'Withdraw & Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} CRX-V2</TYPE.body>
            <TYPE.body fontSize={20}>
              Claiming {stakingInfo?.earnedAmount?.add(stakingInfo?.earnedFromBoostAmount).toSignificant(4)} {stakingInfo?.rewardToken.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew CRX-V2!</TYPE.body>
            <TYPE.body fontSize={20}>Claimed {stakingInfo?.rewardToken.symbol}!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
