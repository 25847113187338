import React, { useCallback, useState, useContext } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, ETHER, Fraction } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ExternalLink, TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'

import CurrencyLogo from '../../components/CurrencyLogo'
import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { EBISUSBAY } from '../../constants/collections'
import { useNFTPortfolio, useNFTUserDeposits } from '../../state/nftPortfolio/hooks'
import NFTCards from '../../components/nftStakingModal/NFTCards'
import { useLPRewardsV2Info } from '../../state/rewardsV2/lpRewardsv2'
import LPStakingV2Modal from '../../components/RewardsV2/LPStakingV2Modal'
import LPWithdrawV2Modal from '../../components/RewardsV2/LPWithdrawV2Modal'
import ClaimV2Modal from '../../components/RewardsV2/ClaimV2Modal'
import BoosterDepositV2Modal from '../../components/RewardsV2/BoosterDepositV2Modal'
import BoosterWithdrawV2Modal from '../../components/RewardsV2/BoosterWithdrawV2Modal'
import { LP_STATUS_TYPES } from '../../state/stake/hooks'
import { Dexscreener } from '../../state/dexscreener/DataContext'

const ButtonRainbowWrapper = styled(AutoColumn) <{ showBackground: boolean }>`
  margin: 10px auto;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  animation: 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s infinite normal none running example;
  @keyframes example {
    0% {
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 12x;
    }
    10% {
      box-shadow: rgb(255, 154, 0) 0px 0px 6px;
    }
    20% {
      box-shadow: rgb(208, 222, 33) 0px 0px 6px;
    }
    30% {
      box-shadow: rgb(79, 220, 74) 0px 0px 6px;
    }
    40% {
      box-shadow: rgb(63, 218, 216) 0px 0px 6px;
    }
    50% {
      box-shadow: rgb(47, 201, 226) 0px 0px 6px;
    }
    60% {
      box-shadow: rgb(28, 127, 238) 0px 0px 6px;
    }
    70% {
      box-shadow: rgb(95, 21, 242) 0px 0px 6px;
    }
    80% {
      box-shadow: rgb(186, 12, 248) 0px 0px 6px;
    }
    90% {
      box-shadow: rgb(251, 7, 217) 0px 0px 6px;
    }
    100% {
      background-color: rgb(255, 0, 0) 0px 0px 6px;
    }
  }
  border-radius: 12px;
  width: 90%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
`


const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledBoosterCard = styled(DataCard)<{ dim: any }>`
  background: #080812;
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 2;
`

const StyledLiqCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const InfoLink = styled(ExternalLink)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bg3};
  padding: 6px 6px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`

const StyledRewardCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg7};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
`

const PoolData = styled(DataCard)`
  background: none;
  padding: 1rem;
  z-index: 1;
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function LPRewardsV2Manage({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account, chainId } = useActiveWeb3React()

  // get currencies and pair
  const stakingInfo = useLPRewardsV2Info(rewardAddr)?.[0]
  const nftPortfolio = useNFTPortfolio(stakingInfo?.collection)
  const boosterInfo = useNFTUserDeposits(stakingInfo?.collection, stakingInfo?.boosters)

  const currencyA = stakingInfo?.tokens[0]
  const currencyB = stakingInfo?.tokens[1]
  // const [currencyA, currencyB] = [useCurrency(stakingInfo?.tokens[0].symbol), useCurrency(stakingInfo?.tokens[1].symbol)]

  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = usePair(tokenA, tokenB)

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showLPStakingModal, setShowLPStakingModal] = useState(false)
  const [showLPWithdrawModal, setShowLPWithdrawModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const [showBoosterNFTStakingModal, setBoosterNFTStakingModal] = useState(false)
  const [showBoosterNFTWithdrawModal, setBoosterNFTWithdrawModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  // const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const WETH = currencyA === ETHER ? tokenA : tokenB

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken?.greaterThan('0') && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by CRO value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  } else {
    valueOfTotalStakedAmountInWETH = undefined
  }

  const countUpAmount = stakingInfo?.pendingReward?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  const countUpBoosterAmount = stakingInfo?.pendingRewardFromBoost?.toFixed(6) ?? '0'
  const countUpBoosterAmountPrevious = usePrevious(countUpBoosterAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)


    const userRewardinUSDC = (stakingInfo?.userShare && valueOfTotalStakedAmountInUSDC) ? valueOfTotalStakedAmountInUSDC?.multiply( parseInt( (100000 * parseFloat(stakingInfo?.userShare.toFixed(6))).toString() ).toString() ).divide("100000") : new Fraction('0')
    const toggleWalletModal = useWalletModalToggle()

  const handleLPDepositClick = useCallback(() => {
    if (account) {
      setShowLPStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTDepositClick = useCallback(() => {
    if (account) {
      setBoosterNFTStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTWithdrawClick = useCallback(() => {
    if (account) {
      setBoosterNFTWithdrawModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])


  const rewardTokenPrice = useUSDCPrice(stakingInfo?.rewardToken)

  const unclaimedTokenAmountinUSDC = stakingInfo?.pendingRewardFromBoost.add(stakingInfo?.pendingReward).multiply(rewardTokenPrice?.adjusted ?? '0')

  const lpAddress =  stakingTokenPair?.liquidityToken.address
  const dexscreenerData = useContext(Dexscreener)
  //  APR FROM LIQ //
  const pairInfo = usePair(stakingInfo?.tokens[0]!, stakingInfo?.tokens[1]!)?.[1]
  const token0reserve = pairInfo ? parseFloat(pairInfo.reserve0.toFixed()) : 0
  const token0price = useUSDCPrice(pairInfo?.token0)
  const token0priceStr = token0price ? `${token0price.toFixed(18)}` : `0`
  const token0priceUSD = parseFloat(token0priceStr)
  const pairReserve = token0reserve * token0priceUSD * 2
  let liqAPR  = 0.09
  if ( lpAddress && dexscreenerData[lpAddress] ){
    liqAPR = (dexscreenerData[lpAddress].volume.h24 * 0.003 * 365 ) / pairReserve 
    liqAPR += dexscreenerData[lpAddress].aprBase / 100
  }

  const rewardAPR =  (parseFloat(stakingInfo?.rewardPerDay?.multiply("365").multiply(rewardTokenPrice?.adjusted ?? '0').divide(valueOfTotalStakedAmountInUSDC?.greaterThan('0') ? valueOfTotalStakedAmountInUSDC:  '1').toFixed(2)))
  const totalAPR = liqAPR + rewardAPR
  const estimatedAPY = (Math.pow( 1 + totalAPR /  365, 365) - 1)


  const hasNFTs = nftPortfolio.length > 0 ? true : false
  const hasBooster = stakingInfo?.boosters.length > 0 ? true : false
  const hasDeposit = stakingInfo?.stakedAmount?.greaterThan('0')
  const hasReward = stakingInfo?.pendingReward?.greaterThan('0')

  const userBoostPerc = (stakingInfo?.boostPermillage / stakingInfo?.boostDiv) * stakingInfo?.boosters.length * 100

  const isRewardEnded = stakingInfo?.status === LP_STATUS_TYPES.FINISHED

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Stake {currencyA?.symbol}-{currencyB?.symbol} LP to earn{' '}
          {stakingInfo?.rewardToken.symbol}
        </TYPE.mediumHeader>

        <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
        {' → '}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} CRO`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>

        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR → APY</TYPE.body>
            {!isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}>
                <Tooltip
                  title={
                    <div>
                      <TYPE.white> From Rewards: {Math.ceil(rewardAPR*100)}% </TYPE.white>{' '}
                      <TYPE.white> From Swap Fees: {Math.ceil(liqAPR*100)}%</TYPE.white>
                    </div>
                  }
                >
                  <IconButton sx={{ paddingTop: '7px' }}>
                    <HelpIcon sx={{ fontSize: 16, color: '#5b5eb1' }} />
                  </IconButton>
                </Tooltip>
                {Math.ceil(totalAPR*100)}% → {Math.ceil(estimatedAPY*100)}%
              </TYPE.body>
            )}
            {isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}>
                {' '}
                {0}%
              </TYPE.body>
            )}
          </AutoColumn>
        </PoolData>
      </DataRow>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {stakingInfo?.rewardPerDay?.toFixed(0, { groupSeparator: ',' })}
              {` ${stakingInfo?.rewardToken.symbol} / day`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Max. Boost</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {`${stakingInfo?.maxBoosterCount} NFTs x ${(stakingInfo?.boostPermillage / stakingInfo?.boostDiv) *
                100}% = ${(stakingInfo?.boostPermillage / stakingInfo?.boostDiv) *
                stakingInfo?.maxBoosterCount *
                100}%  `}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>

      {!hasNFTs && stakingInfo?.projectInfo?.ebisusBay && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Buy {stakingInfo?.collection.symbol} NFTs</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.collection.symbol} NFTs to earn more ${stakingInfo?.rewardToken.symbol}.`}
                </TYPE.white>
              </RowBetween>
              <a
                href={stakingInfo?.projectInfo.ebisusBay}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
                  <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '2px' }} />
                  {`Buy ${stakingInfo?.collection.symbol} at Ebisu's Bay`}
                </ButtonPrimary>
              </a>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {showAddLiquidityButton && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 2. Get CRX-V2 Liquidity tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`CRX-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
              >
                <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={36} />
                &nbsp;&nbsp;&nbsp;
                {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <LPStakingV2Modal
            isOpen={showLPStakingModal}
            onDismiss={() => setShowLPStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <LPWithdrawV2Modal
            isOpen={showLPWithdrawModal}
            onDismiss={() => setShowLPWithdrawModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <ClaimV2Modal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />

          <BoosterDepositV2Modal
            isOpen={showBoosterNFTStakingModal}
            onDismiss={() => setBoosterNFTStakingModal(false)}
            stakingInfo={stakingInfo}
            portfolioInfo={nftPortfolio}
          />

          <BoosterWithdrawV2Modal
            isOpen={showBoosterNFTWithdrawModal}
            onDismiss={() => setBoosterNFTWithdrawModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <TYPE.main style={{ textAlign: 'center' }} fontSize={19}>
        <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
          ⚠️
        </span>
        Deposit your boosters before depositing tokens.
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: '#acaed9', textDecoration: 'none' }}
          href={
            'https://crodex.medium.com/crodex-introduces-yet-another-utility-for-your-nfts-on-cronos-reward-boosting-fd5dfc2ce6d6'
          }
        >
          {' '}
          Learn more↗
        </a>
      </TYPE.main>

      <PositionInfo gap="lg" justify="center" dim={false}>
        <BottomSection gap="lg" justify="center">
          <StyledBoosterCard dim={!hasBooster}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your booster deposits</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={25} fontWeight={600}>
                  {stakingInfo?.boosters.length} {stakingInfo?.collection.name}
                </TYPE.white>
                <TYPE.white fontWeight={600}>{userBoostPerc}% Boost</TYPE.white>
              </RowBetween>
            </AutoColumn>
            <AutoColumn gap="sm">
              <RowBetween>
                <NFTCards
                  visible={hasBooster}
                  stakingInfo={stakingInfo}
                  portfolioInfo={boosterInfo}
                  maxBoosterCount={stakingInfo?.maxBoosterCount}
                />
              </RowBetween>
            </AutoColumn>
          </StyledBoosterCard>

          <StyledLiqCard dim={!hasDeposit}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your liquidity deposits</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                </TYPE.white>
                <TYPE.white>
                  CRX-V2 {currencyA?.symbol}-{currencyB?.symbol}
                </TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`$${userRewardinUSDC?.toFixed(4, { groupSeparator: ',' })}`}
                </TYPE.white>
                <TYPE.white> ≈ USDC</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledLiqCard>

          <StyledRewardCard dim={!hasReward}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.pendingReward && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.pendingReward?.raw) && (
                  <ButtonRainbowWrapper showBackground={true} style={{ width: '20%', marginRight: '0px'}}>
                    <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonPrimary>
                  </ButtonRainbowWrapper>

                )}
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.userRewardRate?.toFixed(4) ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / day` }
                </TYPE.black>
              </RowBetween>

              {hasBooster && (
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.largeHeader fontSize={36} fontWeight={600}>
                    <CountUp
                      key={countUpBoosterAmount}
                      isCounting
                      decimalPlaces={4}
                      start={parseFloat(countUpBoosterAmountPrevious)}
                      end={parseFloat(countUpBoosterAmount)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.largeHeader>

                  <TYPE.black fontSize={16} fontWeight={500}>
                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                      🚀
                    </span>
                    {stakingInfo?.userRewardBoostRate?.toFixed(4, { groupSeparator: ',' })}
                    {` ${stakingInfo?.rewardToken.symbol} / day`}
                  </TYPE.black>
                </RowBetween>
              )}

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${unclaimedTokenAmountinUSDC?.toFixed(4, { groupSeparator: ',' })}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledRewardCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you deposit or withdraw, the contract will automagically claim {stakingInfo?.rewardToken.symbol} on your behalf!
        </TYPE.main>

        {/* NFT STAKING SECTION */}

        <DataRow style={{ marginBottom: '1rem' }}>
          {!stakingInfo?.stakedAmount.greaterThan('0') && !hasBooster && nftPortfolio.length > 0 && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTDepositClick}
            >
              Deposit <br></br> Boosters
            </ButtonPrimary>
          )}

          {hasBooster && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTWithdrawClick}
            >
              Withdraw <br></br> Boosters
            </ButtonPrimary>
          )}

          {!showAddLiquidityButton && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleLPDepositClick}>
              Deposit LP <br></br> Tokens
            </ButtonPrimary>
          )}

          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={() => setShowLPWithdrawModal(true)}>
              Withdraw LP <br></br> Tokens
            </ButtonPrimary>
          )}
        </DataRow>

        <DataRow style={{ marginBottom: '1rem' }}>
          <InfoLink href={'https://dexscreener.com/cronos/' + pairInfo?.liquidityToken.address} target="_blank">
            View pair analytics ↗
          </InfoLink>
        </DataRow>
      </PositionInfo>
    </PageWrapper>
  )
}
