import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Fraction, JSBI } from '@cronosdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { CountUp } from 'use-count-up'

import { currencyId } from '../../utils/currencyId'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'

import CurrencyLogo from '../../components/CurrencyLogo'
import { EBISUSBAY } from '../../constants/collections'
import { useNFTPortfolio, useNFTUserDeposits } from '../../state/nftPortfolio/hooks'
import NFTCards from '../../components/nftStakingModal/NFTCards'
import ClaimV2Modal from '../../components/RewardsV2/ClaimV2Modal'
import BoosterDepositV2Modal from '../../components/RewardsV2/BoosterDepositV2Modal'
import BoosterWithdrawV2Modal from '../../components/RewardsV2/BoosterWithdrawV2Modal'
import { useSSRewardsV2Info } from '../../state/rewardsV2/ssRewardsv2'
import SStakingV2Modal from '../../components/RewardsV2/SStakingV2Modal'
import SSWithdrawV2Modal from '../../components/RewardsV2/SSWithdrawV2Modal'
import { SS_POOL_STATUS_TYPES } from '../../state/singleStake/hooks'

const ButtonRainbowWrapper = styled(AutoColumn) <{ showBackground: boolean }>`
  margin: 10px auto;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  animation: 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s infinite normal none running example;
  @keyframes example {
    0% {
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 12x;
    }
    10% {
      box-shadow: rgb(255, 154, 0) 0px 0px 6px;
    }
    20% {
      box-shadow: rgb(208, 222, 33) 0px 0px 6px;
    }
    30% {
      box-shadow: rgb(79, 220, 74) 0px 0px 6px;
    }
    40% {
      box-shadow: rgb(63, 218, 216) 0px 0px 6px;
    }
    50% {
      box-shadow: rgb(47, 201, 226) 0px 0px 6px;
    }
    60% {
      box-shadow: rgb(28, 127, 238) 0px 0px 6px;
    }
    70% {
      box-shadow: rgb(95, 21, 242) 0px 0px 6px;
    }
    80% {
      box-shadow: rgb(186, 12, 248) 0px 0px 6px;
    }
    90% {
      box-shadow: rgb(251, 7, 217) 0px 0px 6px;
    }
    100% {
      background-color: rgb(255, 0, 0) 0px 0px 6px;
    }
  }
  border-radius: 12px;
  width: 90%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
`


const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledBoosterCard = styled(DataCard) <{ dim: any }>`
  background: #080812;
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 2;
`

const StyledLiqCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const StyledRewardCard = styled(DataCard) <{ dim: any }>`
  background: ${({ theme }) => theme.bg7};
  opacity: ${({ dim }) => (dim ? 0.8 : 1)};
  margin-top: -40px;
  margin-bottom: 5px;
  padding: 0 1.25rem 2rem 1.25rem;
  padding-top: 32px;
`

const PoolData = styled(DataCard)`
  background: none;
  padding: 1rem;
  z-index: 1;
  background: #1f1243cc;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function SSRewardsV2Manage({
  match: {
    params: { rewardAddr }
  }
}: RouteComponentProps<{ rewardAddr: string }>) {
  const { account } = useActiveWeb3React()

  // get currencies and pair
  const stakingInfo = useSSRewardsV2Info(rewardAddr)?.[0]
  const nftPortfolio = useNFTPortfolio(stakingInfo?.collection)
  const boosterInfo = useNFTUserDeposits(stakingInfo?.collection, stakingInfo?.boosters)

  const currency = stakingInfo?.token
  const stakingTokenPrice = useUSDCPrice(stakingInfo?.token)

  const valueOfTotalStakedAmountInUSDC = stakingInfo?.totalAmount?.multiply(stakingTokenPrice?.adjusted ?? '0') ?? new Fraction('0')

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showSStakingModal, setShowSStakingModal] = useState(false)
  const [showSSWithdrawModal, setShowSSWithdrawModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const [showBoosterNFTStakingModal, setBoosterNFTStakingModal] = useState(false)
  const [showBoosterNFTWithdrawModal, setBoosterNFTWithdrawModal] = useState(false)


  const countUpAmount = stakingInfo?.pendingReward?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  const countUpBoosterAmount = stakingInfo?.pendingRewardFromBoost?.toFixed(6) ?? '0'
  const countUpBoosterAmountPrevious = usePrevious(countUpBoosterAmount) ?? '0'

  const userRewardinUSDC = (stakingInfo?.userShare && valueOfTotalStakedAmountInUSDC) ? valueOfTotalStakedAmountInUSDC?.multiply(parseInt((100000 * parseFloat(stakingInfo?.userShare.toFixed(6))).toString()).toString()).divide("100000") : new Fraction('0')
  const toggleWalletModal = useWalletModalToggle()

  const handleLPDepositClick = useCallback(() => {
    if (account) {
      setShowSStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTDepositClick = useCallback(() => {
    if (account) {
      setBoosterNFTStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleBoosterNFTWithdrawClick = useCallback(() => {
    if (account) {
      setBoosterNFTWithdrawModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])


  const rewardTokenPrice = useUSDCPrice(stakingInfo?.rewardToken)

  const unclaimedTokenAmountinUSDC = stakingInfo?.pendingRewardFromBoost.add(stakingInfo?.pendingReward).multiply(rewardTokenPrice?.adjusted ?? '0')


  const rewardAPR = valueOfTotalStakedAmountInUSDC && valueOfTotalStakedAmountInUSDC.greaterThan('0') ? (parseFloat(stakingInfo?.rewardPerDay?.multiply("365").multiply(rewardTokenPrice?.adjusted ?? '0').divide(valueOfTotalStakedAmountInUSDC ?? '1').toFixed(2))) : 0
  const estimatedAPY = (Math.pow(1 + rewardAPR / 365, 365) - 1)


  const hasNFTs = nftPortfolio.length > 0 ? true : false
  const hasBooster = stakingInfo?.boosters.length > 0 ? true : false
  const hasDeposit = stakingInfo?.stakedAmount?.greaterThan('0')
  const hasReward = stakingInfo?.pendingReward?.greaterThan('0')

  const userBoostPerc = (stakingInfo?.boostPermillage / stakingInfo?.boostDiv) * stakingInfo?.boosters.length * 100

  const isRewardEnded = stakingInfo?.status === SS_POOL_STATUS_TYPES.FINISHED

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Stake {stakingInfo?.token.symbol} to earn{' '}
          {stakingInfo?.rewardToken.symbol}
        </TYPE.mediumHeader>

        <CurrencyLogo currency={currency ?? undefined} size={'24px'} />
        {' → '}
        <CurrencyLogo currency={stakingInfo?.rewardToken ?? undefined} size={'24px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {`$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>

        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>APR → APY</TYPE.body>
            {!isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}>
                {Math.ceil(rewardAPR * 100)}% → {Math.ceil(estimatedAPY * 100)}%
              </TYPE.body>
            )}
            {isRewardEnded && (
              <TYPE.body fontSize={18} fontWeight={500}>
                {' '}
                {0}%
              </TYPE.body>
            )}
          </AutoColumn>
        </PoolData>
      </DataRow>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {stakingInfo?.rewardPerDay?.toFixed(0, { groupSeparator: ',' })}
              {` ${stakingInfo?.rewardToken.symbol} / day`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Max. Boost</TYPE.body>
            <TYPE.body fontSize={18} fontWeight={500}>
              {`${stakingInfo?.maxBoosterCount} NFTs x ${(stakingInfo?.boostPermillage / stakingInfo?.boostDiv) *
                100}% = ${(stakingInfo?.boostPermillage / stakingInfo?.boostDiv) *
                stakingInfo?.maxBoosterCount *
                100}%  `}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>

      {!hasNFTs && stakingInfo?.projectInfo?.ebisusBay && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Buy {stakingInfo?.collection.symbol} NFTs</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.collection.symbol} NFTs to earn more ${stakingInfo?.rewardToken.symbol}.`}
                </TYPE.white>
              </RowBetween>
              <a
                href={stakingInfo?.projectInfo.ebisusBay}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
                  <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '2px' }} />
                  {`Buy ${stakingInfo?.collection.symbol} at Ebisu's Bay`}
                </ButtonPrimary>
              </a>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {showAddLiquidityButton && (
        <VoteCard
          style={{
            background: '#1f1243cc',
            boxShadow: 'rgb(221 231 231 / 49%) -1px -1px 3px 2px inset',
            border: ' 1.5px solid rgb(13, 12, 32)'
          }}
        >          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 2. Get ${stakingInfo?.token.symbol} tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Stake your ${stakingInfo?.token.symbol} tokens to earn more ${stakingInfo?.rewardToken.symbol}. No need to worry about impermanent loss!`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/swap/?outputCurrency=${stakingInfo?.token && currencyId(stakingInfo?.token)}`}
              >
                <CurrencyLogo currency={stakingInfo?.token} size={'36px'} />
                &nbsp;&nbsp;&nbsp;
                {`Buy ${stakingInfo?.token.symbol} token`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <SStakingV2Modal
            isOpen={showSStakingModal}
            onDismiss={() => setShowSStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <SSWithdrawV2Modal
            isOpen={showSSWithdrawModal}
            onDismiss={() => setShowSSWithdrawModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />

          <ClaimV2Modal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />

          <BoosterDepositV2Modal
            isOpen={showBoosterNFTStakingModal}
            onDismiss={() => setBoosterNFTStakingModal(false)}
            stakingInfo={stakingInfo}
            portfolioInfo={nftPortfolio}
          />

          <BoosterWithdrawV2Modal
            isOpen={showBoosterNFTWithdrawModal}
            onDismiss={() => setBoosterNFTWithdrawModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <TYPE.main style={{ textAlign: 'center' }} fontSize={19}>
        <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
          ⚠️
        </span>
        Deposit your boosters before depositing tokens.
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: '#acaed9', textDecoration: 'none' }}
          href={
            'https://crodex.medium.com/crodex-introduces-yet-another-utility-for-your-nfts-on-cronos-reward-boosting-fd5dfc2ce6d6'
          }
        >
          {' '}
          Learn more↗
        </a>
      </TYPE.main>

      <PositionInfo gap="lg" justify="center" dim={false}>
        <BottomSection gap="lg" justify="center">
          <StyledBoosterCard dim={!hasBooster}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your booster deposits</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={25} fontWeight={600}>
                  {stakingInfo?.boosters.length} {stakingInfo?.collection.name}
                </TYPE.white>
                <TYPE.white fontWeight={600}>{userBoostPerc}% Boost</TYPE.white>
              </RowBetween>
            </AutoColumn>
            <AutoColumn gap="sm">
              <RowBetween>
                <NFTCards
                  visible={hasBooster}
                  stakingInfo={stakingInfo}
                  portfolioInfo={boosterInfo}
                  maxBoosterCount={stakingInfo?.maxBoosterCount}
                />
              </RowBetween>
            </AutoColumn>
          </StyledBoosterCard>

          <StyledLiqCard dim={!hasDeposit}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your token deposits</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                </TYPE.white>
                <TYPE.white>
                  {currency?.symbol}
                </TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.white fontSize={36} fontWeight={600}>
                  {`$${userRewardinUSDC?.toFixed(4, { groupSeparator: ',' })}`}
                </TYPE.white>
                <TYPE.white> ≈ USDC</TYPE.white>
              </RowBetween>
            </AutoColumn>
          </StyledLiqCard>

          <StyledRewardCard dim={!hasReward}>
            <CardBGImage desaturate />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your unclaimed {stakingInfo?.rewardToken.symbol}</TYPE.black>
                </div>
                {stakingInfo?.pendingReward && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.pendingReward?.raw) && (
                  <ButtonRainbowWrapper showBackground={true} style={{ width: '20%', marginRight: '0px' }}>
                    <ButtonPrimary
                      padding="8px"
                      borderRadius="8px"
                      onClick={() => setShowClaimRewardModal(true)}
                    >
                      Claim
                    </ButtonPrimary>
                  </ButtonRainbowWrapper>

                )}
              </RowBetween>

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.userRewardRate?.toFixed(4) ?? '-'}
                  {` ${stakingInfo?.rewardToken.symbol} / day`}
                </TYPE.black>
              </RowBetween>

              {hasBooster && (
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.largeHeader fontSize={36} fontWeight={600}>
                    <CountUp
                      key={countUpBoosterAmount}
                      isCounting
                      decimalPlaces={4}
                      start={parseFloat(countUpBoosterAmountPrevious)}
                      end={parseFloat(countUpBoosterAmount)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.largeHeader>

                  <TYPE.black fontSize={16} fontWeight={500}>
                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                      🚀
                    </span>
                    {stakingInfo?.userRewardBoostRate?.toFixed(4, { groupSeparator: ',' })}
                    {` ${stakingInfo?.rewardToken.symbol} / day`}
                  </TYPE.black>
                </RowBetween>
              )}

              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.black fontSize={36} fontWeight={600}>
                  {`$${unclaimedTokenAmountinUSDC?.toFixed(4, { groupSeparator: ',' })}`}
                </TYPE.black>
                <TYPE.black> ≈ USDC</TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledRewardCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you deposit or withdraw, the contract will automagically claim {stakingInfo?.rewardToken.symbol} on your behalf!
        </TYPE.main>

        {/* NFT STAKING SECTION */}

        <DataRow style={{ marginBottom: '1rem' }}>
          {!stakingInfo?.stakedAmount.greaterThan('0') && !hasBooster && nftPortfolio.length > 0 && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTDepositClick}
            >
              Deposit <br></br> Boosters
            </ButtonPrimary>
          )}

          {hasBooster && (
            <ButtonPrimary
              style={{ boxShadow: '#9495afd9 0px 0px 6px 3px' }}
              padding="8px"
              borderRadius="8px"
              width="160px"
              onClick={handleBoosterNFTWithdrawClick}
            >
              Withdraw <br></br> Boosters
            </ButtonPrimary>
          )}

          {!showAddLiquidityButton && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleLPDepositClick}>
              Deposit <br></br> Tokens
            </ButtonPrimary>
          )}

          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={() => setShowSSWithdrawModal(true)}>
              Withdraw <br></br> Tokens
            </ButtonPrimary>
          )}
        </DataRow>

      </PositionInfo>
    </PageWrapper>
  )
}
