import { useMemo } from 'react'
import { UNI } from '../../../constants/'
import { BURN_CRO_BEARS_AND_BULLS } from '../../../constants/collections'
import { useActiveWeb3React } from '../../../hooks'
import { useBullsAndBearsContract } from '../../../hooks/useContract'
import { useSingleCallResult } from '../../multicall/hooks'


export const BB_CONTRACT_ADDRESS = BURN_CRO_BEARS_AND_BULLS.address


export interface BBInfo {
    balanceOf: number | undefined
    totalSupply: number | undefined
    mintCountPerTx: number | undefined
    paused: boolean | undefined
    regPrice: number
    maxSupply: number
    mintDate: Date
}

// gets the staking info from the network for the active chain id
export function useBBInfo(): BBInfo {

    const { chainId, account } = useActiveWeb3React()

    const uni = chainId ? UNI[chainId] : undefined
    
    const bbContract = useBullsAndBearsContract(BB_CONTRACT_ADDRESS)

    const balanceOf = useSingleCallResult(bbContract, 'balanceOf', [account ?? undefined])

    const totalSupply = useSingleCallResult(bbContract, 'totalSupply')

    const mintCountPerTx = useSingleCallResult(bbContract, 'mintCountPerTx')

    const paused = useSingleCallResult(bbContract, 'paused')


    return useMemo(() => {

        let memo : BBInfo = {
            balanceOf: undefined,
            totalSupply: undefined,
            mintCountPerTx: undefined,
            paused: undefined,
            regPrice: 100,
            maxSupply: 10000,
            mintDate: new Date(Date.UTC(2024, 9, 9, 19, 0, 0, 0))
        }

        if (!chainId || !uni) return memo
        else {
            if (
                !balanceOf?.loading &&
                !totalSupply?.loading &&
                !paused?.loading  &&
                !mintCountPerTx?.loading
            ){
                if (
                    balanceOf?.error ||
                    totalSupply?.error ||
                    paused?.error ||
                    mintCountPerTx?.error
                ) {
                    console.error('Failed to load Bulls and Bears info')
                    return memo
                }
                memo.balanceOf = parseInt(balanceOf?.result?.[0].toString())
                memo.totalSupply = parseInt(totalSupply?.result?.[0].toString())
                memo.paused = paused?.result?.[0]
                memo.mintCountPerTx = parseInt(mintCountPerTx?.result?.[0].toString())

                return memo
            }
            return memo
        }
    }, [chainId, uni, balanceOf, totalSupply, paused, mintCountPerTx ])
}