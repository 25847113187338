import React, { useState, useContext } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink, ExternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ETHER, JSBI, TokenAmount } from '@cronosdex/sdk'
import { ButtonPrimary } from '../Button'
import { useColor } from '../../hooks/useColor'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
import CurrencyLogo from '../CurrencyLogo'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { Break } from '../earn/styled'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { LPRewardsV2Info } from '../../state/rewardsV2/lpRewardsv2'
import { LP_STATUS_TYPES } from '../../state/stake/hooks'
import { Dexscreener } from '../../state/dexscreener/DataContext'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: #1f1243cc;
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  box-shadow: ${({ theme, showBackground }) => (showBackground ? "inset #f9af00  -1px -1px 6px 3px" : 'inset #dde7e77d -1px -1px 3px 2px')} ;
  border: ${({ theme, showBackground }) => (showBackground ? '1.5px solid #f9af00' : '1.5px solid #0d0c20')};
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`


const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function LPRewardsV2Card({ rewardInfo }: { rewardInfo: LPRewardsV2Info }) {
  const token0 = rewardInfo.tokens[0]
  const token1 = rewardInfo.tokens[1]

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const isStaking = Boolean(rewardInfo?.stakedAmount.greaterThan('0') || rewardInfo?.boosters.length > 0)

  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const WETH = currency0 === ETHER ? token0 : token1
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(rewardInfo?.stakedAmount?.token)
  const [, stakingTokenPair] = usePair(...rewardInfo.tokens)
  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken?.greaterThan('0') && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(rewardInfo?.totalAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  } else {
    valueOfTotalStakedAmountInWETH = undefined
  }

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const lpAddress =  stakingTokenPair?.liquidityToken.address
  const dexscreenerData = useContext(Dexscreener)
  // // APR FROM LIQ //
  const pairInfo = usePair(rewardInfo?.tokens[0]!, rewardInfo?.tokens[1]!)?.[1]
  const token0reserve = pairInfo ? parseFloat(pairInfo.reserve0.toFixed()) : 0
  const token0price = useUSDCPrice(pairInfo?.token0)
  const token0priceStr = token0price ? `${token0price.toFixed(18)}` : `0`
  const token0priceUSD = parseFloat(token0priceStr)
  const pairReserve = token0reserve * token0priceUSD * 2
  let liqAPR  = 0.09
  if ( lpAddress && dexscreenerData[lpAddress] ){
    liqAPR = (dexscreenerData[lpAddress].volume.h24 * 0.003 * 365 ) / pairReserve 
    liqAPR += dexscreenerData[lpAddress].aprBase / 100
  }

  // const liqAPR = dexscreenerData[lpAddress] ? (dexscreenerData[lpAddress].Volume.h24 * 0.003 * 365 ) / pairReserve : 0.09
  // const liqAPR = dailyVolume ? (dailyVolume * 0.003 * 365 ) / pairReserve : 0.09
  const rewardTokenPrice = useUSDCPrice(rewardInfo?.rewardToken)
  const rewardAPR =  (parseFloat(rewardInfo?.rewardPerDay?.multiply("365").multiply(rewardTokenPrice?.adjusted ?? '0').divide(valueOfTotalStakedAmountInUSDC?.greaterThan('0') ? valueOfTotalStakedAmountInUSDC:  '1').toFixed(2)))
  const totalAPR = liqAPR + rewardAPR

  const estimatedAPY = (Math.pow( 1 + totalAPR /  365, 365) - 1)

  const isRewardEnded = rewardInfo?.status === LP_STATUS_TYPES.FINISHED ? true : false
  const hasBooster = rewardInfo?.boosters.length > 0 ? true : false

  // const [showTimerTooltip, setShowTimerTooltip] = useState(false);
  const [showAPRTooltip, setShowAPRTooltip] = useState(false);

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>

      <TopSection >
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
          {currency0.symbol}-{currency1.symbol}
          {/* <Tooltip
          open={showTimerTooltip}
          onOpen={() => setShowTimerTooltip(true)}
          onClose={() => setShowTimerTooltip(false)}
          title={<TYPE.white> {stakingInfo?.periodFinish?.toUTCString()} </TYPE.white>}>
            <IconButton sx={{ paddingTop: '7px' }}  onClick={() => setShowTimerTooltip(!showTimerTooltip)}>
              <QueryBuilderOutlinedIcon sx={{ fontSize: 16, color: "white" }} />
            </IconButton>
          </Tooltip> */}
        </TYPE.white>
        <StyledInternalLink to={`/rewards/lp/${rewardInfo?.stakingRewardAddress}`} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      { rewardInfo?.status === LP_STATUS_TYPES.HALTED && (
        <>
            <Break></Break>
            <ExternalLink href={`https://cronoscan.com/block/countdown/${rewardInfo?.startBlock}`} style={{ textAlign: 'center', marginBottom: '0.3rem', marginTop: '0.3rem'}}>
              <TYPE.white fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
                Remaining blocks for the reward distribution to start: {rewardInfo?.blockLefttoStart}
              </TYPE.white>
              <TimerOutlinedIcon
                sx={{ marginLeft: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
              />
            </ExternalLink>
        </>
      ) }
      <Break></Break>

      <StatContainer>
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>
            {valueOfTotalStakedAmountInUSDC
              ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
              : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} CRO`}
          </TYPE.white>
        </RowBetween>

        <RowBetween>
          <TYPE.white> Daily Reward Rate </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={rewardInfo?.rewardToken} size={'20px'} />
            {`${rewardInfo?.rewardPerDay?.toFixed(0, { groupSeparator: ',' })} ${rewardInfo?.rewardToken.symbol} / day`}
          </TYPE.white>
        </RowBetween>

        <RowBetween>
          <TYPE.white> Max. boost </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={rewardInfo?.collection} size={'20px'} />
            {`${rewardInfo?.maxBoosterCount} ${rewardInfo?.collection.name} x ${rewardInfo?.boostPermillage / rewardInfo?.boostDiv * 100}% = ${rewardInfo?.boostPermillage / rewardInfo?.boostDiv * rewardInfo?.maxBoosterCount * 100}%  `}
          </TYPE.white>
        </RowBetween>


        <RowBetween>
          <TYPE.white> APR → APY </TYPE.white>
          {!isRewardEnded && (

            <TYPE.white>
              <Tooltip
              open={showAPRTooltip}
              onOpen={() => setShowAPRTooltip(true)}
              onClose={() => setShowAPRTooltip(false)} 
              title={
                <div>
                  <TYPE.white> From Rewards: {Math.ceil(rewardAPR*100)}% </TYPE.white>{' '}
                  <TYPE.white> From Swap Fees: {Math.ceil(liqAPR*100)}%</TYPE.white>
                </div>
              }>
                <IconButton sx={{ paddingTop: '7px' }}>
                  <HelpOutlineIcon sx={{ fontSize: 16, color: "white" }} onClick={() => setShowAPRTooltip(!showAPRTooltip)} />
                </IconButton>
              </Tooltip>
              {Math.ceil(totalAPR*100)}% → {Math.ceil(estimatedAPY*100)}%
            </TYPE.white>
          )}
          {isRewardEnded && (
            <TYPE.white> 0%</TYPE.white>
          )}
        </RowBetween>
      </StatContainer>


      {!isRewardEnded && isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your reward rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${rewardInfo?.userRewardRate?.toFixed(2, { groupSeparator: ',' })} ${rewardInfo?.rewardToken.symbol} / day`}
            </TYPE.black>
          </BottomSection>
        </>
      )}

      {rewardInfo?.stakedAmount.greaterThan('0') && !isRewardEnded && hasBooster && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your boost rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                🚀
              </span>
              {`${rewardInfo?.userRewardBoostRate?.toFixed(2, { groupSeparator: ',' })} ${rewardInfo?.rewardToken.symbol} / day`}
            </TYPE.black>
          </BottomSection>
        </>
      )}


    </Wrapper>
  )
}
