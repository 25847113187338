import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import styledComp from 'styled-components'
import { ChainId } from '@cronosdex/sdk'
import { Text } from 'rebass'
import { NavLink, withRouter } from 'react-router-dom'

import Logo from '../../assets/svg/logo.png'
import LogoDark from '../../assets/svg/blacklogo.png'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { TYPE, ExternalLink } from '../../theme'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menus from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import Trade from '../../assets/images/switch.png'
import Staking from '../../assets/images/salary.png'
import Crowd from '../../assets/images/crowdIcon.png'
import MHUB from '../../assets/images/mhubIcon.png'
import SOCIALMEDIA from '../../assets/images/socialMediaIcon.png'
import LINK from '../../assets/images/link.png'
import TelegramIcon from '@mui/icons-material/Telegram'
import TwitterIcon from '@mui/icons-material/Twitter'
import DiscordIcon from '../../../src/assets/images/discord1.png'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import MediumIcon from '../../../src/assets/images/medium.png'
import GithubIcon from '../../../src/assets/images/github1.png'

const StyledSocialButton = styledComp.button`
display: none;
${({ theme }) => theme.mediaWidth.upToMedium`
display: flex;
align-items: left;
background-color: #5b5eb1;
padding: auto;
border-radius: 0.5rem;
margin-left: 5px;
text-align: center;
height: 35px;
align-items: center;
outline: none;
cursor: pointer;
text-decoration: none;
color:  white;
font-size: 1rem;
width: fit-content;
font-weight: 500;
background-color: transparent;

:hover,
:focus {
  color:  white;
}
svg {
  margin-top: 2px;
}

  `};
`
const StyledMenuIcon = styled(KeyboardArrowDownIcon)`
  margin: auto !important;
`

const HeaderFrame = styledComp.div`
display: grid;
grid-template-columns: 1fr 120px;
align-items: center;
justify-content: space-between;
align-items: center;
flex-direction: row;
width: 100%;
top: 0;
position: relative;
background: #1f1243cc;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);
padding: 0 1rem;
z-index: 2;
${({ theme }) => theme.mediaWidth.upToMedium`
display: flex;
  grid-template-columns: 1fr;
  padding: 0.5rem 1rem;
  width: calc(100%);
  position: relative;
`};

${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 0.5rem 1rem;
`}
`
const HeaderControls = styledComp.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  display: flex;
  `};
`

const HeaderElement = styledComp.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styledComp.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styledComp(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  
  `};
`
const MainHeaderLinks = styledComp(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  position: absolute;
  margin-left:20%;
  width: auto;
  display:none;
 `};
`
const AccountElement = styledComp.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`

const UNIAmount = styledComp(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styledComp.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styledComp.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styledComp(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styledComp(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const StyledMenus = styledComp(Menus)`
.MuiPaper-root{ 
  background-color:#0d0c208f;
  color:rgb(160,255,219);
  border-radius:15px;
  backdrop-filter: blur(20px);
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 30px 0px;
    border: 1px solid rgb(51, 52, 96);
    top:60px !important;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    top: calc(100% - 250px)!important;
   `};
}
`
const StyledStakeMenus = styledComp(Menus)`
.MuiPaper-root{ 
  background-color:#0d0c208f;
  color:rgb(160,255,219);
  border-radius:15px;
  backdrop-filter: blur(20px);
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 30px 0px;
    border: 1px solid rgb(51, 52, 96);
    top:60px !important;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    top: calc(100% - 300px)!important;
   `};
}
`
const StyledSocailMenus = styledComp(Menus)`
.MuiPaper-root{ 
  background-color:#0d0c208f;
  color:rgb(160,255,219);
  border-radius:15px;
  backdrop-filter: blur(20px);
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 30px 0px;
    border: 1px solid rgb(51, 52, 96);
    top: calc(100% - 340px)!important;
}
`
const StyledTallMenus = styledComp(Menus)`
.MuiPaper-root{ 
  background-color:#0d0c208f;
  color:rgb(160,255,219);
  border-radius:15px;
  backdrop-filter: blur(20px);
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 30px 0px;
    border: 1px solid rgb(51, 52, 96);
    top:60px !important;
}
`

const Title = styledComp.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styledComp.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  display: flex;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styledComp(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color:white;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  font-size: large;
}

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color:white;
  }

  :hover,
  :focus {
  }
`

const StyledNavPopLink = styledComp(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  width: fit-content;
  font-weight: 500;
  font-size: initial !important;
  margin: 0 18px !important;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color: white;
  }

  :hover,
  :focus {

  }
`
// const StyledNavSoonPopLink = styledComp.p`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: white;
//   width: fit-content;
//   font-weight: 500;
//   font-size: initial !important;
//   margin: 0 18px !important;
// `

const StyledHeaderText = styledComp.p`
font-family: "Inter var", sans-serif;
font-size: 16px;
.MuiTypography-root{
  font-family: "Inter var", sans-serif;
}
`

const StyledDivider = styled(Divider)`
  border-color: #333460 !important;
`

const BottomBarLink = styledComp(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color:white;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  font-size: large;
}

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color:white;
  }

  :hover,
  :focus {
  }
`
const StyledListItemText = styledComp(ListItemText)`
font-family: "Inter var", sans-serif;
.MuiTypography-root{
  font-family: "Inter var", sans-serif;
}
`
const MenuItemExternal = styledComp(ExternalLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  flex: 1;
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  width: 100%;
  font-weight: 500;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color: white;
  }
  :hover,
  :focus {
  }
`
const BottomBarControls = styledComp.div`
display:none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  display:flex;
  justify-content: space-around;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 65px;
    background-color: #221b46a1!important;
    backdrop-filter: blur(20px);
  `};
`

const StyledAppBar = styledComp(AppBar)`
background-color: #221b46a1!important;
.MuiAppBar-root{
  background-color: #221b46a1!important;
}
`

const StyledMenuButton = styledComp.button`
display: flex;
align-items: left;
border: none;
outline: none;
cursor: pointer;
text-decoration: none;
color:  white;
font-size: 1rem;
width: fit-content;
font-weight:500;
background-color: transparent;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color: white;
  }
  :hover,
  :focus {
  }
`
/*              {!availableClaim && aggregateBalance && JSBI.greaterThan(aggregateBalance.raw, JSBI.BigInt(0)) && (
                <UNIWrapper onClick={() => setShowUniBalanceModal(true)}>
                  <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                    <HideSmall>
                      <TYPE.white
                        style={{
                          paddingRight: '.4rem'
                        }}
                      >
                        {aggregateBalance?.toFixed(2, { groupSeparator: ',' })}
                      </TYPE.white>
                    </HideSmall>
                    {'CRX'}
                  </UNIAmount>
                </UNIWrapper>
              )}
              */
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.CRONOSMAINNET]: 'Cronos'
}

function Header({ history }: { history: any }) {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  //const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const node = useRef<HTMLDivElement>()
  const openNode = useModalOpen(ApplicationModal.REWARDS)
  const toggle = useToggleModal(ApplicationModal.REWARDS)

  useOnClickOutside(node, openNode ? toggle : undefined)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorElStake, setAnchorElStake] = React.useState<null | HTMLElement>(null)
  const [anchorElSocial, setAnchorElSocial] = React.useState<null | HTMLElement>(null)
  const [anchorElLink, setAnchorElLink] = React.useState<null | HTMLElement>(null)

  const [openTrade, setOpenTrade] = React.useState(false)
  const [openStake, setOpenStake] = React.useState(false)
  const [openSocial, setOpenSocial] = React.useState(false)
  const [openLink, setOpenLink] = React.useState(false)

  const handleClickTrade = (event: React.MouseEvent<HTMLElement>) => {
    if (openTrade === false) {
      setAnchorEl(event.currentTarget)
      setOpenTrade(true)
      setOpenStake(false)
      setOpenSocial(false)
    } else {
      setOpenTrade(false)
      setAnchorEl(null)
    }
  }

  const handleClickStake = (event: React.MouseEvent<HTMLElement>) => {
    if (openStake === false) {
      setAnchorElStake(event.currentTarget)
      setOpenStake(true)
      setOpenTrade(false)
      setOpenSocial(false)
      setOpenLink(false)
    } else {
      setOpenStake(false)
      setAnchorElStake(null)
    }
  }

  const handleClickSocial = (event: React.MouseEvent<HTMLElement>) => {
    if (openSocial === false) {
      setAnchorElSocial(event.currentTarget)
      setOpenSocial(true)
      setOpenStake(false)
      setOpenTrade(false)
      setOpenLink(false)
    } else {
      setOpenSocial(false)
      setAnchorElSocial(null)
    }
  }
  const handleClickLink = (event: React.MouseEvent<HTMLElement>) => {
    if (openLink === false) {
      setAnchorElLink(event.currentTarget)
      setOpenSocial(false)
      setOpenStake(false)
      setOpenTrade(false)
      setOpenLink(true)
    } else {
      setOpenLink(false)
      setAnchorElLink(null)
    }
  }

  return (
    <>
      <StyledAppBar position="relative">
        <HeaderFrame>
          <ClaimModal />
          <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
            <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
          </Modal>
          <HeaderRow>
            <Title href="." style={{}}>
              <UniIcon>
                <img width={'120px'} src={isDark ? LogoDark : Logo} alt="logo" />
              </UniIcon>
            </Title>
            <MainHeaderLinks>
              <StyledMenuButton onClick={handleClickTrade}>
                <StyledHeaderText
                  style={{
                    fontSize: '16px',
                    fontWeight:
                      history.location.pathname.includes('/swap') ||
                        history.location.pathname.includes('/pool') ||
                        history.location.pathname.includes('/add') ||
                        history.location.pathname.includes('/remove')
                        ? 900
                        : 'normal'
                  }}
                >
                  Trade
                </StyledHeaderText>
                <StyledMenuIcon />
              </StyledMenuButton>
              <StyledMenus
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorEl}
                open={openTrade}
                onClose={handleClickTrade}
                TransitionComponent={Fade}
              >
                <StyledNavPopLink
                  id={`swap-nav-link`}
                  to={'/swap'}
                  isActive={() => history.location.pathname.includes('/swap')}
                >
                  <p onClick={handleClickTrade}>Swap</p>
                </StyledNavPopLink>
                <StyledDivider />

                <StyledNavPopLink
                  id={`pool-nav-link`}
                  to={'/pool'}
                  isActive={() =>
                    history.location.pathname.includes('/pool') ||
                    history.location.pathname.includes('/add') ||
                    history.location.pathname.includes('/remove')
                  }
                >
                  <p onClick={handleClickTrade}>Liquidity</p>
                </StyledNavPopLink>

                <StyledDivider />
                <StyledNavPopLink
                  id={`zapper-nav-link`}
                  to={'/zapper'}
                  isActive={() => history.location.pathname.includes('/zapper')}
                >
                  <p onClick={handleClickTrade}>Zapper</p>
                </StyledNavPopLink>
              </StyledMenus>

              <StyledMenuButton onClick={handleClickStake}>
                <StyledHeaderText
                  style={{
                    fontSize: '16px',
                    fontWeight:
                      history.location.pathname.includes('/rewards/pair') ||
                        history.location.pathname.includes('/rewards/lp') ||
                        history.location.pathname.includes('/rewards/single') ||
                        history.location.pathname.includes('/rewards/ss') ||
                        history.location.pathname.includes('/rewards/nft') ||
                        history.location.pathname.includes('/xvault')
                        ? 900
                        : 'normal'
                  }}
                >
                  Stake
                </StyledHeaderText>
                <StyledMenuIcon />
              </StyledMenuButton>

              <StyledStakeMenus
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElStake}
                open={openStake}
                onClose={handleClickStake}
                TransitionComponent={Fade}
              >
                <StyledNavPopLink
                  id={`stake-nav-link`}
                  to={'/xvault'}
                  isActive={() => history.location.pathname.includes('/xvault')}
                >
                  <p onClick={handleClickStake}>xVault</p>
                </StyledNavPopLink>
                <StyledDivider />

                <StyledNavPopLink
                  id={`stake-nav-link`}
                  to={'/rewards/pair'}
                  isActive={() => history.location.pathname.includes('/rewards/pair')}
                >
                  <p onClick={handleClickStake}>LP Token</p>
                </StyledNavPopLink>
                <StyledDivider />
                <StyledNavPopLink
                  id={`stake-nav-link`}
                  to={'/rewards/single'}
                  isActive={() => history.location.pathname.includes('/rewards/single')}
                >
                  <p onClick={handleClickStake}>Single Token</p>
                </StyledNavPopLink>

                <StyledDivider />

                <StyledDivider />


                <StyledNavPopLink
                  id={`stake-nav-link`}
                  to={'/rewards/nft'}
                  isActive={() => history.location.pathname.includes('rewards/nft')}
                >
                  <p onClick={handleClickStake}>NFT</p>
                </StyledNavPopLink>
              </StyledStakeMenus>

              <StyledNavLink
                id={`ido-nav-link`}
                to={'/ido'}
                isActive={() => history.location.pathname.includes('/ido')}
              >
                <StyledHeaderText>CROWD</StyledHeaderText>
              </StyledNavLink>

              <StyledNavLink
                id={`mhub-nav-link`}
                to={'/mhub'}
                isActive={() => history.location.pathname.includes('/mhub')}
              >
                <StyledHeaderText>MHub</StyledHeaderText>
              </StyledNavLink>
              <StyledSocailMenus
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElSocial}
                open={openSocial}
                onClose={handleClickSocial}
                TransitionComponent={Fade}
              >
                <List component="div" disablePadding>
                  <ListItem sx={{ paddingLeft: '10px' }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://discord.com/invite/xSCzBRYGy4'}>
                      <ListItemIcon style={{ color: 'white' }}>
                        <img src={DiscordIcon} style={{ width: '24px', height: '24px' }} alt="logo" />
                      </ListItemIcon>
                      <StyledListItemText primary="Discord" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ paddingLeft: '10px' }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://t.me/crodexofficial'}>
                      <ListItemIcon style={{ color: 'white' }}>
                        <TelegramIcon />
                      </ListItemIcon>
                      <StyledListItemText primary="Telegram" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ paddingLeft: '10px' }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://twitter.com/crodexapp'}>
                      <ListItemIcon style={{ color: 'white' }}>
                        <TwitterIcon />
                      </ListItemIcon>
                      <StyledListItemText primary="Twitter" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ paddingLeft: '10px' }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://crodex.medium.com/'}>
                      <ListItemIcon style={{ color: 'white' }}>
                        <img src={MediumIcon} style={{ width: '23px', height: '23px' }} alt="logo" />
                      </ListItemIcon>
                      <StyledListItemText primary="Medium" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ paddingLeft: '10px' }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://github.com/CrodexApp'}>
                      <ListItemIcon style={{ color: 'white' }}>
                        <img src={GithubIcon} style={{ width: '24px', height: '24px' }} alt="logo" />
                      </ListItemIcon>
                      <StyledListItemText primary="Github" />
                    </MenuItemExternal>
                  </ListItem>
                </List>
              </StyledSocailMenus>
              <StyledTallMenus
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorElLink}
                open={openLink}
                onClose={handleClickLink}
                TransitionComponent={Fade}
              >
                <List component="div" disablePadding>
                  {/* <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://charts.crodex.app'}>
                      <StyledListItemText primary="Chart" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider /> */}
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://auditor.crodex.app/'}>
                      <StyledListItemText primary="Auditor" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  {/* <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://hibiki.finance/locker'}>
                      <StyledListItemText primary="Locker" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider /> */}
                  {/* <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://info.crodex.app/'}>
                      <StyledListItemText primary="Analytics" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider /> */}
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://app.relaychain.com/'}>
                      <StyledListItemText primary="Bridge - Relay" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://anyswap.exchange/#/router'}>
                      <StyledListItemText primary="Bridge - Any" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/SEwMUmrYjbwyvChh9'}>
                      <StyledListItemText primary="Form - Token WL" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/gAJiCLk5o7fwr6wW8'}>
                      <StyledListItemText primary="Form - CROWD IDO" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/ASJjuH3mYjbU2eeH6'}>
                      <StyledListItemText primary="Form - Metaverse Hub" />
                    </MenuItemExternal>
                  </ListItem>
                  <StyledDivider />
                  <ListItem sx={{ pl: 4 }}>
                    <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/FeV7rQKTeZaQyAza9'}>
                      <StyledListItemText primary="Form - Ambassador" />
                    </MenuItemExternal>
                  </ListItem>
                </List>
              </StyledTallMenus>
            </MainHeaderLinks>
          </HeaderRow>

          <HeaderControls>
            <HeaderElement>
              <HideSmall>
                {chainId && NETWORK_LABELS[chainId] && (
                  <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
                )}
              </HideSmall>
              {availableClaim && !showClaimPopup && (
                <UNIWrapper onClick={toggleClaimModal}>
                  <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                    <TYPE.white padding="0 2px">
                      {claimTxn && !claimTxn?.receipt ? <Dots>Claiming CRX</Dots> : 'Claim CRX'}
                    </TYPE.white>
                  </UNIAmount>
                </UNIWrapper>
              )}
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                {account && userEthBalance ? (
                  <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                    {userEthBalance?.toSignificant(4)} CRO
                  </BalanceText>
                ) : null}
                <Web3Status />
              </AccountElement>
            </HeaderElement>
            <HeaderElementWrap>
              <Settings />
            </HeaderElementWrap>
            <StyledSocialButton onClick={handleClickLink}>
              <img src={LINK} style={{ width: '20px', height: '20px' }}></img>
            </StyledSocialButton>
          </HeaderControls>
        </HeaderFrame>
      </StyledAppBar>
      <BottomBarControls>
        <div>
          <StyledMenuButton style={{ display: 'grid' }} onClick={handleClickTrade}>
            <img src={Trade} alt="img" style={{ margin: 'auto', marginBottom: '5px' }}></img>
            <span
              style={{
                fontSize: '12px',
                fontWeight:
                  history.location.pathname.includes('/swap') ||
                    history.location.pathname.includes('/pool') ||
                    history.location.pathname.includes('/add') ||
                    history.location.pathname.includes('/remove')
                    ? 900
                    : 'normal'
              }}
            >
              Trade
            </span>
          </StyledMenuButton>
        </div>
        <div>
          <StyledMenuButton style={{ display: 'grid' }} onClick={handleClickStake}>
            <img src={Staking} style={{ margin: 'auto', marginBottom: '5px' }}></img>
            <span
              style={{
                fontSize: '12px',
                fontWeight:
                  history.location.pathname.includes('/rewards/pair') ||
                    history.location.pathname.includes('/rewards/single') ||
                    history.location.pathname.includes('/rewards/nft') ||
                    history.location.pathname.includes('/xvault')
                    ? 900
                    : 'normal'
              }}
            >
              Stake
            </span>
          </StyledMenuButton>
        </div>
        <div>
          <BottomBarLink
            style={{ display: 'grid' }}
            id={`ido-nav-link`}
            to={'/ido'}
            isActive={() => history.location.pathname.includes('/ido')}
          >
            <img src={Crowd} style={{ margin: 'auto', marginBottom: '5px' }}></img>
            <span
              style={{
                fontSize: '12px',
                fontWeight: history.location.pathname.includes('/ido') ? 900 : 'normal'
              }}
            >
              CROWD
            </span>
          </BottomBarLink>
        </div>
        <div>
          <BottomBarLink
            style={{ display: 'grid' }}
            id={`mhub-nav-link`}
            to={'/mhub'}
            isActive={() => history.location.pathname.includes('/mhub')}
          >
            <img src={MHUB} style={{ margin: 'auto', width: '24px', height: '24px', marginBottom: '5px' }}></img>
            <span
              style={{
                fontSize: '12px',
                fontWeight: history.location.pathname.includes('/mhub') ? 900 : 'normal'
              }}
            >
              MHub
            </span>
          </BottomBarLink>
        </div>
        <div>
          <StyledMenuButton style={{ display: 'grid' }} onClick={handleClickSocial}>
            <img src={SOCIALMEDIA} style={{ margin: 'auto', marginBottom: '5px' }}></img>
            <span style={{ fontSize: '12px' }}>Socials</span>
          </StyledMenuButton>
        </div>
      </BottomBarControls>
    </>
  )
}
export default withRouter(Header)
