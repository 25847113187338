import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH } from '@cronosdex/sdk'
import { useMemo } from 'react'
import {
    BOO,
    CLMRS,
    CPB,
    CPBR,
    CROARMY,
    CRONK,
    CRX,
    CSAI,
    DNOT,
    DWL,
    GAT,
    GDRT,
    GREENSTIX_V2,
    ORC,
    PEPE,
    REYE,
    SCOOTER,
    STEAK,
    TATAS_TWISTED_TIME,
    UNI,
    WISH,
    WTS,
    XCRX,
} from '../../constants'
import { BOOSTED_STAKING_REWARDS_INTERFACE } from '../../constants/abis/boosted-staking-rewards'
import { CAPONEBEAR, CAT_WIF_GAT, CROLON_MARS, CRONK_OG, CRXILLION, GDRTOKEN_FOUNDERS, LA_BRIGADE_DE_CUISINE, LIL_SPERMY, RARE_PEPE_CULT, SHROOK_NFT, SPACE_CRXILLIONS, TIME_TROPS } from '../../constants/collections'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { CAPONEBEAR_INFO, CAT_WIF_GAT_INFO, CROLON_MARS_INFO, CRONK_OG_INFO, CRXILLION_INFO, GDRTOKEN_FOUNDERS_INFO, LA_BRIGADE_DE_CUISINE_INFO, LIL_SPERMY_INFO, ProjectInfo, RARE_PEPE_CULT_INFO, SHROOK_NFT_INFO, SPACE_CRXILLION_INFO, TIME_TROPS_INFO } from '../nftStake/projects'
import { SINGLE_STAKE_TYPES, SS_POOL_STATUS_TYPES } from '../singleStake/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1633636800

export const REWARDS_DURATION_DAYS = 28


interface INTERACTION_INFO {
    payable: boolean,
    depositFee: number | undefined,
    withdrawFee: number | undefined,
    claimFee: number | undefined
  }


// TODO add staking rewards addresses h ere
export const SS_BOOST_STAKING_REWARDS_INFO: {
    [chainId in ChainId]?: {
        collection: Token
        projectInfo: ProjectInfo
        token: Token
        stakingRewardAddress: string
        maxBoosterCount: number
        boostDiv: number
        interactionInfo: INTERACTION_INFO
        rewardToken: Token
        round: number
        type: SINGLE_STAKE_TYPES
    }[]
} = {
    [ChainId.CRONOSMAINNET]: [
        {
            collection: SPACE_CRXILLIONS,
            projectInfo: SPACE_CRXILLION_INFO,
            token: ORC,
            stakingRewardAddress: '0x2788991a6C287B8439B8a37A498E3346F14Cf819',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: ORC,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: CRX,
            stakingRewardAddress: '0x5049eB4D1cb87A295b97436bf533283247878b45',
            interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CRX,
            round: 4,
            type: SINGLE_STAKE_TYPES.LEGACY
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: CRX,
            stakingRewardAddress: '0x6FBfAE9aFDCc5E1690237F59e49f7C4596261Be0',
            interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CRX,
            round: 5,
            type: SINGLE_STAKE_TYPES.LEGACY
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: CRX,
            stakingRewardAddress: '0x4d1F978A6518c9d632A3b5ffBC2C220da9A4a5Bf',
            interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CRX,
            round: 5,
            type: SINGLE_STAKE_TYPES.LEGACY
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: CRX,
            stakingRewardAddress: '0xC09F43754Ac26B4e318d2d5447a42D82B7C1d8A8',
            interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CRX,
            round: 6,
            type: SINGLE_STAKE_TYPES.LEGACY
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: CROARMY,
            stakingRewardAddress: '0xc37452D154D95a6F90DcB8ad24E19B287BbdC76e',
            interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CRX,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: SCOOTER,
            stakingRewardAddress: '0x48b9A11a387c64A512fBDA86559D952Bb1271100',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: SCOOTER,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: SHROOK_NFT,
            projectInfo: SHROOK_NFT_INFO,
            token: WTS,
            stakingRewardAddress: '0xE8d6Fc02Ce8caAA140EC9c06E5AAbd4e9C004084',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: WTS,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: SPACE_CRXILLIONS,
            projectInfo: SPACE_CRXILLION_INFO,
            token: CSAI,
            stakingRewardAddress: '0x53847b08da65f88b16d0acd10c3987c0a91bcb3d',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: CSAI,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CAT_WIF_GAT,
            projectInfo: CAT_WIF_GAT_INFO,
            token: GAT,
            stakingRewardAddress: '0xCC5F2e451363444FF7f95aA9FA5A4b4cA27CdF9E',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: GAT,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: SPACE_CRXILLIONS,
            projectInfo: SPACE_CRXILLION_INFO,
            token: DNOT,
            stakingRewardAddress: '0xDEEcEDCd238C9A35D04471baDcEdb2184452085c',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: DNOT,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CAPONEBEAR,
            projectInfo: CAPONEBEAR_INFO,
            token: CPB,
            stakingRewardAddress: '0x0950C0539278DF74c4027D3804DaAE03AD56e0D4',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CPBR,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: DWL,
            stakingRewardAddress: '0x79E35b2C8a005c6536Adf1090Fd137A0253fE62B',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: DWL,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: GDRTOKEN_FOUNDERS,
            projectInfo: GDRTOKEN_FOUNDERS_INFO,
            token: GDRT,
            stakingRewardAddress: '0x9b39C0B113Dd0A75AdE01f4e079251EDA61F3Bb0',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: XCRX,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CROLON_MARS,
            projectInfo: CROLON_MARS_INFO,
            token: CLMRS,
            stakingRewardAddress: '0x95c5644b9c974cf87688205e2cdb13f4b72a354f',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CLMRS,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: LA_BRIGADE_DE_CUISINE,
            projectInfo: LA_BRIGADE_DE_CUISINE_INFO,
            token: STEAK,
            stakingRewardAddress: '0x714E475B2DBC83a0A2E112BeC2cC13317b3b431c',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: STEAK,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: BOO,
            stakingRewardAddress: '0x7DeCcE686827F43629C0d3d85A09A558DAFF387A',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: BOO,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: REYE,
            stakingRewardAddress: '0xECDE49EBE8DC6E2d29D7aB421305BF04bDf2D5C9',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: WETH[ChainId.CRONOSMAINNET],
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRONK_OG,
            projectInfo: CRONK_OG_INFO,
            token: CRONK,
            stakingRewardAddress: '0x9451617be9DcDd3B4FE3Da1e1d3a10BeCe8Cde77',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: CRONK,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: CRXILLION,
            projectInfo: CRXILLION_INFO,
            token: GREENSTIX_V2,
            stakingRewardAddress: '0x0a1d105acc5f0c2a101e20a7d9087bcf4c3594b3',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: WETH[ChainId.CRONOSMAINNET],
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: RARE_PEPE_CULT,
            projectInfo: RARE_PEPE_CULT_INFO,
            token: WISH,
            stakingRewardAddress: '0xD5A93e373F85D73E18A0b731252563877654d13B',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: PEPE,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: LIL_SPERMY,
            projectInfo: LIL_SPERMY_INFO,
            token: PEPE,
            stakingRewardAddress: '0xEcC9522465Ec7104C0c3e5160f25bD2458fBC06C',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: WISH,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
        {
            collection: TIME_TROPS,
            projectInfo: TIME_TROPS_INFO,
            token: TATAS_TWISTED_TIME,
            stakingRewardAddress: '0x34Ca04656c4DCd93A764C63F7742C84d78ffD3d7',
            interactionInfo: { payable: true,  depositFee: 1, withdrawFee:1, claimFee:1 },
            maxBoosterCount: 3,
            boostDiv: 1000,
            rewardToken: TATAS_TWISTED_TIME,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
    ]
}

export interface SSBoostStakingInfo {
    // collection
    collection: Token
    // project info 
    projectInfo: ProjectInfo
    // the address of the reward contract
    stakingRewardAddress: string
    // max booster count
    maxBoosterCount: number
    // boost permillage
    boostPermillage: number
    // boost divider
    boostDiv: number
    // boosters 
    boosters: any[]
    // the tokens involved in this pair
    token: Token
    // interaction info
    interactionInfo: INTERACTION_INFO
    // the reward tokens
    rewardToken: Token
    // round info
    round: number
    // type
    type: SINGLE_STAKE_TYPES
    // pool status
    poolStatus: SS_POOL_STATUS_TYPES
    // the amount of token currently staked, or undefined if no account
    stakedAmount: TokenAmount
    // the amount of reward token earned by the active account, or undefined if no account
    earnedAmount: TokenAmount
    // earned from boost
    earnedFromBoostAmount: TokenAmount
    // the total amount of token staked in the contract
    totalStakedAmount: TokenAmount
    // the amount of token distributed per second to all LPs, constant
    totalRewardRate: TokenAmount
    // the current amount of token distributed to the active account per second.
    // equivalent to percent of total supply * reward rate
    rewardRate: TokenAmount
    // user share
    userShare: number
    // when the period ends
    periodFinish: Date | undefined
    // calculates a hypothetical amount of token distributed to the active account per second.
    getHypotheticalRewardRate: (
        stakedAmount: TokenAmount,
        totalStakedAmount: TokenAmount,
        totalRewardRate: TokenAmount
    ) => TokenAmount
}

// gets the staking info from the network for the active chain id
export function useSSBoostStakingInfo(rewardToFilterBy?: string | null): SSBoostStakingInfo[] {
    const { chainId, account } = useActiveWeb3React()
    const info = useMemo(
        () =>
            chainId
                ? SS_BOOST_STAKING_REWARDS_INFO[chainId]?.filter(
                    stakingRewardInfo =>
                        (rewardToFilterBy === undefined) ? true : 
                        (rewardToFilterBy === stakingRewardInfo.stakingRewardAddress) // rewardToFilterBy.equals(stakingRewardInfo.token)
                ) ?? []
                : [],
        [chainId, rewardToFilterBy]
    )

    const uni = chainId ? UNI[chainId] : undefined

    const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

    const accountArg = useMemo(() => [account ?? undefined], [account])

    // get all the info from the staking rewards contracts
    const balances = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
    const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'earned', accountArg)
    const totalSupplies = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'totalSupply')
    
    const boosters = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'boostersOf', accountArg)
    const earnedFromBoostAmounts = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'earnedFromBoost', accountArg)


    // constants
    const boostPermillage = useMultipleContractSingleData(
        rewardsAddresses,
        BOOSTED_STAKING_REWARDS_INTERFACE,
        'boostPermillage',
        undefined,
        NEVER_RELOAD
    )
    // tokens per second
    const rewardRates = useMultipleContractSingleData(
        rewardsAddresses,
        BOOSTED_STAKING_REWARDS_INTERFACE,
        'rewardRate',
        undefined,
        NEVER_RELOAD
    )
    const periodFinishes = useMultipleContractSingleData(
        rewardsAddresses,
        BOOSTED_STAKING_REWARDS_INTERFACE,
        'periodFinish',
        undefined,
        NEVER_RELOAD
    )

    return useMemo(() => {
        if (!chainId || !uni) return []

        return rewardsAddresses.reduce<SSBoostStakingInfo[]>((memo, rewardsAddress, index) => {
            // these two are dependent on account
            const balanceState = balances[index]
            const earnedAmountState = earnedAmounts[index]

            // these get fetched regardless of account
            const totalSupplyState = totalSupplies[index]
            const rewardRateState = rewardRates[index]
            const periodFinishState = periodFinishes[index]

            const boostersState = boosters[index]
            const earnedFromBoostAmountState = earnedFromBoostAmounts[index]
            const boostPermillageState = boostPermillage[index]

            if (
                // these may be undefined if not logged in
                !balanceState?.loading &&
                !earnedAmountState?.loading &&
                !earnedFromBoostAmountState?.loading &&
                !boostersState?.loading &&
                // always need these
                totalSupplyState &&
                !totalSupplyState.loading &&
                rewardRateState &&
                !rewardRateState.loading &&
                periodFinishState &&
                !periodFinishState.loading &&
                boostPermillageState &&
                !boostPermillageState.loading 
            ) {
                if (
                    balanceState?.error ||
                    earnedAmountState?.error ||
                    boostersState?.error ||
                    earnedFromBoostAmountState?.error ||
                    totalSupplyState.error ||
                    rewardRateState.error ||
                    periodFinishState.error ||
                    boostPermillageState.error 
                ) {
                    console.error('Failed to load staking rewards info')
                    return memo
                }

                // get the LP token
                const token = info[index].token
                // const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

                // check for account, if no account set to 0

                const stakedAmount = new TokenAmount(token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
                const totalStakedAmount = new TokenAmount(token, JSBI.BigInt(totalSupplyState?.result?.[0]))
                const totalRewardRate = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardRateState?.result?.[0]))

                // booster variables
                const boosters = boostersState?.result?.[0] ?? []
                const maxBoosterCount = info[index].maxBoosterCount
                const boostPermillage = parseInt(boostPermillageState?.result?.[0])
                const boostDiv = info[index].boostDiv

                const getHypotheticalRewardRate = (
                    stakedAmount: TokenAmount,
                    totalStakedAmount: TokenAmount,
                    totalRewardRate: TokenAmount
                ): TokenAmount => {
                    return new TokenAmount(
                        info[index].rewardToken,
                        JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
                            ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
                            : JSBI.BigInt(0)
                    )
                }

                const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

                const periodFinishMs = periodFinishState?.result?.[0]?.mul(1000)?.toNumber()
                const userShare =  totalStakedAmount.greaterThan("0") ? parseFloat( stakedAmount?.divide(totalStakedAmount).toFixed(6) ) : 0 

                const isRewardEnded = new Date().getTime() > periodFinishMs ? true : false


                let poolStatus;
                if ( info[index].type === SINGLE_STAKE_TYPES.LEGACY && isRewardEnded)
                  poolStatus = SS_POOL_STATUS_TYPES.FINISHED
                else if ( info[index].type === SINGLE_STAKE_TYPES.LEGACY && !isRewardEnded)
                  poolStatus = SS_POOL_STATUS_TYPES.LIVE
                else if ( isRewardEnded )
                  poolStatus = SS_POOL_STATUS_TYPES.HALTED
                else
                  poolStatus = SS_POOL_STATUS_TYPES.LIVE

                  
                memo.push({
                    collection: info[index].collection,
                    projectInfo: info[index].projectInfo,
                    stakingRewardAddress: rewardsAddress,
                    maxBoosterCount: maxBoosterCount,
                    boostPermillage: boostPermillage,
                    boostDiv: boostDiv,
                    boosters: boosters,
                    interactionInfo: info[index].interactionInfo, 
                    token: info[index].token,
                    rewardToken: info[index].rewardToken,
                    round: info[index].round,
                    type: info[index].type,
                    poolStatus: poolStatus,
                    periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
                    earnedAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
                    earnedFromBoostAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedFromBoostAmountState?.result?.[0] ?? 0)),
                    rewardRate: individualRewardRate,
                    userShare: userShare,
                    totalRewardRate: totalRewardRate,
                    stakedAmount: stakedAmount,
                    totalStakedAmount: totalStakedAmount,
                    getHypotheticalRewardRate
                })
            }
            return memo
        }, [])
    }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni, boostPermillage, earnedFromBoostAmounts, boosters])
}

export function useTotalUniEarned(): TokenAmount | undefined {
    const { chainId } = useActiveWeb3React()
    const uni = chainId ? UNI[chainId] : undefined
    const stakingInfos = useSSBoostStakingInfo()

    return useMemo(() => {
        if (!uni) return undefined
        return (
            stakingInfos?.reduce(
                (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
                new TokenAmount(uni, '0')
            ) ?? new TokenAmount(uni, '0')
        )
    }, [stakingInfos, uni])
}

// based on typed value
export function useSSBoostDerivedStakeInfo(
    typedValue: string,
    stakingToken: Token,
    userLiquidityUnstaked: TokenAmount | undefined
): {
    parsedAmount?: CurrencyAmount
    error?: string
} {
    const { account } = useActiveWeb3React()

    const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

    const parsedAmount =
        parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
            ? parsedInput
            : undefined

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!parsedAmount) {
        error = error ?? 'Enter an amount'
    }

    return {
        parsedAmount,
        error
    }
}

// based on typed value
export function useSSBoostDerivedUnstakeInfo(
    typedValue: string,
    stakingAmount: TokenAmount
): {
    parsedAmount?: CurrencyAmount
    error?: string
} {
    const { account } = useActiveWeb3React()

    const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

    const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!parsedAmount) {
        error = error ?? 'Enter an amount'
    }

    return {
        parsedAmount,
        error
    }
}
