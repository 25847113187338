/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { isMobile } from 'react-device-detect'
// import image from '../../../assets/images/spaceCrxillion01.png'
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const ScrollContent = styled.div`
max-height: ${isMobile ? '400px' : '100%'};
overflow-y: auto;
  scrollbar-gutter: stable;
`


// const IMG = styled.img`
//   display: block;
//   -webkit-background-size: cover;
//   background-size: cover;
//   background-repeat: no-repeat;
//   -webkit-background-position: center;
//   background-position: center;
//   width: 100%;
//   object-fit: cover;
//   width: 200px;
// `

const CustomCell = styled(TableCell)`
    color: white !important;
`



function createData(
    no: string,
    address: string,
    winner: string,
    prize: string 
) {
    return { no, address, winner, prize };
}


const rows = [
    createData("#1", "0xf8e..1fA", "#695", "60k $MHUB"),
    createData("#2", "0x363..D2E", "#1385", "Space Crxillion #1"),
    createData("#3", "0xD1A..1E9", "#912", "Space Crxillion #2"),
    createData("#4", "0xE0E..61F", "#1046", "ICY NFT"),

    createData("#5", "0xA68..e0e", "#1999", "60k $MHUB"),
    createData("#6", "0x363..D2E", "#1385", "Space Crxillion #3"),
    createData("#7", "0xb57..e0e", "#1458", "Space Crxillion #4"),
    createData("#8", "0x96D..753", "#719", "Space Crxillion #448"),
    createData("#9", "0xE0E..61F", "#1708", "Space Crxillion #451")

];


interface MissionReconWinnerListModalProps {
    isOpen: boolean
    onDismiss: () => void
}

export default function MissionReconWinnerListModal({ isOpen, onDismiss }: MissionReconWinnerListModalProps) {
    const wrappedOnDismiss = useCallback(() => {
        onDismiss()
    }, [onDismiss])

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            <ContentWrapper gap="lg">
                <GlobalStyles
                    styles={{
                        '*::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5b5eb1',
                            outline: '1px solid #5b5eb1',
                            borderRadius: '12px'
                        }
                    }}
                />
                <RowBetween>
                    <TYPE.mediumHeader alignItems={'center'} display={'flex'}>Winners: Mission Recon
                    </TYPE.mediumHeader>

                    <CloseIcon onClick={wrappedOnDismiss} />
                </RowBetween>


                <ScrollContent>

                    <TYPE.white
                        fontWeight={500}
                        fontSize={14}
                        style={{ textAlign: 'justify', margin: '0px 3%', lineHeight: '21px', hyphens: 'auto' }}
                    >
                    </TYPE.white>



                    <TableContainer component={Card} style={{ background: 'transparent', marginTop: '1rem', margin: 'auto', width: 'fit-content' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <CustomCell>
                                        <TYPE.white fontFamily={'monospace'}>
                                            No
                                        </TYPE.white>
                                    </CustomCell>

                                    <CustomCell align="right">
                                        <TYPE.white fontFamily={'monospace'}>
                                            Winner Address
                                        </TYPE.white>
                                    </CustomCell>

                                    <CustomCell align="right">
                                        <TYPE.white fontFamily={'monospace'}>
                                            Winner Crxillion
                                        </TYPE.white>
                                    </CustomCell>
                                    
                                    <CustomCell align="right">
                                        <TYPE.white fontFamily={'monospace'}>
                                            Prize
                                        </TYPE.white>
                                    </CustomCell>


                                </TableRow>
                            </TableHead>
                            <TableBody style={{ color: 'white' }}>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.no}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <CustomCell component="th" scope="row">
                                            <TYPE.white fontFamily={'monospace'}>
                                                {row.no}
                                            </TYPE.white>
                                        </CustomCell>

                                        <CustomCell align="right">
                                            <TYPE.white fontFamily={'monospace'}>
                                                {row.address}
                                            </TYPE.white>
                                        </CustomCell>

                                        <CustomCell align="right">
                                            <TYPE.white fontFamily={'monospace'}>
                                                {row.winner}
                                            </TYPE.white>
                                        </CustomCell>

                                        <CustomCell align="right">
                                            <TYPE.white fontFamily={'monospace'}>
                                                {row.prize} 
                                            </TYPE.white>

                                        </CustomCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ScrollContent>
            </ContentWrapper>
        </Modal>
    )
}
