import * as React from 'react'
import Grid from '@mui/material/Grid'
import SpaceCrxillionMintCard from '../../components/MHUB/MintNFT/SpaceCrx_MintCard'
import BBMintCard from '../../components/MHUB/MintNFT/BB_MintCard'

export default function DROPS() {

    return (
        <>
            <Grid container spacing={1}>
                <Grid marginTop={2}>
                    <BBMintCard />
                    <SpaceCrxillionMintCard />
                </Grid>
            </Grid>
        </>
    )
}
