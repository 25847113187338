export interface ProjectInfo {
    name: string
    webSite: string | undefined
    twitter: string | undefined
    telegram: string | undefined
    discord: string | undefined
    github: string | undefined
    medium: string | undefined
    reddit: string | undefined
    whitepaper: string | undefined
    docs: string | undefined
    audit: string | undefined
    description: string | undefined
    idoDescription: string | undefined
}

export const TCN_PROJECT_INFO: ProjectInfo = {
    name: "TCN",
    webSite: undefined,
    twitter: undefined,
    telegram: undefined,
    discord: undefined,
    github: undefined,
    medium: undefined,
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    idoDescription: undefined
}

export const CRODEX_PROJECT_INFO: ProjectInfo = {
    name: "CRODEX",
    webSite: "https://crodex.app/",
    twitter: "https://twitter.com/crodexapp",
    telegram: "https://t.me/crodexofficial",
    discord: "https://discord.gg/gxkbYT4zYK",
    github: "https://github.com/crodexapp",
    medium: "https://crodex.medium.com/",
    reddit: undefined,
    whitepaper: "https://crodex.app/crodex_whitepaper.pdf",
    docs: "https://docs.crodex.app/",
    audit: "https://hacken.io/wp-content/uploads/2021/11/Crodex_23112021SCAudit_Report.pdf",
    description: `Crodex is a decentralized exchange (DEX), providing liquidity and enabling peer-to-peer transactions on Cronos.
    Crodex works with the same automated market-making (AMM) model as Uniswap which is a game changing tech. Automated market makers allow digital assets to be traded without permission and automatically by using liquidity pools instead of a traditional market of buyers and sellers.
    Crodex features a native governance token called CRX.`,
    idoDescription: undefined

}

export const CROKING_PROJECT_INFO: ProjectInfo = {
    name: "CroKing",
    webSite: "https://croking.net/",
    twitter: "https://twitter.com/CroKingofficial",
    telegram: "https://t.me/CroKingOfficial",
    discord: "https://discord.com/invite/j2jZ5pRxug",
    github: undefined,
    medium: "https://medium.com/@crokingteam",
    reddit: undefined,
    whitepaper: "https://croking.net/wp-content/uploads/2022/02/Croking-1.pdf",
    docs: undefined,
    audit: "https://github.com/Tech-Audit/Smart-Contract-Audits/blob/main/TECHAUDIT_CROKING.pdf",
    description: `CroKing is a multifunctional utility token which
    utilizes the speed and versatility of the Cronos
    Chain to pr
    ovide investors with one of the most
    exciting opportunities for passive earnings.
    Holders will receive wrapped CRO multiple
    times per day (based on number of owned
    CRK, and system volume), just for having CRK
    in their wallet. Meanwhile, CRK will offer an NFT
    marketplace and a launchpad for emerging
    projects which will feature anti-rug pull
    protections, so investors can operate with confidence.`,
    idoDescription: undefined
}

export const MARBLEVERSE_PROJECT_INFO: ProjectInfo = {
    name: "MarbleVerse",
    webSite: "https://www.marbleverse.io/index",
    twitter: "https://twitter.com/marbleverse_io",
    telegram: undefined,
    discord: "https://discord.com/invite/Jdj2uCUBdq",
    github: undefined,
    medium: undefined,
    reddit: undefined,
    whitepaper: "https://www.marbleverse.io/documents/Whitepaper.pdf",
    docs: undefined,
    audit: undefined,
    description: `We are the MarbleVerse Team, formed by highly
    experienced game developers, visual artists, game
    designers, business developers and community managers.
    The aim of MarbleVerse is to create an ecosystem
    where players can both enjoy playing the game and use
    the marketplace to earn at the same time. We worked on
    an idea that reminded us of our childhood, made us enjoy
    a friendly competition again and also features the
    beautiful glass art that all of us admired throughout our
    lifetimes. `,
    idoDescription: undefined
}

export const ARCANE_PROJECT_INFO: ProjectInfo = {
    name: "ARCANE",
    webSite: "https://arcanenft.financial/#",
    twitter: "https://twitter.com/thearcanefi",
    telegram: "https://t.me/arcanefinancial",
    discord: "https://discord.com/invite/ajesZGddzV",
    github: undefined,
    medium: "https://medium.com/@arcanefinancial",
    reddit: undefined,
    whitepaper: "https://arcanenft.financial/ArcaneWhitepaper.pdf",
    docs: undefined,
    audit: undefined,
    description: `Join the ARCANE army and be a part of Cybertopia, Make your allies,
    Craft drones in your workshop. Modifying your body, adding fuel
    boosters and guns and preparing for battle to save your planet cybertopia.
    Win and collect protonium (the most precious matter in the universe). Use
    it to upgrade your mecha or Sell it to be a rich cybertopian.
    The goal of the Arcane army is to protect the cybertopia from dedreux attacks
    (Hunters of the universe) gather the Weapons, Ancient Skills NFTs,
    unique power cores and prepare for the battle to save the universe.`,
    idoDescription: undefined
}


export const CRONOSVERSE_INFO: ProjectInfo = {
    name: "CronosVerse",
    webSite: "https://www.thecronosverse.com/",
    twitter: "https://twitter.com/thecronosverse",
    telegram: undefined,
    discord: undefined,
    github: undefined,
    medium: undefined,
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `The CronosVerse aims to be the first true metaverse project on the Cronos blockchain.  Our goal is to create the ultimate social MMO gaming experience for the entire Cronos community.  Landowners will be able to fully immerse themselves into the Crovilian society.  Buy your digital land plot, develop your property & enter The CronosVerse!`,
    idoDescription: undefined
}

export const MHUB_INFO: ProjectInfo = {
    name: "Metaverse Hub",
    webSite: "https://crodex.medium.com/here-we-go-crx-metaverse-hub-2-0-initial-token-sale-and-launch-on-crowd-launchpad-218ce9fca040",
    twitter: "https://twitter.com/crodexapp",
    telegram: "https://t.me/crodexofficial",
    discord: "https://discord.gg/utfzWn3RGR",
    github: undefined,
    medium: "https://crodex.medium.com/",
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `Metaverse Hub 2.0 is here to usher in a new era of NFT/Metaverse centric DeFi experience on Cronos. The native currency of this expanding hub of metaverse is the $MHUB token. $MHUB will be used for everything you do at the Hub — profits you make, assets and artifacts you purchase/sell, participation tickets you’ll acquire and more.`,
    idoDescription: undefined
}


export const XCRX_INFO: ProjectInfo = {
    name: "xCRX",
    webSite: "https://crodex.medium.com/the-new-era-of-crodex-protocol-1d8fe36e746b",
    twitter: "https://twitter.com/crodexapp",
    telegram: "https://t.me/crodexofficial",
    discord: "https://discord.gg/utfzWn3RGR",
    github: undefined,
    medium: "https://crodex.medium.com/",
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `First and foremost, we would like to express our gratitude for your support thus far. We are fully motivated to keep building in the anticipation of sharing new achievements with you all in the future. Our primary objective is creating cutting-edge, user-oriented goods that will lower the level of volatility in our ecosystem and foster user confidence. To further encourage long-term staking of Crodex Ecosystem tokens and reduce sell pressure, we are launching a new protocol with the new main token xCRX.`,
    idoDescription: undefined
}


export const ORC_INFO: ProjectInfo = {
    name: "ORC",
    webSite: "https://groggtheorc.xyz",
    twitter: "https://twitter.com/groggtheorc",
    telegram: undefined,
    discord: "https://discord.com/invite/Ga3qtMtf",
    github: undefined,
    medium: "https://crodex.medium.com/initial-orc-offering-ioo-on-crowd-launchpad-822ab2f93975",
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `A culture-themed meme project from the Crodex power-incubator, Grogg the $ORC is launching on CROWD. It is a typical meme project with an unusual dedication to the ORC theme. Which is also the reverse of CRO. Grogg is an entrepreneurial Orc bringing his utmost intellect (!) to Cronos.
    The project is technically monitored and supervised by Crodex while maintaining its autonomy in decision making and building a community around Grogg the $ORC.`,
    idoDescription: undefined
}

export const DNOT_INFO: ProjectInfo = {
    name: "DNOT",
    webSite: "https://donutcro.com/",
    twitter: "https://x.com/donutcro",
    telegram: "https://t.me/donutcro",
    discord: "https://discord.gg/5J4MDfx3TV",
    github: undefined,
    medium: undefined,
    reddit: undefined,
    whitepaper: "https://donutcro.com/whitepaper.pdf",
    docs: undefined,
    audit: undefined,
    description: `
    Donut Coin, is a revolutionary memecoin built on the principles of community, humor, and innovation.
    Drawing inspiration from the chaotic yet captivating world of internet memes, Donut Coin aims to redefine the memecoin landscape by offering a platform that not only entertains but also empowers its community through decentralized governance and sustainable utility.
    This whitepaper outlines the vision, mechanics, and potential impact of Donut Coin in the ever-evolving cryptocurrency ecosystem.
    Donut Coin represents a paradigm shift in the memecoin ecosystem, combining humor, innovation, and community empowerment to create a truly unique and impactful platform.
    Join us on this exciting journey as we unleash the full potential of memes in the world of cryptocurrency.`,
    idoDescription: `The sale progresses in 3 phases, phase 2 unlocks when 250k is reached, phase 3 unlocks when 750k is reached, and the sale ends when the 1.75m target is reached.`
}

export const CSAI_INFO: ProjectInfo = {
    name: "CSAI",
    webSite: "https://crosaitama.com/",
    twitter: "https://x.com/CroSaitama",
    telegram: "https://t.me/+tdAms40-IyczYTlk",
    discord: "https://discord.com/invite/U6Gz3Z7ZS9",
    github: undefined,
    medium: "https://crodex.medium.com/initial-token-sale-at-crowd-launchpad-crosaitama-1421ec3595f4",
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `CroSaitama was born on the cronoschain as one of the many memes. Even if its purpose is to become one of the most important and use. It was born from the imagination of a small team, which has great ambitions and therefore wants to become a large team. This is because it will have to be a community project where the fate will not be decided by a single person but by all the people who integrate with CroSaitama. Beyond the roadmap, all decisions that will be made once the launch is completed will be voted on by all owners. This is just the beginning.`,
    idoDescription: `The sale progresses in 3 phases, phase 2 unlocks when 100k is reached, phase 3 unlocks when 200k is reached, and the sale ends when the 300k target is reached.`
}

export const PPTZ_INFO: ProjectInfo = {
    name: "PPTZ",
    webSite: "https://www.puppetzcoin.com",
    twitter: "https://x.com/puppetznft",
    telegram: undefined,
    discord: "https://discord.gg/ttkM7XFbC3",
    github: undefined,
    medium: undefined,
    reddit: undefined,
    whitepaper: undefined,
    docs: undefined,
    audit: undefined,
    description: `Puppetz NFT is a web3 project that blends creativity with community governance. Originating from a small team and a artist, it aims to become a significant NFT player. Post-launch decisions will be made by the community through voting, ensuring every NFT holder has a voice. This is just the beginning.`,
    idoDescription: `
        Total Supply: 33,333,333,333.
            Presale;
            Two phases with a combined allocation of 30% of the total supply.
            Phase 1 is for a limited time or until the hard cap of 300,000 CRO is reached.
            Phase 2 is open to the public with a hard cap of 200,000 CRO.
            Total presale hard cap: 500,000 CRO.`
}