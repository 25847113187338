import {  Fraction, JSBI, TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { RCRX, XCRX } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useRCRXContract, useXVaultContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../multicall/hooks'
import { useTokenBalance } from '../wallet/hooks'

export const XVAULT_CONTRACT_ADDRESS = "0x1D0EC982448689fbB5Ee46eC1395502657e55517"

const XVAULT_START_DATE = new Date(Date.UTC(2022, 9, 28, 19, 0, 0, 0))

export interface XVaultInfo {
    depositedAmount: TokenAmount | undefined
    lockedUntil: Date | undefined
    availableToWithdraw: boolean | undefined
    rCRXBalance: TokenAmount | undefined
    xCRXBalance: TokenAmount | undefined
    withdrawableDividend: TokenAmount | undefined
    totalClaimed: TokenAmount | undefined
    totalDistributed: TokenAmount | undefined
    totalWithdrawn: TokenAmount | undefined
    userShare: string | undefined
    dailyRewardRate: Fraction | undefined
    estimatedAPY: string | undefined
}



// gets the staking info from the network for the active chain id
export function useXVaultInfo(): XVaultInfo {

    const { chainId, account } = useActiveWeb3React()
    
    const xVaultContract = useXVaultContract(XVAULT_CONTRACT_ADDRESS)
    const rCrxContract = useRCRXContract(RCRX.address)

    const depositedAmountRes = useSingleCallResult(xVaultContract, 'depositedAmount')
    const lockedUntilRes = useSingleCallResult(xVaultContract, 'lockedUntil', [account ?? undefined])

    const xcrxBalance = useTokenBalance(account ?? undefined, XCRX )
    const rcrxBalance = useTokenBalance(account ?? undefined, RCRX )
    const withdrawableDividendRes = useSingleCallResult(rCrxContract, 'withdrawableDividendOf', [account ?? undefined])
    const totalClaimedRes = useSingleCallResult(rCrxContract, 'withdrawnDividendOf', [account ?? undefined])
    const totalSupplyRCRXRes = useSingleCallResult(rCrxContract, 'totalSupply')

    const totalDistributedRes = useSingleCallResult(rCrxContract, 'totalDistributed')
    const totalWithdrawnRes = useSingleCallResult(rCrxContract, 'totalWithdrawn')

    

    return useMemo(() => {

        let memo : XVaultInfo = {
            depositedAmount: undefined,
            lockedUntil: undefined,
            availableToWithdraw: undefined,
            rCRXBalance: undefined,
            xCRXBalance: undefined,
            withdrawableDividend: undefined,
            totalClaimed: undefined,
            totalDistributed: undefined,
            totalWithdrawn: undefined,
            userShare: undefined,
            dailyRewardRate: undefined,
            estimatedAPY: undefined
        }

        if (!chainId ) 
            return memo
        else {
            if (
                !depositedAmountRes?.loading &&
                !lockedUntilRes?.loading &&
                !withdrawableDividendRes?.loading && 
                !totalClaimedRes?.loading &&
                !totalDistributedRes?.loading &&
                !totalWithdrawnRes?.loading &&
                !totalSupplyRCRXRes?.loading
            ){
                if (
                    depositedAmountRes?.error ||
                    lockedUntilRes?.error ||
                    withdrawableDividendRes?.error ||
                    totalClaimedRes?.error ||
                    totalDistributedRes?.error ||
                    totalWithdrawnRes?.error ||
                    totalSupplyRCRXRes?.error
                ) {
                    console.error('Failed to load xVault info')
                    return memo
                }

                // data from contract
                memo.depositedAmount = new TokenAmount(XCRX, JSBI.BigInt(depositedAmountRes.result?.[0]))
                const lockedUntil_timestamp = lockedUntilRes.result?.[0]?.mul(1000)?.toNumber()
                memo.lockedUntil = lockedUntil_timestamp ? new Date(lockedUntil_timestamp) : undefined
                memo.rCRXBalance = rcrxBalance ?? new TokenAmount(RCRX, JSBI.BigInt('0'))
                memo.xCRXBalance = xcrxBalance ?? new TokenAmount(XCRX, JSBI.BigInt('0'))

                memo.withdrawableDividend = new TokenAmount(XCRX, JSBI.BigInt(withdrawableDividendRes?.result?.[0]?? 0))
                memo.totalClaimed = new TokenAmount(XCRX, JSBI.BigInt(totalClaimedRes?.result?.[0]?? 0))
                memo.totalDistributed = new TokenAmount(XCRX, JSBI.BigInt(totalDistributedRes?.result?.[0]?? 0))
                memo.totalWithdrawn = new TokenAmount(XCRX, JSBI.BigInt(totalWithdrawnRes?.result?.[0]?? 0))

                // calculated data
                const totalRCRXSupply = new TokenAmount(RCRX, JSBI.BigInt(totalSupplyRCRXRes?.result?.[0]?? 0)) 
                memo.userShare = ( rcrxBalance && totalRCRXSupply && !totalRCRXSupply.equalTo("0")) ? (100 * parseFloat(rcrxBalance.divide(totalRCRXSupply).toFixed(4))).toFixed(3) : "0"
                if ( memo.lockedUntil ){
                    memo.availableToWithdraw = memo.lockedUntil && rcrxBalance?.greaterThan('0') && ( Date.now() > memo.lockedUntil.getTime() ) ? true : false
                } else {
                    memo.availableToWithdraw = undefined
                }

                const dateNow = new Date()
                const differenceInMs = dateNow.getTime() - XVAULT_START_DATE.getTime()
                const totalDays = Math.floor(differenceInMs / (1000 * 3600 * 24))
                memo.dailyRewardRate = memo.totalDistributed.divide(totalDays.toString())
                memo.estimatedAPY = (100* Math.pow( 1 + parseFloat(memo.dailyRewardRate?.divide(memo.depositedAmount).toFixed(6)), 365)).toFixed(2)
                return memo
            }
            return memo
        }
    }, [chainId, xcrxBalance, rcrxBalance, totalSupplyRCRXRes, depositedAmountRes, lockedUntilRes, totalClaimedRes, totalDistributedRes, totalWithdrawnRes, withdrawableDividendRes ])
}
