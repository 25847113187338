import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { TYPE, ExternalLink } from '../../../theme'
import { ButtonPrimary } from '../../Button'
import { Break } from './styled'
import CurrencyLogo from '../../CurrencyLogo'
import { SPACE_CRXILLIONS } from '../../../constants/collections'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import { useNFTPortfolio } from '../../../state/nftPortfolio/hooks'
import NFTPortfolioModal from './NFTPortfolioModal'
import { useActiveWeb3React } from '../../../hooks'
import { useWalletModalToggle } from '../../../state/application/hooks'
import { SPACE_CRXILLION_INFO } from '../../../state/nftStake/projects'
import SpaceCrxillionMintModal from './SpaceCrx_MintModal'
import { BarWave } from 'react-cssfx-loading'
import { useSpaceCrxillionInfo } from '../../../state/mhub/mintInfo/spaceCrxillionInfo'
import Grid from '@mui/material/Grid'
import { LightCard } from '../../../components/Card'
import { isMobile } from 'react-device-detect'
import { RowBetween } from '../../Row'

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: none;
  color: none;
  box-shadow: none;
  border: none;
`
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: '8px !important',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#eeeeeeb8'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5b5eb1'
  }
}))

const Wrapper = styled(AutoColumn) <{ showBackground: boolean }>`
  background: #1f1243cc;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
`

const BgWrapper = styled(AutoColumn) <{ showBackground: boolean }>`
  margin: 10px auto;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  animation: 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s infinite normal none running example;
  @keyframes example {
    0% {
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 24x;
    }
    10% {
      box-shadow: rgb(255, 154, 0) 0px 0px 12px;
    }
    20% {
      box-shadow: rgb(208, 222, 33) 0px 0px 12px;
    }
    30% {
      box-shadow: rgb(79, 220, 74) 0px 0px 12px;
    }
    40% {
      box-shadow: rgb(63, 218, 216) 0px 0px 12px;
    }
    50% {
      box-shadow: rgb(47, 201, 226) 0px 0px 12px;
    }
    60% {
      box-shadow: rgb(28, 127, 238) 0px 0px 12px;
    }
    70% {
      box-shadow: rgb(95, 21, 242) 0px 0px 12px;
    }
    80% {
      box-shadow: rgb(186, 12, 248) 0px 0px 12px;
    }
    90% {
      box-shadow: rgb(251, 7, 217) 0px 0px 12px;
    }
    100% {
      background-color: rgb(255, 0, 0) 0px 0px 12px;
    }
  }
  border-radius: 12px;
  width: 90%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`
const ProgressBox = styled(Box)`
  padding-left: 20px;
  mr: 1;
  alignitems: center;
`

const NFT_COLLECTION = SPACE_CRXILLIONS

export default function SpaceCrxillionMintCard() {
  const [showPortfolioModal, setShowPortfolioModal] = useState(false)
  const [showMintModal, setShowMintModal] = useState(false)

  const { account } = useActiveWeb3React()

  const nftInfo = useSpaceCrxillionInfo()

  const isLoading = nftInfo?.balanceOf !== undefined
  const hasNFT = nftInfo?.balanceOf! > 0
  const nftPortfolio = useNFTPortfolio(NFT_COLLECTION)

  const progPerc = (nftInfo?.totalSupply! / nftInfo?.maxSupply) * 100
  const isOver = nftInfo?.totalSupply! === nftInfo?.maxSupply
  const titleText = ''
  // if (nftInfo?.paused !== undefined && !nftInfo?.paused && !isOver) titleText = '- NOW MINTING!'
  // else if (nftInfo?.paused !== undefined && !nftInfo?.paused && isOver) titleText = '- SOLD OUT!'

  const toggleWalletModal = useWalletModalToggle()

  const handlePortfolioClick = useCallback(() => {
    if (account) {
      setShowPortfolioModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleMintClick = useCallback(() => {
    if (account) {
      setShowMintModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <BgWrapper showBackground={true}>
      <Wrapper showBackground={hasNFT}>
        <Cards>
          <Grid container>
            <Grid item xs={12} sm={9} md={9} style={{ display: 'flex', padding: '1rem 0' }}>
              <CurrencyLogo currency={NFT_COLLECTION} size={'72px'} />
              <div style={{ margin: 'auto 0' }}>
                <TYPE.white fontWeight={600} fontSize={isMobile ? 20 : 28} style={{ marginLeft: '32px' }}>
                  {NFT_COLLECTION.name} {titleText}
                </TYPE.white>
              </div>
            </Grid>

            <Grid item xs={12} sm={3} md={3} margin={'auto'}>
              <ExternalLink href="https://crodex.medium.com/" style={{ margin: '100%' }}>
                <ButtonPrimary padding="10px 24px" borderRadius="8px" width="auto" margin="auto">
                  Learn More↗
                </ButtonPrimary>
              </ExternalLink>
            </Grid>
            <Break />

            <Grid item xs={12} sm={8} md={8} style={{ padding: '1rem' }}>
              <TYPE.white style={{ textAlign: 'justify' }}>
                Space Crxillions are our second non-fungible token (NFT) collection of hyper-realistic felines coming
                from the unknown corners of the Cosmos with brand new set of traits and improved visuals. Space
                Crxillions are the main characters of the Metaverse Hub Adventures along with the original Crxillion
                Club members. While your usual NFTs are one-dimensional investments that you can just hodl and trade,
                Space Crxillions will enable you to actively utilize your NFTs as yield generators, boosters or tickets
                for adventures and games with various offerings.
              </TYPE.white>

              <Break style={{ marginTop: '1rem' }} />
              <RowBetween>
                <TYPE.white style={{ marginTop: '1rem', textAlign: 'center' }}>
                  <TYPE.white fontWeight={600} fontSize={14} style={{ marginBottom: '10px' }}>
                    Whitelist
                  </TYPE.white>
                  <TYPE.white fontWeight={600} fontSize={14} style={{ marginBottom: '10px' }}>
                    Price
                  </TYPE.white>
                  <TYPE.white fontSize={18}>{nftInfo?.wlPrice} CRO</TYPE.white>
                </TYPE.white>

                <TYPE.white style={{ marginTop: '1rem', textAlign: 'center' }}>
                  <TYPE.white fontWeight={600} fontSize={14} style={{ marginBottom: '10px' }}>
                    Ebisu's Bay
                  </TYPE.white>
                  <TYPE.white fontWeight={600} fontSize={14} style={{ marginBottom: '10px' }}>
                    Member Price
                  </TYPE.white>
                  <TYPE.white fontSize={18}>{nftInfo?.ebPrice} CRO</TYPE.white>
                </TYPE.white>

                <TYPE.white style={{ marginTop: '1rem', textAlign: 'center' }}>
                  <TYPE.white fontWeight={600} fontSize={14} style={{ marginBottom: '10px' }}>
                    Regular
                  </TYPE.white>
                  <TYPE.white fontWeight={600} fontSize={14} style={{ marginBottom: '10px' }}>
                    Price
                  </TYPE.white>
                  <TYPE.white fontSize={18}>{nftInfo?.regPrice} CRO</TYPE.white>
                </TYPE.white>
              </RowBetween>
            </Grid>

            <Grid item xs={12} sm={4} md={4} margin={'auto'}>
              <ProgressBox sx={{}}>
                {!isLoading && <BarWave style={{ margin: '20px auto' }} color="#5b5eb1"></BarWave>}

                {isLoading && (
                  <>
                    <Box>
                      <TYPE.white style={{ marginBottom: '10px', textAlign: 'right' }} variant="body2" color="white">
                        {parseInt(progPerc.toString())}% Minted
                      </TYPE.white>
                    </Box>
                    <Box>
                      <Box>
                        <BorderLinearProgress variant="determinate" value={progPerc} />
                      </Box>
                    </Box>
                    <Box>
                      <TYPE.white style={{ marginTop: '10px', textAlign: 'center' }} variant="body2" color="white">
                        {nftInfo?.totalSupply} of {nftInfo?.maxSupply}
                      </TYPE.white>
                    </Box>

                    {/* <Box>
                        <TYPE.white style={{ marginBottom: '10px', textAlign: 'right' }} variant="body2" color="white">
                          {0}% Minted
                        </TYPE.white>
                      </Box>
                      <Box>
                        <Box>
                          <BorderLinearProgress variant="determinate" value={0} />
                        </Box>
                      </Box>
                      <Box>
                        <TYPE.white style={{ marginTop: '10px', textAlign: 'center' }} variant="body2" color="white">
                          {0} of {3000}
                        </TYPE.white>
                      </Box> */}

                    {isOver ||
                      (true && (
                        <>
                        <ButtonPrimary
                          onClick={handleMintClick}
                          style={{
                            width: 'auto',
                            margin: nftInfo?.totalSupply ? ' 20px auto' : '20px auto',
                            textAlign: 'right',
                            display: 'flex',
                            padding: '10px 3rem',
                            borderRadius: '8px'
                          }}
                        >
                          Mint
                        </ButtonPrimary>
                        </>
                      ))}
                  </>
                )}
              </ProgressBox>
            </Grid>
          </Grid>
        </Cards>

        {hasNFT && (
          <>
            <Break />
            <BottomSection showBackground={hasNFT}>
              <ButtonPrimary
                onClick={handlePortfolioClick}
                style={{
                  width: 'auto',
                  margin: 'auto',
                  textAlign: 'right',
                  display: 'flex',
                  padding: '10px 24px',
                  borderRadius: '8px'
                }}
              >
                List of Your NFTs ({nftInfo?.balanceOf ?? undefined})
              </ButtonPrimary>
            </BottomSection>
          </>
        )}

        {nftPortfolio && (
          <>
            <NFTPortfolioModal
              isOpen={showPortfolioModal}
              onDismiss={() => setShowPortfolioModal(false)}
              portfolioInfo={nftPortfolio}
              projectInfo={SPACE_CRXILLION_INFO}
            />
            <SpaceCrxillionMintModal isOpen={showMintModal} onDismiss={() => setShowMintModal(false)} />
          </>
        )}
      </Wrapper>
    </BgWrapper>
  )
}
