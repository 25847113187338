/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react'

import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import iamge from '../../../assets/svg/262.png'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import GlobalStyles from '@mui/material/GlobalStyles'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { NFTPortfolioInfo } from '../../../state/nftPortfolio/hooks'
import styled from 'styled-components'
import { AutoColumn } from '../../Column'
import { DataCard } from './styled'
import Modal from '../../Modal'
import { RowBetween } from '../../Row'
import { CloseIcon, TYPE } from '../../../theme'
import { ProjectInfo } from '../../../state/nftStake/projects'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    zIndex: 1203
  }
})

const ErrorSection = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const StyledImageListItemBar = styled(ImageListItemBar)`
  .MuiImageListItemBar-titleWrap {
    display: none;
  }
  .MuiImageListItemBar-actionIcon {
    background: transparent;
    width: 100%;
    text-align: center;
  }
`

const Styledimg = styled.img`
  width: 75px;
  height: 75px;
  margin: auto;
  display: flex;
`

const StyleFormControlLabel = styled(FormControlLabel)`
  margin-right: 0 !important;
`

const StyledFormControl = styled(FormControl)`
  max-height: 430px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 0px 20px !important;
`

interface NFTPortfolioModalProps {
  isOpen: boolean
  onDismiss: () => void
  portfolioInfo: NFTPortfolioInfo[]
  projectInfo: ProjectInfo
}

interface NFTInfo {
  tokenID: string
  tokenURI: string
  metaData: any
}

interface Metadata {
  image: string
  name: string
}

export default function NFTPortfolioModal({ isOpen, onDismiss, portfolioInfo, projectInfo }: NFTPortfolioModalProps) {
  // const { chainId } = useActiveWeb3React()
  const [nftInfoList, setNFTInfoList] = useState<NFTInfo[]>([])
  const [loading, setLoading] = React.useState(false)
  const [previousPortfolio, setPreviousPortfolio] = useState<NFTPortfolioInfo[]>([])
  const [update, setUpdate] = React.useState(false)

  const [metadataFail, setMetadataFail] = React.useState(false)
  async function getReq(url: string): Promise<Metadata> {
    return fetch(url)
      .then(async response => {
        const tokenData = await response.text()
        const metaData = JSON.parse(tokenData)
        metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
        return metaData
      })
      .catch(error => {
        console.error('Error:', error)
        return null
      })
  }

  async function getMetaData(portfolioInfo: NFTPortfolioInfo[], operation: string) {
    if (operation === 'getData') {
      const portList = []
      for (let idx = 0; idx < portfolioInfo.length; idx++) {
        let metaData: Metadata | undefined = undefined
        if (portfolioInfo[idx].tokenURI !== 'undefined') {
          if (projectInfo.hasMetadata) {
            let metadataURI = portfolioInfo[idx].tokenURI
            if (projectInfo.tokenURIPrefix) {
              metadataURI = projectInfo.tokenURIPrefix + portfolioInfo[idx].tokenURI
            }
            metaData = await getReq(metadataURI)
          } else {
            metaData = { image: '', name: '' }
            metaData.image = portfolioInfo[idx]?.tokenURI
            if (projectInfo.imageURIPrefix) {
              metaData.image = projectInfo.imageURIPrefix + portfolioInfo[idx].tokenURI
            }
          }
        }
        if (metaData) {
          metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
          portList[idx] = {
            metaData: metaData,
            tokenID: portfolioInfo[idx].tokenID,
            tokenURI: portfolioInfo[idx].tokenURI
          }
          setNFTInfoList([...portList])
          setUpdate(!update)
        } else {
          portList[idx] = {
            metaData: { image: 'https://swap.crodex.app/ant.jpg', name: 'Placeholder' },
            tokenID: portfolioInfo[idx].tokenID,
            tokenURI: portfolioInfo[idx].tokenURI
          }
          setMetadataFail(true)
          setNFTInfoList([...portList])
          setUpdate(!update)
        }
      }
    } else if (operation === 'updateData') {
      setUpdate(!update)
    }
  }

  useEffect(() => {
    if (portfolioInfo.length === 0) {
      setLoading(false)
    } else if (portfolioInfo.length !== 0 && isOpen === true && portfolioInfo[0].tokenURI !== undefined) {
      setLoading(false)
      if (previousPortfolio !== undefined) {
        if (previousPortfolio.length !== portfolioInfo.length) {
          getMetaData(portfolioInfo, 'getData')
          setPreviousPortfolio(portfolioInfo)
        }
      } else {
        setPreviousPortfolio(portfolioInfo)
      }
    } else if (portfolioInfo[0]?.tokenURI === undefined) {
      setLoading(true)
    }
  }, [portfolioInfo, isOpen, previousPortfolio])

  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      <ContentWrapper gap="lg">
        <RowBetween>
          <TYPE.mediumHeader>List of Your NFTs</TYPE.mediumHeader>
          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <StyledFormControl>
          <GlobalStyles
            styles={{
              '*::-webkit-scrollbar': {
                width: '0.4em'
              },
              '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
              },
              '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#5b5eb1',
                outline: '1px solid #5b5eb1',
                borderRadius: '12px'
              }
            }}
          />
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms'
            }}
            unmountOnExit
          >
            <LinearProgress style={{ margin: '15px' }} color="secondary" />
          </Fade>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {nftInfoList.map(nftInfo => {
              return (
                <Grid item xs={4}>
                  <PoolData style={{ padding: '0.5rem' }}>
                    <ImageListItem key={nftInfo.tokenID}>
                      <CustomWidthTooltip title={nftInfo.metaData.name} placement="right">
                        <>
                        <a href={nftInfo.metaData.image} target="_blank">
                          <Styledimg src={nftInfo.metaData.image} alt={iamge} loading="lazy" />
                        </a>
                        </>
                        
                      </CustomWidthTooltip>
                      <StyledImageListItemBar
                        position="below"
                        actionIcon={
                          <StyleFormControlLabel
                            style={{ margin: 'auto', marginTop: '5px' }}
                            value={nftInfo.tokenID}
                            control={<></>}
                            label={`#${nftInfo.tokenID}`}
                          />
                        }
                      />
                    </ImageListItem>
                  </PoolData>
                </Grid>
              )
            })}
          </Grid>
        </StyledFormControl>

        {metadataFail && (
          <ErrorSection dim={true}>
            <TYPE.black fontWeight={600}>NFT metadata failed to load.</TYPE.black>
          </ErrorSection>
        )}

        {/* <RowBetween align="center">
          <a
            href={projectInfo?.ebisusBay}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', margin: 'auto' }}
          >
            <ButtonPrimary padding="8px" borderRadius="8px" width={'fit-content'}>
              <CurrencyLogo currency={EBISUSBAY} size={'36px'} style={{ marginRight: '2px' }} />
              {`Check on Ebisu's Bay`}
            </ButtonPrimary>
          </a>
        </RowBetween> */}
      </ContentWrapper>
    </Modal>
  )
}
