// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'https://swap.crodex.app/tokens.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const DEFAULT_HARDCODED_LP_TOKEN_LIST = {
  name: 'ERC20 Token List',
  timestamp: '2021-07-08T07:24:15.408Z',
  version: {
    major: 1,
    minor: 9,
    patch: 29
  },
  tags: {},
  logoURI: 'https://swap.crodex.app/cro.png',
  keywords: ['Cronos', 'default'],
  tokens: [
    {
      name: 'wCRO-CRX',
      address: '0xd141aed3fa86f411808d5d74bebbc038e94f300d',
      symbol: 'wCRO-CRX',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crx.png'
    },
    {
      name: 'wCRO-USDC',
      address: '0x182414159c3eef1435af91bcf0d12abcbe277a46',
      symbol: 'wCRO-USDC',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crx.png'
    },
    {
      name: 'wCRO-ARMY',
      address: '0xdcb68aba4c9b87c76fa6adb54214451e91bfd1bc',
      symbol: 'wCRO-ARMY',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crystl.png'
    },
    {
      name: 'wCRO-VRSE',
      address: '0xcd2e6b295b19ce9d4d476b2b24d5b65a778b09b6',
      symbol: 'wCRO-VRSE',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crystl.png'
    },
    {
      name: 'wCRO-STEAK',
      address: '0xdcbee84e534c8ac458ef0f57e2137f086e034f09',
      symbol: 'wCRO-STEAK',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crystl.png'
    }
  ]
}
