/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { DataCard } from '../../components/earn/styled'
import { JSBI, TokenAmount } from '@cronosdex/sdk'
// import { useActiveWeb3React } from '../../hooks'
// import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useNFTStakingContract } from '../../hooks/useContract'
import { ApprovalState, useNFTApprovalStatus } from '../../hooks/useApproveCallback'
// import { useDerivedStakeInfo } from '../../state/singleStake/hooks'

// import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'

import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import iamge from '../../assets/svg/262.png'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { NFTStakeInfo } from '../../state/nftStake/hooks'
import { NFTPortfolioInfo } from '../../state/nftPortfolio/hooks'
import GlobalStyles from '@mui/material/GlobalStyles'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    zIndex: 1203
  }
})

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const StyledImageListItemBar = styled(ImageListItemBar)`
  .MuiImageListItemBar-titleWrap {
    display: none;
  }
  .MuiImageListItemBar-actionIcon {
    background: #46499333;
    width: 100%;
    text-align: center;
  }
`

const Styledimg = styled.img`
  width: 75px;
  height: 75px;
  margin: auto;
  display: flex;
`

const RadioStyle = styled(Radio)`
  color: ${({ theme }) => theme.bg3}!important;
  &.Mui-checked {
    color: ${({ theme }) => theme.bg3}!important;
  }
`

const StyleFormControlLabel = styled(FormControlLabel)`
  margin-right: 0 !important;
`
const StyledFormControl = styled(FormControl)`
  max-Height: 430px;
  overflow-y: auto;
     scrollbar-gutter: stable;
   padding: 0px 20px !important;
`
interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: NFTStakeInfo
  nftDepositInfo: NFTPortfolioInfo[]
  userLiquidityUnstaked: TokenAmount | undefined
}

interface NFTInfo {
  tokenID: string
  tokenURI: string
  metaData: any
}

export default function NFTUnstakingModal({
  isOpen,
  onDismiss,
  stakingInfo,
  nftDepositInfo,
  userLiquidityUnstaked
}: StakingModalProps) {

  // const { chainId } = useActiveWeb3React()
  const [nftInfoList, setNFTInfoList] = useState<NFTInfo[]>([])

  async function getReq(url: string): Promise<object> {
    return fetch(url)
      .then(
        async response => {
          let tokenData = await response.text()
          let metaData = JSON.parse(tokenData);
          return metaData
        }
      )
      .catch(error => {
        console.error('Error:', error);
        return null
      });
  }

  async function getMetaData(portfolioInfo: NFTPortfolioInfo[]) {
    const portList = []
    for (let idx = 0; idx < portfolioInfo.length; idx++) {
      let metaData = {} as any
      if ( stakingInfo?.projectInfo.hasMetadata ){
        let metadataURI = portfolioInfo[idx].tokenURI
        if ( stakingInfo?.projectInfo.tokenURIPrefix ){
          metadataURI = stakingInfo?.projectInfo.tokenURIPrefix + portfolioInfo[idx].tokenURI
        }
        metaData = await getReq(metadataURI)
      } else {
        metaData.image = portfolioInfo[idx].tokenURI
        if ( stakingInfo?.projectInfo.imageURIPrefix ){
          metaData.image = stakingInfo?.projectInfo.imageURIPrefix + portfolioInfo[idx].tokenURI
        }
      }
      
      if (metaData?.image ) {
        metaData.image = metaData.image.replace("ipfs://", "https://ipfs.io/ipfs/")
        portList[idx] = {
          metaData: metaData,
          tokenID: portfolioInfo[idx].tokenID,
          tokenURI: portfolioInfo[idx].tokenURI
        }
        setNFTInfoList([...portList])
      }
    }
  }

  useEffect(() => {
    if (nftDepositInfo.length !== 0) {
      getMetaData(nftDepositInfo)
    }
  }, [nftDepositInfo])


  let approval = useNFTApprovalStatus(stakingInfo?.collection, stakingInfo?.stakingRewardAddress)
  // track and parse user input
  // const [typedValue, setTypedValue] = useState('')
  // const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo.stakedAmount.token, userLiquidityUnstaked)
  // const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  // let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  // if (parsedAmountWrapped?.greaterThan('0')) {
  //   hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
  //     stakingInfo.stakedAmount.add(parsedAmountWrapped),
  //     stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
  //     stakingInfo.totalRewardRate
  //   )
  // }
  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // approval data for stake
  // const deadline = useTransactionDeadline()
  // const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)

  // const isArgentWallet = useIsArgentWallet()
  const stakingContract = useNFTStakingContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    setAttempting(true)
    if (stakingContract) {
      if (approval === ApprovalState.APPROVED && radioValue !== 'null') {
        if (stakingInfo?.interactionInfo?.payable) {
        var withdrawFee = stakingInfo?.interactionInfo?.withdrawFee ? JSBI.BigInt(stakingInfo?.interactionInfo?.withdrawFee * Math.pow(10, 18)).toString() : 0
        stakingContract
          .withdrawNFT(parseInt(radioValue), {value: withdrawFee})
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw NFT`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
        } else {
          stakingContract
          .withdrawNFT(parseInt(radioValue))
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw NFT`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
        }
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  // const onUserInput = useCallback((typedValue: string) => {
  //   setSignatureData(null)
  //   setTypedValue(typedValue)
  // }, [])

  // used for max input button
  // const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  // const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  // const handleMax = useCallback(() => {
  //   maxAmountInput && onUserInput(maxAmountInput.toExact())
  // }, [maxAmountInput, onUserInput])

  const [radioValue, setRadioValue] = React.useState('null');

  const handleChangeNft = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (isOpen === false) {
      setRadioValue('null')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <StyledFormControl>
            <GlobalStyles
              styles={{
                '*::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '*::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                  backgroundColor: '#5b5eb1',
                  outline: '1px solid #5b5eb1',
                  borderRadius: '12px'
                }
              }}
            />
            <RadioGroup
              row aria-label="gender"
              name="row-radio-buttons-group"
              value={radioValue}
              onChange={handleChangeNft}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {nftInfoList.map(nftInfo => {
                  return (
                    <Grid item xs={4}>
                      <PoolData style={{ padding: '0.5rem' }}>
                        <ImageListItem key={nftInfo.tokenID}>
                          <CustomWidthTooltip title={nftInfo.metaData.name} placement="right">
                            <Styledimg src={nftInfo.metaData.image} alt={iamge} loading="lazy" />
                          </CustomWidthTooltip>
                          <StyledImageListItemBar
                            position="below"
                            actionIcon={
                              <StyleFormControlLabel value={nftInfo.tokenID} control={<RadioStyle id={nftInfo.tokenID} />} label={`#${nftInfo.tokenID}`} />
                            }
                          />
                        </ImageListItem>
                      </PoolData>
                    </Grid>
                  )
                })}
              </Grid>
            </RadioGroup>
          </StyledFormControl>
          <RowBetween>
            <ButtonError
              disabled={(approval !== ApprovalState.APPROVED) || radioValue === 'null'}
              // error={!!error && !!parsedAmount}
              onClick={onWithdraw}
            >
              {'Withdraw'}
            </ButtonError>
          </RowBetween>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Withdrawing NFT</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              #{radioValue} {stakingInfo?.collection.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Withdrew #{radioValue} {stakingInfo?.collection.symbol}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
