import { JSBI, TokenAmount } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { CRX, UNI } from '../../constants/'
import { useActiveWeb3React } from '../../hooks'
import { useCrowdTicketContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../multicall/hooks'


export const CROWD_TICKET_CONTRACT_ADDRESSES = "0x2008A0d549FDdA2D9eD91e62996303E7B469F96E"

export enum IDOTicketState {
    BRONZE,
    SILVER,
    GOLD
}

export interface CrowdTicketInfo {
    bronzeMintPrice: TokenAmount | undefined
    silverMintPrice: TokenAmount | undefined
    goldMintPrice: TokenAmount | undefined
    bronzeBalance: Array<any> | undefined
    silverBalance: Array<any> | undefined
    goldBalance: Array<any> | undefined
}

// gets the staking info from the network for the active chain id
export function useCrowdTicketInfo(): CrowdTicketInfo {

    const { chainId, account } = useActiveWeb3React()

    const uni = chainId ? UNI[chainId] : undefined
    
    const crowdTicketContract = useCrowdTicketContract(CROWD_TICKET_CONTRACT_ADDRESSES)

    const mintPrices = useSingleCallResult(crowdTicketContract, 'mintPrices')

    const bronzeBalance = useSingleCallResult(crowdTicketContract, 'tokensOf', [account ?? undefined, IDOTicketState.BRONZE])
    const silverBalance = useSingleCallResult(crowdTicketContract, 'tokensOf', [account ?? undefined, IDOTicketState.SILVER])
    const goldBalance = useSingleCallResult(crowdTicketContract, 'tokensOf', [account ?? undefined, IDOTicketState.GOLD])

    return useMemo(() => {

        let memo : CrowdTicketInfo = {
            bronzeMintPrice: undefined,
            silverMintPrice: undefined,
            goldMintPrice: undefined,
            bronzeBalance: undefined,
            silverBalance: undefined,
            goldBalance: undefined
        }

        if (!chainId || !uni) return memo
        else {
            if (
                !mintPrices?.loading &&
                !bronzeBalance?.loading &&
                !silverBalance?.loading &&
                !goldBalance?.loading
            ){
                if (
                    mintPrices?.error ||
                    bronzeBalance?.error ||
                    silverBalance?.error ||
                    goldBalance?.error
                ) {
                    console.error('Failed to load IDO info')
                    return memo
                }

                memo.bronzeMintPrice = new TokenAmount(CRX, JSBI.BigInt(mintPrices?.result?.[0][IDOTicketState.BRONZE] ?? 0))
                memo.silverMintPrice = new TokenAmount(CRX, JSBI.BigInt(mintPrices?.result?.[0][IDOTicketState.SILVER] ?? 0))
                memo.goldMintPrice = new TokenAmount(CRX, JSBI.BigInt(mintPrices?.result?.[0][IDOTicketState.GOLD] ?? 0))
                memo.bronzeBalance = bronzeBalance?.result?.[0]
                memo.silverBalance = silverBalance?.result?.[0]
                memo.goldBalance = goldBalance?.result?.[0]

                return memo
            }
            return memo
        }   
    }, [chainId, uni, bronzeBalance, silverBalance, goldBalance, mintPrices])
}