import * as React from 'react'
import { LightCard } from '../../components/Card'
import styled from 'styled-components'
import image from '../../assets/images/crxArtifact.png'
import { TYPE } from '../../theme'
import Grid from '@mui/material/Grid'
import { Break } from '../../components/nftEarn/styled'
import { ButtonPrimary } from '../../components/Button'
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CurrencyLogo from '../../components/CurrencyLogo'
import { METAVERSEHUB, NATIVE_CRO } from '../../constants/index'
import { MHUB_TOKEN_CONTRACT_ADDRESSES, useMHUBTokenInfo } from '../../state/mhub/token'
import { useMHubTokenContract } from '../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { isMobile } from 'react-device-detect'
import { BarWave } from 'react-cssfx-loading'

// const BorderLinearProgress = styled(LinearProgress)(() => ({
//   height: '8px !important',
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: '#eeeeeeb8'
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: '#5b5eb1'
//   }
// }))

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: #1f1243cc;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`
const IMG = styled.img`
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  width: 200px;
`

export default function HUB() {
  const mhubInfo = useMHUBTokenInfo()

  const mhubContract = useMHubTokenContract(MHUB_TOKEN_CONTRACT_ADDRESSES)
  const addTransaction = useTransactionAdder()

  async function onClaim() {
    if (mhubContract) {
      // setAttempting(true)
      await mhubContract
        .withdrawDividend()
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `MHUB dividend accumulated wCRO rewards`
          })
          // setHash(response.hash)
        })
        .catch((error: any) => {
          // setAttempting(false)
          console.log(error)
        })
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={9} xs={12} sx={{ display: 'flex' }}>

          <Cards >
            <Grid container spacing={0}>
              <Grid item xs={isMobile ? 12 : 8} >
                <TYPE.white fontWeight={600} fontSize={30} style={{ marginLeft: '20px' }}>
                  Welcome to the Metaverse Hub (2.0)
                </TYPE.white>
                <TYPE.white
                  fontWeight={500}
                  fontSize={14}
                  style={{ textAlign: 'justify', marginLeft: '20px', marginTop: '16px', lineHeight: '21px' }}
                >
                  CRX Metaverse Hub is the place of resort for NFT, Metaverse and GameFi enthusiasts on Cronos.<br></br>
                  The local inhabitants of CRX Metaverse are Crxillions and its currency is <b>$MHUB</b>.<br></br>
                  Crxillions are members of a limited group of felines known as the Crxillion Club, the original NFT collection of CRODEX.<br></br>
                  While the Hub has been hosting numerous NFT collections on Cronos,
                  this new iteration of the Hub kicks off with a series of adventures Crxillions have.<br></br>
                  You can join the Crxillion Club and take part in the adventures by purchasing a Crxillion on <a style={{ color: 'white' }} target="_blank" href="https://app.ebisusbay.com/collection/crxillion">Secondary Market</a>.
                  <br></br>

                  Local currency of the Metaverse Hub is <b>$MHUB</b>.
                </TYPE.white>
              </Grid>
              {!isMobile && (
                <Grid item xs={4} margin={'auto'}>
                  <IMG src={image} alt="crx" style={{ width: 200, height: 184 }} />
                </Grid>
              )}
            </Grid>
          </Cards>
        </Grid>


        <Grid item sm={3} xs={12} sx={{ display: 'flex' }}>
          <Cards>

            <TYPE.white
              fontWeight={600}
              fontSize={24}
              style={{ textAlign: 'center', margin: 'auto', marginTop: '16px' }}
            >
               MHUB Dividends
            </TYPE.white>

            <Break style={{ width: '80%', margin: '16px auto auto' }} />

            <Grid>


              {!mhubInfo?.balance && (
                <BarWave style={{ minHeight: '200px', margin: '20px auto' }} color="#5b5eb1"></BarWave>
              )}

              {mhubInfo?.balance && (
                <List sx={{ width: '100%', maxWidth: 320, bgcolor: 'transparent', margin: 'auto' }}>
                  <ListItem style={{ textAlign: 'center' }}>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <TYPE.white fontWeight={600} fontSize={16} color={'#ffffffa1'}>
                          MHUB in Wallet
                        </TYPE.white>
                      }
                      secondary={
                        <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'center' }}>
                          <CurrencyLogo currency={METAVERSEHUB} size={'24px'} style={{ marginRight: '8px' }}></CurrencyLogo>
                          <TYPE.white fontWeight={600} fontSize={20}>
                            {mhubInfo?.balance?.toFixed(2, { groupSeparator: ',' })}
                          </TYPE.white>
                        </div>
                      }
                    />
                  </ListItem>

                  <ListItem style={{ textAlign: 'center' }}>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <TYPE.white fontWeight={600} fontSize={16} color={'#ffffffa1'}>
                          Total Claimed wCRO
                        </TYPE.white>
                      }
                      secondary={
                        <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'center' }}>
                          <CurrencyLogo currency={NATIVE_CRO} size={'24px'} style={{ marginRight: '8px' }}></CurrencyLogo>
                          <TYPE.white fontWeight={600} fontSize={20}>
                            {mhubInfo?.totalClaimed?.toFixed(2, { groupSeparator: ',' })}
                          </TYPE.white>
                        </div>
                      }
                    />
                  </ListItem>

                  <ListItem style={{ textAlign: 'center' }}>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <TYPE.white fontWeight={600} fontSize={16} color={'#ffffffa1'}>
                          wCRO to Claim
                        </TYPE.white>
                      }
                      secondary={
                        <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'center' }}>
                          <CurrencyLogo currency={NATIVE_CRO} size={'24px'} style={{ marginRight: '8px' }}></CurrencyLogo>
                          <TYPE.white fontWeight={600} fontSize={20}>
                            {mhubInfo?.withdrawableDividend?.toFixed(2, { groupSeparator: ',' })}
                          </TYPE.white>
                        </div>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ButtonPrimary
                      className="select-button"
                      style={{
                        borderRadius: '12px',
                        fontSize: '14px'
                      }}
                      onClick={onClaim}
                    >
                      Claim Dividend
                    </ButtonPrimary>
                  </ListItem>
                </List>


              )}
            </Grid>

          </Cards>
        </Grid>
      </Grid>
    </>
  )
}
