import * as React from 'react'
import styledComp from 'styled-components'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Audit from './audit'
import TelegramIcon from '@mui/icons-material/Telegram'
import TwitterIcon from '@mui/icons-material/Twitter'
import DiscordIcon from '../../../src/assets/images/discord1.png'
import MediumIcon from '../../../src/assets/images/medium.png'
import GithubIcon from '../../../src/assets/images/github1.png'
import { ExternalLink } from '../../theme'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import Fade from '@mui/material/Fade'
import LINK from '../../assets/images/link.png'
import Menus from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

const StyledDivider = styled(Divider)`
  border-color: #333460 !important;
`

const StyledAppBar = styledComp(AppBar)`
background-color: #221b46a1!important;
backdrop-filter: blur(20px);
position:fixed!important;
top:auto!important;

 bottom:0;
 ${({ theme }) => theme.mediaWidth.upToMedium`
 display:none!important;
`}
.MuiAppBar-root{
  background-color: #221b46a1!important;
  backdrop-filter: blur(20px);
`
const StyledListItemText = styledComp(ListItemText)`
font-family: "Inter var", sans-serif;
.MuiTypography-root{
  font-family: "Inter var", sans-serif;
}
`

const MenuItemExternal = styledComp(ExternalLink)`
    align-items: left;
    border-radius: 3rem;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: white;
    ext-align: center;
    align-items: center;
    margin: auto 10px;
  `
const StyledToolbar = styledComp(Toolbar)`
  min-height: 50px!important;
  `
const StyledMenuButton = styledComp.button`
  display: flex;
  align-items: left;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color:  white;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  background-color: transparent;

  :hover,
  :focus {
    color:  white;
  }

  svg {
    margin-top: 2px;
  }
`
const StyledTallMenus = styledComp(Menus)`
.MuiPaper-root{ 
  background-color:#0d0c208f;
  color:rgb(160,255,219);
  border-radius:15px;
  backdrop-filter: blur(20px);
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 30px 0px;
    border: 1px solid rgb(51, 52, 96);
    top: calc(100% - 560px)!important;
}
`
export default function BottomAppBar() {
  const [anchorElLinks, setAnchorElLinks] = React.useState<null | HTMLElement>(null)
  const [openLinks, setOpenLinks] = React.useState(false)
  const handleClickLink = (event: React.MouseEvent<HTMLElement>) => {
    if (openLinks === false) {
      setAnchorElLinks(event.currentTarget)
      setOpenLinks(true)
    } else {
      setOpenLinks(false)
      setAnchorElLinks(null)
    }
  }

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <StyledMenuButton onClick={handleClickLink}>
            <img src={LINK} style={{ width: '20px', height: '20px', margin: 'auto', marginBottom: '5px' }}></img>
          </StyledMenuButton>
          <Box sx={{ flexGrow: 1 }} />
          <Audit />
          <Box sx={{ flexGrow: 1 }} />
          <MenuItemExternal id={`stake-nav-link`} href={'https://t.me/crodexofficial'}>
            <TelegramIcon />
          </MenuItemExternal>
          <MenuItemExternal id={`stake-nav-link`} href={'https://twitter.com/crodexapp'}>
            <TwitterIcon />
          </MenuItemExternal>
          <MenuItemExternal id={`stake-nav-link`} href={'https://discord.com/invite/xSCzBRYGy4'}>
            <img src={DiscordIcon} style={{ width: '24px', height: '24px' }} alt="logo" />
          </MenuItemExternal>
          <MenuItemExternal id={`stake-nav-link`} href={'https://crodex.medium.com/'}>
            <img src={MediumIcon} style={{ width: '23px', height: '23px' }} alt="logo" />
          </MenuItemExternal>
          <MenuItemExternal id={`stake-nav-link`} href={'https://github.com/CrodexApp'}>
            <img src={GithubIcon} style={{ width: '24px', height: '24px' }} alt="logo" />
          </MenuItemExternal>
        </StyledToolbar>
      </StyledAppBar>
      <StyledTallMenus
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorElLinks}
        open={openLinks}
        onClose={handleClickLink}
        TransitionComponent={Fade}
      >
        <List component="div" disablePadding>
          {/* <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://charts.crodex.app'}>
              <StyledListItemText primary="Chart" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider /> */}
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://auditor.crodex.app/'}>
              <StyledListItemText primary="Auditor" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider />
          {/* <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://hibiki.finance/locker'}>
              <StyledListItemText primary="Locker" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider /> */}
          {/* <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://info.crodex.app/'}>
              <StyledListItemText primary="Analytics" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider /> */}
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://app.relaychain.com/'}>
              <StyledListItemText primary="Bridge - Relay" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider />
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://anyswap.exchange/#/router'}>
              <StyledListItemText primary="Bridge - Any" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider />
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/SEwMUmrYjbwyvChh9'}>
              <StyledListItemText primary="Form - Token WL" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider />
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/gAJiCLk5o7fwr6wW8'}>
              <StyledListItemText primary="Form - CROWD IDO" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider />
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/ASJjuH3mYjbU2eeH6'}>
              <StyledListItemText primary="Form - Metaverse Hub" />
            </MenuItemExternal>
          </ListItem>
          <StyledDivider />
          <ListItem sx={{ pl: 4 }}>
            <MenuItemExternal id={`stake-nav-link`} href={'https://forms.gle/FeV7rQKTeZaQyAza9'}>
              <StyledListItemText primary="Form - Ambassador" />
            </MenuItemExternal>
          </ListItem>
        </List>
      </StyledTallMenus>
    </>
  )
}
