import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH } from '@cronosdex/sdk'
import { useMemo } from 'react'
import { CRX, UNI, CRONIC, CROKING, DARK, COUGAR, ROLLIUM, LOOTNETWORK, TREASURE, CRONOSVERSE } from '../../constants'
import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1633636800

export const REWARDS_DURATION_DAYS = 28

export enum SINGLE_STAKE_TYPES {
  LEGACY,
  RENEWABLE
}

export enum SS_POOL_STATUS_TYPES {
  FINISHED,
  HALTED,
  LIVE,
  NEW
}


const REWARDS = [
  ////////////////////////////
  ///// ROUND 0 REWARDS /////
  //////////////////////////
  {
    token: CRX,
    stakingRewardAddress: '0x2EBbcf9912327510064Ae9c06F3018B6f940b80c',
    rewardToken: CRX,
    round: 0,
    type: SINGLE_STAKE_TYPES.LEGACY
  },


  ////////////////////////////
  ///// ROUND 1 REWARDS /////
  //////////////////////////
  {
    token: CRX,
    stakingRewardAddress: '0x7856ccDDb71b3EB816A6F732a4BcBd83329F8abD',
    rewardToken: CRX,
    round: 1,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRX,
    stakingRewardAddress: '0x67FDCA7FC9b72F7986B10940A096D744FaC3AD92',
    rewardToken: WETH[ChainId.CRONOSMAINNET],
    round: 1,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRONIC,
    stakingRewardAddress: '0x2e4fe976420206B9E621153fAED2B8673f20Fe78',
    rewardToken: CRX,
    round: 1,
    type: SINGLE_STAKE_TYPES.LEGACY
  },


  ////////////////////////////
  ///// ROUND 2 REWARDS /////
  //////////////////////////
  {
    token: CRX,
    stakingRewardAddress: '0xCD034379D8b10e7E86487029f4C527178937cc54',
    rewardToken: CRX,
    round: 2,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRX,
    stakingRewardAddress: '0xA10CAf06B1310b2ea56d0EB78F8BfBdbbF466A18',
    rewardToken: CROKING,
    round: 2,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CROKING,
    stakingRewardAddress: '0xC4c972f3f88B513e8dc55aD8aCc4269F3e1E1077',
    rewardToken: CRX,
    round: 2,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  
  ////////////////////////////
  ///// ROUND 3 REWARDS /////
  //////////////////////////
  {
    token: CRX,
    stakingRewardAddress: '0x1EF0415A9CddfE75220d827E0cb7368283dfdD6c',
    rewardToken: CRX,
    round: 3,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRX,
    stakingRewardAddress: '0xED2f5C5C9E8a359D09778863C1ce2237a07DD106',
    rewardToken: CROKING,
    round: 3,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRONIC,
    stakingRewardAddress: '0x26612248f6f1334BFeF4107B4e8A19318a407e62',
    rewardToken: CRX,
    round: 3,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CROKING,
    stakingRewardAddress: '0xDe1137506BDc53F900fDE4bb2f232E6238180daC',
    rewardToken: CRX,
    round: 3,
    type: SINGLE_STAKE_TYPES.LEGACY
  },

  ////////////////////////////
  ///// ROUND 4 REWARDS /////
  //////////////////////////
  {
    token: CRX,
    stakingRewardAddress: '0x7f4813604c15369049CA9a4FD8b70F1eb4F8Fb6A',
    rewardToken: CRX,
    round: 4,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRX,
    stakingRewardAddress: '0xe21BAB3E2c4F6BC7233B8E5F787C431c9846Cf30',
    rewardToken: DARK,
    round: 4,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRX,
    stakingRewardAddress: '0x76B15D707bbC7698Fb376DD196F5D3030B29AAc7',
    rewardToken: COUGAR,
    round: 4,
    type: SINGLE_STAKE_TYPES.LEGACY
  },


  ////////////////////////////
  ///// ROUND 5 REWARDS /////
  //////////////////////////
  {
    token: CRX,
    stakingRewardAddress: '0x5E533B08Df00Fe4193F8d511e0D3C467abAC33A9',
    rewardToken: ROLLIUM,
    round: 5,
    type: SINGLE_STAKE_TYPES.LEGACY
  },
  {
    token: CRONOSVERSE,
    stakingRewardAddress: '0x34a55c2Ba7f1028045Dff51eAF1c568831fB1141',
    rewardToken: CRONOSVERSE,
    round: 5,
    type: SINGLE_STAKE_TYPES.RENEWABLE
  },
  {
    token: LOOTNETWORK,
    stakingRewardAddress: '0xBF7F5eA5D5f5b3202B4c52ED294375EC2688c0B3',
    rewardToken: TREASURE,
    round: 6,
    type: SINGLE_STAKE_TYPES.RENEWABLE
  }


  /////////////////////////
  ///// TEST REWARDS /////
  ///////////////////////
  // {
  //   token: CRX,
  //   stakingRewardAddress: '0x8CfcDEaB43B0f8233BDE9Bd1601F8c3409dd06B2',
  //   rewardToken: WETH[ChainId.CRONOSMAINNET],
  //   round: 2
  // },
]

// TODO add staking rewards addresses h ere
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    token: Token
    stakingRewardAddress: string
    rewardToken: Token
    round: number
    type: SINGLE_STAKE_TYPES
  }[]
} = {
  [ChainId.CRONOSMAINNET]: REWARDS
}

export interface SingleStakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  token: Token
  // the reward tokens
  rewardToken: Token
  // round info
  round: number
  // type
  type: SINGLE_STAKE_TYPES
  // pool status
  poolStatus: SS_POOL_STATUS_TYPES
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

// gets the staking info from the network for the active chain id
export function useSingleStakingInfo(rewardToFilterBy?: string | null): SingleStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(
          stakingRewardInfo =>
            (rewardToFilterBy === undefined) ? true :
            (rewardToFilterBy === stakingRewardInfo.stakingRewardAddress) // rewardToFilterBy.equals(stakingRewardInfo.token)
        ) ?? []
        : [],
    [chainId, rewardToFilterBy]
  )

  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<SingleStakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const token = info[index].token
        // const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(token, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            info[index].rewardToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()
        const isRewardEnded = new Date().getTime() > periodFinishMs ? true : false

        let poolStatus;
        if ( info[index].type === SINGLE_STAKE_TYPES.LEGACY )
          poolStatus = SS_POOL_STATUS_TYPES.FINISHED
        else if ( isRewardEnded )
          poolStatus = SS_POOL_STATUS_TYPES.HALTED
        else
          poolStatus = SS_POOL_STATUS_TYPES.LIVE

        memo.push({
          stakingRewardAddress: rewardsAddress,
          token: info[index].token,
          rewardToken: info[index].rewardToken,
          round: info[index].round,
          type: info[index].type,
          poolStatus: poolStatus,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined
  const stakingInfos = useSingleStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
