import React from 'react'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import { ButtonPrimary } from '../Button'
import { useColor } from '../../hooks/useColor'
import { Break } from './styled'
import CurrencyLogo from '../CurrencyLogo'
import { IDOInfo, IDOState } from '../../state/ido/hooks'
import { RowBetween } from '../Row'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: #1f1243cc;
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  box-shadow: ${({ theme, showBackground }) =>
    showBackground ? 'inset #F1C232  -1px -1px 6px 3px' : 'inset #dde7e77d -1px -1px 3px 2px'};
  border: ${({ theme, showBackground }) => (showBackground ? '1.5px solid #F1C232' : '1.5px solid #0d0c20')};
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.8')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function IDOCard({ idoInfo }: { idoInfo: IDOInfo }) {
  const backgroundColor = useColor(idoInfo?.token)
  return (
    <Wrapper showBackground={idoInfo?.isContributing} bgColor={backgroundColor}>
      <TopSection>
        <CurrencyLogo currency={idoInfo?.token} size={'48px'} />
        <div>
          <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
            {idoInfo?.name}
          </TYPE.white>
          <TYPE.white fontWeight={600} fontSize={16} style={{ marginLeft: '8px' }}>
            {`${idoInfo?.startDate.toUTCString()}`}
          </TYPE.white>
        </div>

        <StyledInternalLink to={`/ido/${idoInfo?.contractAddress}`} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {idoInfo?.cardButtonText}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>

        { idoInfo?.softCap && idoInfo?.isSoftEnabled ? (
          <RowBetween>
          <TYPE.white> Soft Cap</TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            <CurrencyLogo style={{ marginRight: '3px' }} currency={idoInfo?.contributionToken} size={'20px'} />
            {`${idoInfo?.softCap.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
          </TYPE.white>
        </RowBetween>
        ):
        (
          <RowBetween>
          <TYPE.white> Target </TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            {`${idoInfo?.hardCap.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
            <CurrencyLogo style={{ marginLeft: '3px' }} currency={idoInfo?.contributionToken} size={'20px'} />
          </TYPE.white>
        </RowBetween>
        )}
        

        <RowBetween>
          <TYPE.white> Min Contribution</TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            {`${idoInfo?.minContribution.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
            <CurrencyLogo style={{ marginLeft: '3px' }} currency={idoInfo?.contributionToken} size={'20px'} />
          </TYPE.white>
        </RowBetween>

        <RowBetween>
          <TYPE.white> Max Contribution</TYPE.white>
          <TYPE.white style={{ display: 'flex' }}>
            {`${idoInfo?.maxContribution.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.contributionToken.symbol}`}
            <CurrencyLogo style={{ marginLeft: '3px' }} currency={idoInfo?.contributionToken} size={'20px'} />
          </TYPE.white>
        </RowBetween>

        {idoInfo?.status !== IDOState.UPCOMING && idoInfo?.status !== IDOState.LIVE && idoInfo?.isContributing && (
          <RowBetween>
            <TYPE.white> Claimable Token Percentage </TYPE.white>
            <TYPE.white style={{ display: 'flex' }}>{`${idoInfo?.claimablePercent}%`}</TYPE.white>
          </RowBetween>
        )}
      </StatContainer>

      {idoInfo?.isContributing && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.white fontWeight={500}>Your Total Reserve</TYPE.white>

            <TYPE.white style={{ textAlign: 'right', display: 'flex' }} fontWeight={500}>
              {`${idoInfo?.amountContributed.toFixed(2, { groupSeparator: ',' })} ${idoInfo?.token.symbol}`}
              <CurrencyLogo style={{ marginLeft: '5px' }} currency={idoInfo?.token} size={'20px'} />
            </TYPE.white>
          </BottomSection>
        </>
      )}

      <Break />

      <BottomSection showBackground={true}>
        <TYPE.white style={{ margin: 'auto', textAlign: 'right', display: 'flex' }} fontWeight={500}>
          {idoInfo?.statusText}
        </TYPE.white>
      </BottomSection>
    </Wrapper>
  )
}
