import React, { useState } from 'react'
import styled from 'styled-components'
import { TYPE } from '../../../theme'
import { LightCard } from '../../Card'
import { Break } from '../MintNFT/styled'
import CurrencyLogo from '../../CurrencyLogo'
import { METAVERSEHUB, WCRO } from '../../../constants'
import { CRXILLION, SPACE_CRXILLIONS } from '../../../constants/collections'
import { RowBetween } from '../../Row'
import { ButtonPrimary } from '../../Button'
import { Hypnosis } from 'react-cssfx-loading'
import Grid from '@mui/material/Grid';
import './index.css'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { useCrxillionCannotJoinBefore, useFightAdventure, useFightAdventureAttackHistory, useSpaceCrxillionCannotJoinBefore } from '../../../state/mhub/adventures/fightAdventure'
import { useNFTPortfolio, useNFTUserDeposits } from '../../../state/nftPortfolio/hooks'
import FightAdventureDepositModal from './FightAdventureDepositModal'
import { CRXILLION_INFO, SPACE_CRXILLION_INFO } from '../../../state/nftStake/projects'
import FightAdventureClaimModal from './FightAdventureClaimModal'
import FightAdventureWithdrawModal from './FightAdventureWithdrawModal'
import FightAdventureAttackModal from './FightAdventureAttackModal'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Versus from "../../../assets/images/versus2.png"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FightAdventureBattleHistoryModal from './FightAdventureBattleHistoryModal'
import ButtonGroup from '@mui/material/ButtonGroup';
import FightAdventureStoryModal from './FightAdventureStoryModal'
import FightAdventureMechanicsModal from './FightAdventureMechanicsModal'
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});


// const IconWrapper = styled.div<{ size?: number }>`
//   ${({ theme }) => theme.flexColumnNoWrap};
//   align-items: center;
//   justify-content: center;
//   margin-right: 8px;
//   & > img,
//   span {
//     height: ${({ size }) => (size ? size + 'px' : '32px')};
//     width: ${({ size }) => (size ? size + 'px' : '32px')};
//   }
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     align-items: flex-end;
//   `};
// `


const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
`

const Cards = styled(LightCard)`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  opacity: 1;
  background: #1f1243cc;
  color: #ffffff !important;
  box-shadow: inset #dde7e77d -1px -1px 3px 2px;
  border: 1.5px solid #0d0c20;
`
const StyledListItemText = styled(ListItemText)`
.MuiTypography-body1{
    height: 2.5rem;
    font-weight: 500;
    font-size: 2rem;
    font-family: "Inter var", sans-serif;
    @media (max-width: 650px) {
        font-size: 1.7rem;
      }
}
.MuiTypography-body2{
    font-size: 0.8rem;
    color :#c9c5c5;
    word-break: break-all;
    display:initial;
    font-family: "Inter var", sans-serif;
    @media (max-width: 650px) {
        font-size: 0.7rem;
        display:none;
      }
}
`

const MuiAvatar = styled(ListItemAvatar)`
.MuiAvatar-root{
    width:60px; 
    height:60px;
}
`

const StyledButon = styled(ButtonPrimary)`    
    background: #2d2e68;
    width:100%;
    box-shadow: inset #dde7e77d -1px -1px 3px 2px;
    border: 1.5px solid #0d0c20;
    max-width:300px;
    padding:8px;
    margin-bottom: 16px;
    height: 50px;
    &:disabled {
        background-color: #262558;
        color: #6C7284;
        border: 1.5px solid #0d0c20;
      }
`
const StyledAvatar = styled(Avatar)`
    margin:auto;
    width:60px; 
    height:60px;
`
const VersusImage = styled.img`
    width:80px; 
    height:90px;
    @media (max-width: 650px) {
        width:60px; 
        height:60px;
      }
`
const StyledGrid = styled(Grid)`
    padding:inherit;

`

const Title = styled(TYPE.white)`   
    font-size: 1rem;
    color :#c9c5c5!important;
    display:initial;
    font-family: "Inter var", sans-serif;
    @media (max-width: 650px) {
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
`

const InfoButton = styled(ButtonPrimary)`
    background: linear-gradient(321deg,#0d0c20 13%,#221b46 78%);
    border-radius: 12px;
    box-shadow: inset #9475bc7d 0px 1px 2px 1px;
    max-width: 300px;
    width: 100%;
    margin: auto;
`

// const GlobalInfoButton = styled(ButtonPrimary)`
//     background: linear-gradient(321deg,#0d0c20 13%,#221b46 78%);
//     border-radius: 12px;
//     box-shadow: inset #9475bc7d 0px 1px 2px 1px;
//     max-width: 150px;
//     width: 100%;
//     padding: 5px;
//     margin-top: 5px;
// `

const CustomButton = styled(ButtonPrimary)`
    margin: 0;
    padding:8px;
    width: 50%;
    font-size: 13px;
    &:disabled {
        background-color: #262558;
        color: #6C7284;
        border: 1px solid #5b5eb1;
      }

`
export default function FightAdventure() {
    const adventureInfo = useFightAdventure()

    const crxillionPortfolio = useNFTPortfolio(CRXILLION)
    const spaceCrxillionPortfolio = useNFTPortfolio(SPACE_CRXILLIONS)

    const userCrxillionDeposits = useNFTUserDeposits(CRXILLION, adventureInfo?.crxillionIDs ?? [])
    const userSpaceCrxillionDeposits = useNFTUserDeposits(SPACE_CRXILLIONS, adventureInfo?.spaceCrxillionIDs ?? [])

    const [showCrxillionDepositModal, setShowCrxillionDepositModal] = useState(false)
    const [showSpaceCrxillionDepositModal, setShowSpaceCrxillionDepositModal] = useState(false)

    const [showCrxillionWithdrawModal, setShowCrxillionWithdrawModal] = useState(false)
    const [showSpaceCrxillionWithdrawModal, setShowSpaceCrxillionWithdrawModal] = useState(false)

    const [showCrxillionAttackModal, setShowCrxillionAttackModal] = useState(false)
    const [showSpaceCrxillionAttackModal, setShowSpaceCrxillionAttackModal] = useState(false)

    const [showBattleHistoryDefenceModal, setShowBattleHistoryDefenceModal] = useState(false)
    const [showBattleHistoryAttackModal, setShowBattleHistoryAttackModal] = useState(false)
    const [showBattleHistoryGlobalModal, setShowBattleHistoryGlobalModal] = useState(false)

    const attackHistory = useFightAdventureAttackHistory(adventureInfo?.userAttackHistory ?? [])
    const defendHistory = useFightAdventureAttackHistory(adventureInfo?.userDefendHistory ?? [])
    const globalHistory = useFightAdventureAttackHistory(adventureInfo?.globalBattleHistory ?? [])

    const spaceCrxillionJoinable = useSpaceCrxillionCannotJoinBefore(spaceCrxillionPortfolio ?? [])
    const crxillionJoinable = useCrxillionCannotJoinBefore(crxillionPortfolio ?? [])

    const [showStoryModal, setShowStoryModal] = useState(false)
    const [showMechanicsModal, setShowMechanicsModal] = useState(false)

    const [showClaimModal, setShowClaimModal] = useState(false)

    const isLoading = crxillionPortfolio && spaceCrxillionPortfolio && adventureInfo?.totalAttackFee !== undefined ? false : true

    // const isStaking = ((adventureInfo?.crxillionAmount !== undefined && adventureInfo?.spaceCrxillionAmount !== undefined) && (adventureInfo?.crxillionAmount > 0 || adventureInfo?.spaceCrxillionAmount > 0)) ? true : false
    const hasCrxillion = crxillionPortfolio?.length > 0
    const hasSpaceCrxillion = spaceCrxillionPortfolio?.length > 0
    const hasFightingCrxillion = adventureInfo?.crxillionAmount !== undefined && adventureInfo?.crxillionAmount > 0
    const hasFightingSpaceCrxillion = adventureInfo?.spaceCrxillionAmount !== undefined && adventureInfo?.spaceCrxillionAmount > 0

    const curDate = new Date(Date.now())

    let cannotAttack = false;
    let countDownTitle: string | undefined = undefined
    let countDownDate: Date | undefined = undefined
    if (adventureInfo?.globallyCannotAttackBefore && adventureInfo?.cannotAttackBefore) {
        if (curDate < adventureInfo?.cannotAttackBefore) {
            cannotAttack = true
            countDownTitle = "You can't attack until"
            countDownDate = adventureInfo?.cannotAttackBefore
        }
        else if (curDate < adventureInfo?.globallyCannotAttackBefore) {
            cannotAttack = true
            countDownTitle = "Noone can attack until"
            countDownDate = adventureInfo?.globallyCannotAttackBefore
        }
    }

    return (
        <div style={{ maxWidth: '600px', margin: 'auto' }}>

            <FightAdventureStoryModal isOpen={showStoryModal} onDismiss={() => setShowStoryModal(false)} />

            <FightAdventureMechanicsModal
                isOpen={showMechanicsModal}
                onDismiss={() => setShowMechanicsModal(false)}
            />

            <FightAdventureClaimModal
                isOpen={showClaimModal}
                adventureInfo={adventureInfo}
                onDismiss={() => setShowClaimModal(false)}
            />

            <FightAdventureDepositModal
                isOpen={showCrxillionDepositModal}
                onDismiss={() => setShowCrxillionDepositModal(false)}
                collection={CRXILLION}
                projectInfo={CRXILLION_INFO}
                portfolioInfo={crxillionPortfolio}
                joinableInfo={crxillionJoinable}
            />

            <FightAdventureDepositModal
                isOpen={showSpaceCrxillionDepositModal}
                onDismiss={() => setShowSpaceCrxillionDepositModal(false)}
                collection={SPACE_CRXILLIONS}
                projectInfo={SPACE_CRXILLION_INFO}
                portfolioInfo={spaceCrxillionPortfolio}
                joinableInfo={spaceCrxillionJoinable}

            />

            <FightAdventureWithdrawModal
                isOpen={showCrxillionWithdrawModal}
                onDismiss={() => setShowCrxillionWithdrawModal(false)}
                collection={CRXILLION}
                projectInfo={CRXILLION_INFO}
                nftDepositInfo={userCrxillionDeposits}
            />

            <FightAdventureWithdrawModal
                isOpen={showSpaceCrxillionWithdrawModal}
                onDismiss={() => setShowSpaceCrxillionWithdrawModal(false)}
                collection={SPACE_CRXILLIONS}
                projectInfo={SPACE_CRXILLION_INFO}
                nftDepositInfo={userSpaceCrxillionDeposits}
            />

            <FightAdventureAttackModal
                isOpen={showCrxillionAttackModal}
                onDismiss={() => setShowCrxillionAttackModal(false)}
                adventureInfo={adventureInfo}
                collection={CRXILLION}
                projectInfo={CRXILLION_INFO}
                nftDepositInfo={userCrxillionDeposits}
            />

            <FightAdventureAttackModal
                isOpen={showSpaceCrxillionAttackModal}
                onDismiss={() => setShowSpaceCrxillionAttackModal(false)}
                adventureInfo={adventureInfo}
                collection={SPACE_CRXILLIONS}
                projectInfo={SPACE_CRXILLION_INFO}
                nftDepositInfo={userSpaceCrxillionDeposits}
            />

            <FightAdventureBattleHistoryModal
                isGlobal={false}
                isOpen={showBattleHistoryAttackModal}
                onDismiss={() => setShowBattleHistoryAttackModal(false)}
                isAttack={true}
                historyList={attackHistory}
            />

            <FightAdventureBattleHistoryModal
                isGlobal={false}
                isOpen={showBattleHistoryDefenceModal}
                onDismiss={() => setShowBattleHistoryDefenceModal(false)}
                isAttack={false}
                historyList={defendHistory}
            />

            <FightAdventureBattleHistoryModal
                isGlobal={true}
                isOpen={showBattleHistoryGlobalModal}
                onDismiss={() => setShowBattleHistoryGlobalModal(false)}
                isAttack={true}
                historyList={globalHistory}
            />

            <Cards>


                <RowBetween align="center">
                    <ButtonPrimary
                        style={{ background: 'transparent', border: 'none', boxShadow: 'rgb(91 95 177) 0px 0px 12px' }}
                        height="100%"
                        padding="8px"
                        onClick={() => setShowStoryModal(true)}
                    >
                        <>
                            <CurrencyLogo currency={CRXILLION} size={'48px'} ></CurrencyLogo>
                            <CurrencyLogo currency={SPACE_CRXILLIONS} size={'48px'} ></CurrencyLogo>

                            <TYPE.white fontWeight={600} fontSize={24} style={{ textAlign: 'center', margin: 'auto' }}>
                                Skirmishes
                            </TYPE.white>
                            <a className="play-btn"></a>
                        </>
                    </ButtonPrimary>
                </RowBetween>

                {adventureInfo?.isEvent && (
                    <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.6rem' }}>
                        <TYPE.white
                            fontWeight={500} fontSize={16} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}>
                            🚨 Frenzy Event Live 🚨
                        </TYPE.white>
                    </div>
                )}


                <Break style={{ width: '100%', margin: '16px auto auto' }} />

                {isLoading && (
                    <div style={{ display: 'flex', height: '200px', alignItems: 'center' }}>
                        <Hypnosis style={{ margin: '20px auto' }} color="#5b5eb1"></Hypnosis>
                    </div>
                )}

                {!isLoading && (
                    <>
                        <ThemeProvider theme={theme}>
                            <StatContainer>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <ListItem>
                                            <StyledListItemText
                                                primary={adventureInfo?.crxillionCount?.toString()}
                                                style={{ textAlign: 'end', margin: 'auto 0.5rem' }} />
                                            <MuiAvatar>
                                                <StyledAvatar>
                                                    <CurrencyLogo currency={CRXILLION} size={'60px'} />
                                                </StyledAvatar>
                                            </MuiAvatar>
                                        </ListItem>

                                        <div style={{ textAlign: 'center', marginBottom: '4px', height: '35px' }}>
                                            <Title> Crxillion Squad</Title>
                                        </div>

                                        <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ width: '100%' }}>
                                            <CustomButton
                                                borderRadius="8px 0 0 8px"
                                                onClick={() => setShowCrxillionDepositModal(true)}
                                                disabled={!hasCrxillion}
                                            >
                                                Join
                                            </CustomButton>
                                            <CustomButton
                                                borderRadius="0 8px 8px 0"
                                                padding="12px 12px 12px 18px"
                                                margin="6px"
                                                width="fit-content"
                                                disabled={!hasFightingCrxillion}
                                                onClick={() => setShowCrxillionWithdrawModal(true)}
                                            >
                                                My Squad
                                            </CustomButton>

                                        </ButtonGroup>

                                    </Grid>
                                    <StyledGrid item xs={2}>
                                        <VersusImage src={Versus} alt='versus' />
                                    </StyledGrid>
                                    <Grid item xs={5}>
                                        <ListItem>
                                            <MuiAvatar>
                                                <StyledAvatar>
                                                    <CurrencyLogo currency={SPACE_CRXILLIONS} size={'60px'} />
                                                </StyledAvatar>
                                            </MuiAvatar>
                                            <StyledListItemText
                                                primary={adventureInfo?.spaceCrxillionCount?.toString()}
                                                style={{ margin: 'auto 0.5rem' }} />
                                        </ListItem>

                                        <div style={{ textAlign: 'center', marginBottom: '4px', height: '35px' }}>
                                            <Title> Space Crxillion Squad</Title>
                                        </div>

                                        <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ width: '100%' }}>
                                            <CustomButton
                                                borderRadius="8px 0 0 8px"
                                                onClick={() => setShowSpaceCrxillionDepositModal(true)}
                                                disabled={!hasSpaceCrxillion}>
                                                Join
                                            </CustomButton>

                                            <CustomButton
                                                borderRadius="0 8px 8px 0"
                                                padding="12px 12px 12px 18px"
                                                margin="6px"
                                                width="fit-content"
                                                disabled={!hasFightingSpaceCrxillion}
                                                onClick={() => setShowSpaceCrxillionWithdrawModal(true)}>
                                                My Squad
                                            </CustomButton>

                                        </ButtonGroup>

                                    </Grid>
                                </Grid>
                                {cannotAttack && (

                                    <StatContainer style={{ margin: 'auto', alignItems: 'center', marginTop: '10px' }}>
                                        <TYPE.white>{countDownTitle}</TYPE.white>
                                        <TYPE.white>
                                            {countDownDate?.toUTCString()}
                                        </TYPE.white>
                                    </StatContainer>
                                )}

                                <Break style={{ width: '80%', margin: '16px auto auto' }} />


                                <StatContainer>
                                    <Grid container spacing={1}>
                                        <Grid item sm={4} xs={12}>

                                            <StyledButon
                                                style={{ float: 'left' }}
                                                onClick={() => setShowCrxillionAttackModal(true)}
                                                disabled={cannotAttack || !hasFightingCrxillion}
                                            >
                                                Attack
                                                <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={CRXILLION} size={'20px'} />
                                            </StyledButon>
                                        </Grid>
                                        <Grid item sm={4} xs={12} style={{ textAlign: 'center' }}>
                                            <div>
                                                <TYPE.white> Reward Pool</TYPE.white>
                                                <TYPE.white style={{ fontSize: '2rem' }}>
                                                    {adventureInfo?.pendingReward?.toFixed(1)}
                                                    <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={METAVERSEHUB} size={'20px'} />
                                                </TYPE.white>
                                            </div>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <StyledButon
                                                style={{ float: 'right' }}
                                                onClick={() => setShowSpaceCrxillionAttackModal(true)}
                                                disabled={cannotAttack || !hasFightingSpaceCrxillion}
                                            >
                                                Attack
                                                <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={SPACE_CRXILLIONS} size={'20px'} />

                                            </StyledButon>
                                        </Grid>
                                    </Grid>
                                </StatContainer>

                                <Break style={{ width: '80%', margin: ' auto ' }} />

                                <StatContainer>
                                    <TYPE.white style={{ textAlign: 'center' }}>Skirmish History </TYPE.white>
                                    <Grid container spacing={1}>
                                        <Grid item sm={4} xs={12}>
                                            <InfoButton
                                                borderRadius="8px"
                                                padding="12px"
                                                width="100%"
                                                onClick={() => setShowBattleHistoryAttackModal(true)}
                                            >
                                                Your Att. ⚔️
                                            </InfoButton>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <InfoButton
                                                borderRadius="8px"
                                                padding="12px"
                                                width="inherit"
                                                onClick={() => setShowBattleHistoryDefenceModal(true)}
                                            >
                                                Your Def. 🛡️

                                            </InfoButton>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <InfoButton
                                                borderRadius="8px"
                                                padding="12px"
                                                width="inherit"
                                                onClick={() => setShowBattleHistoryGlobalModal(true)}
                                            >
                                                Global 📜
                                            </InfoButton>
                                        </Grid>
                                    </Grid>
                                </StatContainer>

                            </StatContainer>
                        </ThemeProvider>

                        <Break style={{ width: '80%', margin: '16px auto auto' }} />
                        <StatContainer>

                            <RowBetween>
                                <TYPE.white> Total Claimed Loot  </TYPE.white>
                                <TYPE.white style={{ display: 'flex' }}>
                                    {adventureInfo?.totalClaimedLoot?.toFixed(2)}
                                    <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={WCRO} size={'20px'} />
                                </TYPE.white>
                            </RowBetween>
                            <RowBetween>
                                <TYPE.white> Pending Loot </TYPE.white>

                                <ButtonPrimary
                                    width="fit-content"
                                    padding="6px 12px 6px 12px"
                                    borderRadius="8px"
                                    onClick={() => setShowClaimModal(true)}
                                >
                                    <TYPE.white style={{ display: 'flex' }}>
                                        Claim {adventureInfo?.lootBalanceOf?.toFixed(2)}
                                        <CurrencyLogo style={{ marginLeft: '0.5rem' }} currency={WCRO} size={'20px'} />
                                    </TYPE.white>
                                </ButtonPrimary>
                            </RowBetween>

                        </StatContainer>

                        <Break style={{ marginTop: '1rem' }}></Break>
                    </>
                )}

                <RowBetween style={{ display: 'flex' }}>

                </RowBetween>

                <div style={{ textAlign: 'center', margin: 'auto', marginTop: '0.5rem' }}>
                    <SettingsSuggestRoundedIcon
                        sx={{ marginRight: '0.2rem', fontSize: 18, color: 'white', display: 'inline-block', verticalAlign: 'middle' }}
                    />
                    <TYPE.white onClick={() => setShowMechanicsModal(true)}
                        fontWeight={500} fontSize={14} style={{ textAlign: 'center', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer' }}>
                        Read the mechanics of the adventure here
                    </TYPE.white>
                </div>
            </Cards>
        </div>
    )
}
