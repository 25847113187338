import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH } from '@cronosdex/sdk'
import { useMemo } from 'react'
import {
    RHOS,
    RHOS_CRO_VVS_LP,
    UNI,
} from '../../constants'
import { BOOSTED_STAKING_REWARDS_INTERFACE } from '../../constants/abis/boosted-staking-rewards'
import { SPACE_CRXILLIONS } from '../../constants/collections'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import {  ProjectInfo, SPACE_CRXILLION_INFO } from '../nftStake/projects'
import { SINGLE_STAKE_TYPES, SS_POOL_STATUS_TYPES } from '../singleStake/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1633636800

export const REWARDS_DURATION_DAYS = 28


interface INTERACTION_INFO {
    payable: boolean,
    depositFee: number | undefined,
    withdrawFee: number | undefined,
    claimFee: number | undefined
}


// TODO add staking rewards addresses h ere
export const OTHER_LP_BOOST_STAKING_REWARDS_INFO: {
    [chainId in ChainId]?: {
        collection: Token
        projectInfo: ProjectInfo
        pairTokens: [Token, Token]
        token: Token
        stakingRewardAddress: string
        maxBoosterCount: number
        boostDiv: number
        interactionInfo: INTERACTION_INFO
        rewardToken: Token
        round: number
        type: SINGLE_STAKE_TYPES
    }[]
} = {
    [ChainId.CRONOSMAINNET]: [
        {
            collection: SPACE_CRXILLIONS,
            projectInfo: SPACE_CRXILLION_INFO,
            pairTokens: [ RHOS, WETH[ChainId.CRONOSMAINNET] ],
            token: RHOS_CRO_VVS_LP,
            stakingRewardAddress: '0xb3d18C5Aac5a958B97404678D95A0b2324824F00',
            interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
            maxBoosterCount: 0,
            boostDiv: 1000,
            rewardToken: RHOS,
            round: 6,
            type: SINGLE_STAKE_TYPES.RENEWABLE
        },
    ]
}

export interface OtherLPBoostStakingInfo {
    // collection
    collection: Token
    // project info 
    projectInfo: ProjectInfo
    // the address of the reward contract
    stakingRewardAddress: string
    // max booster count
    maxBoosterCount: number
    // boost permillage
    boostPermillage: number
    // boost divider
    boostDiv: number
    // boosters 
    boosters: any[]
    // the tokens involved in this pair
    pairTokens: [Token, Token]
    // the tokens involved in this pair
    token: Token
    // interaction info
    interactionInfo: INTERACTION_INFO
    // the reward tokens
    rewardToken: Token
    // round info
    round: number
    // type
    type: SINGLE_STAKE_TYPES
    // pool status
    poolStatus: SS_POOL_STATUS_TYPES
    // the amount of token currently staked, or undefined if no account
    stakedAmount: TokenAmount
    // the amount of reward token earned by the active account, or undefined if no account
    earnedAmount: TokenAmount
    // earned from boost
    earnedFromBoostAmount: TokenAmount
    // the total amount of token staked in the contract
    totalStakedAmount: TokenAmount
    // the amount of token distributed per second to all LPs, constant
    totalRewardRate: TokenAmount
    // the current amount of token distributed to the active account per second.
    // equivalent to percent of total supply * reward rate
    rewardRate: TokenAmount
    // user share
    userShare: number
    // when the period ends
    periodFinish: Date | undefined
    // calculates a hypothetical amount of token distributed to the active account per second.
    getHypotheticalRewardRate: (
        stakedAmount: TokenAmount,
        totalStakedAmount: TokenAmount,
        totalRewardRate: TokenAmount
    ) => TokenAmount
}

// gets the staking info from the network for the active chain id
export function useOtherLPBoostStakingInfo(rewardToFilterBy?: string | null): OtherLPBoostStakingInfo[] {
    const { chainId, account } = useActiveWeb3React()
    const info = useMemo(
        () =>
            chainId
                ? OTHER_LP_BOOST_STAKING_REWARDS_INFO[chainId]?.filter(
                    stakingRewardInfo =>
                        (rewardToFilterBy === undefined) ? true :
                            (rewardToFilterBy === stakingRewardInfo.stakingRewardAddress) // rewardToFilterBy.equals(stakingRewardInfo.token)
                ) ?? []
                : [],
        [chainId, rewardToFilterBy]
    )

    const uni = chainId ? UNI[chainId] : undefined

    const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

    const accountArg = useMemo(() => [account ?? undefined], [account])

    // get all the info from the staking rewards contracts
    const balances = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
    const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'earned', accountArg)
    const totalSupplies = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'totalSupply')

    const boosters = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'boostersOf', accountArg)
    const earnedFromBoostAmounts = useMultipleContractSingleData(rewardsAddresses, BOOSTED_STAKING_REWARDS_INTERFACE, 'earnedFromBoost', accountArg)


    // constants
    const boostPermillage = useMultipleContractSingleData(
        rewardsAddresses,
        BOOSTED_STAKING_REWARDS_INTERFACE,
        'boostPermillage',
        undefined,
        NEVER_RELOAD
    )
    // tokens per second
    const rewardRates = useMultipleContractSingleData(
        rewardsAddresses,
        BOOSTED_STAKING_REWARDS_INTERFACE,
        'rewardRate',
        undefined,
        NEVER_RELOAD
    )
    const periodFinishes = useMultipleContractSingleData(
        rewardsAddresses,
        BOOSTED_STAKING_REWARDS_INTERFACE,
        'periodFinish',
        undefined,
        NEVER_RELOAD
    )

    return useMemo(() => {
        if (!chainId || !uni) return []

        return rewardsAddresses.reduce<OtherLPBoostStakingInfo[]>((memo, rewardsAddress, index) => {
            // these two are dependent on account
            const balanceState = balances[index]
            const earnedAmountState = earnedAmounts[index]

            // these get fetched regardless of account
            const totalSupplyState = totalSupplies[index]
            const rewardRateState = rewardRates[index]
            const periodFinishState = periodFinishes[index]

            const boostersState = boosters[index]
            const earnedFromBoostAmountState = earnedFromBoostAmounts[index]
            const boostPermillageState = boostPermillage[index]

            if (
                // these may be undefined if not logged in
                !balanceState?.loading &&
                !earnedAmountState?.loading &&
                !earnedFromBoostAmountState?.loading &&
                !boostersState?.loading &&
                // always need these
                totalSupplyState &&
                !totalSupplyState.loading &&
                rewardRateState &&
                !rewardRateState.loading &&
                periodFinishState &&
                !periodFinishState.loading &&
                boostPermillageState &&
                !boostPermillageState.loading
            ) {
                if (
                    balanceState?.error ||
                    earnedAmountState?.error ||
                    boostersState?.error ||
                    earnedFromBoostAmountState?.error ||
                    totalSupplyState.error ||
                    rewardRateState.error ||
                    periodFinishState.error ||
                    boostPermillageState.error
                ) {
                    console.error('Failed to load staking rewards info')
                    return memo
                }

                // get the LP token
                const stakingToken = info[index].token

                // const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

                // check for account, if no account set to 0

                const stakedAmount = new TokenAmount(stakingToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
                const totalStakedAmount = new TokenAmount(stakingToken, JSBI.BigInt(totalSupplyState?.result?.[0]))
                const totalRewardRate = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardRateState?.result?.[0]))

                // booster variables
                const boosters = boostersState?.result?.[0] ?? []
                const maxBoosterCount = info[index].maxBoosterCount
                const boostPermillage = parseInt(boostPermillageState?.result?.[0])
                const boostDiv = info[index].boostDiv

                const getHypotheticalRewardRate = (
                    stakedAmount: TokenAmount,
                    totalStakedAmount: TokenAmount,
                    totalRewardRate: TokenAmount
                ): TokenAmount => {
                    return new TokenAmount(
                        info[index].rewardToken,
                        JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
                            ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
                            : JSBI.BigInt(0)
                    )
                }

                const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

                const periodFinishMs = periodFinishState?.result?.[0]?.mul(1000)?.toNumber()
                const userShare = totalStakedAmount.greaterThan("0") ? parseFloat(stakedAmount?.divide(totalStakedAmount).toFixed(6)) : 0

                const isRewardEnded = new Date().getTime() > periodFinishMs ? true : false


                let poolStatus;
                if (isRewardEnded)
                    poolStatus = SS_POOL_STATUS_TYPES.HALTED
                else
                    poolStatus = SS_POOL_STATUS_TYPES.LIVE

                memo.push({
                    collection: info[index].collection,
                    projectInfo: info[index].projectInfo,
                    stakingRewardAddress: rewardsAddress,
                    maxBoosterCount: maxBoosterCount,
                    boostPermillage: boostPermillage,
                    boostDiv: boostDiv,
                    boosters: boosters,
                    interactionInfo: info[index].interactionInfo,
                    pairTokens: info[index].pairTokens,
                    token: info[index].token,
                    rewardToken: info[index].rewardToken,
                    round: info[index].round,
                    type: info[index].type,
                    poolStatus: poolStatus,
                    periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
                    earnedAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
                    earnedFromBoostAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedFromBoostAmountState?.result?.[0] ?? 0)),
                    rewardRate: individualRewardRate,
                    userShare: userShare,
                    totalRewardRate: totalRewardRate,
                    stakedAmount: stakedAmount,
                    totalStakedAmount: totalStakedAmount,
                    getHypotheticalRewardRate
                })
            }
            return memo
        }, [])
    }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni, boostPermillage, earnedFromBoostAmounts, boosters])
}

export function useTotalUniEarned(): TokenAmount | undefined {
    const { chainId } = useActiveWeb3React()
    const uni = chainId ? UNI[chainId] : undefined
    const stakingInfos = useOtherLPBoostStakingInfo()

    return useMemo(() => {
        if (!uni) return undefined
        return (
            stakingInfos?.reduce(
                (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
                new TokenAmount(uni, '0')
            ) ?? new TokenAmount(uni, '0')
        )
    }, [stakingInfos, uni])
}

// based on typed value
export function useSSBoostDerivedStakeInfo(
    typedValue: string,
    stakingToken: Token,
    userLiquidityUnstaked: TokenAmount | undefined
): {
    parsedAmount?: CurrencyAmount
    error?: string
} {
    const { account } = useActiveWeb3React()

    const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

    const parsedAmount =
        parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
            ? parsedInput
            : undefined

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!parsedAmount) {
        error = error ?? 'Enter an amount'
    }

    return {
        parsedAmount,
        error
    }
}

// based on typed value
export function useSSBoostDerivedUnstakeInfo(
    typedValue: string,
    stakingAmount: TokenAmount
): {
    parsedAmount?: CurrencyAmount
    error?: string
} {
    const { account } = useActiveWeb3React()

    const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

    const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!parsedAmount) {
        error = error ?? 'Enter an amount'
    }

    return {
        parsedAmount,
        error
    }
}
