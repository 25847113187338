/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../../Modal'
import { AutoColumn } from '../../Column'
import styled from 'styled-components'
import { RowBetween } from '../../Row'
import { TYPE, CloseIcon } from '../../../theme'
import { ButtonError } from '../../Button'
import { DataCard } from '../../earn/styled'
import { JSBI, Token } from '@cronosdex/sdk'

import { useFightAdventureContract } from '../../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../../ModalViews'

import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import iamge from '../../../assets/svg/262.png'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { NFTPortfolioInfo } from '../../../state/nftPortfolio/hooks'
import GlobalStyles from '@mui/material/GlobalStyles'
import Checkbox from '@mui/material/Checkbox'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { ProjectInfo } from '../../../state/nftStake/projects'
import { FightAdventureInfo, FIGHT_ADVENTURE_CONTRACT_ADDRESS, powerOfCollection } from '../../../state/mhub/adventures/fightAdventure'


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
        zIndex: 1203
    }
})

const ErrorSection = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`


const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const StyledImageListItemBar = styled(ImageListItemBar)`
  .MuiImageListItemBar-titleWrap {
    display: none;
  }
  .MuiImageListItemBar-actionIcon {
    background: #46499333;
    width: 100%;
    text-align: center;
  }
`

const Styledimg = styled.img`
  width: 75px;
  height: 75px;
  margin: auto;
  display: flex;
`

const CheckboxStyle = styled(Checkbox)`
  color: ${({ theme }) => theme.bg3}!important;
  &.Mui-checked {
    color: ${({ theme }) => theme.bg3}!important;
  }
  &.Mui-disabled {
    color: #6c7284!important;
  }
`

const StyleFormControlLabel = styled(FormControlLabel)`
  margin-right: 0 !important;
  .MuiFormControlLabel-label.Mui-disabled {
    color: #6c7284!important;
}
`

const StyledFormControl = styled(FormControl)`
  max-height: 330px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 0px 20px !important;
`

interface FightAdventureAttackModalProps {
    isOpen: boolean
    onDismiss: () => void
    adventureInfo: FightAdventureInfo
    collection: Token
    projectInfo: ProjectInfo
    nftDepositInfo: NFTPortfolioInfo[]
}

interface NFTInfo {
    tokenID: string
    tokenURI: string
    metaData: any
}


interface Metadata {
    image: string
    name: string
}

const MAX_COUNT = 1;
export default function FightAdventureAttackModal({
    isOpen,
    onDismiss,
    adventureInfo,
    collection,
    projectInfo,
    nftDepositInfo,
}: FightAdventureAttackModalProps) {
    // const { chainId } = useActiveWeb3React()
    const [nftInfoList, setNFTInfoList] = useState<NFTInfo[]>([])
    const [allSelectedNft, setAllSelectedNft] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState(false)
    const [previousPortfolio, setPreviousPortfolio] = useState<NFTPortfolioInfo[]>([])
    const [update, setUpdate] = React.useState(false)
    const [allCheck, setAllCheck] = React.useState(false)

    const [metadataFail, setMetadataFail] = React.useState(false)
    async function getReq(url: string): Promise<Metadata> {
        return fetch(url)
            .then(async response => {
                const tokenData = await response.text()
                const metaData = JSON.parse(tokenData)
                metaData.image = metaData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
                return metaData
            })
            .catch(error => {
                console.error('Error:', error)
                return null
            })
    }


    async function getMetaData(portfolioInfo: NFTPortfolioInfo[], operation: string) {
        if (operation === 'getData') {
            const portList = []
            for (let idx = 0; idx < portfolioInfo.length; idx++) {
                let metaData: Metadata | undefined = undefined

                if (portfolioInfo[idx].tokenURI !== 'undefined') {
                    if (projectInfo.hasMetadata) {
                        let metadataURI = portfolioInfo[idx].tokenURI
                        if (projectInfo.tokenURIPrefix) {
                            metadataURI = projectInfo.tokenURIPrefix + portfolioInfo[idx].tokenURI
                        }
                        metaData = await getReq(metadataURI)
                    } else {
                        metaData = { image: '', name: '' }
                        metaData.image = portfolioInfo[idx]?.tokenURI
                        if (projectInfo.imageURIPrefix) {
                            metaData.image = projectInfo.imageURIPrefix + portfolioInfo[idx].tokenURI
                        }
                    }
                }
                if (metaData) {
                    metaData.image = metaData.image.replace("ipfs://", "https://ipfs.io/ipfs/")
                    portList[idx] = {
                        metaData: metaData,
                        tokenID: portfolioInfo[idx].tokenID,
                        tokenURI: portfolioInfo[idx].tokenURI,
                    }
                    setNFTInfoList([...portList])
                    setUpdate(!update)
                } else {
                    portList[idx] = {
                        metaData: { image: "https://swap.crodex.app/ant.jpg", name: 'Placeholder' },
                        tokenID: portfolioInfo[idx].tokenID,
                        tokenURI: portfolioInfo[idx].tokenURI,
                    }
                    setMetadataFail(true)
                    setNFTInfoList([...portList])
                    setUpdate(!update)
                }
            }
        } else if (operation === 'updateData') {
            setUpdate(!update)
        }
    }

    useEffect(() => {
        if (nftDepositInfo.length === 0) {
            setLoading(false)
        }
        else if (nftDepositInfo.length !== 0 && isOpen === true && nftDepositInfo[0].tokenURI !== undefined) {
            setLoading(false)
            if (previousPortfolio !== undefined) {
                if (previousPortfolio.length !== nftDepositInfo.length) {
                    getMetaData(nftDepositInfo, 'getData')
                    setPreviousPortfolio(nftDepositInfo)
                }
            } else {
                setPreviousPortfolio(nftDepositInfo)
            }
        } else if (nftDepositInfo[0]?.tokenURI === undefined) {
            setLoading(true)
        }
    }, [nftDepositInfo, isOpen, previousPortfolio])

    const addTransaction = useTransactionAdder()
    const [attempting, setAttempting] = useState<boolean>(false)
    const [hash, setHash] = useState<string | undefined>()
    const wrappedOnDismiss = useCallback(() => {
        setHash(undefined)
        setAttempting(false)
        onDismiss()
    }, [onDismiss])

    const adventureContract = useFightAdventureContract(FIGHT_ADVENTURE_CONTRACT_ADDRESS)

    const handleChangeNft = (event: React.ChangeEvent<HTMLInputElement>) => {
        const initialChecked = allSelectedNft

        if ((event.target as HTMLInputElement).checked === true) {
            initialChecked?.push((event.target as HTMLInputElement).value as never)
            if (allCheck === false && initialChecked.length === nftDepositInfo.length) {
                setAllCheck(true)
            }
        } else if ((event.target as HTMLInputElement).checked === false) {
            initialChecked.forEach((element, index) => {
                if (element === (event.target as HTMLInputElement).value) {
                    initialChecked.splice(index, 1)
                }
                if (allCheck === true) {
                    setAllCheck(false)
                }
            })
        }
        setAllSelectedNft(initialChecked)
        getMetaData(nftDepositInfo, 'updateData')
    }

    useEffect(() => {
        if (isOpen === false) {
            setAllSelectedNft([])
            setAllCheck(false)
        }
    }, [isOpen])


    async function onStake() {
        setAttempting(true)
        if (adventureContract && adventureInfo?.totalAttackFee) {
            if (allSelectedNft.length !== 0) {
                const numberSelectedNFT = allSelectedNft.map(i => Number(i))
                const serviceFeeRes = JSBI.BigInt(parseFloat(adventureInfo?.totalAttackFee.toExact()) * Math.pow(10, 18)).toString()

                const estimatedGas = await adventureContract.estimateGas['attack'](numberSelectedNFT[0], collection.address, { value: serviceFeeRes }).then(estimatedGasLimit => { return estimatedGasLimit })
                const estimatedGasLimit = estimatedGas.mul(120).div(100).toString()
                adventureContract
                    .attack(numberSelectedNFT[0], collection.address, { value: serviceFeeRes, gasLimit: estimatedGasLimit })
                    .then((response: TransactionResponse) => {
                        addTransaction(response, {
                            summary: `Attack with ${collection.symbol}`
                        })
                        setHash(response.hash)
                    })
                    .catch((error: any) => {
                        setAttempting(false)
                        console.log(error)
                    })
            } else {
                setAttempting(false)
                throw new Error('Attempting to stake without approval or a signature. Please contact support.')
            }
        }
    }

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            {!attempting && !hash && (
                <ContentWrapper gap="lg">
                    <RowBetween>
                        <TYPE.mediumHeader>Attacking with {collection.symbol}</TYPE.mediumHeader>
                        <CloseIcon onClick={wrappedOnDismiss} />
                    </RowBetween>
                    <StyledFormControl>
                        <GlobalStyles
                            styles={{
                                '*::-webkit-scrollbar': {
                                    width: '0.4em'
                                },
                                '*::-webkit-scrollbar-track': {
                                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                                },
                                '*::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#5b5eb1',
                                    outline: '1px solid #5b5eb1',
                                    borderRadius: '12px'
                                }
                            }}
                        />
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '800ms' : '0ms'
                            }}
                            unmountOnExit
                        >
                            <LinearProgress style={{ margin: '15px' }} color="secondary" />
                        </Fade>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {nftInfoList.map(nftInfo => {
                                return (
                                    <Grid item xs={4}>
                                        <PoolData style={{ padding: '0.5rem' }}>
                                            <ImageListItem key={nftInfo.tokenID}>
                                                <CustomWidthTooltip title={nftInfo.metaData.name} placement="right">
                                                    <Styledimg src={nftInfo.metaData.image} alt={iamge} loading="lazy" />
                                                </CustomWidthTooltip>
                                                <StyledImageListItemBar
                                                    position="below"
                                                    actionIcon={
                                                        <StyleFormControlLabel
                                                            value={nftInfo.tokenID}
                                                            control={
                                                                <CheckboxStyle
                                                                    id={nftInfo.tokenID}
                                                                    checked={allSelectedNft.some(element => element == nftInfo.tokenID)}
                                                                    value={nftInfo.tokenID}
                                                                    onChange={handleChangeNft}
                                                                    disabled={allSelectedNft.length === MAX_COUNT && !allSelectedNft.some(element => element == nftInfo.tokenID)}

                                                                />
                                                            }
                                                            label={`#${nftInfo.tokenID}`}
                                                        />
                                                    }
                                                />
                                            </ImageListItem>
                                            <TYPE.body fontSize={10} color={'#93871a'} style={{ margin: '3px auto' }}> 🎖️ {powerOfCollection(collection, nftInfo.tokenID).rank}</TYPE.body>
                                            <TYPE.body fontSize={10} color={'#959aaa'} style={{ margin: '3px auto' }}> ⚡ {powerOfCollection(collection, nftInfo.tokenID).low}-{powerOfCollection(collection, nftInfo.tokenID).high}</TYPE.body>                                        </PoolData>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </StyledFormControl>


                    {metadataFail && (
                        <ErrorSection dim={true}>
                            <TYPE.black fontSize={12} fontWeight={400}>NFT metadata failed to load.</TYPE.black>
                        </ErrorSection>
                    )}

                    <RowBetween>
                        <ButtonError
                            disabled={allSelectedNft.length === 0}
                            onClick={onStake}
                        >
                            Attack
                        </ButtonError>
                    </RowBetween>
                </ContentWrapper>
            )}
            {attempting && !hash && (
                <LoadingView onDismiss={wrappedOnDismiss}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.largeHeader>Attacking with</TYPE.largeHeader>
                        <TYPE.body fontSize={20}>
                            #{allSelectedNft.join(', ')} {collection.symbol}
                        </TYPE.body>
                    </AutoColumn>
                </LoadingView>
            )}
            {attempting && hash && (
                <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
                        <TYPE.body fontSize={20}>
                            Attack with #{allSelectedNft.join(', ')} {collection.symbol}
                        </TYPE.body>
                    </AutoColumn>
                </SubmittedView>
            )}
        </Modal>
    )
}

