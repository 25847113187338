import React, { useState, useCallback } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { JSBI, TokenAmount } from '@cronosdex/sdk'
// import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useBoostedStakingRewardsContract } from '../../hooks/useContract'
import { useDerivedStakeInfo } from '../../state/singleStake/hooks'

// import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { SSBoostStakingInfo } from '../../state/boostStake/SSBoost'
import WithdrawCurrencyInputPanel from '../WithdrawCurrencyInputPanel'



const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: SSBoostStakingInfo
  userLiquidityStaked: TokenAmount | undefined
}

export default function PartialTokenWithdrawModal({
  isOpen,
  onDismiss,
  stakingInfo,
  userLiquidityStaked
}: StakingModalProps) {
  //   const { chainId } = useActiveWeb3React()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo.stakedAmount.token, userLiquidityStaked)


  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // const isArgentWallet = useIsArgentWallet()
  const stakingContract = useBoostedStakingRewardsContract(stakingInfo.stakingRewardAddress)
  async function onWithdraw() {
    setAttempting(true)
    if (stakingContract && parsedAmount) {
      if (stakingInfo?.interactionInfo.payable) {
        const withdrawFee = stakingInfo?.interactionInfo?.withdrawFee
          ? JSBI.BigInt(
            stakingInfo?.interactionInfo?.withdrawFee * Math.pow(10, 18)
          ).toString()
          : 0
        stakingContract.withdraw(`0x${parsedAmount.raw.toString(16)}`, { value: withdrawFee })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw token`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      }
      else {
        stakingContract.withdraw(`0x${parsedAmount.raw.toString(16)}`)
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw token`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      }
    }

  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityStaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <WithdrawCurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo.stakedAmount.token}
            disableCurrencySelect={true}
            customBalanceText={'Available to withdraw: '}
            id="partial-withdraw-token"
            withdrawableAmount={userLiquidityStaked}
          />

          <RowBetween>
            <ButtonError
              disabled={!!error}
              error={!!error && !!parsedAmount}
              onClick={onWithdraw}
            >
              {error ?? 'Withdraw'}
            </ButtonError>
          </RowBetween>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Withdrawing Tokens</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {parsedAmount?.toSignificant(4)} {stakingInfo?.token.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Withdrew {parsedAmount?.toSignificant(4)} {stakingInfo?.token.symbol}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
