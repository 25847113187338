import { useActiveWeb3React } from '../../hooks'
import { useTimestampFromBlock } from '../../hooks/useTimestampFromBlock'

export enum ADVENTURE_STATUS {
    WAITING_TO_START,
    LIVE,
    FINISHED
}

export function useAvgBlockTimeInSeconds(): number | undefined {
    const { library } = useActiveWeb3React()
    const margin = 20000
    const currentBlockNumber = library?.blockNumber ? library?.blockNumber : undefined
    const pastBlockNumber = currentBlockNumber ? currentBlockNumber - margin - 1 : undefined
    const currentTimestamp = useTimestampFromBlock(currentBlockNumber)
    const pastTimestamp = useTimestampFromBlock(pastBlockNumber)
    const diffTimestamp = pastTimestamp && currentTimestamp ? currentTimestamp - pastTimestamp : undefined
    const avgBlockTimeinSeconds = diffTimestamp ? diffTimestamp / margin : undefined
    return avgBlockTimeinSeconds
}