// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'https://swap.crodex.app/tokens.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const DEFAULT_HARDCODED_ZAPPER_INPUT_TOKEN_LIST = {
  name: 'ERC20 Token List',
  timestamp: '2021-07-08T07:24:15.408Z',
  version: {
    major: 1,
    minor: 9,
    patch: 29
  },
  tags: {},
  logoURI: 'https://swap.crodex.app/cro.png',
  keywords: ['Cronos', 'default'],
  tokens: [
    {
      name: 'Crodex Token',
      address: '0xe243CCab9E66E6cF1215376980811ddf1eb7F689',
      symbol: 'CRX',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/crx.png'
    },
    {
      name: 'Wrapped CRO',
      address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
      symbol: 'wCRO',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://swap.crodex.app/cro.png'
    },
    {
      name: 'USDC',
      address: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
      symbol: 'USDC',
      decimals: 6,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=014'
    },
    {
      name: "CroArmy",
      address: "0xb20115b8b176d72f077c4f93e95492870240a0b2",
      symbol: "ARMY",
      decimals: 18,
      chainId: 25,
      logoURI: "https://swap.crodex.app/ARMY.png"
    },
    {
      name: "ROLLIUM",
      address: "0x19258a1df9E929D02b34621CF52797998aE1Aa27",
      symbol: "RLM",
      decimals: 18,
      chainId: 25,
      logoURI: "https://marbleversecdn.ams3.digitaloceanspaces.com/MarbleVerse/RLM200x200.png"
    },
    {
      name: "CronosVerse",
      address: "0x2Ba01269EaFCe04c8DCCC4A9887884AB66E4Bcb1",
      symbol: "VRSE",
      decimals: 18,
      chainId: 25,
      logoURI: "https://swap.crodex.app/CRONOSVERSE.png"
    },
    {
      name: "Steak Token",
      address: "0x4137A9F6eb939a8d7d620b239B562596E48d6F41",
      symbol: "STEAK",
      decimals: 18,
      chainId: 25,
      logoURI: "https://swap.crodex.app/STEAK.png"
    }
  ]
}
